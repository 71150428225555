import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { googleSearchProduct } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "google";

// Async thunk for Google lens search
export const searchProduct = createAsyncThunk(
    `${namespace}/searchProduct`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await googleSearchProduct({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const googleSearch = createSlice({
    name: namespace,
    initialState: {
        googleSearchResult: [],
        googleSearchLoading: false,
        googleSearchSuccess: false,
        googleSearchError: false,
        googleSearchErrorMessage: "",
    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle get google lens records
            .addCase(searchProduct.pending, (state) => {
                state.googleSearchResult = [];
                state.googleSearchLoading = true;
                state.googleSearchSuccess = false;
                state.googleSearchError = false;
                state.googleSearchErrorMessage = "";
            })
            .addCase(searchProduct.fulfilled, (state, action) => {
                console.log(action, action.payload.data?.successfulResults, 'googleresponse');

                state.googleSearchResult = action.payload.data?.successfulResults;
                state.googleSearchSuccess = true;
                state.googleSearchLoading = false;
            })
            .addCase(searchProduct.rejected, (state, action) => {
                const payload = action.payload;
                state.googleSearchError = true;
                state.googleSearchErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;
                state.googleSearchLoading = false;
            })
    },
});

export const { resetSuccessFlag } = googleSearch.actions;

export const googleSearchSelector = (state) => state[namespace];

export default googleSearch.reducer;
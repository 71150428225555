import React, { Children } from 'react'
import { Modal } from 'antd'

const ModalComponent = ({open,title,onOk,onCancel,width,children}) => {
    return (
        <Modal
            title={title}
            centered
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            width={width}
            footer={null}
        >
           {children}
        </Modal>
    )
}

export default ModalComponent
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientDelete, clientList, createClient, editClient, getClientDataByID, updateClientStatus } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";


const namespace = "client";

// Async thunk for fetching the client list
export const getClientList = createAsyncThunk(
    `${namespace}/getClientList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await clientList(query, page, limit, sortby, order);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for delete client
export const deleteClient = createAsyncThunk(
    `${namespace}/clientDelete`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await clientDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);



// Async thunk for adding a staff
export const addClient = createAsyncThunk(
    `${namespace}/staff`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await createClient({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for fetching staff data by ID
export const getClientData = createAsyncThunk(
    `${namespace}/staffbyId`,
    async ({ id }, { rejectWithValue }) => {
        console.log(id,'client_id');
        try {
            const data = await getClientDataByID(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Update client Status
export const clientStatus = createAsyncThunk(
    `${namespace}/UpdateStatus`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await updateClientStatus({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating staff data
export const updateClient = createAsyncThunk(
    `${namespace}/UpdateCategory`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editClient({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


const clientSlice = createSlice({
    name: namespace,
    initialState: {
        clientListData: [],
        clientListLoading: false,
        addClientLoading: false,
        addClientSuccess: false,
        clientError: null,
        addedClient: null,
        getAddedData: [],
        getClientAddedDataLoading: false,
        updateData: [],
        updateClientSuccess: false,
        deleteClientLoading: false,
        deleteClientSuccess: false,
        deleteClientError: false,
        deleteClientErrorMessage: "",
        clientStatusLoading: false,
        clientStatusSuccess: false,
        clientStatusError: false,
        clientStatusErrorMessage: "",
    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle getClientList
            .addCase(getClientList.pending, (state) => {
                state.clientListLoading = true;
                state.addClientLoading = false;
                state.addClientSuccess = false;
                state.clientError = null;
                state.addedClient = null;
                state.getAddedData = [];
                state.updateData = [];
                state.updateClientSuccess = false;
                state.deleteClientLoading = false;
                state.deleteClientSuccess = false;
                state.deleteClientError = false;
                state.deleteClientErrorMessage = "";
                state.clientStatusLoading = false;
                state.clientStatusSuccess = false;
                state.clientStatusError = false;
                state.clientStatusErrorMessage = "";
            })
            .addCase(getClientList.fulfilled, (state, action) => {
                const data = action.payload;
                state.clientListData = data;
                state.clientListLoading = false;
                state.clientError = ''
            })
            .addCase(getClientList.rejected, (state,action) => {
                state.clientListLoading = false;
                // state.clientError = action?.payload?.data?.errors[0]?.message;
                state.clientError = action.payload.data.message;
            })

            // Update client status
            .addCase(clientStatus.pending, state => {
                state.clientStatusLoading = true;
                state.clientStatusSuccess = false;
                state.clientStatusError = false;
                state.clientStatusErrorMessage = "";
                state.deleteClientLoading = false;
                state.deleteClientSuccess = false;
                state.deleteClientError = false;
                state.deleteClientErrorMessage = "";
            })
            .addCase(clientStatus.fulfilled, (state, action) => {
                state.clientStatusSuccess = true;
                state.clientStatusLoading = false;
                state.clientError = ''
            })
            .addCase(clientStatus.rejected, (state, action) => {
                state.clientStatusError = true;
                state.clientStatusLoading = false;
                // state.clientStatusErrorMessage = action.error.message;
            })

            // Handle delete client
            .addCase(deleteClient.pending, state => {
                state.deleteClientLoading = true;
                state.deleteClientError = false;
                state.deleteClientSuccess = false;
                state.deleteClientErrorMessage = "";
                state.clientStatusLoading = false;
                state.clientStatusSuccess = false;
                state.clientStatusError = false;
                state.clientStatusErrorMessage = "";
            })
            .addCase(deleteClient.fulfilled, (state, action) => {
                state.deleteClientSuccess = true;
                state.deleteClientLoading = false;
                state.clientError = ''
            })
            .addCase(deleteClient.rejected, (state, action) => {
                state.deleteClientError = true;
                state.deleteClientLoading = false;
                // state.deleteClientErrorMessage = action.error.message;
            })


            // Handle addClient
            .addCase(addClient.pending, (state) => {
                state.addClientLoading = true;
                state.clientError = null;
                state.addedStaff = null;
                state.addClientSuccess = false;
            })
            .addCase(addClient.fulfilled, (state, action) => {
                state.addClientLoading = false;
                state.addedStaff = action.payload;
                state.addClientSuccess = true;
                state.clientError = ''
            })
            .addCase(addClient.rejected, (state, action) => {
                state.addClientLoading = false;
                // state.clientError = action.error.message;
                state.addClientSuccess = false;
                // state.clientError = action?.payload?.data?.errors[0]?.message;
                state.clientError = action.payload.data.message;
            })

            // Handle getClientData by ID
            .addCase(getClientData.pending, (state) => { 
                state.getClientAddedDataLoading = true;
            })
            .addCase(getClientData.fulfilled, (state, action) => {
                const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                state.getAddedData = data.response;
                state.clientError = '';
                state.getClientAddedDataLoading = false;
            })
            .addCase(getClientData.rejected, (state,action) => { 
                // state.clientError = action?.payload?.data?.errors[0]?.message;
                state.clientError = action.payload.data.message;
                state.getClientAddedDataLoading = false;

            })

            // Handle updateClient
            .addCase(updateClient.pending, (state) => {
                state.updateClientSuccess = false;
            })
            .addCase(updateClient.fulfilled, (state, action) => {
                state.updateData = action.payload;
                state.updateClientSuccess = true;
                state.clientError = ''
            })
            .addCase(updateClient.rejected, (state,action) => {
                state.updateClientSuccess = false;
                // state.clientError = action?.payload?.data?.errors[0]?.message;
                state.clientError = action.payload.data.message;
            });
    },
});

export const { resetSuccessFlag } = clientSlice.actions;

export const clientSelector = (state) => state[namespace];

export default clientSlice.reducer;

import React, { useEffect, useState } from 'react'
import ProjectCard from '../../fields/ProjectCard'
import CardComponent from '../../fields/CardComponent';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import { getPhotographerProjectData } from '../../store/reducer/photographerSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const PhotographerDashboard = () => {
    const { Option } = Select;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { getPhotographerProjectDatas } = useSelector((state) => state.photographer);
    const [selectedValue, setSelectedValue] = useState('scheduled')

    const userId = localStorage.getItem('user_id')
    const photographerId = localStorage.getItem('photographer_id')
    const jobStatus = [
        {
            label: 'Scheduled',
            value: 'scheduled'
        },
        {
            label: 'Ready to Edit/Build',
            value: 'ready_to_edit_or_build'
        },
        {
            label: 'Ready to QC',
            value: 'ready_to_qc'
        },
        {
            label: 'Ready to Deliver',
            value: 'ready_to_deliver'
        },
        {
            label: 'Delivered',
            value: 'delivered'
        }
    ]

    console.log(getPhotographerProjectDatas, 'getPhotographerProjectDatas');
    useEffect(() => {
        dispatch(getPhotographerProjectData({ id: photographerId, status: selectedValue }));
    }, [selectedValue])


    const onSearch = (value) => {
        console.log('search:', value);
    };

    return (
        <div id="full-view-components">
            <div className="row clearfix">
                <CardComponent>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                {/* <center><h5><b>{selectedValue === 1 ? "Assigned" : selectedValue === 2 ? "Completed" : "Cancelled"} Projects</b></h5></center> */}
                                <center><p><b>Welcome {localStorage.getItem("username")}</b><br />
                                    You have {`(${getPhotographerProjectDatas?.length})`} {selectedValue === 'scheduled' ? "Scheduled" : selectedValue === 'ready_to_edit_or_build' ? "Ready to Edit/Build" : selectedValue === 'ready_to_qc' ? "Ready to QC" : selectedValue === 'ready_to_deliver' ? "Ready to Deliver" : "Delivered"} TakeStock assignments.</p>
                                </center>
                            </div>
                            <div className="col-md-4 d-flex flex-end">
                                <Select
                                    name="status"
                                    value={selectedValue ? selectedValue : null}
                                    placeholder="Choose status"
                                    onChange={(value) => {
                                        setSelectedValue(value)
                                    }}
                                    onSearch={onSearch}
                                    options={jobStatus}
                                    style={{ width: '80%' }}
                                />
                            </div>
                        </div>
                    </div>
                    {getPhotographerProjectDatas && getPhotographerProjectDatas.length > 0 ?
                        <div className="photogarpher-dashboard">
                            {getPhotographerProjectDatas.map((project, index) => (
                                <ProjectCard
                                    key={index}
                                    projectName={project.job_name}
                                    status={'Due Date'}
                                    count={moment(project.due_date).format('MMMM D, YYYY, h:mm A')}
                                    link={`/photographer/jobs`}
                                    jobId={project?.id}
                                    photographerId={Number(photographerId)}
                                    gradientClass={index % 2 === 0 ? 'orange' : 'gray'}
                                />
                            ))}
                        </div> :
                        <div className="col-md-12 mt-4">
                            <div className="row">
                                <div className="col-md-4">

                                </div>
                                <div className="col-md-4">
                                    <h6><center>No Projects Found</center></h6>
                                </div>
                                <div className="col-md-4">

                                </div>
                            </div>
                        </div>
                    }

                </CardComponent>
            </div>
        </div>
    )
}

export default PhotographerDashboard
import { WarningFilled } from "@ant-design/icons";
import {
   Avatar,
   Button,
   Card,
   Col,
   Collapse,
   Divider,
   Empty,
   Flex,
   Image,
   Input,
   Pagination,
   Row,
   Select,
   Space,
   Tabs,
   Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { BiSolidFilePdf } from "react-icons/bi";
import { FaFileExport } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { assestURL } from "../../services/config";
import {
   executorClaim,
   executorClaimList,
   executorUserList,
} from "../../store/reducer/portalUserSlice";
import { TAG_COLOURS } from "../../utils/constants";
import PageHeader from "../../view/pageheader";
import PDFViewer from "./PDFViewer";
import { formatToDollars } from "../../utils/formatToDollars";
import Loader from "../../fields/loader/Loader";

function ClaimentForm({ sourceData, onAssignData, onAssign, isReadOnly }) {
   const [itemClaimReasons] = useState({
      1: "Pre-Marriage",
      2: "Gift",
      3: "Inherited",
      4: "Bought During Marriage",
      5: "Other - See Notes",
   });
   const { TextArea } = Input;

   return (
      <Collapse
         key={sourceData.item_id}
         // activeKey="1"
         size="small"
         items={[
            {
               key: 1,
               label: (
                  <span className="fw-semibold">
                     {sourceData.portalusers.display_name}
                     <i
                        className="fa fa-tag fa-lg pull-right"
                        style={{
                           color: TAG_COLOURS[sourceData.portalusers.icon_type],
                        }}
                        aria-hidden="true"
                     ></i>
                  </span>
               ),
               children: (
                  <Space direction="vertical" size={"small"} className="w-100">
                     <div>
                        <label className="mb-1">Estimated Value</label>
                        <Input
                           className=""
                           disabled
                           size="small"
                           value={formatToDollars(sourceData.estimated_value)}
                           placeholder="Estimated Value"
                        />
                     </div>
                     <div>
                        <label className="mb-1">Date of Purchase</label>
                        <Input
                           className=""
                           size="small"
                           disabled
                           value={
                              sourceData?.purchase_date
                                 ? new Date(sourceData.purchase_date).toLocaleDateString()
                                 : ""
                           }
                           placeholder="Date of Purchase"
                        />
                     </div>
                     <div>
                        <label className="mb-1">Reason for Claim</label>
                        <Input
                           className=""
                           size="small"
                           disabled
                           value={itemClaimReasons[sourceData.claimed_as] || ""}
                           placeholder="Reason for Claim"
                        />
                     </div>
                     <div>
                        <label className="mb-1">General Notes</label>
                        <TextArea
                           className=""
                           size="small"
                           disabled
                           value={sourceData.comment}
                           placeholder="General Notes"
                           style={{ resize: "none" }}
                           rows={2}
                           onChange={e => console.log(e.target.value)}
                        />
                     </div>
                     <div className="text-center">
                        <Button
                           shape="round"
                           type="primary"
                           size="small"
                           disabled={isReadOnly}
                           style={{
                              backgroundColor:
                                 onAssignData.portal_user_id === sourceData.portal_user_id
                                    ? "#6E6E6E"
                                    : "#f7931e",
                           }}
                           onClick={() =>
                              onAssign({
                                 ...onAssignData,
                                 item_id: sourceData.item_id,
                                 portal_user_id: sourceData.portal_user_id,
                              })
                           }
                        >
                           Assign
                        </Button>
                     </div>
                  </Space>
               ),
            },
         ]}
      ></Collapse>
   );
}

function EstateExecutorClaiment() {
   const dispatch = useDispatch();
   const { pathname } = useLocation();
   const { job_id, user_id } = useParams();
   const portalUserID = localStorage.getItem("client_id");
   const portalUserProjectID = localStorage.getItem("pu_project_id");
   const portalUserType = localStorage.getItem("pu_type");
   const [pageTitle, setPageTitle] = useState("Executor - Judge");
   const { executorUserListData, executorClaimListData, executorClaimSettleSuccess, executorClaimListDataLoading, executorUserListDataLoading } = useSelector(
      state => state.portalUser
   );
   const [nav1, setNav1] = useState(null);
   const [nav2, setNav2] = useState(null);
   const mainSliderRef = useRef(null);
   let sliderRef1 = useRef(null);
   let sliderRef2 = useRef(null);
   const [selectedMainImage, setSelectedMainImage] = useState(0);
   const [viewItems, setViewItems] = useState([]);
   const [selectedUser, setSelectedUser] = useState("");
   const [selectedDocument, setSelectedDocument] = useState({
      name: "",
      url: "",
   });
   const [claimList, setClaimList] = useState([]);
   const [mainItemsList, setMainItemsList] = useState([]);
   const [reportUrl, setReportUrl] = useState("");
   const [claimSettleData, setClaimSettleData] = useState({
      item_id: "",
      portal_user_id: "",
      executor_id: portalUserID,
      executor_judge_notes: "",
   });
   const [userList, setUserList] = useState([]);
   const [pageSize, setPageSize] = useState(10);
   const { TextArea } = Input;
   const { Title } = Typography;

   useEffect(() => {
      if (pathname.includes("/user/")) {
         setPageTitle("User");
      }
   }, []);

   useEffect(() => {
      if (portalUserProjectID) {
         dispatch(executorUserList({ id: atob(job_id) }));
      }
   }, [portalUserProjectID]);

   useEffect(() => {
      console.log("executorUserList", executorUserListData);

      if (executorUserListData?.length) {
         let users = [
            { id: "all", username: "All" },
            ...executorUserListData,
            { id: "contested", username: "Contested" },
            { id: "unselected", username: "Unselected" },
         ];

         setUserList(users);
         setSelectedMainImage(0);
      }
   }, [executorUserListData]);

   useEffect(() => {
      const listTypes = ["all", "contested", "unselected"];
      if (userList?.length) {
         let isUserExist;

         if (user_id) {
            isUserExist = listTypes.includes(user_id) ? user_id : Number(atob(user_id));
            console.log("useruser ===>", isUserExist);
         }

         if (isUserExist) {
            setSelectedUser(isUserExist);
         } else {
            setSelectedUser("all");
         }
      }
   }, [userList]);

   useEffect(() => {
      let values = ["all", "contested", "unselected"];

      console.log("job_id", job_id);

      console.log("selectedUser", selectedUser);

      if (selectedUser) {
         setMainItemsList([]);
         setViewItems([]);
         setClaimList([]);
         setSelectedMainImage(0);
         setPageSize(10);

         if (values.includes(selectedUser)) {
            dispatch(
               executorClaimList({
                  page: 1,
                  limit: pageSize,
                  sortby: "",
                  order: "",
                  user_id: portalUserID,
                  claimType: selectedUser,
               })
            );
            setReportUrl(`/reports/executor/${job_id}/${btoa(portalUserID)}/${selectedUser}`);
         } else {
            dispatch(
               executorClaimList({
                  page: 1,
                  limit: pageSize,
                  sortby: "",
                  order: "",
                  user_id: selectedUser,
                  claimType: null,
               })
            );
            setReportUrl(`/reports/executor/${job_id}/${btoa(selectedUser)}/${null}`);
         }
      }
   }, [selectedUser]);

   useEffect(() => {
      setNav1(sliderRef1);
      setNav2(sliderRef2);
   }, []);

   useEffect(() => {
      console.log("executorClaimListData", executorClaimListData);

      if (executorClaimListData?.data?.length) {
         setMainItemsList([...executorClaimListData.data]);
         // mainSliderRef.current.slickGoTo(0);
      }
   }, [executorClaimListData]);

   useEffect(() => {
      console.log("SelectedImage", mainItemsList[selectedMainImage]);

      if (mainItemsList?.length) {
         let item = { ...mainItemsList?.[selectedMainImage] };
         // let view = [...item?.sequenceImages];
         // let claim = [...item?.item_claim];
         setViewItems(prev => [...item?.sequenceImages]);
         setClaimList(prev => [...item?.item_claim]);
         // mainSliderRef.current.slickGoTo(selectedMainImage);

         // setViewItems([...mainItemsList?.[selectedMainImage]?.sequenceImages]);
         // setClaimList([...mainItemsList?.[selectedMainImage]?.item_claim]);
         // sliderRef1.slickGoTo(0);
         // sliderRef2.slickNext();
         // mainSliderRef.current.slickGoTo(selectedMainImage);
      }
      setClaimSettleData({
         ...claimSettleData,
         executor_judge_notes: "",
         item_id: "",
         portal_user_id: "",
      });

      setSelectedDocument({
         name: "",
         url: "",
      });

      mainSliderRef.current.slickGoTo(selectedMainImage);
   }, [selectedMainImage, mainItemsList]);

   useEffect(() => {
      // sliderRef1?.current?.slickGoTo(0);
   }, [viewItems]);

   useEffect(() => {
      if (executorClaimSettleSuccess) {
         toast.success("Item claim settle Successfully");
         setClaimSettleData({
            ...claimSettleData,
            executor_judge_notes: "",
            item_id: "",
            portal_user_id: "",
         });

         let values = ["all", "contested", "unselected"];

         setMainItemsList([]);
         setViewItems([]);
         setClaimList([]);
         setSelectedMainImage(0);

         if (values.includes(selectedUser)) {
            // dispatch(executorClaimList({ user_id: portalUserID, claimType: selectedUser }));
            dispatch(
               executorClaimList({
                  page: 1,
                  limit: pageSize,
                  sortby: "",
                  order: "",
                  user_id: portalUserID,
                  claimType: selectedUser,
               })
            );
         } else {
            // dispatch(executorClaimList({ user_id: selectedUser, claimType: null }));
            dispatch(
               executorClaimList({
                  page: 1,
                  limit: pageSize,
                  sortby: "",
                  order: "",
                  user_id: selectedUser,
                  claimType: null,
               })
            );
         }
      }
   }, [executorClaimSettleSuccess]);

   const handleClaimSettle = () => {
      if (claimSettleData?.item_id && claimSettleData?.portal_user_id) {
         dispatch(executorClaim({ postData: claimSettleData }));
      } else {
         toast.error("Item not assigned.");
      }
   };

   return (
      <div className="pe-2 mb-3">
         <Loader loading={executorClaimListDataLoading || executorUserListDataLoading} />
         <PageHeader HeaderText={pageTitle} Breadcrumb={[{ name: pageTitle }]} />
         <Card styles={{ body: { paddingTop: 5 } }}>
            <Flex gap={16} className="py-2 px-2 mb-3">
               <div className="w-25">
                  <label className="pe-2">Select User : </label>
                  <Select
                     style={{ width: 200 }}
                     defaultActiveFirstOption
                     value={selectedUser}
                     onChange={value => setSelectedUser(value)}
                  >
                     {userList?.length > 0 &&
                        userList.map(user => (
                           <Select.Option value={user.id} key={user.id}>
                              {user.username}
                           </Select.Option>
                        ))}
                  </Select>
               </div>
               <div className="w-75">
                  <div className="d-inline-block float-end">
                     <Link to={reportUrl}>
                        <Button
                           // icon={<BsFillGridFill style={{ marginTop: -3 }} />}
                           type="default"
                           shape="round"
                           className="text-right text-white"
                           icon={<FaFileExport style={{ marginTop: -2 }} />}
                           style={{ backgroundColor: "#F7931E" }}
                        >
                           Report
                        </Button>
                     </Link>
                  </div>
                  <Title level={5} className="d-inline-block clearfix">
                     Total Values
                  </Title>
                  <Flex align="center" wrap>
                     {claimList?.map((item, i) => {
                        if (item?.estimated_value) {
                           return (
                              <div className="w-25 px-2 text-left" key={i}>
                                 <label className="mb-1">
                                    {item?.portalusers?.display_name}
                                    <i
                                       className="fa fa-tag fa-lg ms-2"
                                       style={{
                                          color: TAG_COLOURS[item?.portalusers?.icon_type],
                                       }}
                                       aria-hidden="true"
                                    ></i>
                                 </label>
                                 <p>{formatToDollars(item?.estimated_value)}</p>
                              </div>
                           );
                        }
                     })}
                  </Flex>
               </div>
            </Flex>
            <div className={`executor-image-slider main-slider px-4`}>
               <Slider
                  ref={mainSliderRef}
                  style={{ minHeight: 150 }}
                  initialSlide={0}
                  slidesToShow={4}
                  slidesToScroll={mainItemsList?.length > 4 ? 3 : 1}
                  swipeToSlide={false}
                  focusOnSelect={true}
                  centerMode={false}
                  infinite={false}
                  variableWidth={true}
                  // beforeChange={(current, next) => {
                  //    console.log("current", current, next);
                  //    if (current >= 0 && next >= 0 && current !== next) {
                  //       setSelectedMainImage(next);
                  //    }
                  // }}
                  afterChange={(current, next) => {
                     const element = document.querySelector(
                        ".executor-image-slider .slick-current"
                     );

                     if (element?.getAttribute("data-index")) {
                        setSelectedMainImage(element.getAttribute("data-index"));
                     }
                  }}
               >
                  {mainItemsList.map((item, index) => (
                     <div
                        key={item.id}
                        className="p-1 position-relative w-100 text-center"
                        onClick={e => {
                           e.preventDefault();
                           setSelectedMainImage(index);
                        }}
                     >
                        {/* <span className="position-absolute z-1" style={{ top: 10, right: 10 }}>
                           <i
                              className="fa fa-tag fa-lg"
                              style={{
                                 color: TAG_COLOURS[8],
                              }}
                              aria-hidden="true"
                           ></i>
                        </span> */}
                        {item?.item_claim?.length > 1 && (
                           <span
                              className="position-absolute z-1 fs-4"
                              style={{ top: 10, right: 10, color: "red" }}
                           >
                              <WarningFilled />
                           </span>
                        )}
                        <Image
                           src={assestURL + item.item_image}
                           preview={false}
                           height={150}
                           className="mw-100 mh-100"
                           style={{ objectFit: "contain" }}
                        />
                     </div>
                  ))}
               </Slider>
               <Flex justify="center" className="mt-3">
                  <Pagination
                     defaultCurrent={1}
                     // total={mainItemsList.length}
                     total={executorClaimListData?.count}
                     pageSize={pageSize}
                     size="small"
                     showSizeChanger={true}
                     showTotal={(total, range) =>
                        `Showing ${range[0]} - ${range[1]} of ${total} items`
                     }
                     onChange={(page, pageSize) => {
                        let values = ["all", "contested", "unselected"];
                        setPageSize(pageSize);

                        if (values.includes(selectedUser)) {
                           dispatch(
                              executorClaimList({
                                 page: page,
                                 limit: pageSize,
                                 sortby: "",
                                 order: "",
                                 user_id: portalUserID,
                                 claimType: selectedUser,
                              })
                           );
                        } else {
                           dispatch(
                              executorClaimList({
                                 page: page,
                                 limit: pageSize,
                                 sortby: "",
                                 order: "",
                                 user_id: selectedUser,
                                 claimType: null,
                              })
                           );
                        }
                     }}
                  />
               </Flex>
            </div>
            <Divider />
            <Row gutter={8} style={{ minHeight: "80vh" }}>
               <Col span={10} className="ps-3 pe-5">
                  <Title level={4}>View Item</Title>
                  {/* {viewItems?.length > 0 && ( */}
                  <div className={viewItems.length ? "" : "d-none"} key={selectedMainImage}>
                     <Slider
                        className="view-item"
                        // asNavFor={nav2}
                        initialSlide={0}
                        ref={slider => (sliderRef1 = slider)}
                        // arrows={false}
                        swipe={false}
                        // infinite={viewItems.length > 2}
                        infinite={false}
                        // slide={false}
                     >
                        {viewItems?.map((item, index) => (
                           <Flex
                              key={item.id}
                              justify="center"
                              align="center"
                              className="position-relative w-100 text-center px-2"
                              // style={{ width: 300, height: 300 }}
                           >
                              {/* <i
                                 className="fa fa-tag fa-2x position-absolute z-1"
                                 style={{
                                    color: TAG_COLOURS[item.tag],
                                    top: 20,
                                    right: 0,
                                 }}
                              ></i> */}
                              <Image
                                 src={assestURL + item.sequence_image}
                                 preview={false}
                                 // width={300}
                                 height={window.screen.height < 1000 ? 300 : 500}
                                 className="mw-100 mh-100"
                                 style={{ objectFit: "contain" }}
                              />
                           </Flex>
                        ))}
                     </Slider>
                  </div>
               </Col>
               <Col span={14} className="h-100 pt-0">
                  {/* <Title level={4}>Claim Information</Title> */}
                  <Tabs
                     // type="card"
                     defaultActiveKey="1"
                     centered
                     size="large"
                     items={[
                        {
                           label: "Claim Information",
                           key: 1,
                           children: (
                              <>
                                 <Flex
                                    className="p-2 overflow-y-auto"
                                    style={{
                                       minHeight: window.screen.height * 0.48,
                                       scrollbarWidth: "thin",
                                    }}
                                 >
                                    <Row gutter={8} className="w-100">
                                       {!claimList.length && (
                                          <Col span={24}>
                                             <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                description={
                                                   <Typography.Text>
                                                      No claims available
                                                   </Typography.Text>
                                                }
                                             />
                                          </Col>
                                       )}
                                       <Col span={12}>
                                          <Row gutter={[10, 12]}>
                                             {claimList
                                                .slice(0, Math.ceil(claimList.length / 2))
                                                .map((item, inx) => (
                                                   <Col span={24} key={inx}>
                                                      <ClaimentForm
                                                         isReadOnly={portalUserType === "3"}
                                                         sourceData={item}
                                                         onAssignData={claimSettleData}
                                                         onAssign={setClaimSettleData}
                                                      />
                                                   </Col>
                                                ))}
                                          </Row>
                                       </Col>
                                       <Col span={12}>
                                          <Row gutter={[10, 12]}>
                                             {claimList
                                                .slice(Math.ceil(claimList.length / 2))
                                                .map((item, inx) => (
                                                   <Col span={24} key={inx}>
                                                      <ClaimentForm
                                                         isReadOnly={portalUserType === "3"}
                                                         sourceData={item}
                                                         onAssignData={claimSettleData}
                                                         onAssign={setClaimSettleData}
                                                      />
                                                   </Col>
                                                ))}
                                          </Row>
                                       </Col>
                                    </Row>
                                 </Flex>
                                 <Divider className="mb-2" />
                                 <Space className="w-100 text-center" direction="vertical">
                                    <Flex gap={10} justify="space-between" align="center">
                                       <label className="text-nowrap">Executor Judge Notes</label>
                                       <TextArea
                                          className=""
                                          placeholder="Judge Notes"
                                          // disabled={true}
                                          style={{ resize: "none" }}
                                          value={claimSettleData?.executor_judge_notes}
                                          rows={2}
                                          onChange={e =>
                                             setClaimSettleData({
                                                ...claimSettleData,
                                                executor_judge_notes: e.target.value,
                                             })
                                          }
                                       />
                                    </Flex>
                                    <Button
                                       shape="round"
                                       size="small"
                                       type="primary"
                                       disabled={portalUserType === "3"}
                                       style={{ backgroundColor: "#f7931e" }}
                                       onClick={handleClaimSettle}
                                    >
                                       Claim Settled
                                    </Button>
                                 </Space>
                              </>
                           ),
                        },
                        {
                           label: "Documents",
                           key: 2,
                           children: (
                              <>
                                 <Flex wrap gap={10} justify="center" align="center">
                                    {mainItemsList?.[selectedMainImage]?.item_documents?.map(
                                       (item, index) => (
                                          <Avatar
                                             key={item.item_id}
                                             style={{
                                                backgroundColor:
                                                   selectedDocument.name === item.file_name
                                                      ? "#F7931E"
                                                      : "white",
                                             }}
                                             shape="square"
                                             title={item.file_name}
                                             icon={<BiSolidFilePdf color="red" size={80} />}
                                             onClick={() =>
                                                setSelectedDocument({
                                                   name: item.file_name,
                                                   url: item.pdf_path,
                                                })
                                             }
                                          />
                                       )
                                    )}
                                    {mainItemsList?.[selectedMainImage]?.item_documents?.length ==
                                       0 && (
                                       <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                          description={
                                             <Typography.Text>No documents</Typography.Text>
                                          }
                                       />
                                    )}
                                 </Flex>
                                 <Divider>{selectedDocument.name}</Divider>
                                 {selectedDocument.url && (
                                    <div style={{ height: 500 }}>
                                       <PDFViewer
                                          pdfUrl={
                                             assestURL.substring(0, assestURL.length - 1) +
                                             selectedDocument.url
                                          }
                                       />
                                    </div>
                                 )}
                              </>
                           ),
                        },
                     ]}
                  ></Tabs>
               </Col>
            </Row>
         </Card>
      </div>
   );
}

export default EstateExecutorClaiment;

import { CaretRightOutlined } from "@ant-design/icons";
import { Card, Collapse } from "antd";

const CardOrCollapse = ({ children, type, titleName, panelHeader }) => {
   const { Panel } = Collapse;

   return type === "add" ? (
      <Card title={titleName}>{children}</Card>
   ) : (
      <Collapse
         bordered={false}
         defaultActiveKey={["1"]}
         expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
         <Panel
            key="1"
            style={{ backgroundColor: "white" }}
            header={panelHeader ? panelHeader : <strong>{titleName}</strong>}
         >
            {children}
         </Panel>
      </Collapse>
   );
};

export default CardOrCollapse;

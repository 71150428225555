import { Card, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComponent from "../../fields/ButtonComponent";
import SearchComponent from "../../fields/SearchComponent";
import SwalComponent from "../../fields/SwalComponent";
import TableComponent from "../../fields/TableComponent";
import Loader from "../../fields/loader/Loader";
import {
   deletePortalUser,
   getPortalUserList,
   portalUserStatus,
} from "../../store/reducer/portalUserSlice";
import { TAG_COLOURS } from "../../utils/constants";
import PageHeader from "../../view/pageheader";

const PortalUsers = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { project_number, project_id, client_id } = useParams();
   const [basePath] = useState("/project");

   //Selectors
   const {
      portalUserListLoading,
      portalUserListData,
      deletePortalUserLoading,
      deletePortalUserSuccess,
      deletePortalUserError,
      deletePortalUserErrorMessage,
      portalUserStatusLoading,
      portalUserStatusSuccess,
      portalUserStatusError,
      portalUserStatusErrorMessage,
   } = useSelector(state => state.portalUser);

   const { count: totalCount, data: tableData } = portalUserListData;
   const roleID = localStorage.getItem('role_id')
   const category_type = localStorage.getItem('category_type')
   const [statusType] = useState({
      1: "Active",
      0: "Inactive",
   });

   function getData(data) {
      const payload = { ...data, id: atob(project_id) };
      dispatch(getPortalUserList(payload));
   }

   useEffect(() => {
      getData({});

      if (deletePortalUserSuccess) {
         toast.success("Portal user deleted successfully");
      }

      if (deletePortalUserError) {
         toast.error(deletePortalUserErrorMessage);
      }

      if (portalUserStatusSuccess) {
         toast.success("Portal user status updated successfully");
      }

      if (portalUserStatusError) {
         toast.error(portalUserStatusErrorMessage);
      }
   }, [
      deletePortalUserError,
      deletePortalUserSuccess,
      portalUserStatusError,
      portalUserStatusSuccess,
   ]);

   const columns = [
      {
         title: "First Name",
         dataIndex: "first_name",
         key: "first_name",
         align: "center",
         sorter: true,
      },
      {
         title: "Last Name",
         dataIndex: "last_name",
         key: "last_name",
         align: "center",
         sorter: true,
      },
      {
         title: "Display Name",
         dataIndex: "display_name",
         key: "display_name",
         align: "center",
         sorter: true,
      },
      {
         title: "Username",
         dataIndex: "username",
         key: "username",
         align: "center",
         sorter: true,
      },
      {
         title: "Icon Tag",
         key: "icon_type",
         align: "center",
         render: record => (
            <span>
               <i
                  className="fa fa-tag fa-lg"
                  style={{
                     color: TAG_COLOURS[record.icon_type],
                  }}
               ></i>
            </span>
         ),
      },
      {
         title: "Status",
         key: "status",
         align: "center",
         render: record => {
            if (roleID === '2' && category_type === '3') {
               return <span>{statusType[record.status]}</span>
            }
            else {
              return <SwalComponent
                  message="Are you sure you want to change the portal user status?"
                  onClickDispatch={portalUserStatus}
                  id={record.id}
                  status={true}
                  statusValue={record.status}
                  postData={{ status: record.status === 1 ? 0 : 1, user_id: record.user_id }}
               />
            }
         },
         sorter: true,
      },
      {
         title: "Action",
         align: "center",
         render: record => (
            <Space size="large" align="center">
               <span className="fs-6" style={{ color: "blue", cursor: "pointer" }}>
                  <Tooltip title="Edit">
                     <i
                        className="icon-pencil"
                        onClick={() => {
                           console.log(record, "record");
                           navigate(
                              `${basePath}/edit-portaluser/${btoa(
                                 record.id
                              )}/${project_number}/${project_id}/${client_id} `
                           );
                        }}
                     ></i>
                  </Tooltip>
               </span>
               <SwalComponent
                  message="Are you sure you want to delete the portal user?"
                  onClickDispatch={deletePortalUser}
                  id={record.id}
               />
            </Space>
         ),
      },
   ];

   return (
      <div>
         <Loader
            loading={portalUserListLoading || deletePortalUserLoading || portalUserStatusLoading}
         />
         <div className="container-fluid">
            <PageHeader
               HeaderText="TakeStock Portal Users"
               Breadcrumb={[{ name: "TakeStock Portal Users" }]}
               backButtonUrl={`/editproject/${project_id}/${client_id}`}
            />
            <Card
               title="TakeStock Portal Users"
               extra={
                  <Space className="my-3">
                     <SearchComponent
                        placeHolder="Search Client"
                        onChange={e => {
                           const payloadData = {
                              query: e.target.value,
                           };
                           getData(payloadData);
                        }}
                     />
                     {/* <Link to={`/editclient/${client_id}`}> */}
                     <Link to={`/editproject/${project_id}/${client_id}`}>
                        <ButtonComponent
                           style={{ backgroundColor: "#6E6E6E", color: "white" }}
                           buttonName={atob(project_number)}
                           icon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
                        />
                     </Link>
                     <Link
                        to={`${basePath}/add-portaluser/${project_number}/${project_id}/${client_id}`}
                     >
                        <ButtonComponent
                           style={{ backgroundColor: "#f7931e", color: "white" }}
                           buttonName="Add Portal User"
                           icon={<i className="fa fa-user-plus" aria-hidden="true"></i>}
                        />
                     </Link>
                     {/* <Tooltip title="Refresh">
                        <Button
                           shape="circle"
                           icon={
                              <SyncOutlined
                                 spin={deletePortalUserLoading || portalUserStatusLoading}
                              />
                           }
                           onClick={getData}
                        ></Button>
                     </Tooltip> */}
                  </Space>
               }
            >
               <TableComponent
                  columns={columns}
                  dataSource={tableData}
                  TotalDataCount={totalCount}
                  onChangeDispatch={getData}
               />
            </Card>
         </div>
      </div>
   );
};

export default PortalUsers;

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { UserOutlined, MailOutlined, PhoneOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Image, Input, Upload, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Toast } from 'bootstrap';
import PageHeader from '../view/pageheader';
import InputComponent from '../fields/InputComponent'
import SelectComponent from '../fields/SelectComponent';
import ButtonComponent from '../fields/ButtonComponent';
import CardComponent from '../fields/CardComponent';
import { addStaff, getData, getStaffData, updateStaff, resetSuccessFlag } from '../store/reducer/staffSlice';
import { getStatesList } from '../store/reducer/statesListSlice';
import ModalComponent from '../fields/ModalComponent';
import { toast } from 'react-toastify';
import { assestURL } from '../services/config';

const AddStaff = () => {

    const [userDetailsArray, setUserDetailsArray] = useState({
        role_id: 2,
        user_id: '',
        first_name: '',
        last_name: '',
        email: '',
        address_one: '',
        address_two: '',
        password: '',
        city: '',
        state: '',
        confirm_password: '',
        zip_code: '',
        category_type: '',
        status: 1,
        imgpath: '',
        image: '',
    });
    const [categoryData, setCategoryData] = useState([
        {
            value: 1,
            label: 'Admin',
        },
        {
            value: 2,
            label: 'Management',
        },
        {
            value: 3,
            label: 'Production',
        }
    ])
    const [status, setStatus] = useState([
        {
            value: 1,
            label: 'Active',
        },
        {
            value: 0,
            label: 'Inactive',
        }
    ])
    const [open, setOpen] = useState(false);
    const [touchedFields, setTouchedFields] = useState({});
    const [, forceUpdate] = useState();  // Use state to trigger re-renders
    const [type, setType] = useState('add')
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileListOnChange, setFileListOnChange] = useState([]);
    const [stateList, setStateList] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const staffArray = localStorage.getItem('staff')


    console.log(staffArray, 'staffArray');

    //Selectors
    const { addStaffLoading, addStaffError, addedStaff, addStaffSuccess, updateStaffSuccess, getAddedData, staffErrorMessages } = useSelector((state) => state.staff);
    console.log(getAddedData, 'getAddedData');

    const { statesListData, statesListLoading, statesError, statesErrorMessage } = useSelector((state) => state.states);

    const validator = useRef(new SimpleReactValidator({
        className: 'validation-message',
        messages: {
            required: 'Please enter :attribute',
            email: 'Email should contain @ and .',
            alpha: ':attribute should contain only alphabets',
            regex: ':attribute format is invalid',
            in: 'Confirm password does not match with password field',
            min: ':attribute must be at least :min characters',
            confirmed: 'Passwords do not match',
            strong_password: 'Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character'
        },
        validators: {
            strong_password: { // name the validator
                message: 'Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/) && params.indexOf(val) === -1;
                },
                required: true // This is an optional parameter. If true, the field will be required.
            }
        }
    }));

    const validationRules = {
        first_name: `required|alpha`,
        last_name: `alpha`,
        email: `required|email`,
        address_one: `required`,
        address_two: '',
        password: type === 'add' ? `required|strong_password` : userDetailsArray.password === '' && type === 'edit' ? '' : `strong_password`, // use the custom validator
        confirm_password: type === 'add' ? `required|in:${userDetailsArray.password}` : `in:${userDetailsArray.password}`,
        city: `required`,
        state: `required`,
        zip_code: `required|numeric|min:5|max:12`,
        category_type: `required`,
        status: `required`
    };

    const addEditUserDetails = (e) => {
        const { name, value } = e.target;
        setUserDetailsArray(prevState => ({
            ...prevState,
            [name]: value
        }));
        setTouchedFields(prevState => ({
            ...prevState,
            [name]: true
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Dynamically add or remove validation rules based on the form type
        // if (type === 'add') {
        //     validator.current.fields.password = 'required|strong_password';
        //     validator.current.fields.confirm_password = `required|in:${userDetailsArray.password}`;
        // } else {
        //     delete validator.current.fields.password;
        //     delete validator.current.fields.confirm_password;
        // }
        if (userDetailsArray.password === '' && type === 'edit') {
            delete validator.current.fields.password;
            delete validator.current.fields.confirm_password;
        }

        if (validator.current.allValid()) {
            userDetailsArray.role_id = 2
            userDetailsArray.imgpath = fileListOnChange && fileListOnChange.length > 0 ? fileListOnChange?.[0]?.name : fileList && fileList.length > 0 && fileList?.[0]?.name
            userDetailsArray.image = fileListOnChange && fileListOnChange.length > 0 ? fileListOnChange?.[0]?.thumbUrl : fileList && fileList.length > 0 && fileList?.[0]?.thumbUrl != undefined ? fileList?.[0]?.thumbUrl : userDetailsArray.image
            let postData = userDetailsArray
            if (type === 'add') {
                delete postData.user_id
                dispatch(addStaff({ postData }));
            }
            else {
                let updateID = atob(id)
                console.log(updateID, 'updateID');
                dispatch(updateStaff({ postData, updateID }));
            }
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    };

    const reset = () => {
        setUserDetailsArray({
            ...userDetailsArray,
            first_name: '',
            last_name: '',
            email: '',
            address_one: '',
            address_two: '',
            password: '',
            city: '',
            state: '',
            confirm_password: '',
            zip_code: '',
            category_type: '',
            status: 1
        })
        setTouchedFields({})

    }

    const onChange = (value, name) => {
        setUserDetailsArray(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        console.log(file, 'file');
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => {
        console.log(newFileList, 'newFileList');
        if (newFileList && newFileList.length === 0) {
            setUserDetailsArray({
                ...userDetailsArray,
                image: '',
                imgpath: ''
            })
            setFileListOnChange([])
        }
        setFileListOnChange(newFileList)
        setFileList(newFileList)
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <UserOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload Image
            </div>
        </button>
    );

    useEffect(() => {
        if (addStaffSuccess) {
            setUserDetailsArray({
                ...userDetailsArray,
                first_name: '',
                last_name: '',
                email: '',
                address_one: '',
                address_two: '',
                password: '',
                city: '',
                state: '',
                confirm_password: '',
                zip_code: '',
                category_type: '',
                status: 1
            })
            toast.success('Staff added Successfully!');
            dispatch(resetSuccessFlag({ flag: 'addStaffSuccess' }));
            navigate('/staff');

        }
        if (updateStaffSuccess) {
            setUserDetailsArray({
                ...userDetailsArray,
                first_name: '',
                last_name: '',
                email: '',
                address_one: '',
                address_two: '',
                password: '',
                city: '',
                state: '',
                confirm_password: '',
                zip_code: '',
                category_type: '',
                status: 1
            })
            toast.success('Staff Updated Successfully!');
            dispatch(resetSuccessFlag({ flag: 'updateStaffSuccess' }));
            navigate('/staff');

        }
        if (staffErrorMessages) {
            toast.error(staffErrorMessages)
        }
    }, [addStaffSuccess, updateStaffSuccess, staffErrorMessages, dispatch, navigate]);

    useEffect(() => {
        if (id) {
            setType('edit')
            const decodedId = atob(id); // Use a different variable name
            dispatch(getStaffData({ id: decodedId }));
        }
        else {
            setType('add')
            setUserDetailsArray({
                ...userDetailsArray,
                first_name: '',
                last_name: '',
                email: '',
                address_one: '',
                address_two: '',
                password: '',
                city: '',
                state: '',
                confirm_password: '',
                zip_code: '',
                category_type: '',
                status: 1
            })
            setTouchedFields({})
        }
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(getStatesList({}));

        if (getAddedData && type === 'edit') {
            setUserDetailsArray({
                ...userDetailsArray,
                role_id: getAddedData?.role_id,
                user_id: getAddedData?.user_id,
                first_name: getAddedData?.first_name,
                last_name: getAddedData?.last_name,
                image: getAddedData?.image ? getAddedData?.image : '',
                email: getAddedData?.email,
                address_one: getAddedData?.address_one,
                address_two: getAddedData?.address_two,
                password: '',
                city: getAddedData?.city,
                state: getAddedData?.state,
                confirm_password: '',
                zip_code: getAddedData?.zip_code,
                category_type: getAddedData?.category_type,
                status: getAddedData?.status
            })
        }
    }, [getAddedData, type])

    useEffect(() => {
        if (statesListData.length) {
            const list = statesListData.map(item => ({
                value: item.state,
                label: item.state,
            }));
            setStateList([...list]);
        }
    }, [statesListData])

    useEffect(() => {
        if (userDetailsArray.image) {
            const imageSrc = `${assestURL}${userDetailsArray.image}`;
            setFileList([{ uid: '-1', name: 'image.png', status: 'done', url: imageSrc }]);
        }
    }, [userDetailsArray]);

    console.log(userDetailsArray, 'userDetailsArray');

    return (
        <div className="container-fluid-staff" >
            <PageHeader
                HeaderText={type === 'add' ? 'Add Staff' : 'Update Staff'}
                Breadcrumb={[{ name: type === 'add' ? 'Add Staff' : 'Update Staff' }]}
                backButtonUrl={`/staff`}
            />
            <CardComponent>
                <div class="grid-container">
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="first_name">First Name</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="first_name"
                                placeholder="First Name"
                                type="text"
                                icon={<UserOutlined />}
                                value={userDetailsArray.first_name}
                                onChange={addEditUserDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="last_name">Last Name</label>
                            <InputComponent
                                name="last_name"
                                placeholder="Last Name"
                                type="text"
                                icon={<UserOutlined />}
                                value={userDetailsArray.last_name}
                                onChange={addEditUserDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item start-col-3 row-span-2">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <>
                                {/* <ImgCrop rotate> */}
                                <Upload
                                    className='staff'
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    maxCount={1}
                                    action="https://yourserver.com/upload"  // Replace with your actual upload URL
                                    onRemove={(file) => {
                                        // Optionally, handle image removal logic
                                        message.success('Image removed');
                                        setUserDetailsArray({
                                            ...userDetailsArray,
                                            image: ""
                                        })
                                    }}
                                    style={{ height: '185px', width: '185px' }}
                                >
                                    {fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                                {/* </ImgCrop> */}
                                {previewImage && (
                                    <Image
                                        style={{
                                            display: 'none',
                                            height: '150px',
                                            width: '150px'
                                        }}
                                        preview={{
                                            visible: previewOpen,
                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                        }}
                                        src={previewImage}
                                    />
                                )}
                            </>
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="password">Password</label>{type === 'add' ? <span className='m-1' style={{ color: 'red' }}>*</span> : null}
                            {/* <InputComponent
                                name="password"
                                placeholder="Password"
                                type="text"
                                icon={<EyeInvisibleOutlined />}
                                value={userDetailsArray.password}
                                onChange={addEditUserDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            /> */}
                            <Input.Password
                                autoComplete="new-password"
                                type={type}
                                placeholder={"Enter password"}
                                name={"password"}
                                style={{
                                    padding: "5px",
                                }}
                                value={userDetailsArray.password}
                                onChange={addEditUserDetails}
                                suffix={<EyeInvisibleOutlined />}
                            />
                            {(validationRules['password'] || touchedFields['password']) && (
                                <div style={{ color: "red" }}>
                                    {validator.current.message('password', userDetailsArray.password, validationRules['password'])}
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="confirm_password">Confirm Password</label>{type === 'add' ? <span className='m-1' style={{ color: 'red' }}>*</span> : null}
                            {/* <InputComponent
                                name="confirm_password"
                                placeholder="Confirm Password"
                                type="text"
                                icon={<EyeInvisibleOutlined />}
                                value={userDetailsArray.confirm_password}
                                onChange={addEditUserDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            /> */}
                            <Input.Password
                                autoComplete="new-password"
                                type={type}
                                placeholder={"Confirm Password"}
                                name={"confirm_password"}
                                style={{
                                    padding: "5px",
                                }}
                                value={userDetailsArray.confirm_password}
                                onChange={addEditUserDetails}
                                suffix={<EyeInvisibleOutlined />}
                            />
                            {(validationRules['confirm_password'] || touchedFields['confirm_password']) && (
                                <div style={{ color: "red" }}>
                                    {validator.current.message('confirm_password', userDetailsArray.confirm_password, validationRules['confirm_password'])}
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="email">Email</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="email"
                                placeholder="Email Id"
                                type="text"
                                icon={<MailOutlined />}
                                value={userDetailsArray.email}
                                onChange={addEditUserDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="address_one">Address 1</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="address_one"
                                placeholder="Address line 1"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={userDetailsArray.address_one}
                                onChange={addEditUserDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="address_two">Address 2</label>
                            <InputComponent
                                name="address_two"
                                placeholder="Address line 2"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={userDetailsArray.address_two}
                                onChange={addEditUserDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="city">City</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="city"
                                placeholder="City"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={userDetailsArray.city}
                                onChange={addEditUserDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="state">State</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <SelectComponent
                                name="state"
                                value={userDetailsArray.state ? userDetailsArray.state : null}
                                placeholder="Select State"
                                onChange={(value) => onChange(value, 'state')}
                                onSearch={onSearch}
                                options={stateList}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                            {/* <InputComponent
                                name="state"
                                placeholder="State"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={userDetailsArray.state}
                                onChange={addEditUserDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            /> */}
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="zip_code">Zip Code</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="zip_code"
                                placeholder="Zip Code"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={userDetailsArray.zip_code}
                                onChange={addEditUserDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="name">Category</label>
                            <span className="m-1" style={{ color: 'red' }}>*</span><br />
                            <SelectComponent
                                name="category_type"
                                value={userDetailsArray.category_type ? userDetailsArray.category_type : null}
                                placeholder="Select Category"
                                onChange={(value) => onChange(value, 'category_type')}
                                onSearch={onSearch}
                                options={categoryData}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="name">Status</label>
                            <span className="m-1" style={{ color: 'red' }}>*</span><br />
                            <SelectComponent
                                name="status"
                                value={userDetailsArray.status}
                                placeholder="Please choose staff category_type"
                                onChange={(value) => onChange(value, 'status')}
                                onSearch={onSearch}
                                options={status}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                </div>
                <div className='text-center mt-3'>
                    <ButtonComponent style={{ backgroundColor: '#f7931e', color: 'white' }} onClick={handleSubmit} buttonName={type === 'add' ? 'Add Staff' : 'Update Staff'} icon={<i class="fa fa-user-plus" aria-hidden="true"></i>} />&nbsp;
                    <ButtonComponent style={{ backgroundColor: '#6E6E6E', color: 'white' }} onClick={reset} buttonName="Reset" icon={<i class="fa fa-refresh" aria-hidden="true"></i>} />
                </div>
            </CardComponent>
            {/* </Card> */}
        </div>
    )
}

export default AddStaff
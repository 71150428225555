import { Card, Divider, Image, Table } from "antd";
import { useEffect, useState } from "react";
import { assestURL } from "../../services/config";
import { formatToDollars } from "../../utils/formatToDollars";

const GridViewCard = ({
   sourceData,
   projectType,
   portalUserType,
   mainIndex,
   subIndex,
   roleID,
   reportType,
   clientID,
}) => {
   const [data, setData] = useState({});
   const [reportFieldsSchema] = useState({
      name: "Item Name",
      client_value_range: "Client's Estimate",
      google_value_range: "AI Estimate",
      category: "Category",
      quantity: "Quantity",
      distribute_to: "Assign To",
      item_condition: "Condition",
      purchase_date: "Purchase Date",
      notes: "Notes",
      tags: "Tags",
   });

   const [itemClaimReasons] = useState({
      1: "Pre-Marriage",
      2: "Gift",
      3: "Inherited",
      4: "Bought During Marriage",
      5: "Other - See Notes",
   });

   useEffect(() => {
      if (clientID) {
         const clientData = sourceData?.item_claim?.find(
            item => item.portal_user_id === Number(clientID)
         );
         if (clientData?.portal_user_id) {
            setData({ ...clientData });
         }
      }
   }, [clientID]);

   function usersList(data) {
      let name = "";
      data?.item_claim?.forEach(item => (name = `${name + " " + item.portalusers.display_name},`));
      return name;
   }

   return (
      <Card type="inner" className="my-2 reports-item h-100" style={{ minHeight: 500 }}>
         <div
            data-main-index={mainIndex}
            data-sub-index={subIndex}
            className="text-center pdf-image-container"
         >
            <Image
               src={assestURL + sourceData.item_image}
               preview={false}
               style={{ height: 250 }}
            />
         </div>
         <Divider />
         {/* <table style={tableStyle} className="fs-6">
            <tbody>{createTableRows(homeOwner, reportFieldsSchema)}</tbody>
         </table> */}

         {projectType === 1 && Number(portalUserType) !== 2 && roleID !== "1" && (
            <table className="table-sm">
               <tbody>
                  <tr>
                     <th>Item Name</th>
                     <td>{sourceData.name || ""}</td>
                  </tr>
                  <tr>
                     <th>Client’s Estimate</th>
                     <td>{formatToDollars(data.estimated_value || "")}</td>
                  </tr>
                  <tr>
                     <th>AI Estimate</th>
                     <td>{formatToDollars(sourceData.google_value_range || "")}</td>
                  </tr>
                  <tr>
                     <th>Notes</th>
                     <td>{data.comment}</td>
                  </tr>
               </tbody>
            </table>
         )}

         {projectType === 1 && Number(portalUserType) === 2 && (
            <table className="table-sm">
               <tbody>
                  <tr>
                     <th style={{ verticalAlign: "top", whiteSpace: "nowrap" }}>Item Name</th>
                     <td>{sourceData.name || ""}</td>
                  </tr>
                  <tr>
                     <th style={{ verticalAlign: "top", whiteSpace: "nowrap" }}>Estimate Value</th>
                     <td>{formatToDollars(sourceData.client_value_range || "")}</td>
                  </tr>
                  <tr>
                     <th style={{ verticalAlign: "top", whiteSpace: "nowrap" }}>Claimed By</th>
                     <td>{usersList(sourceData)}</td>
                  </tr>
               </tbody>
            </table>
         )}

         {projectType === 2 && Number(portalUserType) !== 2 && (
            <table className="table-sm">
               <tbody>
                  <tr>
                     <th>Item Name</th>
                     <td>{sourceData.name || ""}</td>
                  </tr>
                  <tr>
                     <th>Client’s Estimate</th>
                     <td>{formatToDollars(data.estimated_value || "")}</td>
                  </tr>
                  <tr>
                     <th>AI Estimate</th>
                     <td>{formatToDollars(sourceData.google_value_range || "")}</td>
                  </tr>
                  <tr>
                     <th>Claim Reason</th>
                     <td>{itemClaimReasons[data.claimed_as]}</td>
                  </tr>
                  <tr>
                     <th>Notes</th>
                     <td>{data.comment}</td>
                  </tr>
               </tbody>
            </table>
         )}

         {projectType === 2 && Number(portalUserType) === 2 && (
            <table className="table-sm text-center">
               <tbody>
                  <tr style={{ verticalAlign: "top", whiteSpace: "nowrap" }}>
                     <th>Claimed By</th>
                     <th>Claim Reason</th>
                     <th>Client’s Estimate</th>
                     <th>AI Estimate</th>
                  </tr>
                  {sourceData?.item_claim?.map(item => (
                     <tr key={item?.id}>
                        <td>{item.portalusers.display_name}</td>
                        <td>{itemClaimReasons[item.claimed_as]}</td>
                        <td>{formatToDollars(item.estimated_value || "")}</td>
                        <td>{formatToDollars(sourceData.google_value_range || "")}</td>
                     </tr>
                  ))}
               </tbody>
            </table>
         )}

         {projectType > 2 && (
            <table className="table-sm">
               <tbody>
                  <tr>
                     <th>Item Name</th>
                     <td>{sourceData.name || ""}</td>
                  </tr>
                  <tr>
                     <th>Client’s Estimate</th>
                     <td>{formatToDollars(data.estimated_value || "")}</td>
                  </tr>
                  <tr>
                     <th>AI Estimate</th>
                     <td>{formatToDollars(sourceData.google_value_range || "")}</td>
                  </tr>
                  <tr>
                     <th>Notes</th>
                     <td>{data.comment}</td>
                  </tr>
               </tbody>
            </table>
         )}

         {/* {roleID === "1" && reportType === "normal" && (
            <table className="table-sm">
               <tbody>
                  <tr>
                     <th>Item Name</th>
                     <td>{sourceData.name || ""}</td>
                  </tr>
                  <tr>
                     <th>Client’s Estimate</th>
                     <td>{sourceData.client_value_range || ""}</td>
                  </tr>
                  <tr>
                     <th>AI Estimate</th>
                     <td>{sourceData.google_value_range || ""}</td>
                  </tr>
                  <tr>
                     <th>Notes</th>
                     <td>{sourceData.notes}</td>
                  </tr>
               </tbody>
            </table>
         )}

         {roleID === "1" && reportType === "contested" && (
            <table className="table text-center">
               <tbody>
                  <tr style={{ verticalAlign: "top", whiteSpace: "nowrap" }}>
                     <th>Claimed By</th>
                     <th>Claim Reason</th>
                     <th>Client’s Estimate</th>
                     <th>AI Estimate</th>
                  </tr>
                  {sourceData?.item_claim?.map(item => (
                     <tr>
                        <td>{item.portalusers.display_name}</td>
                        <td>{itemClaimReasons[item.claimed_as]}</td>
                        <td>{item.estimated_value || ""}</td>
                        <td>{sourceData.google_value_range || ""}</td>
                     </tr>
                  ))}
               </tbody>
            </table>
         )} */}
      </Card>
   );
};

export default GridViewCard;

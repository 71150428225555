import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './reducer/login';
import staffReducer from './reducer/staffSlice';
import photographerReducer from './reducer/photographerSlice';
import clientReducer from './reducer/clientSlice';
import projectReducer from './reducer/projectSlice';
import jobReducer from './reducer/jobSlice';
import portalUserReducer from './reducer/portalUserSlice';
import itemCategoriesReducer from './reducer/itemCategoriesSlice';
import roomReducer from './reducer/roomSlice';
import statesListReducer from './reducer/statesListSlice';
import buildThreeSixtyReducer from './reducer/buildThreeSixty';
import reportsSlice from './reducer/reportsSlice';
import googleSearchReducer from './reducer/googleSearch';
// import userReducer from './userSlice'
// import postReducer from './postsSlice'

const store = configureStore({
  reducer: {
    login: loginReducer,
    staff: staffReducer,
    photographer: photographerReducer,
    client: clientReducer,
    project: projectReducer,
    portalUser: portalUserReducer,
    job: jobReducer,
    itemCategories: itemCategoriesReducer,
    rooms: roomReducer,
    states: statesListReducer,
    buildThreeSixty: buildThreeSixtyReducer,
    reports: reportsSlice,
    googleSearch: googleSearchReducer
    // posts: postReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store;
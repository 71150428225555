import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createJob, editJob, getJobDataByID, jobDelete, jobList, jobSort } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "job";

// Async thunk for fetching the job list
export const getJobList = createAsyncThunk(
    `${namespace}/getJobList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "", id = "", type = "", staffid = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await jobList(query, page, limit, sortby, order,id,type,staffid);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a job
export const addJob = createAsyncThunk(
    `${namespace}/job`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await createJob({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for fetching job data by ID
export const getJobData = createAsyncThunk(
    `${namespace}/jobbyId`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await getJobDataByID(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating job data
export const updateJob = createAsyncThunk(
    `${namespace}/UpdateCategory`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editJob({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const jobStatus = createAsyncThunk(
    `${namespace}/UpdateStatus`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            // const data = await updatejobStatus({ payload }, updateID);
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const deleteJob = createAsyncThunk(
    `${namespace}/jobDelete`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await jobDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const jobSequence = createAsyncThunk(
    `${namespace}/jobSequence`,
    async ({ jobs }, { rejectWithValue }) => {
        try {
            console.log(jobs, 'postData');
            let payload = EncryptDecrypt.encryptdata(jobs, secretKey);
            console.log(payload, 'payload');
            const data = await jobSort({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


const jobSlice = createSlice({
    name: namespace,
    initialState: {
        jobListData: [],
        jobListLoading: false,
        addJobLoading: false,
        addJobSuccess: false,
        addJobError: null,
        getAddedJobData: [],
        getAddedJobDataLoading: false,
        updateData: [],
        updateJobSuccess: false,
        updateJobError: false,
        deleteJobLoading: false,
        deleteJobSuccess: false,
        deleteJobError: false,
        deleteJobErrorMessage: "",
        jobStatusLoading: false,
        jobStatusSuccess: false,
        jobStatusError: false,
        jobStatusErrorMessage: "",
        jobSortSuccess: false,
        jobSortError: false,
        jobSortLoading: false
    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle getJobList
            .addCase(getJobList.pending, (state) => {
                state.jobListLoading = true;
                state.addJobLoading = false;
                state.addJobSuccess = false;
                state.addJobError = null;
                state.addedjob = null;
                state.getAddedJobData = [];
                state.updateData = [];
                state.updateJobSuccess = false;
                state.deleteJobLoading = false;
                state.deleteJobSuccess = false;
                state.deleteJobError = false;
                state.deleteJobErrorMessage = "";
                state.jobStatusLoading = false;
                state.jobStatusSuccess = false;
                state.jobStatusError = false;
                state.jobStatusErrorMessage = "";
                state.jobSortSuccess = false;
            })
            .addCase(getJobList.fulfilled, (state, action) => {
                const data = action.payload;
                state.jobListData = data;
                state.jobListLoading = false;
            })
            .addCase(getJobList.rejected, (state) => {
                state.jobListLoading = false;
            })

            // Handle addJob
            .addCase(addJob.pending, (state) => {
                state.addJobLoading = true;
                state.addJobError = null;
                state.addJobSuccess = false;
            })
            .addCase(addJob.fulfilled, (state, action) => {
                state.addJobLoading = false;
                state.addJobSuccess = true;
            })
            .addCase(addJob.rejected, (state, action) => {
                state.addJobLoading = false;
                if (action.payload.data.message) {
                    state.addJobError = action.payload.data.message;
                }
                else {
                    state.addJobError = action.error.message;
                }
                state.addJobSuccess = false;
            })

            // Handle getJobData by ID
            .addCase(getJobData.pending, (state) => {
                state.getAddedJobDataLoading = true;
            })
            .addCase(getJobData.fulfilled, (state, action) => {
                const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                state.getAddedJobData = data.response;
                state.getAddedJobDataLoading = false;
            })
            .addCase(getJobData.rejected, (state) => {
                state.getAddedJobDataLoading = false;
            })

            // Handle updateJob
            .addCase(updateJob.pending, (state) => {
                state.updateJobSuccess = false;
            })
            .addCase(updateJob.fulfilled, (state, action) => {
                state.updateData = action.payload;
                state.updateJobSuccess = true;
                state.updateJobError = ''
            })
            .addCase(updateJob.rejected, (state, action) => {
                console.log(action, 'action');
                state.updateJobSuccess = false;
                if (action.payload.data.message) {
                    state.updateJobError = action.payload.data.message;
                }
                else {
                    state.updateJobError = action.error.message;
                }
                // state.updateJobError = action?.payload?.data?.errors[0]?.message ? action?.payload?.data?.errors[0]?.message : '';
            })

            // Update job status
            .addCase(jobStatus.pending, state => {
                state.jobStatusLoading = true;
                state.jobStatusSuccess = false;
                state.jobStatusError = false;
                state.jobStatusErrorMessage = "";
                state.deleteJobLoading = false;
                state.deleteJobError = false;
                state.deleteJobSuccess = false;
                state.deleteJobErrorMessage = "";
            })
            .addCase(jobStatus.fulfilled, (state, action) => {
                state.jobStatusSuccess = true;
                state.jobStatusLoading = false;
            })
            .addCase(jobStatus.rejected, (state, action) => {
                state.jobStatusError = true;
                state.jobStatusLoading = false;
                state.jobStatusErrorMessage = action.error.message;
            })

            // Handle Deletejob
            .addCase(deleteJob.pending, state => {
                state.deleteJobLoading = true;
                state.deleteJobError = false;
                state.deleteJobSuccess = false;
                state.deleteJobErrorMessage = "";
                state.jobStatusLoading = false;
                state.jobStatusSuccess = false;
                state.jobStatusError = false;
                state.jobStatusErrorMessage = "";
            })
            .addCase(deleteJob.fulfilled, (state, action) => {
                state.deleteJobSuccess = true;
                state.deleteJobLoading = false;
            })
            .addCase(deleteJob.rejected, (state, action) => {
                state.deleteJobError = true;
                state.deleteJobLoading = false;
                state.deleteJobErrorMessage = action.error.message;
            })

            // Handle jobSequence
            .addCase(jobSequence.pending, (state) => {
                state.jobSortLoading = true;
                state.jobSortSuccess = false;
                state.jobSortError = false;
            })
            .addCase(jobSequence.fulfilled, (state) => {
                state.jobSortSuccess = true;
                state.jobSortLoading = false;
            })
            .addCase(jobSequence.rejected, (state, action) => {
                state.jobSortError = true;
                state.jobSortLoading = false;
            });
    },
});

export const { resetSuccessFlag } = jobSlice.actions;

export const jobSelector = (state) => state[namespace];

export default jobSlice.reducer;

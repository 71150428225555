import { Spin } from "antd";
import "./Loader.css";

const LoaderIcon = () => {
   return (
      <div id="loader-wrapper">
         <div id="loader"></div>

         <div className="loader-section section-left"></div>
         <div className="loader-section section-right"></div>
         <div className="loader-bg"></div>
      </div>
   );
};

const Loader = ({ loading = true }) => {
   return <Spin indicator={<LoaderIcon />} spinning={loading} fullscreen />;
};

export default Loader;

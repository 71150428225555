import { SyncOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComponent from "../fields/ButtonComponent";
import SearchComponent from "../fields/SearchComponent";
import SwalComponent from "../fields/SwalComponent";
import TableComponent from "../fields/TableComponent";
import { clientStatus, deleteClient, getClientList } from "../store/reducer/clientSlice";
import PageHeader from "../view/pageheader";
import moment from "moment";
import Loader from "../fields/loader/Loader";

const Clients = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   //Selectors
   const {
      clientListData,
      clientListLoading,
      deleteClientLoading,
      deleteClientSuccess,
      deleteClientError,
      deleteClientErrorMessage,
      clientStatusLoading,
      clientStatusSuccess,
      clientStatusError,
      clientStatusErrorMessage,
   } = useSelector(state => state.client);
   const roleID = localStorage.getItem('role_id')
   const category_type = localStorage.getItem('category_type')
   const [statusType] = useState({
      1: "Active",
      0: "Inactive",
   });

   const { count: totalCount, data: tableData } = clientListData;

   function getData(payload = {}) {
      dispatch(getClientList(payload));
   }

   useEffect(() => {
      getData({});

      if (deleteClientSuccess) {
         toast.success("Client deleted successfully");
      }

      if (deleteClientError) {
         toast.error(deleteClientErrorMessage);
      }

      if (clientStatusSuccess) {
         toast.success("Client status updated successfully");
      }

      if (clientStatusError) {
         toast.error(clientStatusErrorMessage);
      }
   }, [deleteClientError, deleteClientSuccess, clientStatusError, clientStatusSuccess]);

   console.log(tableData, 'tableData');

   const columns = [
      {
         title: "Client ID",
         key: "client_number",
         align: "center",
         render: record => (
            <Link to={`/editclient/${btoa(record?.id)}`}>
               <span>{record.client_number}</span>
            </Link>
         ),
         sorter: true,
      },
      {
         title: "Last Name",
         dataIndex: "last_name",
         key: "last_name",
         align: "center",
         sorter: true,
      },
      {
         title: "City",
         dataIndex: "city",
         key: "city",
         align: "center",
         sorter: true,
      },
      {
         title: "State",
         dataIndex: "state",
         key: "state",
         align: "center",
         sorter: true,
      },
      {
         title: "Status",
         key: "client_status",
         align: "center",
         render: record => {
            console.log(record,'record');
            
            if (roleID === '2' && category_type === '3') {
               return <span>{statusType[record.client_status]}</span>
            }
            else {
              return <SwalComponent
                  message="Are you sure you want to change the client status?"
                  onClickDispatch={clientStatus}
                  id={record.id}
                  status={true}
                  statusValue={record.client_status}
                  postData={{ client_status: record.client_status === 1 ? 0 : 1 }}
               />
            }
         }
         ,
         sorter: true,
      },
      {
         title: "Created",
         key: "created_at",
         align: "center",
         render: record => (
            <span>
               {" "}
               {moment(record?.created_at).format("MM/DD/YY")}
            </span>
         ),
         sorter: true,
      },
      {
         title: "Last Updated",
         key: "updated_at",
         align: "center",
         render: record => (
            <span>
               {" "}
               {moment(record?.updated_at).format("MM/DD/YY")}
            </span>
         ),
         sorter: true,
      },
      ...(roleID === '2' && category_type === '3' ? [] : [
         {
            title: "Action",
            align: "center",
            render: (record) => {
               return (
                  <Space size="large" align="center">
                     <span className="fs-6" style={{ color: "blue", cursor: "pointer" }}>
                        <Tooltip title="Edit">
                           <i
                              className="icon-pencil"
                              onClick={() => {
                                 console.log(record, "record");
                                 navigate(`/editclient/${btoa(record?.id)}`);
                              }}
                           ></i>
                        </Tooltip>
                     </span>
                     <SwalComponent
                        message="Are you sure you want to delete the client?"
                        onClickDispatch={deleteClient}
                        id={record.id}
                     />
                  </Space>
               );
            },
         },
      ])
   ];

   return (
      <div>
         <Loader loading={clientListLoading || deleteClientLoading || clientStatusLoading} />
         <div className="container-fluid">
            <PageHeader
               HeaderText="Clients Management"
               Breadcrumb={[{ name: "Clients Management" }]}
            />
            <Card
               title="Clients Management"
               extra={
                  <Space size={"middle"} className="my-3">
                     <SearchComponent
                        placeHolder="Search Client"
                        onChange={e => {
                           const payloadData = {
                              query: e.target.value,
                           };
                           getData(payloadData);
                        }}
                     />
                     {roleID === '2' && category_type === '3' ? null :
                        <Link to="/addclient">
                           <ButtonComponent
                              style={{ backgroundColor: "#f7931e", color: "white" }}
                              buttonName="Add Client"
                              icon={<i className="fa fa-user-plus" aria-hidden="true"></i>}
                           />
                        </Link>}
                     {/* <Tooltip title="Refresh">
                        <Button
                           shape="circle"
                           icon={
                              <SyncOutlined
                                 spin={
                                    clientListLoading || deleteClientLoading || clientStatusLoading
                                 }
                              />
                           }
                           onClick={getData}
                        ></Button>
                     </Tooltip> */}
                  </Space>
               }
            >
               <TableComponent
                  columns={columns}
                  dataSource={tableData}
                  TotalDataCount={totalCount}
                  onChangeDispatch={getData}
               />
            </Card>
         </div>
      </div>
   );
};

export default Clients;

import { SyncOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComponent from "../fields/ButtonComponent";
import SearchComponent from "../fields/SearchComponent";
import SwalComponent from "../fields/SwalComponent";
import TableComponent from "../fields/TableComponent";
import {
   deletePhotographer,
   getPhotographerList,
   photographerStatus,
} from "../store/reducer/photographerSlice";
import PageHeader from "../view/pageheader";
import Loader from "../fields/loader/Loader";

const Photographer = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   //Selectors
   const {
      photographerListData,
      photographerListLoading,
      deletePhotographerLoading,
      deletePhotographerSuccess,
      deletePhotographerError,
      deletePhotographerErrorMessage,
      photographerStatusLoading,
      photographerStatusSuccess,
      photographerStatusError,
      photographerStatusErrorMessage,
   } = useSelector(state => state.photographer);
   const roleID = localStorage.getItem('role_id')
    const category_type = localStorage.getItem('category_type')
    const [statusType] = useState({
      1: "Active",
      0: "Inactive",
   });

   const { count: totalCount, data: tableData } = photographerListData;

   console.log(totalCount, tableData, 'table');

   function getData(payload = {}) {
      dispatch(getPhotographerList(payload));
   }

   useEffect(() => {
      getData({});

      if (deletePhotographerSuccess) {
         toast.success("Photographer deleted successfully");
      }

      if (deletePhotographerError) {
         toast.error(deletePhotographerErrorMessage);
      }

      if (photographerStatusSuccess) {
         toast.success("Photographer status updated successfully");
      }

      if (photographerStatusError) {
         toast.error(photographerStatusErrorMessage);
      }
   }, [
      deletePhotographerError,
      deletePhotographerSuccess,
      photographerStatusSuccess,
      photographerStatusError,
   ]);

   const columns = [
      {
         title: "Name",
         key: "first_name",
         align: "center",
         render: record => `${record.first_name} ${record.last_name}`,
         sorter: true,
      },
      {
         title: "City",
         dataIndex: "city",
         key: "city",
         align: "center",
         sorter: true,
      },
      {
         title: "State",
         dataIndex: "state",
         key: "state",
         align: "center",
         sorter: true,
      },
      {
         title: "Zip",
         dataIndex: "zip_code",
         key: "zip_code",
         align: "center",
         sorter: true,
      },
      {
         title: "Status",
         key: "status",
         align: "center",
         render: record => {
            if(roleID === '2' && category_type === '3'){
               return <span>{statusType[record.status]}</span>
            }
            else{
            return <SwalComponent
               message="Are you sure you want to change the photographer status?"
               onClickDispatch={photographerStatus}
               id={record.id}
               status={true}
               statusValue={record.status}
               postData={{ status: record.status === 1 ? 0 : 1, user_id: record.user_id }}
            />
            }
         },
         sorter: true,
      },
      ...(roleID === '2' && category_type === '3' ? [] : [
         {
           title: "Action",
           align: "center",
           render: (record) => {
             if (roleID === '2' && category_type === '2') {
               // Render only the edit action
               return (
                 <Space size="large" align="center">
                   <span className="fs-6" style={{ color: "blue", cursor: "pointer" }}>
                     <Tooltip title="Edit">
                       <i
                         className="icon-pencil"
                         onClick={() => {
                           console.log(record, "record");
                           navigate(`/editphotographer/${btoa(record?.id)}`);
                         }}
                       ></i>
                     </Tooltip>
                   </span>
                 </Space>
               );
             }
     
             // Render the full action column (edit and delete)
             return (
               <Space size="large" align="center">
                 <span className="fs-6" style={{ color: "blue", cursor: "pointer" }}>
                   <Tooltip title="Edit">
                     <i
                       className="icon-pencil"
                       onClick={() => {
                         console.log(record, "record");
                         navigate(`/editphotographer/${btoa(record?.id)}`);
                       }}
                     ></i>
                   </Tooltip>
                 </span>
                 <SwalComponent
                   message="Are you sure you want to delete the photographer?"
                   onClickDispatch={deletePhotographer}
                   id={record.id}
                 />
               </Space>
             );
           },
         },
       ])
   ];

   return (
      <div>
         <Loader
            loading={
               photographerListLoading || deletePhotographerLoading || photographerStatusLoading
            }
         />
         <div className="container-fluid">
            <PageHeader
               HeaderText="Inventory Team"
            />
            <Card
               title="Inventory Team"
               extra={
                  <Space size={"middle"} className="my-3">
                     <SearchComponent
                        placeHolder="Search Photographer"
                        onChange={e => {
                           const payloadData = {
                              query: e.target.value,
                           };
                           getData(payloadData);
                        }}
                     />
                     {(roleID === '2' && (category_type === '2' || category_type === '3')) ? null :
                     <Link to="/addphotographer">
                        <ButtonComponent
                           style={{ backgroundColor: "#f7931e", color: "white" }}
                           buttonName="Add Photographer"
                           icon={<i className="fa fa-user-plus" aria-hidden="true"></i>}
                        />
                     </Link>}
                     {/* <Tooltip title="Refresh">
                        <Button
                           shape="circle"
                           icon={
                              <SyncOutlined
                                 spin={
                                    photographerListLoading ||
                                    deletePhotographerLoading ||
                                    photographerStatusLoading
                                 }
                              />
                           }
                           onClick={getData}
                        ></Button>
                     </Tooltip> */}
                  </Space>
               }
            >
               <TableComponent
                  columns={columns}
                  dataSource={tableData}
                  TotalDataCount={tableData?.length}
                  onChangeDispatch={getData}
               />
            </Card>
         </div>
      </div>
   );
};

export default Photographer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createStaff, editStaff, getDataByID, staffDelete, staffList, updateStaffStatus } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "staff";

// Async thunk for fetching the staff list
export const getStaffList = createAsyncThunk(
    `${namespace}/getStaffList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await staffList(query, page, limit, sortby, order);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a staff
export const addStaff = createAsyncThunk(
    `${namespace}/staff`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await createStaff({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for fetching staff data by ID
export const getStaffData = createAsyncThunk(
    `${namespace}/staffbyId`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await getDataByID(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating staff data
export const updateStaff = createAsyncThunk(
    `${namespace}/UpdateCategory`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editStaff({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const staffStatus = createAsyncThunk(
    `${namespace}/UpdateStatus`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await updateStaffStatus({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const deleteStaff = createAsyncThunk(
    `${namespace}/staffDelete`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await staffDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const staffSlice = createSlice({
    name: namespace,
    initialState: {
        staffListData: [],
        staffListLoading: false,
        addStaffLoading: false,
        addStaffSuccess: false,
        staffErrorMessages: null,
        addedStaff: null,
        getAddedData: [],
        updateData: [],
        updateStaffSuccess: false,
        deleteStaffLoading: false,
        deleteStaffSuccess: false,
        deleteStaffError: false,
        deleteStaffErrorMessage: "",
        staffStatusLoading: false,
        staffStatusSuccess: false,
        staffStatusError: false,
        staffStatusErrorMessage: "",
    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle getStaffList
            .addCase(getStaffList.pending, (state) => {
                state.staffListLoading = true;
                state.addStaffLoading = false;
                state.addStaffSuccess = false;
                state.staffErrorMessages = null;
                state.addedStaff = null;
                state.getAddedData = [];
                state.updateData = [];
                state.updateStaffSuccess = false;
                state.deleteStaffLoading = false;
                state.deleteStaffSuccess = false;
                state.deleteStaffError = false;
                state.deleteStaffErrorMessage = "";
                state.staffStatusLoading = false;
                state.staffStatusSuccess = false;
                state.staffStatusError = false;
                state.staffStatusErrorMessage = "";
            })
            .addCase(getStaffList.fulfilled, (state, action) => {
                const data = action.payload;
                state.staffListData = data;
                state.staffListLoading = false;
                state.staffErrorMessages = '';
            })
            .addCase(getStaffList.rejected, (state,action) => {
                state.staffListLoading = false;
                // state.staffErrorMessages = action?.payload?.data?.errors[0]?.message;
                state.staffErrorMessages = action.payload.data.message;
            })

            // Handle addStaff
            .addCase(addStaff.pending, (state) => {
                state.addStaffLoading = true;
                state.staffErrorMessages = null;
                state.addedStaff = null;
                state.addStaffSuccess = false;
            })
            .addCase(addStaff.fulfilled, (state, action) => {
                state.addStaffLoading = false;
                state.addedStaff = action.payload;
                state.addStaffSuccess = true;
                state.staffErrorMessages = '';
            })
            .addCase(addStaff.rejected, (state, action) => {
                console.log(action,'action');
                state.addStaffLoading = false;
                // state.staffErrorMessages = action?.payload?.data?.errors[0]?.message;
                state.staffErrorMessages = action.payload.data.message;
                state.addStaffSuccess = false;
            })

            // Handle getStaffData by ID
            .addCase(getStaffData.pending, (state) => { })
            .addCase(getStaffData.fulfilled, (state, action) => {
                const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                state.getAddedData = data.response;
                state.staffErrorMessages = '';
            })
            .addCase(getStaffData.rejected, (state,action) => {
                // state.staffErrorMessages = action?.payload?.data?.errors[0]?.message;
                state.staffErrorMessages = action.payload.data.message;

             })

            // Handle updateStaff
            .addCase(updateStaff.pending, (state) => {
                state.updateStaffSuccess = false;
            })
            .addCase(updateStaff.fulfilled, (state, action) => {
                state.updateData = action.payload;
                state.updateStaffSuccess = true;
                state.staffErrorMessages = '';
            })
            .addCase(updateStaff.rejected, (state,action) => {
                state.updateStaffSuccess = false;
                // state.staffErrorMessages = action?.payload?.data?.errors[0]?.message;
                state.staffErrorMessages = action.payload.data.message;
            })

            // Update staff status
            .addCase(staffStatus.pending, state => {
                state.staffStatusLoading = true;
                state.staffStatusSuccess = false;
                state.staffStatusError = false;
                state.staffStatusErrorMessage = "";
                state.deleteStaffLoading = false;
                state.deleteStaffError = false;
                state.deleteStaffSuccess = false;
                state.deleteStaffErrorMessage = "";
            })
            .addCase(staffStatus.fulfilled, (state, action) => {
                state.staffStatusSuccess = true;
                state.staffStatusLoading = false;
                state.staffErrorMessages = '';
            })
            .addCase(staffStatus.rejected, (state, action) => {
                state.staffStatusError = true;
                state.staffStatusLoading = false;
                // state.staffErrorMessages = action?.payload?.data?.errors[0]?.message;
                state.staffErrorMessages = action.payload.data.message;

            })

            // Handle DeleteStaff
            .addCase(deleteStaff.pending, state => {
                state.deleteStaffLoading = true;
                state.deleteStaffError = false;
                state.deleteStaffSuccess = false;
                state.deleteStaffErrorMessage = "";
                state.staffStatusLoading = false;
                state.staffStatusSuccess = false;
                state.staffStatusError = false;
                state.staffStatusErrorMessage = "";
            })
            .addCase(deleteStaff.fulfilled, (state, action) => {
                state.deleteStaffSuccess = true;
                state.deleteStaffLoading = false;
                state.staffErrorMessages = '';
            })
            .addCase(deleteStaff.rejected, (state, action) => {
                state.deleteStaffError = true;
                state.deleteStaffLoading = false;
                // state.staffErrorMessages = action?.payload?.data?.errors[0]?.message;
                state.staffErrorMessages = action.payload.data.message;

            })
    },
});

export const { resetSuccessFlag } = staffSlice.actions;

export const staffSelector = (state) => state[namespace];

export default staffSlice.reducer;

import { SyncOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComponent from "../fields/ButtonComponent";
import SearchComponent from "../fields/SearchComponent";
import SwalComponent from "../fields/SwalComponent";
import TableComponent from "../fields/TableComponent";
// import { deleteStaff, getStaffList,staffStatus} from "../store/reducer/staffSlice";
import PageHeader from "../view/pageheader";
import Loader from "../fields/loader/Loader";
import { googleLogs } from "../store/reducer/reportsSlice";
import dayjs from "dayjs";

const GoogleLogs = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { googleLogsData, googleLogsLoading, googleLogsSuccess, googleLogsError, googleLogsErrorMessage } = useSelector(state => state.reports);
   const [categoryType] = useState({
      1: "Admin",
      2: "Management",
      3: "Production",
   });
   const roleID = localStorage.getItem('role_id')
   const category_type = localStorage.getItem('category_type')

   console.log(googleLogsData, 'googleLogsData');

   function getData(payload = {}) {
      dispatch(googleLogs(payload));
   }

   useEffect(() => {
      getData({})
   }, [])

   const columns = [
      {
         title: "Request Date",
         key: "first_name",
         align: "center",
         render: record => dayjs(record.request_date).format('MM/DD/YYYY'),
         sorter: true,
      },
      {
         title: "Project",
         key: "first_name",
         align: "center",
         render: record => record?.roomDetails?.jobs?.project?.project_name,
         sorter: true,
      },
      {
         title: "Job",
         key: "city",
         align: "center",
         render: record => record?.roomDetails?.jobs?.job_name,
         sorter: true,
      },
      {
         title: "Room",
         key: "state",
         align: "center",
         render: record => record?.roomDetails?.room_name,
         sorter: true,
      },
      {
         title: "Total Searched Items",
         dataIndex: "item_count",
         key: "item_count",
         align: "center",
         sorter: true,
      },
      {
         title: "Success Count",
         dataIndex: "success_count",
         key: "success_count",
         align: "center",
         sorter: true,
      },
      {
         title: "Search Status",
         key: "status",
         align: "center",
         render: record => {
            console.log(record?.roomDetails?.jobs?.job_name, 'record?.roomDetails?.jobs?.job_name');
            if (record.search_status === "1") {
               return <span style={{ color: 'red' }}>Inprogress</span>
            }
            else {
               return <span style={{ color: 'green' }}>Completed</span>
            }
         },
         sorter: true,
      }
   ];

   return (
      <div>
         {/* <Loader 
         // loading={staffListLoading || deleteStaffLoading || staffStatusLoading} 
         /> */}
         <div className="container-fluid">
            <PageHeader HeaderText="Google Search Logs" Breadcrumb={[{ name: "Staff List" }]} />
            <Card
               title="Google Search Logs"
               extra={
                  <Space size={"middle"} className="my-3">
                     {/* {roleID === '2' && category_type === '2' ? null :
                     <Link to="/addstaff">
                        <ButtonComponent
                           style={{ backgroundColor: "#f7931e", color: "white" }}
                           onClick={null}
                           buttonName="Add Staff"
                           icon={<i className="fa fa-user-plus" aria-hidden="true"></i>}
                        />
                     </Link>} */}
                     {/* <Tooltip title="Refresh">
                        <Button
                           shape="circle"
                           icon={
                              <SyncOutlined
                                 spin={staffListLoading || deleteStaffLoading || staffStatusLoading}
                              />
                           }
                           onClick={getData}
                        ></Button>
                     </Tooltip> */}
                  </Space>
               }
            >
               <TableComponent
                  columns={columns}
                  dataSource={googleLogsData?.data}
                  TotalDataCount={googleLogsData?.count}
                  onChangeDispatch={getData}
               />
            </Card>
         </div>
      </div>
   );
};

export default GoogleLogs;

import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

const baseUrl = getBaseEndpointUrl();

export const tryLogin = postData => {
    return Http.post(baseUrl + "auth/signin", postData);
};
export const forgotPassword = postData => {
    return Http.post(baseUrl + "auth/forgot-password", postData);
};
export const resetPassword = postData => {
    return Http.post(baseUrl + "auth/reset-password", postData);
};

export const dashboardData = (type,id,staff_admin) => {
    return Http.get(baseUrl + `dashboard/admin/${type}/${id}/${staff_admin}`, Http.getAuthToken());
};

export const staffDashboardData = (id) => {
    return Http.get(baseUrl + `dashboard/staff/${id}`, Http.getAuthToken());
};

export const dashboardJobData = (query, page, limit, sortby, order, type) => {
    return Http.get(baseUrl + `dashboard/joblist?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&type=${type}`, Http.getAuthToken());
};

//Edit Staff
export const jobStatusUpdate = (postData, job_id) => {
    return Http.put(baseUrl + `job/status/` + job_id, postData, Http.getAuthToken());
};


export const staffDashboardJobData = (query, page, limit, sortby, order, type, id) => {
    return Http.get(baseUrl + `dashboard/staffjoblist?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&type=${type}&id=${id}`, Http.getAuthToken());
};

export const clientDashboardJobData = (id, job_id) => {
    return Http.get(baseUrl + `dashboard/client/${id}/${job_id}`, Http.getAuthToken());
};

export const clientDashboardReviewData = (id, job_id) => {
    return Http.get(baseUrl + `dashboard/clientreview/${id}/${job_id}`, Http.getAuthToken());
};

// <================================== Staff Module =========================================>

// Get Staff List
export const staffList = (query, page, limit, sortby, order) => {
    return Http.get(
        `${baseUrl}staff/list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
        Http.getAuthToken()
    );
};

//Create Staff
export const createStaff = postData => {
    return Http.post(baseUrl + "staff/create", postData, Http.getAuthToken());
};

//Edit Staff
export const editStaff = (postData, updateID) => {
    console.log(updateID, 'updateID');
    return Http.put(baseUrl + `staff/` + updateID, postData, Http.getAuthToken());
};

export const getDataByID = (id) => {
    console.log(id, 'id');
    return Http.get(baseUrl + `staff/${Number(id)}`, Http.getAuthToken());
};

// Update Staff Status
export const updateStaffStatus = (postData, updateID) => {
    return Http.put(`${baseUrl}staff/status/${updateID}`, postData, Http.getAuthToken());
};

// Delete Staff
export const staffDelete = (deleteID) => {
    return Http.delete(`${baseUrl}staff/${deleteID}`, Http.getAuthToken());
};

// <================================== Staff Module ==========================================>


// <================================ Photographer Module =====================================>

// Get Photographer List
export const photographerList = (query, page, limit, sortby, order) => {
    return Http.get(
        `${baseUrl}photo/list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
        Http.getAuthToken()
    );
};

//Create Photographer
export const createPhotographer = postData => {
    return Http.post(baseUrl + "photo/create", postData, Http.getAuthToken());
};

//Edit Photographer
export const editPhotographer = (postData, updateID) => {
    console.log(updateID, 'updateID');
    return Http.put(baseUrl + `photo/` + updateID, postData, Http.getAuthToken());
};

export const getPhotographerDataByID = (id) => {
    console.log(id, 'id');
    return Http.get(baseUrl + `photo/${Number(id)}`, Http.getAuthToken());
};

// update photographer Status
export const updatePhotographerStatus = (postData, updateID) => {
    return Http.put(`${baseUrl}photo/status/${updateID}`, postData, Http.getAuthToken());
};

// Delete Photographer
export const photographerDelete = (deleteID) => {
    return Http.delete(`${baseUrl}photo/${deleteID}`, Http.getAuthToken());
};

// <================================ Photographer Module =====================================>


// <==================================== Client Module =======================================>

// Get Client List
export const clientList = (query, page, limit, sortby, order) => {
    return Http.get(
        `${baseUrl}client/list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`,
        Http.getAuthToken()
    );
};

//Create client
export const createClient = postData => {
    return Http.post(baseUrl + "client/create", postData, Http.getAuthToken());
};

//Edit client
export const editClient = (postData, updateID) => {
    console.log(updateID, 'updateID');
    return Http.put(baseUrl + `client/` + updateID, postData, Http.getAuthToken());
};

export const getClientDataByID = (id) => {
    console.log(id, 'id');
    return Http.get(baseUrl + `client/${Number(id)}`, Http.getAuthToken());
};

// Update client status
export const updateClientStatus = (postData, updateID) => {
    return Http.put(`${baseUrl}client/status/${updateID}`, postData, Http.getAuthToken());
};

// Delete Client
export const clientDelete = (deleteID) => {
    return Http.delete(`${baseUrl}client/${deleteID}`, Http.getAuthToken());
};

// <==================================== Client Module =======================================>

// <==================================== Project Module =======================================>

// Get Client List
export const projectList = (query, page, limit, sortby, order, id) => {
    console.log(id, 'idd');
    return Http.get(
        `${baseUrl}project/list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${id}`,
        Http.getAuthToken()
    );
};

//Create client
export const createProject = postData => {
    return Http.post(baseUrl + "project/create", postData, Http.getAuthToken());
};

//Edit client
export const editProject = (postData, updateID) => {
    console.log(updateID, 'updateID');
    return Http.put(baseUrl + `project/` + updateID, postData, Http.getAuthToken());
};

export const getProjectDataByID = (id) => {
    console.log(id, 'id');
    return Http.get(baseUrl + `project/${Number(id)}`, Http.getAuthToken());
};

// Update client status
export const updateProjectStatus = (postData, updateID) => {
    return Http.put(`${baseUrl}project/status/${updateID}`, postData, Http.getAuthToken());
};

// Delete Client
export const projectDelete = (deleteID) => {
    return Http.delete(`${baseUrl}project/${deleteID}`, Http.getAuthToken());
};

// <==================================== Project Module =======================================>

// <================================== Portal User Module =====================================>

const portalUserUrl = `${baseUrl}portaluser/`;

// Get Portal User List
export const portalUserList = (query, page, limit, sortby, order, id) => {
    console.log("id", id);
    return Http.get(
        `${portalUserUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${id}`,
        Http.getAuthToken()
    );
};

//Create Portal User
export const createPortalUser = postData => {
    return Http.post(`${portalUserUrl}create`, postData, Http.getAuthToken());
};

//Edit Portal User
export const editPortalUser = (postData, updateID) => {
    console.log(updateID, 'updateID');
    return Http.put(`${portalUserUrl + updateID}`, postData, Http.getAuthToken());
};

// get Portal User by ID
export const getPortalUserDataByID = (id) => {
    console.log(id, 'id');
    return Http.get(`${portalUserUrl + Number(id)}`, Http.getAuthToken());
};

// Update Portal User Status
export const updatePortalUserStatus = (postData, updateID) => {
    return Http.put(`${portalUserUrl}status/${updateID}`, postData, Http.getAuthToken());
};

// Delete Portal User
export const portalUserDelete = (deleteID) => {
    return Http.delete(`${portalUserUrl}${deleteID}`, Http.getAuthToken());
};

// get Portal User by ID
export const getPortalUserJobDetails = (id) => {
    console.log(id, 'id');
    return Http.get(`${portalUserUrl + "jobDetails/" + Number(id)}`, Http.getAuthToken());
};

// Executor User
export const getExecutorUserList = (id) => {
    console.log(id, 'id');
    return Http.get(`${portalUserUrl + "executorUserlist/" + id}`, Http.getAuthToken());
};

export const getExecutorClaimList = (page, limit, sortby, order, user_id, claimType) => {
    // return Http.get(`${portalUserUrl + "executorClaimlist/" + user_id + "/" + claimType}`, Http.getAuthToken());

    return Http.get(
        `${portalUserUrl}executorClaimlist?page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${user_id}&type=${claimType}`,
        Http.getAuthToken()
    );
};

export const executorClaimSettle = postData => {
    return Http.post(`${portalUserUrl}executorClaimSettle`, postData, Http.getAuthToken());
};

export const addItemDocuments = (formData) => {
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    return Http.post(`${portalUserUrl}addDocuments`, formData, options);
};

export const deleteItemDocuments = (id) => {
    return Http.delete(`${portalUserUrl}deleteDocuments/${id}`, Http.getAuthToken());
};

export const getItemDocuments = (user_id, itemId) => {
    return Http.get(`${portalUserUrl}getDocuments/${user_id}/${itemId}`, Http.getAuthToken());
};

// <================================== Portal User Module =====================================>


// <==================================== Jobs Module =======================================>

// Get Client List
export const jobList = (query, page, limit, sortby, order, id) => {
    return Http.get(
        `${baseUrl}job/list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${id}`,
        Http.getAuthToken()
    );
};

//Create client
export const createJob = postData => {
    return Http.post(baseUrl + "job/create", postData, Http.getAuthToken());
};

//Edit client
export const editJob = (postData, updateID) => {
    console.log(updateID, 'updateID');
    return Http.put(baseUrl + `job/` + updateID, postData, Http.getAuthToken());
};

export const getJobDataByID = (id) => {
    console.log(id, 'id');
    return Http.get(baseUrl + `job/${Number(id)}`, Http.getAuthToken());
};

// Update client status
export const updateJobStatus = (postData, updateID) => {
    return Http.put(`${baseUrl}job/status/${updateID}`, postData, Http.getAuthToken());
};

// Delete Client
export const jobDelete = (deleteID) => {
    return Http.delete(`${baseUrl}job/${deleteID}`, Http.getAuthToken());
};

//Sort Job

export const jobSort = (postData) => {
    return Http.put(baseUrl + "job/sorting", postData, Http.getAuthToken());
};

// <==================================== Jobs Module =======================================>

// <=============================== Item Categories Module =================================>

const itemCategoriesUrl = `${baseUrl}itemcategories/`;

// Get item Categories List
export const itemCategoriesList = (query, page, limit, sortby, order, id) => {
    return Http.get(
        `${itemCategoriesUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${id}`,
        Http.getAuthToken()
    );
};

//Create item Categories
export const createItemCategories = postData => {
    return Http.post(`${itemCategoriesUrl}create`, postData, Http.getAuthToken());
};

//Edit item Categories
export const editItemCategories = (postData, updateID) => {
    console.log(updateID, 'updateID');
    return Http.put(`${itemCategoriesUrl + updateID}`, postData, Http.getAuthToken());
};

// Delete item Categories
export const itemCategoriesDelete = (deleteID) => {
    return Http.delete(`${itemCategoriesUrl}${deleteID}`, Http.getAuthToken());
};

// <=============================== Item Categories Module =================================>

// <=============================== Rooms Module =================================>
// Get item Categories List

const roomsUrl = `${baseUrl}rooms/`;


export const roomsList = (query, page, limit, sortby, order, id) => {
    return Http.get(
        `${roomsUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${id}`,
        Http.getAuthToken()
    );
};

//Create item Categories
export const createRoom = postData => {
    return Http.post(`${roomsUrl}create`, postData, Http.getAuthToken());
};

//Edit item Categories
export const editRoom = (postData, updateID) => {
    console.log(updateID, 'updateID');
    return Http.put(`${roomsUrl + updateID}`, postData, Http.getAuthToken());
};

// Delete item Categories
export const roomDelete = (deleteID) => {
    return Http.delete(`${roomsUrl}${deleteID}`, Http.getAuthToken());
};

// get room items


export const getRoomItemDataById = (query, page, limit, sortby, order, room_id) => {
    console.log(room_id,'room_id');
    
    return Http.get(
        `${roomsUrl}images?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${room_id}`,
        Http.getAuthToken()
    );
};

//Room Batch Items

export const createBatchItems = (formData) => {
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    return Http.post(`${roomsUrl}batchImages`, formData, options);
};

export const createSequenceItems = (formData) => {
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    return Http.post(`${roomsUrl}sequenceImages`, formData, options);
};

export const additionalSequenceItems = (formData) => {
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    return Http.post(`${roomsUrl}addItemSequenceImages`, formData, options);
};

export const editImage = postData => {
    return Http.post(`${roomsUrl}editSequenceImages`, postData, Http.getAuthToken());
};

export const getClientPortalRoomItemDataById = postData => {
    return Http.post(`${baseUrl}portaluser/getUserRoomList`, postData, Http.getAuthToken());
};

export const deleteImage = postData => {
    return Http.post(`${roomsUrl}deleteItemSequence`, postData, Http.getAuthToken());
};

export const deleteItem = (id) => {
    return Http.delete(`${roomsUrl}deleteItem/${id}`, Http.getAuthToken());
};

export const sequenceImage = postData => {
    return Http.post(`${roomsUrl}sequenceImageSerial`, postData, Http.getAuthToken());
};

// <=============================== General API =================================>
const generalUrl = `${baseUrl}general/`;

export const statesList = () => {
    return Http.get(`${generalUrl}getStateList`, Http.getAuthToken());
};

// <=============================== General API =================================>

// <=============================== Panos API =================================>

export const createPanoItems = (formData) => {
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    return Http.post(baseUrl + 'pano/uploadpanoImages', formData, options);
};

export const panoList = (query, page, limit, sortby, order, id) => {
    return Http.get(baseUrl + `pano/panolist?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${id}`, Http.getAuthToken());
};

export const hotspotView = (postData, updateID) => {
    console.log(updateID, 'updateID');
    return Http.put(`${baseUrl + 'pano/updatePano/' + updateID}`, postData, Http.getAuthToken());
};

export const panoSort = (postData) => {
    return Http.put(baseUrl + "pano/sorting", postData, Http.getAuthToken());
};

// Delete Pano
export const panoDelete = (deleteID) => {
    return Http.delete(`${baseUrl}pano/${deleteID}`, Http.getAuthToken());
};

export const getPhotographerProjectDataByID = (id, status) => {
    console.log(id, 'id');
    return Http.get(baseUrl + `photo/projects/${Number(id)}/${status}`, Http.getAuthToken());
};

export const getPhotographerJobsDataByID = (id) => {
    console.log(id, 'id');
    return Http.get(baseUrl + `photo/jobs/${Number(id)}`, Http.getAuthToken());
};

export const getPhotographerRoomsDataByID = (id) => {
    console.log(id, 'id');
    return Http.get(baseUrl + `photo/rooms/${Number(id)}`, Http.getAuthToken());
};


// <=============================== Client/Portal User API =================================>

export const getClientUserJobsById = (id) => {
    console.log(id, 'id');
    return Http.get(`${portalUserUrl}userJobs/${Number(id)}`, Http.getAuthToken());
};

export const getDemoClientUserJobsById = (id) => {
    console.log(id, 'id');
    return Http.get(`${baseUrl}demo/jobs/${Number(id)}`, Http.getAuthToken());
};

export const getClientUserRoomsById = (query, page, limit, sortby, order,id, job_id) => {
    console.log(id, 'id');
    return Http.get(`${portalUserUrl}userRooms?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${Number(id)}&job_id=${Number(job_id)}`, Http.getAuthToken());
};

export const getDemoClientUserRoomsById = (job_id) => {
    return Http.get(`${baseUrl}demo/rooms/${Number(job_id)}`, Http.getAuthToken());
};

export const getClientUserProjectById = (project_id) => {
    return Http.get(`${portalUserUrl}getProjectType/${Number(project_id)}`, Http.getAuthToken());
};

export const getClientUserPanosById = (job_id,room_id) => {
    return Http.get(`${portalUserUrl}userPanos/${Number(job_id)}/${Number(room_id)}`, Http.getAuthToken());
};

export const getClientClaimDetailsById = (id, user_id, manager) => {
    console.log(id, 'idCheck');
    return Http.get(`${portalUserUrl}getItemClaim/${Number(id)}/${Number(user_id)}/${manager}`, Http.getAuthToken());
};
export const updateClientClaimDetails = (postData) => {
    return Http.post(`${portalUserUrl}addItemClaim`, postData, Http.getAuthToken());
};

// <=============================== Reports API =================================>

const googleUrl = `${baseUrl}google/`;

export const googleSearchProduct = (postData) => {
    return Http.post(`${googleUrl}searchProduct`, postData, Http.getAuthToken());
};

export const getAssignedToById = (id) => {
    return Http.get(`${portalUserUrl}getAssignto/${id}`, Http.getAuthToken());
};

export const createAssignedTo = (postData) => {
    return Http.post(`${portalUserUrl}addAssignto`, postData, Http.getAuthToken());
};

// <=============================== Reports API =================================>

const reportsUrl = `${baseUrl}reports/`;

export const getClientReports = (query, page, limit, sortby, order, user_id, type) => {
    return Http.get(`${reportsUrl}clientlist/?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${user_id}&type=${type}`, Http.getAuthToken());
};

export const getGoogleLogs = (query, page, limit, sortby, order) => {
    return Http.get(`${baseUrl}google/googlelogs/?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}`, Http.getAuthToken());
};

export const getAdminReports = (query, page, limit, sortby, order, user_id) => {
    return Http.get(`${reportsUrl}adminlist?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${user_id}`, Http.getAuthToken());
};

export const reportShare = (formData) => {
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    return Http.post(`${reportsUrl}reportShare`, formData, options);
};

import { UnorderedListOutlined } from "@ant-design/icons";
import { Card, Input, Modal, Row, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComponent from "../../fields/ButtonComponent";
import ItemCategoryCard from "../../fields/ItemCategoryCard";
import SearchComponent from "../../fields/SearchComponent";
import {
   addItemCategories,
   deleteItemCategories,
   getItemCategoriesList,
   updateItemCategories,
} from "../../store/reducer/itemCategoriesSlice";
import PageHeader from "../../view/pageheader";

const ItemCategories = () => {
   const dispatch = useDispatch();
   const inputRef = useRef(null);
   const { project_id, client_id } = useParams();
   //Selectors
   const {
      itemCategoriesListData,
      itemCategoriesListLoading,
      addItemCategoriesLoading,
      addItemCategoriesSuccess,
      addItemCategoriesError,
      addItemCategoriesErrorMessage,
      updateItemCategoriesSuccess,
      updateItemCategoriesError,
      updateItemCategoriesErrorMessage,
      deleteItemCategoriesLoading,
      deleteItemCategoriesSuccess,
      deleteItemCategoriesError,
      deleteItemCategoriesErrorMessage,
   } = useSelector(state => state.itemCategories);
   const roleID = localStorage.getItem("role_id");
   const category_type = localStorage.getItem("category_type");

   const { count: totalCount, data: categoryList } = itemCategoriesListData;
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [addItemCategory, setAddItemCategory] = useState({
      category_name: "",
      project_id: atob(project_id),
   });

   const handleSave = () => {
      if (addItemCategory.category_name) {
         setIsModalOpen(false);
         let postData = addItemCategory;
         dispatch(addItemCategories({ postData }));
         setAddItemCategory({ ...addItemCategory, category_name: "" });
      } else {
         toast.error("Please enter item category.")
      }
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      setAddItemCategory({ ...addItemCategory, category_name: "" });
   };

   function getData(data) {
      const payload = { ...data, id: atob(project_id) };
      console.log("payload", payload);
      dispatch(getItemCategoriesList(payload));
   }

   useEffect(() => {
      getData();

      if (addItemCategoriesSuccess) {
         toast.success("Item category added Successfully");
      }
      if (addItemCategoriesError) {
         toast.error(addItemCategoriesErrorMessage);
      }

      if (updateItemCategoriesSuccess) {
         toast.success("Item category updated successfully");
      }

      if (updateItemCategoriesError) {
         toast.error(updateItemCategoriesErrorMessage);
      }

      if (deleteItemCategoriesSuccess) {
         toast.success("Item category deleted successfully");
      }

      if (deleteItemCategoriesError) {
         toast.error(updateItemCategoriesErrorMessage);
      }
   }, [
      addItemCategoriesSuccess,
      addItemCategoriesError,
      updateItemCategoriesSuccess,
      updateItemCategoriesError,
      deleteItemCategoriesSuccess,
      deleteItemCategoriesError,
   ]);

   useEffect(() => {
      if (isModalOpen) {
         setTimeout(() => {
            inputRef.current.focus();
         }, 300);
      }
   }, [isModalOpen]);

   return (
      <div className="pe-2 mb-3 mt-2">
         <PageHeader
            HeaderText="Item Categories"
            Breadcrumb={[{ name: "Item Categories" }]}
            backButtonUrl={`/editproject/${project_id}/${client_id}`}
         />
         <Card
            title="Item Categories"
            style={{ minHeight: 300 }}
            extra={
               <Space size={"middle"} className="my-3">
                  <SearchComponent
                     placeHolder="Search Item Category"
                     onChange={e => {
                        const payloadData = {
                           query: e.target.value,
                        };
                        getData(payloadData);
                     }}
                  />
                  {roleID === "2" && (category_type === "3" || category_type === "2") ? null : (
                     <ButtonComponent
                        style={{ backgroundColor: "#f7931e", color: "white" }}
                        buttonName="Add Category"
                        icon={<UnorderedListOutlined />}
                        onClick={() => setIsModalOpen(true)}
                     />
                  )}
                  <Modal
                     destroyOnClose
                     title="Add Item Category"
                     open={isModalOpen}
                     onOk={handleSave}
                     okText="Save"
                     okButtonProps={{ shape: "round", style: { backgroundColor: "#f7931e" } }}
                     cancelButtonProps={{
                        shape: "round",
                        style: { backgroundColor: "#6E6E6E", color: "white" },
                     }}
                     onCancel={handleCancel}
                  >
                     <Input
                        ref={inputRef}
                        placeholder="Item Category"
                        value={addItemCategory.category_name}
                        onChange={e =>
                           setAddItemCategory({ ...addItemCategory, category_name: e.target.value })
                        }
                        onPressEnter={handleSave}
                     />
                  </Modal>
               </Space>
            }
         >
            <Row gutter={[10, 10]}>
               {categoryList &&
                  categoryList.map(category => (
                     <ItemCategoryCard
                        key={category.id}
                        category={category.category_name}
                        id={category.id}
                        projectId={category.project_id}
                        onUpdate={updateItemCategories}
                        onDelete={roleID === "2" && category_type === "2" ? null : deleteItemCategories}
                        icons={roleID === "2" && category_type === "3" ? false : true}
                     />
                  ))}
            </Row>
         </Card>
      </div>
   );
};

export default ItemCategories;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getClientReports, getAdminReports, getGoogleLogs, reportShare } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "reports";

// Async thunk for fetching the Portal User list
export const clientReports = createAsyncThunk(
   `${namespace}/getClientReports`,
   async (
      { query = "", page = 0, limit = 0, sortby = "", order = "", user_id = "", type = "" },
      { rejectWithValue }
   ) => {
      try {
         const data = await getClientReports(query, page, limit, sortby, order, user_id, type);
         return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
      } catch (error) {
         return rejectWithValue(error.response);
      }
   }
);

// Async thunk for fetching the Portal User list
export const adminReports = createAsyncThunk(
   `${namespace}/getAdminReports`,
   async (
      { query = "", page = 0, limit = 0, sortby = "", order = "", user_id = "" },
      { rejectWithValue }
   ) => {
      try {
         const data = await getAdminReports(query, page, limit, sortby, order, user_id);
         return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
      } catch (error) {
         return rejectWithValue(error.response);
      }
   }
);

// Async thunk for fetching the Portal User list
export const googleLogs = createAsyncThunk(
   `${namespace}/googleLogs`,
   async ({ query = "", page = 1, limit = 10, sortby = "", order = "" }, { rejectWithValue }) => {
      try {
         const data = await getGoogleLogs(query, page, limit, sortby, order);
         console.log(data, "data");
         return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
      } catch (error) {
         return rejectWithValue(error.response);
      }
   }
);

export const emailShare = createAsyncThunk(
   `${namespace}/reportShare`,
   async ({ formData }, { rejectWithValue }) => {
      try {
         const data = await reportShare(formData);
         return data;
      } catch (error) {
         return rejectWithValue(error.response);
      }
   }
);

const reports = createSlice({
   name: namespace,
   initialState: {
      clientReportsData: { count: 0, rows: [] },
      clientReportsLoading: false,
      clientReportsSuccess: false,
      clientReportsError: false,
      clientReportsErrorMessage: "",
      googleLogsData: { count: 0, rows: [] },
      googleLogsLoading: false,
      googleLogsSuccess: false,
      googleLogsError: false,
      googleLogsErrorMessage: "",
      emailShareLoading: false,
      emailShareSuccess: false,
      emailShareError: false,
      emailShareErrorMessage: false,
   },
   reducers: {
      resetSuccessFlag: (state, action) => {
         const { flag } = action.payload;
         state[flag] = false;
      },
      resetEmailShareState: (state, action) => {
         state.emailShareLoading = false;
         state.emailShareSuccess = false;
         state.emailShareError = false;
         state.emailShareErrorMessage = false;
      },
   },
   extraReducers: builder => {
      builder
         // Handle get client reports
         .addCase(clientReports.pending, state => {
            state.clientReportsData = { count: 0, rows: [] };
            state.clientReportsLoading = true;
            state.clientReportsSuccess = false;
            state.clientReportsError = false;
            state.clientReportsErrorMessage = "";
         })
         .addCase(clientReports.fulfilled, (state, action) => {
            state.clientReportsData = action.payload.responses;
            state.clientReportsSuccess = true;
            state.clientReportsLoading = false;
         })
         .addCase(clientReports.rejected, (state, action) => {
            const payload = action.payload;
            state.clientReportsError = true;
            state.clientReportsErrorMessage =
               payload && "errors" in payload ? payload.errors[0].message : payload.message;
            state.clientReportsLoading = false;
         })

         // Handle get admin reports
         .addCase(adminReports.pending, state => {
            state.clientReportsData = { count: 0, rows: [] };
            state.clientReportsLoading = true;
            state.clientReportsSuccess = false;
            state.clientReportsError = false;
            state.clientReportsErrorMessage = "";
         })
         .addCase(adminReports.fulfilled, (state, action) => {
            state.clientReportsData = action.payload.roomItems;
            console.log("adminReports", action.payload);

            state.clientReportsSuccess = true;
            state.clientReportsLoading = false;
         })
         .addCase(adminReports.rejected, (state, action) => {
            const payload = action.payload.data;
            state.clientReportsError = true;
            state.clientReportsErrorMessage =
               "errors" in payload ? payload.errors[0].message : payload.message;
            state.clientReportsLoading = false;
         })

         // Handle get google reports
         .addCase(googleLogs.pending, state => {
            state.googleLogsData = { count: 0, rows: [] };
            state.googleLogsLoading = true;
            state.googleLogsSuccess = false;
            state.googleLogsError = false;
            state.googleLogsErrorMessage = "";
         })
         .addCase(googleLogs.fulfilled, (state, action) => {
            state.googleLogsData = action.payload;
            console.log("adminReports", action);

            state.googleLogsSuccess = true;
            state.googleLogsLoading = false;
         })
         .addCase(googleLogs.rejected, (state, action) => {
            const payload = action.payload;
            state.googleLogsError = true;
            // state.googleLogsErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;
            // state.googleLogsLoading = false;
         })

         .addCase(emailShare.pending, state => {
            state.emailShareLoading = true;
            state.emailShareSuccess = false;
            state.emailShareError = false;
            state.emailShareErrorMessage = false;
         })
         .addCase(emailShare.fulfilled, (state, action) => {
            console.log("reportShare", action);
            state.emailShareLoading = false;
            state.emailShareSuccess = true;
         })
         .addCase(emailShare.rejected, (state, action) => {
            console.log("reportShare", action);
            const payload = action.payload.data;
            state.emailShareLoading = false;
            state.emailShareError = false;
            state.emailShareErrorMessage =
               "errors" in payload ? payload.errors[0].message : payload.message;
         });
   },
});

export const { resetSuccessFlag, resetEmailShareState } = reports.actions;

export const reportsSelector = state => state[namespace];

export default reports.reducer;

import { HomeOutlined, PrinterFilled } from "@ant-design/icons";
import {
   Breadcrumb,
   Button,
   Card,
   Checkbox,
   Col,
   Divider,
   Flex,
   Pagination,
   Row,
   Space,
} from "antd";
import { useEffect, useState } from "react";
import { BsFillGridFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../fields/loader/Loader";
import { assestURL } from "../../services/config";
import { executorClaimList } from "../../store/reducer/portalUserSlice";
import { getProjectData } from "../../store/reducer/projectSlice";
import { adminReports, clientReports } from "../../store/reducer/reportsSlice";
import PageHeader from "../../view/pageheader";
import ReportItemCard from "./ReportItemCard";

const Reports = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();
   const { room_or_user_id, job_id, project_id, claim_type } = useParams();
   // const { reportItemID, previousUrl, room } = location?.state;
   const roleID = localStorage.getItem("role_id");
   const portalUserID = localStorage.getItem("client_id");
   const adminUserID = localStorage.getItem("user_id");
   const portalUserProjectID = localStorage.getItem("pu_project_id");
   const portalUserType = localStorage.getItem("pu_type");
   const {
      clientReportsData,
      clientReportsLoading,
      clientReportsSuccess,
      clientReportsError,
      clientReportsErrorMessage,
   } = useSelector(state => state.reports);
   const { count, rows } = clientReportsData;
   const { getAddedProjectData, getAddedProjectDataLoading } = useSelector(state => state.project);
   const { executorClaimListData, executorClaimListDataLoading, executorUserListDataLoading } =
      useSelector(state => state.portalUser);
   const userID = localStorage.getItem("pu_user_id");
   const [reportType, setReportType] = useState("grid");
   const [reportFieldsSchema] = useState({
      display_name: "UserName",
      name: "Item Name",
      client_value_range: "Client's Estimate",
      google_value_range: "AI Estimate",
      category: "Category",
      quantity: "Quantity",
      distribute_to: "Assign To",
      item_condition: "Condition",
      purchase_date: "Purchase Date",
      dispose_by: "Appraise",
      distribute: "Do you want this item?",
      claimed_as: "Reason for Claim",
      model: "Model",
      serial_number: "Serial Number",
      notes: "Notes",
   });
   const [reportFields, setReportFields] = useState(Object.keys(reportFieldsSchema));
   const [projectDetails, setProjectDetails] = useState({});
   const [selectAll, setSelectAll] = useState({});
   const [selectedItems, setSelectedItems] = useState({});
   const [selectedFields, setSelectedFields] = useState([]);
   const [pageSize, setPageSize] = useState(10);
   const [gridReportType, setGridReportType] = useState("normal");
   const [fullReportAvaliableUsers, setFullReportAvailableUsers] = useState(["1", "4"]);
   const [sortOrderReference, setSortOrderReference] = useState([]);
   const [inventoryUrl, setInventoryUrl] = useState("");
   const [isExecutorUser, setIsExecutorUser] = useState(true);
   const [reportItems, setReportItems] = useState([]);

   useEffect(() => {
      // console.log("SelectedItems ===>", selectedItems);
      // console.log(Object.values(reportFieldsSchema));
      // setSelectedFields(Object.keys(reportFieldsSchema));
      dispatch(getProjectData({ id: portalUserProjectID }));
      setInventoryUrl(`/clientportal/${project_id}/${job_id}/${room_or_user_id}`);
   }, []);

   useEffect(() => {
      let userType = ["2", "3"];
      const isExecutor = userType.includes(portalUserType);
      setIsExecutorUser(isExecutor);

      let valueExist = room_or_user_id && atob(room_or_user_id);
      let isClaimType = claim_type !== "null";

      console.log("roomOrUserID", room_or_user_id);
      console.log("claimtype", isClaimType ? claim_type : room_or_user_id);

      if (portalUserType == "2") {
         setInventoryUrl(
            `/client/executor/${job_id}/${isClaimType ? claim_type : room_or_user_id}`
         );
      }

      if (portalUserType == "3") {
         setInventoryUrl(`/client/user/${job_id}/${isClaimType ? claim_type : room_or_user_id}`);
      }

      if (isExecutor && valueExist) {
         dispatch(
            executorClaimList({
               page: 1,
               limit: pageSize,
               sortby: "",
               order: "",
               user_id: atob(room_or_user_id),
               claimType: claim_type,
            })
         );
      } else {
         setReportItems([]);
      }
   }, []);

   useEffect(() => {
      // setReportItems([]);
      if (!isExecutorUser && rows?.length) {
         setReportItems([...rows]);
      }

      if (isExecutorUser && executorClaimListData?.data) {
         setReportItems([...executorClaimListData.data]);
      }
   }, [rows, executorClaimListData, isExecutorUser]);

   useEffect(() => {
      console.log("ProjectData", getAddedProjectData);
      if (getAddedProjectData?.project_type) {
         setProjectDetails({ ...getAddedProjectData });
         const projectType = getAddedProjectData?.project_type;
         let fields = Object.keys(reportFieldsSchema);
         setSortOrderReference([...fields]);

         if (projectType == "3") {
            fields = fields.filter(item => item !== "claimed_as" && item !== "distribute");
         }

         if (projectType == "1") {
            fields = fields.filter(item => item !== "claimed_as");
         }

         if (projectType == "2") {
            fields = fields.filter(item => item !== "distribute_to");
         }

         if (Number(projectType) > 3) {
            fields = fields.filter(
               item => item !== "distribute_to" && item !== "claimed_as" && item !== "distribute"
            );
         }

         let userType = ["1", "4"];
         const isNormalUser = userType.includes(portalUserType);

         if (isNormalUser) {
            fields = fields.filter(item => item !== "display_name");
         }

         setSelectedFields(fields);

         const splitItems = (arr, size) => {
            const result = [];
            for (let i = 0; i < arr.length; i += size) {
               result.push(arr.slice(i, i + size));
            }
            return result;
         };

         // Chunk the data into arrays of 4 items each
         const chunkedData = splitItems(fields, 4);
         setReportFields(chunkedData);
      }
   }, [getAddedProjectData]);

   useEffect(() => {
      if (!isExecutorUser && portalUserID) {
         // dispatch(clientReports({ user_id: portalUserID }));
         console.log("roomOrUser", room_or_user_id);
         console.log("reportItemID", location.state);
         if (location?.state?.reportItemID?.length > 0) {
            setInventoryUrl(location?.state?.previousUrl);
            dispatch(
               clientReports({
                  user_id: portalUserID || "",
                  query: "",
                  page: 1,
                  limit: pageSize,
                  sortby: "",
                  order: "",
                  // type: room_or_user_id ? atob(room_or_user_id) : "all",
                  type: location?.state?.reportItemID?.join(),
               })
            );
         }
      }

      // else {
      //    // dispatch(adminReports({}));
      //    dispatch(
      //       adminReports({
      //          user_id: "",
      //          query: "",
      //          page: 1,
      //          limit: 50,
      //          sortby: "",
      //          order: "",
      //       })
      //    );
      // }
   }, [portalUserID, adminUserID, isExecutorUser]);

   useEffect(() => {
      console.log("clientReports", clientReportsData);
   }, [clientReportsData]);

   useEffect(() => {
      if (reportItems?.length) {
         const all = {};
         reportItems.forEach(item => (all[item.id] = true));
         // console.log("SelectAll ===>", all);
         // console.log("reportItems", reportItems);

         setSelectAll(all);
      }
   }, [reportItems]);

   const onChange = e => {
      setGridReportType(e.target.value);
   };

   const sortByReference = arr => {
      return arr.sort((a, b) => {
         return sortOrderReference.indexOf(a) - sortOrderReference.indexOf(b);
      });
   };

   return (
      <div>
         <Loader
            loading={
               clientReportsLoading ||
               getAddedProjectDataLoading ||
               executorClaimListDataLoading ||
               executorUserListDataLoading
            }
         />
         <div className="container-fluid">
            <PageHeader
               HeaderText="Reports"
               //    Breadcrumb={[{ name: "TakeStock Portal Users" }]}
               backButtonUrl={inventoryUrl}
               buttonName="Return to Inventory"
            />
            {portalUserType !== "2" && portalUserType !== "3" && (
               <Breadcrumb
                  style={{ marginTop: -20 }}
                  className="mb-1 ms-1"
                  separator=">"
                  items={[
                     {
                        title: (
                           <>
                              <HomeOutlined />
                              <span
                                 className="p-1 rounded"
                                 style={{ cursor: "pointer" }}
                                 onMouseEnter={e =>
                                    e.currentTarget.classList.add(
                                       "text-black",
                                       "bg-secondary-subtle"
                                    )
                                 }
                                 onMouseLeave={e =>
                                    e.currentTarget.classList.remove(
                                       "text-black",
                                       "bg-secondary-subtle"
                                    )
                                 }
                              >
                                 Home
                              </span>
                           </>
                        ),
                        onClick: () => navigate("/clientportal/home"),
                     },
                     {
                        title: (
                           <span
                              className="p-1 rounded"
                              style={{ cursor: "pointer" }}
                              onMouseEnter={e =>
                                 e.currentTarget.classList.add("text-black", "bg-secondary-subtle")
                              }
                              onMouseLeave={e =>
                                 e.currentTarget.classList.remove(
                                    "text-black",
                                    "bg-secondary-subtle"
                                 )
                              }
                           >
                              Inventory
                           </span>
                        ),
                        onClick: () => navigate(inventoryUrl),
                     },
                     {
                        title: "Reports",
                     },
                  ]}
               />
            )}
            <Card
               title={fullReportAvaliableUsers.includes(portalUserType) && "Select Report Type"}
               // title={
               //    <div>
               //       <label>Select Rooms :</label>
               //       <Select
               //          className="ms-2 w-25"
               //          placeholder="Select Rooms"
               //          mode="multiple"
               //          maxTagCount="responsive"
               //       >
               //          <Select.Option value="all" key="all">
               //             All
               //          </Select.Option>
               //       </Select>
               //    </div>
               // }
               type="inner"
            >
               {/* <Checkbox
                  checked={Object.keys(selectedItems).length === clientReportsData?.rows?.length}
                  onChange={e => {
                     if (e.target.checked) {
                        console.log("true ==> ", selectAll);
                        setSelectedItems({ ...selectAll });
                     } else {
                        setSelectedItems({});
                     }
                  }}
               >
                  Step 1 - Check box for all or select specific items to include in the report by
                  checking the boxes below them.
               </Checkbox> */}
               {portalUserType && fullReportAvaliableUsers.includes(portalUserType) && (
                  // <Card type="inner" size="small" title="Select Report Type">
                  <>
                     <Space>
                        <Button
                           icon={<FaList style={{ marginTop: -3 }} />}
                           type="default"
                           shape="round"
                           className={reportType === "full" ? "text-white border-0" : ""}
                           style={{ backgroundColor: reportType === "full" ? "#F7931E" : "" }}
                           onClick={() => setReportType("full")}
                        >
                           Full
                        </Button>
                        <Button
                           icon={<BsFillGridFill style={{ marginTop: -3 }} />}
                           type="default"
                           shape="round"
                           className={reportType === "grid" ? "text-white border-0" : ""}
                           style={{ backgroundColor: reportType === "grid" ? "#F7931E" : "" }}
                           onClick={() => setReportType("grid")}
                        >
                           Grid
                        </Button>
                     </Space>
                     {reportType === "full" && (
                        <Flex className="py-3 px-1 w-100" wrap="nowrap">
                           <Checkbox.Group
                              className="w-100"
                              value={selectedFields}
                              onChange={checkedValues => {
                                 setSelectedFields(checkedValues);
                                 console.log("checked = ", checkedValues);
                              }}
                           >
                              {/* {reportFields?.map(field => (
                                 <Checkbox key={field} className="mb-2" value={field}>
                                    {reportFieldsSchema[field]}
                                 </Checkbox>
                              ))} */}
                              {reportFields.map((item, index) => {
                                 return (
                                    <div className="me-5" key={index}>
                                       {item.map(field => (
                                          <div className="mb-1" key={field}>
                                             <Checkbox value={field}>
                                                {reportFieldsSchema[field]}
                                             </Checkbox>
                                          </div>
                                       ))}
                                    </div>
                                 );
                              })}
                           </Checkbox.Group>
                        </Flex>
                     )}
                     <div className="mt-1 text-right">
                        {selectedFields.length > 0 || reportType === "grid" ? (
                           <Button
                              icon={<PrinterFilled />}
                              type="primary"
                              shape="round"
                              className="text-white"
                              style={{ backgroundColor: "#F7931E" }}
                              onClick={() => {
                                 const fields = sortByReference(selectedFields);
                                 // const items = groupById(selectedItems)
                                 if (Object.keys(selectedItems).length) {
                                    navigate("/reports/view", {
                                       state: {
                                          data: reportItems,
                                          selectedFields: fields,
                                          reportType: reportType,
                                          selectedItems: selectedItems,
                                          projectType: getAddedProjectData?.project_type,
                                          portalUserType: portalUserType,
                                          gridReportType: gridReportType,
                                          returnUrl: inventoryUrl,
                                       },
                                    });
                                 } else {
                                    toast.error(
                                       "Please select at least one item to generate the report."
                                    );
                                 }
                              }}
                           >
                              Generate
                           </Button>
                        ) : (
                           // </Link>
                           <Button
                              icon={<PrinterFilled />}
                              disabled={true}
                              shape="round"
                              // className="text-white"
                              // style={{ backgroundColor: "#F7931E" }}
                           >
                              Generate
                           </Button>
                        )}
                     </div>
                  </>
                  // </Card>
               )}
               <div className="mt-2 text-right">
                  {!fullReportAvaliableUsers.includes(portalUserType) && (
                     <Button
                        icon={<PrinterFilled />}
                        type="primary"
                        shape="round"
                        className="text-white"
                        style={{ backgroundColor: "#F7931E" }}
                        onClick={() => {
                           const fields = sortByReference(selectedFields);
                           if (Object.keys(selectedItems).length) {
                              navigate("/reports/view", {
                                 state: {
                                    data: reportItems,
                                    selectedFields: fields,
                                    reportType: reportType,
                                    selectedItems: selectedItems,
                                    projectType: getAddedProjectData?.project_type,
                                    portalUserType: portalUserType,
                                    gridReportType: gridReportType,
                                    returnUrl: inventoryUrl,
                                 },
                              });
                           } else {
                              toast.error(
                                 "Please select at least one item to generate the report."
                              );
                           }
                        }}
                     >
                        Generate
                     </Button>
                  )}
               </div>
               <Divider />
               <Flex justify="space-between" className="mb-3">
                  <Button
                     shape="round"
                     className="border-0"
                     style={{
                        backgroundColor:
                           Object.keys(selectedItems).length === clientReportsData?.rows?.length
                              ? "#F7931E"
                              : "lightgrey",
                     }}
                  >
                     <Checkbox
                        className="text-left"
                        checked={Object.keys(selectedItems).length === reportItems?.length}
                        onChange={e => {
                           if (e.target.checked) {
                              // console.log("true ==> ", selectAll);
                              setSelectedItems({ ...selectAll });
                           } else {
                              setSelectedItems({});
                           }
                        }}
                     >
                        Select All
                     </Checkbox>
                  </Button>
                  <Pagination
                     total={
                        isNaN(pageSize)
                           ? reportItems?.length
                           : isExecutorUser
                           ? executorClaimListData?.count
                           : count
                     }
                     showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                     showSizeChanger={true}
                     size="small"
                     pageSize={pageSize}
                     pageSizeOptions={
                        isExecutorUser
                           ? executorClaimListData?.count > 100
                              ? [10, 20, 50, 100, executorClaimListData?.count]
                              : [10, 20, 50, 100]
                           : count > 100
                           ? [10, 20, 50, 100, count]
                           : [10, 20, 50, 100]
                     }
                     onChange={(page, pageSize) => {
                        setPageSize(pageSize);
                        setSelectedItems([]);
                        if (
                           !isExecutorUser &&
                           portalUserID &&
                           location?.state?.reportItemID?.length > 0
                        ) {
                           dispatch(
                              clientReports({
                                 user_id: portalUserID || "",
                                 query: "",
                                 page: page,
                                 limit: pageSize,
                                 sortby: "",
                                 order: "",
                                 // type: room_or_user_id ? atob(room_or_user_id) : "all",
                                 type: location?.state?.reportItemID?.join(),
                              })
                           );
                        }

                        if (isExecutorUser) {
                           dispatch(
                              executorClaimList({
                                 page: page,
                                 limit: pageSize,
                                 sortby: "",
                                 order: "",
                                 user_id: atob(room_or_user_id),
                                 claimType: claim_type,
                              })
                           );
                        }
                        // else {
                        //    dispatch(
                        //       adminReports({
                        //          user_id: "",
                        //          query: "",
                        //          page: page,
                        //          limit: pageSize,
                        //          sortby: "",
                        //          order: "",
                        //       })
                        //    );
                        // }
                     }}
                  />
               </Flex>
               <Row
                  gutter={[12, 12]}
                  className="overflow-y-auto"
                  style={{ height: 500, scrollbarWidth: "thin" }}
               >
                  {reportItems?.map(item => (
                     <Col span={6} key={item.id}>
                        <ReportItemCard
                           imageUrl={assestURL + item.item_image}
                           id={item.id}
                           title={item.name}
                           isAIEstimate={!!item.google_value_range}
                           isClientEstimte={!!item.client_value_range}
                           selectedItems={selectedItems}
                           onChange={e => {
                              const id = item.id;
                              if (e.target.checked) {
                                 setSelectedItems({
                                    ...selectedItems,
                                    [id]: true,
                                 });
                              } else {
                                 const data = { ...selectedItems };
                                 delete data[id];
                                 setSelectedItems({ ...data });
                              }
                           }}
                        />
                     </Col>
                  ))}
               </Row>
               {/* <Divider /> */}
               {/* {projectDetails?.project_type === 3 ? ( */}
               {/* {portalUserType && fullReportAvaliableUsers.includes(portalUserType) && ( */}
               {/* <Card type="inner" size="small" title="Step 2 - Select Report Type"> */}
               {/* <Space>
                        <Button
                           icon={<FaList style={{ marginTop: -3 }} />}
                           type="default"
                           shape="round"
                           className={reportType === "full" ? "text-white" : ""}
                           style={{ backgroundColor: reportType === "full" ? "#F7931E" : "" }}
                           onClick={() => setReportType("full")}
                        >
                           Full
                        </Button>
                        <Button
                           icon={<BsFillGridFill style={{ marginTop: -3 }} />}
                           type="default"
                           shape="round"
                           className={reportType === "grid" ? "text-white" : ""}
                           style={{ backgroundColor: reportType === "grid" ? "#F7931E" : "" }}
                           onClick={() => setReportType("grid")}
                        >
                           Grid
                        </Button>
                     </Space> */}
               {/* {reportType === "full" && ( */}
               {/* <Flex className="py-3 px-1 w-100">
                           <Checkbox.Group
                              className="w-100"
                              value={selectedFields}
                              onChange={checkedValues => {
                                 setSelectedFields(checkedValues);
                                 console.log("checked = ", checkedValues);
                              }}
                           >
                              {reportFields?.map(field => (
                                 <Checkbox key={field} className="mb-2" value={field}>
                                    {reportFieldsSchema[field]}
                                 </Checkbox>
                              ))}
                           </Checkbox.Group>
                        </Flex> */}
               {/* )} */}
               {/* <div className="mt-2 text-right">
                        {selectedFields.length > 0 || reportType === "grid" ? (
                           <Button
                              icon={<PrinterFilled />}
                              type="primary"
                              shape="round"
                              className="text-white"
                              style={{ backgroundColor: "#F7931E" }}
                              onClick={() => {
                                 if (Object.keys(selectedItems).length) {
                                    navigate("/reports/view", {
                                       state: {
                                          selectedFields: selectedFields,
                                          reportType: reportType,
                                          selectedItems: selectedItems,
                                          projectType: getAddedProjectData?.project_type,
                                          portalUserType: portalUserType,
                                          gridReportType: gridReportType,
                                       },
                                    });
                                 } else {
                                    toast.error(
                                       "Please select at least one item to generate the report."
                                    );
                                 }
                              }}
                           >
                              Generate
                           </Button>
                        ) : (
                           // </Link>
                           <Button
                              icon={<PrinterFilled />}
                              disabled={true}
                              shape="round"
                              // className="text-white"
                              // style={{ backgroundColor: "#F7931E" }}
                           >
                              Generate
                           </Button>
                        )}
                     </div> */}
               {/* </Card> */}
               {/* )} */}
               {/* <div className="mt-2 text-right">
                  {!fullReportAvaliableUsers.includes(portalUserType) && (
                     <Button
                        icon={<PrinterFilled />}
                        type="primary"
                        shape="round"
                        className="text-white"
                        style={{ backgroundColor: "#F7931E" }}
                        onClick={() => {
                           if (Object.keys(selectedItems).length) {
                              navigate("/reports/view", {
                                 state: {
                                    selectedFields: selectedFields,
                                    reportType: reportType,
                                    selectedItems: selectedItems,
                                    projectType: getAddedProjectData?.project_type,
                                    portalUserType: portalUserType,
                                    gridReportType: gridReportType,
                                 },
                              });
                           } else {
                              toast.error(
                                 "Please select at least one item to generate the report."
                              );
                           }
                        }}
                     >
                        Generate
                     </Button>
                  )}
               </div> */}
               {/* <div>
                     {reportType === "grid" && roleID === "1" && (
                        <div className="p-4 ps-0">
                           <label className="me-3">Report Type :</label>
                           <Radio.Group onChange={onChange} value={gridReportType}>
                              <Radio value={"normal"}>Normal</Radio>
                              <Radio value={"contested"}>Contested</Radio>
                           </Radio.Group>
                        </div>
                     )}
                     <Button
                        icon={<PrinterFilled />}
                        type="primary"
                        shape="round"
                        className="text-white"
                        style={{ backgroundColor: "#F7931E" }}
                        onClick={() => {
                           if (Object.keys(selectedItems).length) {
                              navigate("/reports/view", {
                                 state: {
                                    reportType: reportType,
                                    selectedItems: selectedItems,
                                    projectType: getAddedProjectData?.project_type,
                                    portalUserType: portalUserType,
                                    gridReportType: gridReportType,
                                 },
                              });
                           } else {
                              toast.error(
                                 "Please select at least one item to generate the report."
                              );
                           }
                        }}
                     >
                        Generate
                     </Button>
                  </div>
               )} */}
            </Card>
         </div>
      </div>
   );
};

export default Reports;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import Login from "./components/login";
import PrivateRoute from "./PrivateRoute";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./components/forgotpassword";
import ResetPassword from "./components/resetpassword";
import WelcomePage from "./components/welcome";
import Dashboard from "./components/dashboard";
import AddStaff from "./components/AddStaff";
import Staff from "./components/staff";
import Photographer from "./components/photographer";
import Clients from "./components/clients";
import ManagedPhotographer from "./components/Photographer/ManagedPhotographer";
import ManagedClients from "./components/ManagedClients";
import Projects from "./components/Projects/projects";
import ManagedProject from "./components/Projects/ManagedProject";
import ManagedJobs from "./components/Projects/ManagedJobs";

import PortalUsers from "./components/Projects/PortalUsers";
import AddPortalUser from "./components/Projects/AddPortalUser";
// import Rooms from "./components/Projects/Rooms";
// import ImageCarousel from "./fields/ImageCarousel";
import Rooms from "./components/Projects/Rooms";
import SampleTable from "./components/SampleTable";
import EditJobWithRooms from "./components/Projects/EditJobWithRooms";
import ItemCategories from "./components/Projects/ItemCategories";
import ClientPortal from "./components/ClientPortal/ClientPortal";
import ClientPortalHomeScreen from "./components/ClientPortal/ClientPortalHomeScreen";
import ThreeSixtyView from "./components/ClientPortal/ThreeSixtyView";
import PanoramaAddEdit from "./components/Build360/PanoramaAddEdit";
import HotspotThreeSixtyImg from "./components/Build360/HotspotThreeSixtyImg";
import PieChart3D from "./components/ClientPortal/FamilyLawDashboard";
import PieCharts from "./components/ClientPortal/FamilyLawDashboard";
import PhotographerDashboard from "./components/PhotographerLogin/PhotographerDashboard";
import PhotographerJobList from "./components/PhotographerLogin/PhotographerJobList";

import TourViewer from "./components/ClientPortal/TourViewer";
import EstateExecutorClaiment from "./components/ClientPortal/EstateExecutorClaiment";
import ClientTourViewer from "./components/ClientPortal/ClientTourViewer";
import Reports from "./components/Reports/Reports";
import GeneratedReport from "./components/Reports/GeneratedReport";
import ExecutorInventory from "./components/ClientPortal/ExecutorInventory";
import DashboardJobList from "./components/DashboardJobList";
import DashboardStaffJobList from "./components/DashboardStaffJobList";
import GoogleLogs from "./components/GoogleLogs";


function App() {
   return (
      <Fragment>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
         <Router>
            <Routes>
               <Route exact path="/" element={<Login />} />
               <Route exact path="/login" element={<Login />} />
               <Route exact path="/forgotpassword" element={<ForgotPassword />} />
               <Route exact path="/resetpassword/:token" element={<ResetPassword />} />
               <Route path="/" element={<PrivateRoute />}>
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route exact path="/joblist/:type" element={<DashboardJobList/>} />
                  <Route exact path="/staffjoblist/:type" element={<DashboardStaffJobList/>} />
                  <Route exact path="/addstaff" element={<AddStaff />} />
                  <Route exact path="/editstaff/:id" element={<AddStaff />} />
                  <Route exact path="/backoffice/admin" element={<WelcomePage />} />
                  <Route exact path="/staff" element={<Staff />} />
                  <Route exact path="/photographer" element={<Photographer />} />
                  <Route exact path="/clients" element={<Clients />} />
                  <Route exact path="/addphotographer" element={<ManagedPhotographer />} />
                  <Route exact path="/editphotographer/:id" element={<ManagedPhotographer />} />
                  <Route exact path="/addclient" element={<ManagedClients />} />
                  <Route exact path="/editclient/:client_id" element={<ManagedClients />} />

                  {/* <============================== Projects ============================> */}
                  <Route exact path="/projects" element={<Projects />} />
                  <Route exact path="/addproject/:client_id" element={<ManagedProject />} />
                  <Route exact path="/editproject/:id/:client_id" element={<ManagedProject />} />
                  <Route
                     exact
                     path="/project/item-categories/:project_id/:client_id"
                     element={<ItemCategories />}
                  />
                  <Route
                     exact
                     path="/project/addjob/:project_id/:client_id"
                     element={<ManagedJobs />}
                  />
                  <Route
                     exact
                     path="/project/editjob/:project_id/:client_id/:job_id"
                     element={<ManagedJobs />}
                  />
                  <Route
                     exact
                     path="/project/portalusers/:project_number/:project_id/:client_id"
                     element={<PortalUsers />}
                  />
                  <Route
                     exact
                     path="/project/add-portaluser/:project_number/:project_id/:client_id"
                     element={<AddPortalUser />}
                  />
                  <Route
                     exact
                     path="/project/edit-portaluser/:pu_id/:project_number/:project_id/:client_id"
                     element={<AddPortalUser />}
                  />
                  <Route
                     exact
                     path="/projects/:project_id/:job_id/rooms/:roomid"
                     element={<Rooms />}
                  />
                  <Route exact path="/googlelogs" element={<GoogleLogs />} />

                  {/* <Route exact path="/projects/image" element={<ImageCarousel />} /> */}

                  {/* <============================== Projects ============================> */}
                  <Route exact path="/sample/table" element={<SampleTable />} />
                  <Route
                     exact
                     path="/projects/editjob/:project_id/:client_id/:job_id"
                     element={<EditJobWithRooms />}
                  />

                  {/* <Route exact path="/categorysorting" element={<CategoryManagement />} /> */}

                  {/* <============================== Client Portal ============================> */}
                  <Route exact path="/clientportal" element={<ClientPortal />} />
                  <Route exact path="/clientportal/home" element={<ClientPortalHomeScreen />} />
                  <Route exact path="/tourview" element={<ThreeSixtyView />} />
                  <Route exact path="/chart/:id" element={<PieCharts />} />


            {/* <Route exact path="/categorysorting" element={<CategoryManagement />} /> */}
            
            {/* <============================== Client Portal ============================> */}
            <Route exact path="/clientportal/home" element={<ClientPortalHomeScreen />} />
            <Route exact path="/clientportal/:project_id/:jobid" element={<ClientPortal />} />
            <Route exact path="/clientportal/:project_id/:jobid/:room_id" element={<ClientPortal />} />
            <Route exact path="/tourview" element={<ThreeSixtyView />} />
            <Route exact path="/chart/:id" element={<PieCharts />} />
            <Route exact path="/client/tour/:roomid/:jobid/:project_id" element={<ClientTourViewer />} />
            <Route exact path="/preview/clientportal/home/:project_id" element={<ClientPortalHomeScreen />} />
            <Route exact path="/preview/clientportal/:project_id/:jobid" element={<ClientPortal />} />
            <Route exact path="/preview/client/tour/:roomid/:jobid/:project_id" element={<ClientTourViewer />} />
            <Route exact path="/preview/chart" element={<PieCharts />} />
                  {/* <============================== Photographer Login ============================> */}
                  <Route exact path="/photographer/dashboard" element={<PhotographerDashboard />} />
                  <Route exact path="/photographer/jobs" element={<PhotographerJobList />} />
                  {/* <============================== Client Portal ============================> */}
                  <Route exact path="/clientportal/executor/home" element={<ExecutorInventory />} />
                  <Route exact path="/client/executor/:job_id" element={<EstateExecutorClaiment />} />
                  <Route exact path="/client/executor/:job_id/:user_id" element={<EstateExecutorClaiment />} />
                  <Route exact path="/clientportal/user/home" element={<ExecutorInventory />} />
                  <Route exact path="/client/user/:job_id" element={<EstateExecutorClaiment />} />

            {/* <============================== Photographer Login ============================> */}
            <Route exact path="/photographer/dashboard" element={<PhotographerDashboard />} />
            <Route exact path="/photographer/jobs" element={<PhotographerJobList />} />
            <Route exact path="/uploadPanos/:project_id/:job_id" element={<PanoramaAddEdit />} />
            <Route exact path="/uploadPanos/:client_id/:project_id/:job_id" element={<PanoramaAddEdit />} />
            <Route exact path="/start/tour" element={<HotspotThreeSixtyImg />} />

            {/* <============================== Client Portal ============================> */}
            <Route exact path="/client/tour" element={<TourViewer />} />
            
            <Route exact path="/reports" element={<Reports />} />
            <Route exact path="/reports/executor/:job_id/:room_or_user_id/:claim_type" element={<Reports />} />
            <Route exact path="/reports/:room_or_user_id/:job_id/:project_id" element={<Reports />} />
            <Route exact path="/reports/view" element={<GeneratedReport />} />
          </Route>
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import { Pannellum } from 'pannellum-react';
import panorama from '../../assets/images/xs/panorama.jpg';
import { Button } from 'antd';
import ButtonComponent from '../../fields/ButtonComponent';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { assestURL } from '../../services/config';
import { useDispatch, useSelector } from 'react-redux';
import { resetSuccessFlag, saveHotspotView } from '../../store/reducer/buildThreeSixty';
import { toast } from 'react-toastify';
import { ArrowLeftOutlined } from "@ant-design/icons";


const HotspotThreeSixtyImg = () => {
    const pannellumRef = useRef(null);

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    console.log(location, 'location');
    const panoImage = location.state ? location.state?.record?.pano_image : null;
    const panoId = location.state ? location.state?.record?.id : null;
    const { hotspotViewSuccess, hotpotViewError } = useSelector(state => state.buildThreeSixty)
    const roleID = localStorage.getItem('role_id')
    const project_id = localStorage.getItem('projectId')
    console.log(hotspotViewSuccess, hotpotViewError, 'hotpotViewError');
    console.log(panoId, 'panoId');

    const saveView = () => {
        if (pannellumRef.current && pannellumRef.current.getViewer()) {
            const viewer = pannellumRef.current.getViewer();
            const default_pitch = viewer.getPitch();
            const default_yaw = viewer.getYaw();
            const zoom = viewer.getHfov();

            // Log the values to check them
            console.log({ default_pitch, default_yaw, zoom }, 'View Data');

            const viewData = { default_pitch, default_yaw, zoom };
            let postData = viewData;

            // // Make sure the zoom is within a sensible range
            // if (zoom < 30 || zoom > 120) {
            //     postData.zoom = 110; // Default zoom level
            //     console.warn('Zoom value out of range, resetting to default.');
            // }

            dispatch(saveHotspotView({ postData, updateID: panoId }));
        }
    };

    useEffect(() => {
        if (hotspotViewSuccess === true && roleID === '1') {
            toast.success('Start Point has been Set Successfully !', {
                autoClose: 3000  // Display for 3 seconds (3000 milliseconds)
            })
            navigate(`/uploadPanos/${location.state?.clientId}/${btoa(project_id)}/${btoa(location.state?.record?.job_id)}`)
            dispatch(resetSuccessFlag({ flag: 'hotspotViewSuccess' }));

        }
        else if(hotspotViewSuccess === true && roleID === '3'){
            toast.success('Start Point has been Set Successfully !', {
                autoClose: 3000  // Display for 3 seconds (3000 milliseconds)
            })
            navigate(`/uploadPanos/${btoa(project_id)}/${btoa(location.state?.record?.job_id)}`)
            dispatch(resetSuccessFlag({ flag: 'hotspotViewSuccess' }));

        }
    }, [hotspotViewSuccess,location])

    return (
        <div>
            <div className='mt-2 mb-2' style={{ display: 'flex', gap: '16px', marginLeft: '82%' }}>
                <Link to={roleID === '1' ? `/uploadPanos/${location.state?.clientId}/${btoa(project_id)}/${btoa(location.state?.record?.job_id)}` : roleID === '3' ? `/uploadPanos/${btoa(project_id)}/${btoa(location.state?.record?.job_id)}` : ''}>
                    <ButtonComponent
                        style={{ backgroundColor: "#6E6E6E", color: "white" }}
                        buttonName="Back"
                        icon={<ArrowLeftOutlined />}
                    />
                </Link>
                <ButtonComponent
                    style={{ backgroundColor: "#f7931e", color: "white" }}
                    buttonName="Save"
                    onClick={saveView}
                    icon={<i className="fa fa-file-text" aria-hidden="true"></i>}
                />
            </div>
            <Pannellum
                ref={pannellumRef}
                width="100%"
                height="500px"
                image={assestURL + panoImage}
                pitch={location.state ? Number(location.state?.record?.default_pitch) : -4.5}
                yaw={location.state ? Number(location.state?.record?.default_yaw) : 30}
                hfov={location.state ? Number(location.state?.record?.zoom) : 150}
                autoLoad
                mouseZoom
                mouseDrag
            />

        </div>
    );
};

export default HotspotThreeSixtyImg;

import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProjectCard = ({ projectName, status, count, jobId, photographerId, gradientClass, link }) => {
  const navigate = useNavigate()
  const handleCardClick = () => {
    localStorage.setItem('photographerId', photographerId);
    localStorage.setItem('selectedJobId',jobId)
    navigate(link)
  };

  return (
    <a href="javascript:;" className={`project-card ${gradientClass}`} onClick={handleCardClick}>
      <div className="card-content-photo-dashboard">
        <h3 className="project-name">{projectName}</h3>
        <p className="due-date">{status} : {count}</p>
      </div>
    </a>
  );
};

export default ProjectCard;

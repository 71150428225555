import React, { useEffect } from "react";
import { Table, Button, Tooltip, Space, Card, Select } from "antd";
import { HolderOutlined } from "@ant-design/icons";
import moment from "moment";
// import SwalComponent from "./SwalComponent"; // Assuming you have a SwalComponent
import { Link, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../view/pageheader";
import { useDispatch, useSelector } from "react-redux";
import { clearData, getDashboardJobsData, getStaffDashboardData, getStaffDashboardJobsData, loginSelector, updateJobStatus } from "../store/reducer/login";
import { toast } from "react-toastify";

const DashboardStaffJobList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {type} = useParams();
    const { staffDashboardJobsDatas,jobStatusResponse } = useSelector(loginSelector);

    const roleID = localStorage.getItem('role_id'); // Assuming roleID
    const staffId = localStorage.getItem('staff_id')
    const category_type = '3'; // Assuming category type
    const projectDetails = { project_id: 123, project_number: "PN-456" }; // Dummy project details
    const clientID = "client-789"; // Dummy client ID
    const jobStatusArray = [
        {
            label: 'Scheduled',
            value: 'scheduled'
        },
        {
            label: 'Ready to Edit/Build',
            value: 'ready_to_edit_or_build'
        },
        {
            label: 'Ready to QC',
            value: 'ready_to_qc'
        },
        {
            label: 'Ready to Deliver',
            value: 'ready_to_deliver'
        },
        {
            label: 'Delivered',
            value: 'delivered'
        }
    ]
    const dataSource = [
        {
            key: "1",
            job_name: "Inventory Count",
            job_number: "JOB-001",
            search_count: 5,
            status: "Scheduled",
            job_date: "2024-08-28",
            due_date: "2024-09-05",
            assign_staff_name: "John Doe",
            job_id: "job-123",
        },
        {
            key: "2",
            job_name: "QC Inspection",
            job_number: "JOB-002",
            search_count: 10,
            status: "In Progress",
            job_date: "2024-08-30",
            due_date: null,
            assign_staff_name: "Jane Smith",
            job_id: "job-124",
        },
    ];

    const onChange = (record,value, name) => {
        console.log(record,value, name, 'changes');
        let postData = {
            status:value
        }
        dispatch(updateJobStatus({ postData, job_id: record?.id }));
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const columns = [
        {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text, record, index) => (
                <>
                    &nbsp;<span>{index + 1}</span>
                </>
            ),
            sorter: false,
        },
        {
            title: "Job Name",
            dataIndex: "job_name",
            align: "center",
            sorter: true,
            render: (record) => <span>{record ? record : "-"}</span>,
        },
        {
            title: "Job #",
            dataIndex: "job_number",
            align: "center",
            sorter: true,
            render: (text, record) => (
                <Link to={`/editproject/${btoa(record?.project_id)}/client`}>
               <span>{record.job_number}</span>
            </Link>
            ),
        },
        {
            title: "Search Count",
            dataIndex: "search_count",
            align: "center",
            sorter: true,
            render: (record) => <span>{record || 0}</span>,
        },
        {
            title: "Status",
            dataIndex: "status",
            align: "center",
            sorter: true,
            render: (text, record) => {
                return (
                    <Select
                    name="status"
                    value={record.status ? record.status : null}
                    placeholder="Choose status"
                    onChange={(value) => onChange(record,value, 'status')}
                    onSearch={onSearch}
                    options={jobStatusArray}
                    style={{ width: '80%' }}
                />
                )
            },
        },
        {
            title: "Job Date",
            dataIndex: "job_date",
            align: "center",
            sorter: true,
            render: (text, record) => (
                <span>{moment(record.job_date).format("MM/DD/YY")}</span>
            ),
        },
        {
            title: "Due Date",
            dataIndex: "due_date",
            align: "center",
            sorter: true,
            render: (text, record) => {
                return (
                    <span>
                        {record.due_date === null
                            ? "-"
                            : moment(record.due_date).format("MM/DD/YY")}
                    </span>
                );
            },
        },
        {
            title: "Assigned to ",
            dataIndex: "assign_staff_name",
            align: "center",
            sorter: true,
            render: (record) => (
                <span>{record ? record : "-"}</span>
            ),
        },
        {
            title: "Action",
            align: "center",
            render: (text, record) => (
                <Space size="large" align="center">
                    <Tooltip title="Edit">
                        <span
                            className="fs-6"
                            style={{ color: "blue", cursor: "pointer" }}
                        //   onClick={() => {
                        //     console.log(record, "record");
                        //     localStorage.setItem(
                        //       "project_number",
                        //       projectDetails.project_number
                        //     );
                        //     navigate(
                        //       `/project/editjob/${btoa(projectDetails.project_id)}/${clientID}/${btoa(
                        //         record.job_id
                        //       )}`
                        //     );
                        //   }}
                        >
                            <i className="icon-pencil" />
                        </span>
                    </Tooltip>
                    {/* {roleID === "2" && category_type === "3" ? null : (
            <SwalComponent
              message="Are you sure you want to delete the job?"
              onClickDispatch={() => console.log("Delete job", record.job_id)}
              id={record.job_id}
            />
          )} */}
                </Space>
            ),
        },
    ];

    useEffect(()=>{
            dispatch(getStaffDashboardJobsData({type:type,id:staffId}))
    },[roleID,type,staffId,dispatch])

    useEffect(()=>{
        if(jobStatusResponse === true){
            toast.success('Job Status Updated Successfully !')
            dispatch(getStaffDashboardJobsData({type:type,id:staffId}))
            clearData()
        }
    },[jobStatusResponse])

    console.log(staffDashboardJobsDatas,'staffDashboardJobsDatas');
    

    return (
        <>
            <PageHeader HeaderText="Jobs List"/>
        <Card>
            <Table columns={columns} dataSource={staffDashboardJobsDatas?.data} pagination={false} />
        </Card>
        </>
    )
};

export default DashboardStaffJobList;

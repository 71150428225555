import React, { Fragment, useEffect, useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import ButtonComponent from '../../fields/ButtonComponent'
import InputComponent from '../../fields/InputComponent'
import SelectComponent from '../../fields/SelectComponent'
import { UserOutlined, PhoneOutlined, DatabaseOutlined, SolutionOutlined } from '@ant-design/icons';
import { DatePicker, Divider, message } from 'antd';
import CardComponent from '../../fields/CardComponent';
import PageHeader from '../../view/pageheader';
import { useNavigate, useParams } from 'react-router-dom';
import { getClientData } from '../../store/reducer/clientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addJob, getJobData, resetSuccessFlag, updateJob } from '../../store/reducer/jobSlice';
import moment from 'moment';
import { getStaffList } from '../../store/reducer/staffSlice';
import { getStatesList } from '../../store/reducer/statesListSlice';
import _ from 'lodash'
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { getPhotographerList } from "../../store/reducer/photographerSlice";
import Loader from '../../fields/loader/Loader';

const ManagedJobs = () => {

    const { project_id, job_id, client_id } = useParams();
    console.group();
    console.log("PI", atob(project_id));
    // console.log("JI", atob(job_id));
    console.log("CI", atob(client_id));
    console.groupEnd();

    const [jobDetails, setJobDetails] = useState({
        project_id: '',
        project_number: '',
        date: '',
        due_date: '',
        job_address: 0,
        job_name: '',
        assign_staff: '',
        assign_staff_name: '',
        address_one: '',
        address_two: '',
        inventory_lead: '',
        city: '',
        state: '',
        zip_code: '',
        contact_name: '',
        contact_phone: '',
    });
    console.log(jobDetails, 'jobDetails==');
    const [, forceUpdate] = useState();  // Use state to trigger re-renders
    const [clientID, setClientID] = useState()

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getAddedData, getClientAddedDataLoading } = useSelector((state) => state.client);
    const { staffListData, staffListLoading } = useSelector(state => state.staff);
    const { photographerListData } = useSelector(state => state.photographer);
    const { addJobSuccess, updateJobSuccess, addJobError, getAddedJobData, updateJobError, getAddedJobDataLoading } = useSelector((state) => state.job)
    const { statesListData, statesListLoading, statesError, statesErrorMessage } = useSelector((state) => state.states);

    console.log(getAddedJobData, 'getAddedJobData');
    console.log(jobDetails, 'jobDetails');

    const [type, setType] = useState('add')
    const [touchedFields, setTouchedFields] = useState({});
    const [staffList, setStaffList] = useState();
    const [inventoryLeadList, setInventoryLeadList] = useState([]);
    const [stateList, setStateList] = useState([]);



    //Validation Rules & Validator
    const validator = useRef(new SimpleReactValidator({
        className: 'validation-message',
        messages: {
            required: 'Please enter :attribute',
            email: 'Email should contain @ and .',
            alpha: ':attribute should contain only alphabets',
            regex: ':attribute format is invalid',
            in: ':attribute should be one of the accepted values',
            min: ':attribute must be at least :min characters',
            confirmed: 'Passwords do not match'
        }
    }));

    const validationRules = {
        project_id: '',
        date: `required`,
        due_date: '',
        job_name: 'required|validationRules',
        // job_address: '',
        assign_staff: '',
        address_one: `required`,
        address_two: '',
        inventory_lead: '',
        city: `required`,
        state: `required`,
        zip_code: `required`,
        contact_name: ``,
        contact_phone: `min:12|max:12`,
    };

    const onDateChange = (date, dateString) => {
        console.log(dateString, date, 'dateString');
        console.log(dayjs(dateString), 'dateString');
        // Calculate due date as 10 days from the selected date
        const dueDate = date ? dayjs(date).add(10, 'day') : null;
        setJobDetails({
            ...jobDetails,
            date: date ? dayjs(dateString) : null,
            due_date: dueDate
        });
        setTouchedFields({
            ...touchedFields,
            date: true
        });
    };

    const onDueDateChange = (date, dateString) => {
        console.log('Selected due date:', dateString);
        console.log('Job start date:', jobDetails.date);

        const startDate = moment(jobDetails.date);
        const dueDate = moment(dateString);

        console.log('Start Date:', startDate.format('YYYY-MM-DD'));
        console.log('Due Date:', dueDate.format('YYYY-MM-DD'));

        if (dueDate.isAfter(startDate) || dueDate.isSame(startDate)) {
            setJobDetails({
                ...jobDetails,
                due_date: date ? dueDate : null
            });
            setTouchedFields({
                ...touchedFields,
                due_date: true
            });
        } else {
            message.error('Due date must be greater than or equal to the start date');
            setJobDetails({
                ...jobDetails,
                due_date: null
            });
        }
    };

    const formatPhoneNumber = (value) => {
        // Remove all non-digit characters
        const cleaned = ('' + value).replace(/\D/g, '');

        // Check if the input is longer than 10 characters
        if (cleaned.length <= 10) {
            const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
            if (match) {
                return [match[1], match[2], match[3]].filter(Boolean).join('-');
            }
        }
        return value; // Return unformatted value if it's too long
    };

    //onChange
    const addEditJobDetails = (e) => {
        const { name, value } = e.target;
        const formattedValue = name === "contact_phone" ? formatPhoneNumber(value) : value;

        setJobDetails(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));
        setTouchedFields(prevState => ({
            ...prevState,
            [name]: true
        }));
    };

    useEffect(() => {
        if (project_id) {
            const decodedProjectId = atob(project_id);
            console.log(decodedProjectId, 'project_id');
        }
    }, [project_id])

    useEffect(() => {
        if (job_id) {
            setType('edit')
            const decodedId = atob(job_id); // Use a different variable name
            dispatch(getJobData({ id: decodedId }));
        }
        else {
            setType('add')
        }
    }, [job_id, dispatch]);

    useEffect(() => {
        dispatch(getStatesList({}));
    }, []);

    useEffect(() => {
        if (statesListData.length) {
            const list = statesListData.map(item => ({
                value: item.state,
                label: item.state,
            }));
            setStateList([...list]);
        }
    }, [statesListData])

    useEffect(() => {
        if (getAddedJobData && type === 'edit') {
            setJobDetails({
                ...jobDetails,
                project_id: getAddedJobData?.project_id,
                date: getAddedJobData?.date ? dayjs(getAddedJobData?.date) : null,
                due_date: getAddedJobData?.due_date,
                job_name: getAddedJobData?.job_name,
                job_address: getAddedJobData?.job_address,
                assign_staff: getAddedJobData?.assign_staff,
                assign_staff_name: getAddedJobData?.assign_staff_name,
                address_one: getAddedJobData?.address_one,
                address_two: getAddedJobData?.address_two,
                inventory_lead: getAddedJobData?.inventory_lead,
                city: getAddedJobData?.city,
                state: getAddedJobData?.state,
                zip_code: getAddedJobData?.zip_code,
                contact_name: getAddedJobData?.contact_name,
                contact_phone: getAddedJobData?.contact_phone,
            })
        }
    }, [getAddedJobData, type])

    console.log(getAddedData, 'getAddedData');

    useEffect(() => {
        if (getAddedData && jobDetails.job_address === 1) {
            setJobDetails({
                ...jobDetails,
                address_one: getAddedData?.address_one,
                address_two: getAddedData?.address_two,
                state: getAddedData?.state,
                city: getAddedData?.city,
                zip_code: getAddedData?.zip_code,
            })
        }
        else {
            setJobDetails({
                ...jobDetails,
                address_one: '',
                address_two: '',
                state: '',
                city: '',
                zip_code: '',
            })
        }
    }, [getAddedData, jobDetails.job_address])

    useEffect(() => {
        if (addJobSuccess) {
            toast.success('Job Added Successfully!');
            dispatch(resetSuccessFlag({ flag: 'addJobSuccess' }));
            navigate(`/editproject/${project_id}/${client_id}`);
            setJobDetails({
                ...jobDetails,
                project_id: '',
                date: dayjs(Date.now()),
                due_date: '',
                // job_address: '',
                job_name: '',
                assign_staff: '',
                address_one: '',
                address_two: '',
                inventory_lead: '',
                city: '',
                state: '',
                zip_code: '',
                contact_name: '',
                contact_phone: '',
            })

        }
        if (updateJobSuccess) {
            setJobDetails({
                ...jobDetails,
                project_id: '',
                date: dayjs(Date.now()),
                due_date: '',
                // job_address: '',
                job_name: '',
                assign_staff: '',
                address_one: '',
                address_two: '',
                inventory_lead: '',
                city: '',
                state: '',
                zip_code: '',
                contact_name: '',
                contact_phone: '',
            })
            toast.success('Job Updated Successfully!');
            dispatch(resetSuccessFlag({ flag: 'updateJobSuccess' }));
            navigate(`/editproject/${project_id}/${client_id}`);
        }
        if(addJobError){
            toast.error(addJobError)
            dispatch(resetSuccessFlag({ flag: 'addJobError' }));
        }
        if (updateJobError) {
            toast.error(updateJobError)
            dispatch(resetSuccessFlag({ flag: 'updateJobError' }));
        }
    }, [addJobSuccess, updateJobSuccess,addJobError, updateJobError, dispatch, navigate]);

    useEffect(() => {
        dispatch(getStaffList({}))
        dispatch(getPhotographerList({}))
    }, [])

    console.log(staffListData, 'staffListData');

    useEffect(() => {
        if (staffListData && staffListData.data && staffListData.data.length > 0) {
            let activeStaffList = _.filter(staffListData.data, { status: 1 })
            if (activeStaffList) {
                const options = activeStaffList.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.first_name} ${item.last_name}`
                }));
                console.log(options, 'options');
                setStaffList(options)
                // setClientIds(options)
            }
        }

        const { count, data } = photographerListData;
        if (data?.length) {
            let activePhotographer = _.filter(data, { status: 1 })
            if (activePhotographer) {
                const options = activePhotographer.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.first_name} ${item.last_name}`
                }));
                setInventoryLeadList(options);
            }
        }
    }, [staffListData, photographerListData])



    const handleSubmit = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            jobDetails.project_id = atob(project_id);
            jobDetails.project_number = localStorage.getItem('project_number')
            jobDetails.date = dayjs(jobDetails.date)
            // jobDetails.due_date = jobDetails.due_date ? moment(jobDetails.due_date._d).format('MM/DD/YY') : null
            jobDetails.due_date = dayjs(jobDetails.due_date)
            let postData = jobDetails
            console.log(postData, 'postt');
            if (type === 'add') {
                dispatch(addJob({ postData }));
            }
            else {
                let updateID = atob(job_id)
                console.log(updateID, 'updateID');
                dispatch(updateJob({ postData, updateID }));
            }
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    };


    const reset = () => {

    }

    const onChange = (value, name) => {
        setJobDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'assign_staff') {
            let getStaffName = _.find(staffList, { value: value })
            setJobDetails(prevState => ({
                ...prevState,
                assign_staff_name: getStaffName?.label
            }))
        }
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const disabledDate = (current) => {
        // Disable dates before today
        return current && current < moment().startOf('day');
    };

    return (
        <div className="container-fluid-staff" >
            <Loader loading={getAddedJobDataLoading || staffListLoading || getClientAddedDataLoading} />
            <PageHeader
                HeaderText={type === 'add' ? 'Add Jobs' : 'Update Jobs'}
                Breadcrumb={[{ name: type === 'add' ? 'Jobs' : 'Jobs' }]}
                backButtonUrl={`/editproject/${project_id}/${client_id}`}
            />
            <CardComponent >
                <div className="col-md-12">
                    <div className="row">
                        <div className="col">
                            <div>
                                <label htmlFor="client_id">Project</label><br />
                                <a href="javascript:;" onClick={() => {
                                    navigate(`/editproject/${project_id}/${client_id}`);
                                }}>{localStorage.getItem('project_number')}</a>
                            </div>
                        </div>
                        {type === 'edit' ?
                            <div className="col">
                                <label htmlFor="client_id">Job Number</label><br />
                                <a>{getAddedJobData?.job_number}</a>
                            </div> : null}
                        <div className="col">
                            <label htmlFor="name">Date</label>
                            <span className="m-1" style={{ color: 'red' }}>*</span><br />
                            <DatePicker showTime
                                placeholder='Job Date'
                                name='date'
                                style={{ width: '100%' }}
                                onChange={onDateChange}
                                disabledDate={disabledDate}
                                format={'MM/DD/YYYY hh:mm A'}
                                value={jobDetails.date ? jobDetails.date : undefined}
                                defaultValue={jobDetails.date ? jobDetails.date : undefined}
                            />
                            {(validationRules.date || touchedFields.date) && (
                                <div style={{ color: 'red' }}>
                                    {validator.current.message('date', jobDetails.date, validationRules.date)}
                                </div>
                            )}
                        </div>
                        <div className="col">
                            <label htmlFor="name">Due Date</label><br />
                            <DatePicker placeholder='Job Due Date'
                                name='due_date'
                                format={'MM/DD/YYYY'}
                                value={jobDetails.due_date ? dayjs(jobDetails.due_date) : undefined}
                                defaultValue={jobDetails.due_date ? dayjs(jobDetails.due_date) : undefined}
                                style={{ width: '100%' }}
                                onChange={onDueDateChange}
                                disabledDate={disabledDate}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-2">
                    <div className="row">
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="job_name">Job Name</label>
                                <span className="m-1" style={{ color: 'red' }}>*</span><br />
                                <InputComponent
                                    name="job_name"
                                    placeholder="Job Name"
                                    type="text"
                                    icon={<SolutionOutlined />}
                                    value={jobDetails.job_name}
                                    onChange={addEditJobDetails}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inventory_lead">Inventory Lead</label>
                            <SelectComponent
                                name="inventory_lead"
                                value={jobDetails.inventory_lead ? `${jobDetails.inventory_lead}` : null}
                                placeholder="Select Inventory Lead"
                                onChange={(value) => onChange(value, 'inventory_lead')}
                                onSearch={onSearch}
                                options={inventoryLeadList}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="assign_staff">Assign to Staff</label>
                                <SelectComponent
                                    name="assign_staff"
                                    value={jobDetails.assign_staff ? jobDetails.assign_staff : null}
                                    placeholder="Select Staff"
                                    onChange={(value) => onChange(value, 'assign_staff')}
                                    onSearch={onSearch}
                                    options={staffList}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-12 mt-2">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="clientAddress">Job Address</label><br />
                                <div className="checkbox-container">
                                    <input type="checkbox" id="clientAddress" className="custom-checkbox" checked={jobDetails.job_address === 1 ? true : false} style={{ height: '20px', weight: '20px', color: '#f7931e' }} onChange={(e) => {
                                        console.log(e.target.checked, 'checked');
                                        if (e.target.checked === true) {
                                            dispatch(getClientData({ id: client_id }));
                                            setJobDetails({
                                                ...jobDetails,
                                                job_address: 1
                                            })
                                        }
                                        else {
                                            setJobDetails({
                                                ...jobDetails,
                                                address_one: '',
                                                address_two: '',
                                                state: '',
                                                city: '',
                                                zip_code: '',
                                                job_address: 0
                                            })
                                        }
                                    }} />&nbsp;
                                    <label htmlFor="clientAddress" className="checkbox-label"> Same as client address</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="address_one">Address 1</label>
                            <span className="m-1" style={{ color: 'red' }}>*</span><br />
                            <InputComponent
                                name="address_one"
                                placeholder="Address line 1"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={jobDetails.address_one}
                                onChange={addEditJobDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="address_two">Address 2</label>
                                <InputComponent
                                    name="address_two"
                                    placeholder="Address line 2"
                                    type="text"
                                    icon={<i className="bi bi-geo-alt-fill"></i>}
                                    value={jobDetails.address_two}
                                    onChange={addEditJobDetails}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="city">City</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="city"
                                placeholder="City"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={jobDetails.city}
                                onChange={addEditJobDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="state">State</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <SelectComponent
                                name="state"
                                value={jobDetails.state ? jobDetails.state : null}
                                placeholder="Select State"
                                onChange={value => onChange(value, "state")}
                                onSearch={onSearch}
                                options={stateList}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                            {/* <InputComponent
                                name="state"
                                placeholder="State"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={jobDetails.state}
                                onChange={addEditJobDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            /> */}
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="zip_code">Zip Code</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="zip_code"
                                placeholder="Zip Code"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={jobDetails.zip_code}
                                onChange={addEditJobDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                </div>
                <Divider />
                <strong className='mt-2'>Onsite Contact</strong>
                <div className="col-md-12 mt-1">
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="contact_name">Name</label>
                            {/* <span className='m-1' style={{ color: 'red' }}>*</span> */}
                            <InputComponent
                                name="contact_name"
                                placeholder="Name"
                                type="text"
                                icon={<UserOutlined />}
                                value={jobDetails.contact_name}
                                onChange={addEditJobDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="contact_phone">Phone</label>
                            {/* <span className='m-1' style={{ color: 'red' }}>*</span> */}
                            <InputComponent
                                name="contact_phone"
                                placeholder="Phone"
                                type="text"
                                icon={<PhoneOutlined />}
                                value={jobDetails.contact_phone}
                                onChange={addEditJobDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                </div>
                <div className='text-center mt-3'>
                    <ButtonComponent style={{ backgroundColor: '#f7931e', color: 'white' }} onClick={handleSubmit} buttonName="Save" icon={<i className="fa fa-file-text" aria-hidden="true"></i>} />&nbsp;
                </div>
            </CardComponent>
        </div>
    )
}

export default ManagedJobs
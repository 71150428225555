// LeftSideCard.js
import { Skeleton } from 'antd';
import React from 'react';
import { assestURL } from '../services/config';


const LeftSideCard = ({ jobId,date, imageSrc, isSelected, onSelect }) => {
  return (
    <div
      className={`left-side-card ${isSelected ? 'selected' : ''}`}
      onClick={onSelect}
    >
      {imageSrc === null ?
        <Skeleton.Image active={false} style={{ width: '195px' }} /> :
        <img src={assestURL + imageSrc} alt={jobId} className="card-image" />
      }
      <span><center><b>{jobId}</b></center></span>
      <span><center>{date}</center></span>
    </div>
  );
};

export default LeftSideCard;

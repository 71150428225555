export const API_STATUS = Object.freeze({
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    FULFILLED: 'Fulfilled',
    NETWORK_ERROR: 'Network Error'
})


export const TAG_COLOURS = {
    1: "#5B9BD5",
    2: "#00FA00",
    3: "#ED7D31",
    4: "#9437FF",
    5: "#AB7942",
    6: "#FF40FF",
    7: "#FFFC00",
    8: "#FF0000",
    9: "#006633",
    10: "#73FEFF",
    11: "#777777",
    12: "#000000",
 };

import React, { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import {
   UserOutlined,
   MailOutlined,
   PhoneOutlined,
   EyeInvisibleOutlined,
   CaretRightOutlined,
} from "@ant-design/icons";
import { Card, Collapse, Divider } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Toast } from "bootstrap";
import PageHeader from "../view/pageheader";
import InputComponent from "../fields/InputComponent";
import SelectComponent from "../fields/SelectComponent";
import ButtonComponent from "../fields/ButtonComponent";
import CardComponent from "../fields/CardComponent";
import {
   addClient,
   getClientData,
   updateClient,
   resetSuccessFlag,
} from "../store/reducer/clientSlice";
import ModalComponent from "../fields/ModalComponent";
import { toast } from "react-toastify";
import ManagedProject from "./Projects/ManagedProject";
import Projects from "./Projects/projects";
import CardOrCollapse from "../fields/CardOrCollapse";
import { getProjectList } from "../store/reducer/projectSlice";
import { getStatesList } from '../store/reducer/statesListSlice';

const ManagedClients = () => {
   const { Panel } = Collapse;
   const [clientDetails, setClientDetails] = useState({
      client_number: "",
      email: "",
      address_one: "",
      address_two: "",
      phone: "",
      city: "",
      state: "",
      zip_code: "",
      client_status: 1,
      first_name: '',
      last_name: ''
   });

   const [status, setStatus] = useState([
      {
         value: 1,
         label: "Active",
      },
      {
         value: 0,
         label: "Inactive",
      },
   ]);
   const [open, setOpen] = useState(false);
   const [touchedFields, setTouchedFields] = useState({});
   const [, forceUpdate] = useState(); // Use state to trigger re-renders
   const [type, setType] = useState("add");
   const [stateList, setStateList] = useState([]);
   const location = useLocation();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { client_id } = useParams();
   const roleID = localStorage.getItem('role_id')
   const category_type = localStorage.getItem('category_type')

   console.log(clientDetails, "clientDetails=");

   //Selectors
   const {
      addClientLoading,
      addClientError,
      addedStaff,
      addClientSuccess,
      updateClientSuccess,
      getAddedData,
      clientError,
   } = useSelector(state => state.client);
   const {
      projectListData
   } = useSelector(state => state.project);
   console.log(getAddedData, "getAddedData");

   const { statesListData, statesListLoading, statesError, statesErrorMessage } = useSelector((state) => state.states);

   const validator = useRef(
      new SimpleReactValidator({
         className: "validation-message",
         messages: {
            required: "Please enter :attribute",
            email: "Email should contain @ and .",
            alpha: ":attribute should contain only alphabets",
            regex: ":attribute format is invalid",
            in: ":attribute should be one of the accepted values",
            min: ":attribute must be at least :min characters",
            confirmed: "Passwords do not match",
         },
      })
   );

   const validationRules = {
      client_number: `required`,
      email: `required|email`,
      address_one: `required`,
      address_two: "",
      phone: `required|phone`,
      city: `required`,
      state: `required`,
      zip_code: `required|numeric|min:5|max:12`,
      client_status: `required`,
      first_name: `required|alpha`,
      last_name: `required|alpha`,
   };

   const formatPhoneNumber = (value) => {
      // Remove all non-digit characters
      const cleaned = ('' + value).replace(/\D/g, '');
  
      // Check if the input is longer than 10 characters
      if (cleaned.length <= 10) {
          const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
          if (match) {
              return [match[1], match[2], match[3]].filter(Boolean).join('-');
          }
      }
      return value; // Return unformatted value if it's too long
  };

   const addEditUserDetails = (e) => {
      const { name, value } = e.target;
  
      // If the input is for the phone field, format it
      const formattedValue = name === "phone" ? formatPhoneNumber(value) : value;
  
      setClientDetails({
          ...clientDetails,
          [name]: formattedValue
      });
      setTouchedFields(prevState => ({
         ...prevState,
         [name]: true,
      }));
  };

   const handleSubmit = e => {
      e.preventDefault();
      if (validator.current.allValid()) {
         let postData = clientDetails;
         console.log(postData, "postt");
         if (type === "add") {
            dispatch(addClient({ postData }));
         } else {
            let updateID = atob(client_id);
            console.log(updateID, "updateID");
            dispatch(updateClient({ postData, updateID }));
         }
      } else {
         validator.current.showMessages();
         forceUpdate(1);
      }
   };

   const reset = () => {
      setClientDetails({
         ...clientDetails,
         client_number: "",
         email: "",
         address_one: "",
         address_two: "",
         phone: "",
         city: "",
         state: "",
         zip_code: "",
         client_status: 1,
      });
      setTouchedFields({});
   };

   const onChange = (value, name) => {
      console.log(value, name, "dataaa==");
      setClientDetails(prevState => ({
         ...prevState,
         [name]: value,
      }));
   };

   const onSearch = value => {
      console.log("search:", value);
   };


   function getData(data) {
      const payload = { ...data, id: atob(client_id) };
      console.log("payload", payload);
      dispatch(getProjectList(payload));
   }

   useEffect(() => {
      if (addClientSuccess) {
         setClientDetails({
            ...clientDetails,
            client_number: "",
            email: "",
            address_one: "",
            address_two: "",
            phone: "",
            city: "",
            state: "",
            zip_code: "",
            client_status: 1,
         });
         toast.success("Client added Successfully!");
         dispatch(resetSuccessFlag({ flag: "addClientSuccess" }));
         navigate("/clients");
      }
      if (updateClientSuccess) {
         // setClientDetails({
         //    ...clientDetails,
         //    client_number: "",
         //    email: "",
         //    address_one: "",
         //    address_two: "",
         //    phone: "",
         //    city: "",
         //    state: "",
         //    zip_code: "",
         //    client_status: 1,
         // });
         toast.success("Client Updated Successfully!");
         dispatch(resetSuccessFlag({ flag: "updateClientSuccess" }));
         // navigate("/clients");
      }
      if (clientError) {
         toast.error(clientError);
      }
   }, [addClientSuccess, updateClientSuccess, clientError, dispatch, navigate]);

   console.log(addClientSuccess, updateClientSuccess, "updateClientSuccess");

   useEffect(() => {
      if (client_id) {
         getData()
         setType("edit");
         const decodedId = atob(client_id); // Use a different variable name
         dispatch(getClientData({ id: decodedId }));
      } else {
         setType("add");
         setTouchedFields({});
      }
   }, [client_id, dispatch]);

   useEffect(() => {
      dispatch(getStatesList({}));
   }, [])

   useEffect(() => {
      if (getAddedData && type === "edit") {
         setClientDetails({
            ...clientDetails,
            first_name: getAddedData?.first_name,
            last_name: getAddedData?.last_name,
            client_number: getAddedData?.client_number,
            email: getAddedData?.email,
            address_one: getAddedData?.address_one,
            address_two: getAddedData?.address_two,
            phone: getAddedData?.phone,
            city: getAddedData?.city,
            state: getAddedData?.state,
            zip_code: getAddedData?.zip_code,
            client_status: getAddedData?.client_status,
         });
      }
   }, [getAddedData, type]);

   useEffect(() => {
      if (statesListData.length) {
         const list = statesListData.map(item => ({
            value: item.state,
            label: item.state,
         }));
         setStateList([...list]);
      }
   }, [statesListData])

  

   console.log(clientDetails, "clientDetails");

   return (
      <div className="container-fluid-staff">
         <PageHeader
            HeaderText={type === "add" ? "Add Client" : "Update Client"}
            Breadcrumb={[{ name: type === "add" ? "Add Client" : "Update Client" }]}
            backButtonUrl={`/clients`}
         />
         <CardOrCollapse type={type} titleName="Client Details">
            <div className="col-md-12">
               <div className="row">
                  <div className="col-md-4">
                     <label htmlFor="first_name">First Name</label>
                     <span className="m-1" style={{ color: "red" }}>
                        *
                     </span>
                     <InputComponent
                        name="first_name"
                        placeholder="First Name"
                        type="text"
                        icon={<UserOutlined />}
                        value={clientDetails.first_name}
                        onChange={addEditUserDetails}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                  </div>
                  <div className="col-md-4">
                     <label htmlFor="first_name">Last Name</label>
                     <span className="m-1" style={{ color: "red" }}>
                        *
                     </span>
                     <InputComponent
                        name="last_name"
                        placeholder="Last Name"
                        type="text"
                        icon={<UserOutlined />}
                        value={clientDetails.last_name}
                        onChange={addEditUserDetails}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                  </div>
                  <div className="col-md-4">
                     <label htmlFor="first_name">Client ID</label>
                     <span className="m-1" style={{ color: "red" }}>
                        *
                     </span>
                     <InputComponent
                        disabled={type === "edit"}
                        name="client_number"
                        placeholder="Client ID"
                        type="text"
                        icon={<UserOutlined />}
                        value={clientDetails.client_number}
                        onChange={addEditUserDetails}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                  </div>
               </div>
            </div>
            <div className="col-md-12">
               <div className="row">
                  <div className="col-md-4">
                     <label htmlFor="email">Email</label>
                     <span className="m-1" style={{ color: "red" }}>
                        *
                     </span>
                     <InputComponent
                        name="email"
                        placeholder="Email Id"
                        type="text"
                        icon={<MailOutlined />}
                        value={clientDetails.email}
                        onChange={addEditUserDetails}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                  </div>
                  <div className="col-md-4">
                     <label htmlFor="address_one">Address 1</label>
                     <span className="m-1" style={{ color: "red" }}>
                        *
                     </span>
                     <InputComponent
                        name="address_one"
                        placeholder="Address line 1"
                        type="text"
                        icon={<i className="bi bi-geo-alt-fill"></i>}
                        value={clientDetails.address_one}
                        onChange={addEditUserDetails}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                  </div>
                  <div className="col-md-4">
                     <label htmlFor="address_two">Address 2</label>
                     <InputComponent
                        name="address_two"
                        placeholder="Address line 2"
                        type="text"
                        icon={<i className="bi bi-geo-alt-fill"></i>}
                        value={clientDetails.address_two}
                        onChange={addEditUserDetails}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                  </div>
               </div>
            </div>
            <div className="col-md-12 mt-3">
               <div className="row">
                  <div className="col-md-4">
                     <label htmlFor="city">City</label>
                     <span className="m-1" style={{ color: "red" }}>
                        *
                     </span>
                     <InputComponent
                        name="city"
                        placeholder="City"
                        type="text"
                        icon={<i className="bi bi-geo-alt-fill"></i>}
                        value={clientDetails.city}
                        onChange={addEditUserDetails}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                  </div>
                  <div className="col-md-4">
                     <label htmlFor="state">State</label>
                     <span className="m-1" style={{ color: "red" }}>
                        *
                     </span>
                     <SelectComponent
                        name="state"
                        value={clientDetails.state ? clientDetails.state : null}
                        placeholder="Select State"
                        onChange={value => onChange(value, "state")}
                        onSearch={onSearch}
                        options={stateList}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                     {/* <InputComponent
                        name="state"
                        placeholder="State"
                        type="text"
                        icon={<i className="bi bi-geo-alt-fill"></i>}
                        value={clientDetails.state}
                        onChange={addEditUserDetails}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     /> */}
                  </div>
                  <div className="col-md-4">
                     <label htmlFor="zip_code">Zip Code</label>
                     <span className="m-1" style={{ color: "red" }}>
                        *
                     </span>
                     <InputComponent
                        name="zip_code"
                        placeholder="Zip Code"
                        type="text"
                        icon={<i className="bi bi-geo-alt-fill"></i>}
                        value={clientDetails.zip_code}
                        onChange={addEditUserDetails}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                  </div>
               </div>
            </div>
            <div className="col-md-12 mt-3">
               <div className="row">
                  <div className="col-md-4">
                     <label htmlFor="phone">Phone</label>
                     <span className="m-1" style={{ color: "red" }}>
                        *
                     </span>
                     <InputComponent
                        name="phone"
                        placeholder="xxx-xxx-xxxx"
                        type="text"
                        value={clientDetails.phone}
                        onChange={addEditUserDetails}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                  </div>
                  <div className="col-md-4">
                     <label htmlFor="name">Client Status</label>
                     <span className="m-1" style={{ color: "red" }}>
                        *
                     </span>
                     <br />
                     <SelectComponent
                        name="client_status"
                        value={clientDetails.client_status}
                        placeholder="Please choose staff category_type"
                        onChange={value => onChange(value, "client_status")}
                        onSearch={onSearch}
                        options={status}
                        validator={validator}
                        touchedFields={touchedFields}
                        validationRules={validationRules}
                     />
                  </div>
               </div>
            </div>
            {roleID === '2' && category_type === '3' ? null :
            <div className="text-center mt-3">
               <ButtonComponent
                  style={{ backgroundColor: "#f7931e", color: "white" }}
                  onClick={handleSubmit}
                  buttonName={type === "add" ? "Add Client" : "Update Client"}
                  icon={<i class="fa fa-user-plus" aria-hidden="true"></i>}
               />
               &nbsp;
               <ButtonComponent
                  style={{ backgroundColor: "#6E6E6E", color: "white" }}
                  onClick={reset}
                  buttonName="Reset"
                  icon={<i class="fa fa-refresh" aria-hidden="true"></i>}
               />
            </div>}
         </CardOrCollapse>
         {/* </Panel>
         </Collapse> */}
         <br />
         {type === "edit" ? (
            <Projects clientNumber={clientDetails?.client_number} client_id={atob(client_id)} />
         ) : null}
         {/* {type === 'edit' ?
                
                <ManagedProject />
                : null} */}
         {/* </Card> */}
      </div>
   );
};

export default ManagedClients;

import React, { useEffect, useState } from 'react'
import sampleImage from '../../assets/images/xs/roomImage.jpg'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getClientUserJobs, getDemoClientUserJobs } from '../../store/reducer/portalUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { assestURL } from '../../services/config';
import { Skeleton } from 'antd';

const ClientPortalHomeScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const clientUserId = localStorage.getItem('client_id')
  const projectId = localStorage.getItem('project_id')
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  const currentUrl = window.location.href;
  const demoMode = currentUrl.includes('preview')
  const projectType = localStorage.getItem('project_type')
  const { clientUserJobs, demoClientUserJobs, demoClientUserJobsError } = useSelector(state => state.portalUser)
  const [jobsArray, setJobsArray] = useState()

  const location = useLocation();
  const { project_id } = useParams();

  console.log(demoClientUserJobsError, 'demoClientUserJobsError');
  console.log(clientUserJobs, demoClientUserJobs, 'clientUserJobs');
  console.log(isAuthenticated === 'true', isAuthenticated === true, 'isAuthenticated');


  const cards = [
    { title: '2205-1', image: sampleImage },
    { title: '2205-2', image: sampleImage },
    { title: '2205-3', image: sampleImage },
    // { title: 'Card 2', description: 'Description for card 2' },
    // { title: 'Card 3', description: 'Description for card 3' }
  ];

  useEffect(() => {
    console.log(demoMode, 'demoMode');
    if (demoMode === true && isAuthenticated === 'true') {
      console.log(demoMode, 'demoMode1');

      dispatch(getDemoClientUserJobs({ id: Number(atob(project_id)) }))
    }
    else {
      dispatch(getClientUserJobs({ id: Number(clientUserId) }));
    }
  }, [isAuthenticated, demoMode, clientUserId, project_id])

  useEffect(() => {
    if (demoMode === true) {
      setJobsArray(demoClientUserJobs)
    }
    else {
      setJobsArray(clientUserJobs)
    }
  }, [demoMode, clientUserJobs, demoClientUserJobs])

  return (
    <>
      <div className="heading-container">
        <h1 className="client-heading">Welcome to Your Inventory</h1>
        <p className="subheading">Select a property to get started</p>
      </div>
      <div className="client-portal-home-container">
        <div className="client-portal-home-card-container">
          {jobsArray && jobsArray.length > 0 ? jobsArray.map((card, index) => (
            <div key={index} className="client-portal-home-card"
            //  onClick={() => {
            //   if(demoMode === true){
            //   navigate(`/preview/clientportal/${btoa(card?.project_id)}/${btoa(card?.id)}`)
            //   }else{
            //   navigate(`/clientportal/${btoa(card?.project_id)}/${btoa(card?.id)}`)
            //   }
            // }}
            >
              {!card.front_image ? (
                <Skeleton.Image style={{ height: '155px', width: '100%' }} />
              ) : null}
              <div
                className="client-portal-home-card-image"
                style={{ backgroundImage: card.front_image ? `url(${assestURL + card.front_image})` : 'none' }}
                onClick={() => {
                  if (demoMode === true) {
                    navigate(`/preview/clientportal/${btoa(card?.project_id)}/${btoa(card?.id)}`)
                  } else {
                    navigate(`/clientportal/${btoa(card?.project_id)}/${btoa(card?.id)}`)
                  }
                }}
              >
              </div>
              <a href='javascript:;' onClick={() => {
                if (demoMode === true) {
                  navigate(`/preview/clientportal/${btoa(card?.project_id)}/${btoa(card?.id)}`)
                } else {
                  navigate(`/clientportal/${btoa(card?.project_id)}/${btoa(card?.id)}`)
                }
              }}><center><strong>{card.job_name}</strong></center></a>
              <center className='mt-2'><Link to={`/chart/${btoa(card?.id)}`} onClick={() => {
                localStorage.setItem('valuation', card?.valuation === 1 ? true : false)
              }}><strong>View Dashboard</strong></Link></center>
            </div>
          )) :
            <h4 style={{ marginTop: '150px' }}>No Data</h4>
          }
        </div>
      </div>
    </>
  );
}

export default ClientPortalHomeScreen
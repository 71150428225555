import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Document, Page } from 'react-pdf';

const SampleTable = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Handle file selection
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const pdfFiles = files.filter(file => file.type === 'application/pdf');
        setSelectedFiles(pdfFiles);
        setIsModalOpen(true);
    };

    // Handle file upload
    const handleSubmit = () => {
        if (selectedFiles.length === 0) {
            message.error('Please select at least one file before uploading.');
            return;
        }

        const url = 'http://localhost:3000/uploadFile'; // Replace with your backend URL

        selectedFiles.forEach(file => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            axios.post(url, formData, config)
                .then(response => {
                    console.log(response.data);
                    message.success(`${file.name} uploaded successfully.`);
                })
                .catch(error => {
                    console.error('Error uploading file:', error);
                    message.error(`${file.name} failed to upload.`);
                });
        });

        setIsModalOpen(false);
        setSelectedFiles([]);
    };

    const handleAddDocument = () => {
        document.getElementById('pdf-upload-input').click();
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const documentsFileOnchange = async (e, type) => {
        let newArray = [];
        let name;
            const formData = new FormData();
            let file_size
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('file', e.target.files[i]);
                let size = e.target.files[0].size
                file_size = size / 1000000
 
            }
 
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER,
            // });
            console.log(file_size, "file_size");
            if (file_size <= 5) {
                // const uploadFile = await axios.post(`${url.file}/upload/multiple`,
                //     formData, {
                //     headers: {
                //         'Content-type': 'multipart/form-data',
                //     },
                // });
 
                // name = uploadFile.data.data.files;
                // console.log(name[0], "file-name");
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER,
                // });
            }
            else {
                // toast.error("Please check file size")
            }
    }

    return (
        <div>
            {/* <Button icon={<UploadOutlined />} onClick={handleAddDocument}>
                Add Document
            </Button> */}

            <input
                type="file"
                accept="application/pdf"
                multiple={true}
                // disabled={routename == 'viewVendor' ? true : false}
                // style={{
                //     display:
                //         'none',
                // }}
                name="image_id"
                onChange={(e) => documentsFileOnchange(e, 1)}
            />

            <Modal
                title="Selected PDF Files"
                visible={isModalOpen}
                onCancel={closeModal}
                footer={[
                    <Button key="upload" type="primary" onClick={handleSubmit}>
                        Upload
                    </Button>,
                    <Button key="close" onClick={closeModal}>
                        Close
                    </Button>,
                ]}
                width="80%"
                bodyStyle={{
                    overflowX: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                }}
            >
                <div style={{ display: 'flex', gap: '10px' }}>
                    {selectedFiles.map((file, index) => (
                        <div key={index} style={{ marginRight: '10px' }}>
                            <Document
                                file={URL.createObjectURL(file)}
                            >
                                <Page pageNumber={1} width={200} />
                            </Document>
                        </div>
                    ))}
                </div>
            </Modal>
        </div>
    );
};

export default SampleTable;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPhotographer, editPhotographer, getPhotographerDataByID, getPhotographerJobsDataByID, getPhotographerProjectDataByID, getPhotographerRoomsDataByID, photographerDelete, photographerList, updatePhotographerStatus } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "photographer";

// Async thunk for fetching the staff list
export const getPhotographerList = createAsyncThunk(
    `${namespace}/getPhotographerList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await photographerList(query, page, limit, sortby, order);
            // return data;
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a staff
export const addPhotographer = createAsyncThunk(
    `${namespace}/staff`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await createPhotographer({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for fetching staff data by ID
export const getPhotographerData = createAsyncThunk(
    `${namespace}/getPhotographerDataById`,  // <-- Changed action type to be unique
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await getPhotographerDataByID(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating staff data
export const updatePhotographer = createAsyncThunk(
    `${namespace}/UpdateCategory`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editPhotographer({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Update Photographer Status
export const photographerStatus = createAsyncThunk(
    `${namespace}/UpdateStatus`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await updatePhotographerStatus({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for delete photographer
export const deletePhotographer = createAsyncThunk(
    `${namespace}/photographerDelete`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await photographerDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getPhotographerProjectData = createAsyncThunk(
    `${namespace}/getPhotographerProjectDataById`,  // <-- Changed action type to be unique
    async ({ id, status }, { rejectWithValue }) => {
        try {
            const data = await getPhotographerProjectDataByID(id, status);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getPhotographerJobsData = createAsyncThunk(
    `${namespace}/getPhotographerJobsDataById`,  // <-- Changed action type to be unique
    async ({ id, projectId }, { rejectWithValue }) => {
        try {
            const data = await getPhotographerJobsDataByID(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getPhotographerRoomsData = createAsyncThunk(
    `${namespace}/getPhotographerRoomsData`,  // <-- Changed action type to be unique
    async ({ id, projectId }, { rejectWithValue }) => {
        try {
            const data = await getPhotographerRoomsDataByID(id, projectId);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// actions.js
export const fetchAndSetPhotographerPermissionIds = () => {
    return (dispatch) => {
      const photographerPermissions = JSON.parse(localStorage.getItem('photographer_permission'));
      
      if (photographerPermissions && photographerPermissions.length > 0) {
        const tempIds = photographerPermissions.map((item) => item.permission_id);
        console.log(tempIds, 'tempIds');
        dispatch(setPhotographerPermissionIds(tempIds));
      } else {
        dispatch(setPhotographerPermissionIds([]));
      }
    };
  };

const photographerSlice = createSlice({
    name: namespace,
    initialState: {
        photographerListData: [],
        photographerListLoading: false,
        addStaffLoading: false,
        addPhotographerSuccess: false,
        photographerError: null,
        addedPhotgrapher: null,
        getDataById: [],
        updateData: [],
        updatePhotographerSuccess: false,
        deletePhotographerLoading: false,
        deletePhotographerSuccess: false,
        deletePhotographerError: false,
        deletePhotographerErrorMessage: "",
        photographerStatusLoading: false,
        photographerStatusSuccess: false,
        photographerStatusError: false,
        photographerStatusErrorMessage: "",
        getPhotographerProjectDatas : [],
        getPhotographerJobs : [],
        getPhotographerJobsSuccess : false,
        getPhotographerRooms : [],
        getPhotographerRoomSuccess : false,
        photographerPermissionIds: []
    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
        setPhotographerPermissionIds: (state, action) => { // <-- Add this reducer
            state.photographerPermissionIds = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle getPhotographerList
            .addCase(getPhotographerList.pending, (state) => {
                state.photographerListLoading = true;
                state.addStaffLoading = false;
                state.addPhotographerSuccess = false;
                state.photographerError = null;
                state.addedPhotgrapher = null;
                state.getDataById = [];
                state.updateData = [];
                state.updatePhotographerSuccess = false;
                state.deletePhotographerLoading = false;
                state.deletePhotographerSuccess = false;
                state.deletePhotographerError = false;
                state.deletePhotographerErrorMessage = "";
                state.photographerStatusLoading = false;
                state.photographerStatusSuccess = false;
                state.photographerStatusError = false;
                state.photographerStatusErrorMessage = "";
            })
            .addCase(getPhotographerList.fulfilled, (state, action) => {
                const data = action.payload;
                state.photographerListData = data;
                state.photographerListLoading = false;
                state.photographerError = ''
            })
            .addCase(getPhotographerList.rejected, (state, action) => {
                state.photographerListLoading = false;
                state.photographerError = action.payload.data.message;
            })

            // Handle addPhotographer
            .addCase(addPhotographer.pending, (state) => {
                state.addStaffLoading = true;
                state.photographerError = null;
                state.addedPhotgrapher = null;
                state.addPhotographerSuccess = false;
            })
            .addCase(addPhotographer.fulfilled, (state, action) => {
                state.addStaffLoading = false;
                state.addedPhotgrapher = action.payload;
                state.addPhotographerSuccess = true;
                state.photographerError = ''
            })
            .addCase(addPhotographer.rejected, (state, action) => {
                state.addStaffLoading = false;
                state.photographerError = action.payload.data.message;
                state.addPhotographerSuccess = false;
            })

            // Handle getPhotographerData by ID
            .addCase(getPhotographerData.pending, (state) => { })
            .addCase(getPhotographerData.fulfilled, (state, action) => {
                const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                state.getDataById = data.response;
                state.photographerError = ''
            })
            .addCase(getPhotographerData.rejected, (state, action) => { 
                state.photographerError = action.payload.data.message;
            })

            // Handle updatePhotographer
            .addCase(updatePhotographer.pending, (state) => {
                state.updatePhotographerSuccess = false;
            })
            .addCase(updatePhotographer.fulfilled, (state, action) => {
                state.updateData = action.payload;
                state.updatePhotographerSuccess = true;
                state.photographerError = ''
            })
            .addCase(updatePhotographer.rejected, (state, action) => {
                state.updatePhotographerSuccess = false;
                state.photographerError = action.payload.data.message;
            })

            // Update photographer status
            .addCase(photographerStatus.pending, state => {
                state.photographerStatusLoading = true;
                state.photographerStatusSuccess = false;
                state.photographerStatusError = false;
                state.photographerStatusErrorMessage = "";
                state.deletePhotographerLoading = false;
                state.deletePhotographerSuccess = false;
                state.deletePhotographerError = false;
                state.deletePhotographerErrorMessage = "";
            })
            .addCase(photographerStatus.fulfilled, (state, action) => {
                state.photographerStatusSuccess = true;
                state.photographerStatusLoading = false;
                state.photographerError = ''
            })
            .addCase(photographerStatus.rejected, (state, action) => {
                state.photographerStatusError = true;
                state.photographerStatusLoading = false;
                state.photographerStatusErrorMessage = action.error.message;
            })

            // Handle DeletePhotographer
            .addCase(deletePhotographer.pending, state => {
                state.deletePhotographerLoading = true;
                state.deletePhotographerError = false;
                state.deletePhotographerSuccess = false;
                state.deletePhotographerErrorMessage = "";
                state.photographerStatusLoading = false;
                state.photographerStatusSuccess = false;
                state.photographerStatusError = false;
                state.photographerStatusErrorMessage = "";
            })
            .addCase(deletePhotographer.fulfilled, (state, action) => {
                state.deletePhotographerSuccess = true;
                state.deletePhotographerLoading = false;
                state.photographerError = ''
            })
            .addCase(deletePhotographer.rejected, (state, action) => {
                state.deletePhotographerError = true;
                state.deletePhotographerLoading = false;
                state.deletePhotographerErrorMessage = action.error.message;
            })

            // Handle getPhotographerProjectData by ID
            .addCase(getPhotographerProjectData.pending, (state) => { })
            .addCase(getPhotographerProjectData.fulfilled, (state, action) => {
                console.log(action.payload,'action.payload');
                const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                console.log(data,'data');
                state.getPhotographerProjectDatas = data.response;
            })
            .addCase(getPhotographerProjectData.rejected, (state, action) => { 
                state.photographerError = action.payload.data.message;
            })


            // Handle getPhotographerProjectData by ID
            .addCase(getPhotographerJobsData.pending, (state) => { })
            .addCase(getPhotographerJobsData.fulfilled, (state, action) => {
                console.log(action.payload,'action.payload');
                const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                console.log(data,'data');
                state.getPhotographerJobs = data.response;
                state.getPhotographerJobsSuccess = true

            })
            .addCase(getPhotographerJobsData.rejected, (state, action) => { 
                // state.photographerError = action.payload.data.message;
            })
            
             // Handle getPhotographerProjectData by ID
             .addCase(getPhotographerRoomsData.pending, (state) => { })
             .addCase(getPhotographerRoomsData.fulfilled, (state, action) => {
                 console.log(action.payload,'action.payload');
                 const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                 console.log(data,'data');
                 state.getPhotographerRooms = data.response;
                 state.getPhotographerRoomSuccess = true
 
             })
             .addCase(getPhotographerRoomsData.rejected, (state, action) => { 
                 // state.photographerError = action.payload.data.message;
             })
    },
});

export const { resetSuccessFlag,setPhotographerPermissionIds } = photographerSlice.actions;

export const photographerSelector = (state) => state[namespace];

export default photographerSlice.reducer;

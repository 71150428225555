import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Login from "./components/login";
import NavBar from "./view/navbar";
import PhotographerDashboard from './components/PhotographerLogin/PhotographerDashboard';
import PhotographerJobList from './components/PhotographerLogin/PhotographerJobList';
import TourViewer from './components/ClientPortal/TourViewer';
import Rooms from './components/Projects/Rooms';
import ClientPortal from './components/ClientPortal/ClientPortal';
import ClientPortalHomeScreen from './components/ClientPortal/ClientPortalHomeScreen';
import ThreeSixtyView from './components/ClientPortal/ThreeSixtyView';
import PieCharts from './components/ClientPortal/FamilyLawDashboard';
import PanoramaAddEdit from './components/Build360/PanoramaAddEdit';
import HotspotThreeSixtyImg from './components/Build360/HotspotThreeSixtyImg';
import ClientTourViewer from './components/ClientPortal/ClientTourViewer';
import ExecutorInventory from './components/ClientPortal/ExecutorInventory';
import EstateExecutorClaiment from './components/ClientPortal/EstateExecutorClaiment';
import Reports from './components/Reports/Reports';
import GeneratedReport from './components/Reports/GeneratedReport';
import Dashboard from './components/dashboard';
import Photographer from './components/photographer';
import Clients from './components/clients';
import ManagedProject from './components/Projects/ManagedProject';
import Projects from './components/Projects/projects';
import ManagedJobs from './components/Projects/ManagedJobs';
import EditJobWithRooms from './components/Projects/EditJobWithRooms';
import ManagedClients from './components/ManagedClients';
import ItemCategories from './components/Projects/ItemCategories';
import PortalUsers from './components/Projects/PortalUsers';
import AddPortalUser from './components/Projects/AddPortalUser';
import { doLogin } from './store/reducer/login';
import DashboardStaffJobList from './components/DashboardStaffJobList';

console.log("inasdsad");
const PrivateRoute = () => {
    console.log("inasdsad");
    const dispatch = useDispatch();

    const isAuth = sessionStorage.getItem('isAuthenticated');
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    const roleID = localStorage.getItem('role_id')
    const category_type = localStorage.getItem('category_type')
    const pu_type = localStorage.getItem('pu_type')
    const currentUrl = window.location.href;
    const demoMode = currentUrl.includes('preview')
    console.log(roleID, demoMode, 'roleID==');
    if (demoMode === true) {

        let postData = {
            email: "demo@takestock.com",
            password: "demo@1234"
        };
        console.log(postData, 'pos-check');
        dispatch(doLogin({ postData }));
        localStorage.setItem('isAuthenticated', true)
    }

    console.log(isAuthenticated, demoMode, 'isAuthenticated && demoMode');

    // if (!isAuthenticated) {
    //     localStorage.clear();
    // }
    return (
        demoMode ? (
            <div id="wrapper">
                <div>
                    <NavBar />
                </div>
                <div id="main-content">
                    <Routes>
                        <Route exact path="/preview/clientportal/home/:project_id" element={<ClientPortalHomeScreen />} />
                        <Route exact path="/preview/clientportal/:project_id/:jobid" element={<ClientPortal />} />
                        <Route exact path="/preview/client/tour/:roomid/:jobid/:project_id" element={<ClientTourViewer />} />
                        <Route exact path="/preview/chart" element={<PieCharts />} />
                    </Routes>
                </div>
            </div>
        ) : (
            // All other conditions if demoMode is not true
            isAuthenticated && roleID == '3' ? (
                <div id="wrapper">
                    <div>
                        <NavBar />
                    </div>
                    <div style={{ marginTop: '75px', marginLeft: '35px', marginRight: '35px' }}>
                        <Routes>
                            <Route path="/photographer/dashboard" element={<PhotographerDashboard />} />
                            <Route path="/photographer/jobs" element={<PhotographerJobList />} />
                            <Route exact path="/client/tour" element={<TourViewer />} />
                            <Route exact path="/projects/:project_id/:job_id/rooms/:roomid" element={<Rooms />} />
                            <Route exact path="/uploadPanos/:project_id/:job_id" element={<PanoramaAddEdit />} />
                            <Route exact path="/start/tour" element={<HotspotThreeSixtyImg />} />
                            {/* Add more routes here if needed */}
                        </Routes>
                    </div>
                </div>
            ) : isAuthenticated && roleID == '4' ? (
                <div id="wrapper">
                    <div>
                        <NavBar />
                    </div>
                    <div id="main-content">
                        <Routes>
                            <Route exact path="/clientportal/:project_id/:jobid" element={<ClientPortal />} />
                            <Route exact path="/clientportal/:project_id/:jobid/:room_id" element={<ClientPortal />} />
                            <Route exact path="/clientportal/home" element={<ClientPortalHomeScreen />} />
                            <Route exact path="/client/tour/:roomid/:jobid/:project_id" element={<ClientTourViewer />} />
                            <Route exact path="/chart/:id" element={<PieCharts />} />
                            <Route exact path="/clientportal/executor/home" element={<ExecutorInventory />} />
                            <Route exact path="/client/executor/:job_id" element={<EstateExecutorClaiment />} />
                            <Route exact path="/client/executor/:job_id/:user_id" element={<EstateExecutorClaiment />} />
                            <Route exact path="/clientportal/user/home" element={<ExecutorInventory />} />
                            <Route exact path="/client/user/:job_id" element={<EstateExecutorClaiment />} />
                            <Route exact path="/reports" element={<Reports />} />
                            <Route exact path="/reports/executor/:job_id/:room_or_user_id/:claim_type" element={<Reports />} />
                            <Route exact path="/reports/:room_or_user_id/:job_id/:project_id" element={<Reports />} />
                            <Route exact path="/reports/view" element={<GeneratedReport />} />
                            {/* Add more routes here if needed */}
                        </Routes>
                    </div>
                </div>
            ) : isAuthenticated && roleID == '5' ? (
                <div id="wrapper">
                    <div>
                        <NavBar />
                    </div>
                    <div style={{ marginTop: '75px' }}>
                        {/* <Kitchendispatch /> */}
                    </div>
                </div>
            ) : isAuthenticated && roleID == '2' && category_type === '3' ? (
                <div id="wrapper">
                    <div>
                        <NavBar />
                    </div>
                    <div id="main-content">
                        <Routes>
                            <Route exact path="/dashboard" element={<Dashboard />} />
                            <Route exact path="/staffjoblist/:type" element={<DashboardStaffJobList />} />
                            <Route exact path="/photographer" element={<Photographer />} />
                            <Route exact path="/clients" element={<Clients />} />
                            <Route exact path="/addproject/:client_id" element={<ManagedProject />} />
                            <Route exact path="/editclient/:client_id" element={<ManagedClients />} />
                            <Route exact path="/projects" element={<Projects />} />
                            <Route exact path="/editproject/:id/:client_id" element={<ManagedProject />} />
                            <Route exact path="/project/editjob/:project_id/:client_id/:job_id" element={<ManagedJobs />} />
                            <Route exact path="/project/item-categories/:project_id/:client_id" element={<ItemCategories />} />
                            <Route exact path="/projects/editjob/:project_id/:client_id/:job_id" element={<EditJobWithRooms />} />
                            <Route exact path="/uploadPanos/:client_id/:project_id/:job_id" element={<PanoramaAddEdit />} />
                            <Route exact path="/start/tour" element={<HotspotThreeSixtyImg />} />
                            <Route exact path="/projects/:project_id/:job_id/rooms/:roomid" element={<Rooms />} />
                            <Route exact path="/project/portalusers/:project_number/:project_id/:client_id" element={<PortalUsers />} />
                            <Route exact path="/project/add-portaluser/:project_number/:project_id/:client_id" element={<AddPortalUser />} />
                            <Route exact path="/project/edit-portaluser/:pu_id/:project_number/:project_id/:client_id" element={<AddPortalUser />} />
                        </Routes>
                    </div>
                </div>
            ) : isAuthenticated ? (
                <div id="wrapper">
                    <NavBar />
                    <div id="main-content">
                        <Outlet />
                    </div>
                </div>
            ) : (
                <Navigate to="/login" />
            )
        )
    );

};
//(props) => (!isAuthenticated ? <Navigate to="/login" replace={true} /> : <Component {...props} />)


const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(PrivateRoute);
import { Card, Col, Flex, Typography, Space, Input, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ItemCategoryCard = ({ category, onUpdate, onDelete, id, projectId, icons }) => {
   const { Title } = Typography;
   const [isEdit, setIsEdit] = useState(false);
   const [categoryValue, setCategoryValue] = useState(category);
   const dispatch = useDispatch();

   useEffect(() => {
      setCategoryValue(category);
   }, [category]);

   const handleEditOnClick = () => {
      setIsEdit(!isEdit);
   };

   const handleDeleteOnClick = () => {
      dispatch(onDelete({ deleteID: id }));
   };

   const handleSaveOnClick = () => {
      setIsEdit(!isEdit);
      let postData = { category_name: categoryValue, project_id: projectId };
      dispatch(onUpdate({ postData, updateID: id }));
   };

   const handleCancelOnClick = () => {
      setCategoryValue(category);
      setIsEdit(!isEdit);
   };

   return (
      <Col span={8} key={id}>
         <Card
            key={id}
            type="inner"
            className="item-category-card"
            style={{ backgroundColor: `${isEdit ? "#f7931e" : ""}` }}
         >
            <Flex justify="space-between" align="center" className="text-white">
               {!isEdit ? (
                  <>
                     <Title level={5} className="m-0 w-75 item-category-card-title">
                        {categoryValue}
                     </Title>
                     {icons && (
                        <Space size={"small"} className="ms-1">
                           <Tooltip title="Edit">
                              <EditOutlined
                                 className="fs-6 p-2 text-success rounded-circle bg-body-secondary"
                                 onClick={handleEditOnClick}
                              />
                           </Tooltip>
                           {onDelete === null ? null :
                              <Tooltip title="Delete">
                                 <DeleteOutlined
                                    className="fs-6 text-danger p-2 rounded-circle bg-body-secondary"
                                    onClick={handleDeleteOnClick}
                                 />
                              </Tooltip>
                           }
                        </Space>
                     )}
                  </>
               ) : (
                  <>
                     <Input
                        autoFocus
                        className="w-75 rounded-3"
                        size="large"
                        value={categoryValue}
                        onChange={e => setCategoryValue(e.target.value)}
                        onPressEnter={handleSaveOnClick}
                     />
                     <Space size={"small"} className="ms-1">
                        <Tooltip title="Save">
                           <CheckOutlined
                              className="fs-6 p-2 text-success rounded-circle bg-body-secondary"
                              onClick={handleSaveOnClick}
                           />
                        </Tooltip>
                        <Tooltip title="Cancel">
                           <CloseOutlined
                              className="fs-6 text-danger p-2 rounded-circle bg-body-secondary"
                              onClick={handleCancelOnClick}
                           />
                        </Tooltip>
                     </Space>
                  </>
               )}
            </Flex>
         </Card>
      </Col>
   );
};

export default ItemCategoryCard;

import { SyncOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tooltip } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComponent from "../../fields/ButtonComponent";
import SearchComponent from "../../fields/SearchComponent";
import SwalComponent from "../../fields/SwalComponent";
import TableComponent from "../../fields/TableComponent";
import { deleteProject, getProjectList, projectStatus } from "../../store/reducer/projectSlice";
import PageHeader from "../../view/pageheader";
import Loader from "../../fields/loader/Loader";

const Projects = ({ clientNumber }) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const location = useLocation();
   const { client_id } = useParams();

   // Selectors
   const {
      projectListData,
      projectListLoading,
      deleteProjectLoading,
      deleteProjectSuccess,
      deleteProjectError,
      deleteprojectErrorMessage,
      projectStatusLoading,
      projectStatusSuccess,
      projectStatusError,
      projectStatusErrorMessage,
   } = useSelector(state => state.project);
   const roleID = localStorage.getItem('role_id')
   const category_type = localStorage.getItem('category_type')

   const { clientListData } = useSelector(state => state.client)

   console.log(clientListData, projectListData, 'clientListData');

   //    const { count: totalCount, data: tableData } = photographerListData;

   const status = {
      1: 'Active', 2: 'Completed', 3: 'Cancelled'
   }

   const projectType = {
      1: "Estate/Probate",
      2: "Family Law",
      3: "Homeowner",
      4: "Schools",
      5: "Commercial",
      6: "Storage",
      7: "Other",
   };

   function getData(payload = {}) {
      dispatch(getProjectList(payload));
   }

   const getProjectData = (data) => {
      const payload = { ...data, id: atob(client_id) };
      console.log("payload", payload);
      dispatch(getProjectList(payload));
   }

   useEffect(() => {
      if (client_id) {
         getProjectData()
      }
      else {
         getData({});
      }
   }, [client_id])

   useEffect(() => {

      if (deleteProjectSuccess) {
         toast.success("Project deleted successfully");
      }

      if (deleteProjectError) {
         toast.error(deleteprojectErrorMessage);
      }

      if (projectStatusSuccess) {
         toast.success("Project status updated successfully");
      }

      if (projectStatusError) {
         toast.error(projectStatusErrorMessage);
      }
   }, [deleteProjectError, deleteProjectSuccess, projectStatusError, projectStatusSuccess]);

   const columns = [
      {
         title: "Project Number",
         key: "project_number",
         render: record => (
            <Link to={`/editproject/${btoa(record?.id)}/${client_id ? client_id : "client"}`}>
               <span>{record.project_number}</span>
            </Link>
         ),
         align: "center",
         sorter: true,
      },
      {
         title: "Project Name",
         // dataIndex: "project_name",
         key: "project_name",
         align: "center",
         sorter: true,
         render: (record) => <span>{record["project_name"] ? record["project_name"] : "-"}</span>
      },
      (location.pathname.startsWith('/editclient/') ?
         {
            title: "",
            dataIndex: false,
            key: false,
            align: "center",
            sorter: true,
            hidden: true
         }
         :
         {
            title: "Client Number",
            dataIndex: "client_number",
            key: "client_number",
            align: "center",
            sorter: true,
         }),
      {
         title: "Type",
         key: "project_type",
         align: "center",
         render: record => <span> {projectType[record.project_type]}</span>,
         sorter: true,
      },
      {
         title: "Status",
         key: "project_status",
         align: "center",
         render: record => <span> {status[record.project_status]}</span>,
         sorter: true,
      },
      {
         title: "Created",
         key: "created_at",
         align: "center",
         render: record => <span> {moment(record?.created_at).format("MM/DD/YY")}</span>,
         sorter: true,
      },
      {
         title: "Updated",
         key: "updated_at",
         align: "center",
         render: record => <span> {moment(record?.updated_at).format("MM/DD/YY")}</span>,
         sorter: true,
      },
      ...(roleID === '2' && category_type === '3' ? [] : [
         {
           title: "Action",
           align: "center",
           render: (record) => {
             return (
               <Space size="large" align="center">
               <span className="fs-6" style={{ color: "blue", cursor: "pointer" }}>
                  <Tooltip title="Edit">
                     <i
                        className="icon-pencil"
                        onClick={() => {
                           console.log(record, "record");
                           // navigate(`/editproject/${btoa(record?.id)}/${client_id}`);
                           navigate(`/editproject/${btoa(record?.id)}/${client_id ? client_id : "client"}`);
                        }}
                     ></i>
                  </Tooltip>
               </span>
               <SwalComponent
                  message="Are you sure you want to delete this project? Deleting the project will also remove all associated jobs and rooms."
                  onClickDispatch={deleteProject}
                  id={record.id}
               />
            </Space>
             );
           },
         },
       ])
   ];

   return (
      <div>
         {location.pathname.startsWith('/editclient/') ?
            <div className="container-fluid">
               <Card
                  title={`Projects of ${clientNumber}`}
                  extra={
                     <Space size={"middle"} className="my-3">
                        <SearchComponent
                           placeHolder="Search Projects"
                           onChange={e => {
                              const payloadData = {
                                 query: e.target.value,
                              };
                              getProjectData(payloadData);
                           }}
                        />
                        <Link to={`/addproject/${client_id}`}>
                           <ButtonComponent
                              style={{ backgroundColor: "#f7931e", color: "white" }}
                              buttonName="Add Project"
                              icon={<i class="fa fa-file-text" aria-hidden="true"></i>}
                           // onClick={() => {
                           //    if (clientNumber) {
                           //       localStorage.setItem('client_number', clientNumber)
                           //    }
                           // }}
                           />
                        </Link>
                        {/* <Tooltip title="Refresh">
                           <Button
                              shape="circle"
                              icon={
                                 <SyncOutlined
                                 //  spin={photographerListLoading || deletePhotographerLoading}
                                 />
                              }
                           //    onClick={getData}
                           ></Button>
                        </Tooltip> */}
                     </Space>
                  }
               >
                  <TableComponent
                     columns={columns}
                     dataSource={projectListData?.data}
                     TotalDataCount={projectListData?.count}
                     onChangeDispatch={getData}
                  // loading={photographerListLoading}
                  />
               </Card>
            </div>
            :
            <>
               <Loader loading={projectListLoading || deleteProjectLoading || projectStatusLoading} />
               <div className="container-fluid">
                  <PageHeader
                     HeaderText="TakeStock Projects"
                     Breadcrumb={[{ name: "TakeStock Projects" }]}
                  />
                  <Card
                     title="TakeStock Projects"
                     extra={
                        <Space size={"middle"} className="my-3">
                           <SearchComponent
                              placeHolder="Search Projects"
                              onChange={e => {
                                 const payloadData = {
                                    query: e.target.value,
                                 };
                                 getData(payloadData);
                              }}
                           />
                           {/* <Link to="/addproject">
                              <ButtonComponent
                                 style={{ backgroundColor: "#f7931e", color: "white" }}
                                 buttonName="Add Project"
                                 icon={<i class="fa fa-file-text" aria-hidden="true"></i>}
                              />
                           </Link> */}
                           {/* <Tooltip title="Refresh">
                              <Button
                                 shape="circle"
                                 icon={
                                    <SyncOutlined
                                       spin={
                                          projectListLoading ||
                                          deleteProjectLoading ||
                                          projectStatusLoading
                                       }
                                    />
                                 }
                                 onClick={getData}
                              ></Button>
                           </Tooltip> */}
                        </Space>
                     }
                  >
                     <TableComponent
                        columns={columns}
                        dataSource={projectListData?.data}
                        TotalDataCount={projectListData?.count}
                        onChangeDispatch={getData}
                     />
                  </Card>
               </div>
            </>
         }

      </div>
   );
};

export default Projects;

// import React from 'react'
// import { Card, Col, Row, Space } from 'antd';
// import PageHeader from '../view/pageheader';

// const Dashboard = () => {
//     return (
//         <div>
//             <div className="container-fluid">
//                 <PageHeader
//                     HeaderText="Dashboard"
//                     Breadcrumb={[{ name: "Dashboard" }]}
//                 />
//                 <div className="row clearfix">
//                     <Space
//                         direction="vertical"
//                         size="large"
//                         style={{
//                             display: 'flex',
//                         }}
//                     >
//                         <Row gutter={16}>
//                             <Col span={8}>
//                                 <Card title="Open Inventories" bordered={false}>
//                                     20
//                                 </Card>
//                             </Col>
//                             <Col span={8}>
//                                 <Card title="Inventories in QC" bordered={false}>
//                                     15
//                                 </Card>
//                             </Col>
//                             <Col span={8}>
//                                 <Card title="Ready to Deliver" bordered={false}>
//                                    10
//                                 </Card>
//                             </Col>
//                         </Row>
//                         <Row gutter={16}>
//                             <Col span={8}>
//                                 <Card title="Staff" bordered={false}>
//                                    35
//                                 </Card>
//                             </Col>
//                             <Col span={8}>
//                                 <Card title="Inventory Team" bordered={false}>
//                                     17
//                                 </Card>
//                             </Col>
//                             <Col span={8}>
//                                 <Card title="Clients" bordered={false}>
//                                     25
//                                 </Card>
//                             </Col>
//                         </Row>
//                     </Space>
//                 </div>
//             </div>
//         </div >
//     )
// }

// export default Dashboard

import React, { useEffect, useState } from 'react';
import './dashboard.css';
import { FaClipboardList, FaUsers, FaBoxOpen, FaCheckCircle, FaTruck, FaUserTie, FaWarehouse, FaUser, FaSearch, FaCamera } from 'react-icons/fa';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardData, getStaffDashboardData, loginSelector } from '../store/reducer/login';
import { CheckCircleOutlined } from "@ant-design/icons";
import Loader from '../fields/loader/Loader';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { dashboardDatas, staffDashboardDatas } = useSelector(loginSelector);
    // Local state for card data
    const [cardData, setCardData] = useState([]);
    const roleId = localStorage.getItem('role_id')
    const staffId = localStorage.getItem('staff_id')

    // Effect to fetch data and update card data
    useEffect(() => {
        if (roleId === '1') {
            dispatch(getDashboardData({type:'admin',id:null,staff_admin:0}));
        }
        else if((roleId === '2' && localStorage.getItem('category_type') === '1') || (roleId === '2' && localStorage.getItem('category_type') === '2')){
            dispatch(getDashboardData({type:'staff',id:staffId,staff_admin:1}));
        }
        else {
            dispatch(getStaffDashboardData({ type:'staff',id: staffId,staff_admin:0 }));
        }
    }, [roleId, staffId, dispatch]);

    useEffect(() => {
        if (dashboardDatas && roleId === '1') {
            // Update card data based on dashboardDatas
            const updatedCardData = [
                { id: 2, title: 'Open Inventories', onClick: '/joblist/all', key: '', count: dashboardDatas?.openInventoriesCount || 0, icon: <FaBoxOpen size={40} />, background: 'linear-gradient(to right, rgb(40, 48, 72), rgb(133, 147, 152))' },
                { id: 5, title: 'Staff', onClick: '/staff', key: 'staff', count: dashboardDatas?.staffCount || 0, icon: <FaUserTie size={40} />, background: 'linear-gradient(45deg, rgb(212 0 182), rgb(43 46 46))' },
                { id: 6, title: 'Inventory Team', onClick: '/photographer', key: 'photographer', count: dashboardDatas?.photographerCount || 0, icon: <FaCamera size={40} />, background: 'linear-gradient(45deg,rgb(34 111 9), rgb(84 83 82))' },
                { id: 7, title: 'Clients', onClick: '/clients', key: 'clients', count: dashboardDatas?.clientCount || 0, icon: <FaUsers size={40} />, background: 'linear-gradient(to right, rgb(0, 180, 219), rgb(0, 131, 176))' },
                { id: 1, title: 'Scheduled Inventories', onClick: '/joblist/scheduled', key: '', count: dashboardDatas?.jobInventories?.find(job => job.status === 'scheduled')?.count || 0, icon: <FaClipboardList size={40} />, background: 'linear-gradient(to right, rgb(153 56 68), rgb(244, 92, 67))' },
                { id: 3, title: 'Inventories in QC', onClick: '/joblist/ready_to_qc', key: '', count: dashboardDatas?.jobInventories?.find(job => job.status === 'ready_to_qc')?.count || 0, icon: <FaClipboardList size={40} />, background: 'linear-gradient(to right,rgb(230, 92, 0), rgb(249, 212, 35))' },
                { id: 4, title: 'Ready to Deliver', onClick: '/joblist/ready_to_deliver', key: '', count: dashboardDatas?.jobInventories?.find(job => job.status === 'ready_to_deliver')?.count || 0, icon: <FaTruck size={40} />, background: 'linear-gradient(to right,rgb(218, 68, 83), rgb(137, 33, 107))' },
                { id: 4, title: 'Delivered', onClick: '/joblist/delivered', key: '', count: dashboardDatas?.jobInventories?.find(job => job.status === 'delivered')?.count || 0, icon: <CheckCircleOutlined size={40} />, background: 'linear-gradient(to right,rgb(92 64 67), rgb(33 137 136))' },
                { id: 8, title: 'Assigned Projects', onClick: '/staffjoblist/assign_staff', key: '', count: dashboardDatas?.assignedProjects, icon: <FaBoxOpen size={40} />, background: 'linear-gradient(to right, rgb(25 57 225), rgb(133 152 152))' },
                { id: 9, title: 'QC Projects', onClick: '/staffjoblist/assign_to_qc', key: '', count: dashboardDatas?.inventoriesQC, icon: <FaClipboardList size={40} />, background: 'linear-gradient(to right, rgb(245 88 88), rgb(108 100 100))' },
            ];

            setCardData(updatedCardData); // Update the local state with new data
        }
        else if(roleId === '2' && localStorage.getItem('category_type') === '2' || roleId === '2' && localStorage.getItem('category_type') === '1'){
            const updatedCardData = [
                { id: 2, title: 'Open Inventories', onClick: '/joblist/all', key: '', count: dashboardDatas?.openInventoriesCount || 0, icon: <FaBoxOpen size={40} />, background: 'linear-gradient(to right, rgb(40, 48, 72), rgb(133, 147, 152))' },
                { id: 5, title: 'Staff', onClick: '/staff', key: 'staff', count: dashboardDatas?.staffCount || 0, icon: <FaUserTie size={40} />, background: 'linear-gradient(45deg, rgb(212 0 182), rgb(43 46 46))' },
                { id: 6, title: 'Inventory Team', onClick: '/photographer', key: 'photographer', count: dashboardDatas?.photographerCount || 0, icon: <FaCamera size={40} />, background: 'linear-gradient(45deg,rgb(34 111 9), rgb(84 83 82))' },
                { id: 7, title: 'Clients', onClick: '/clients', key: 'clients', count: dashboardDatas?.clientCount || 0, icon: <FaUsers size={40} />, background: 'linear-gradient(to right, rgb(0, 180, 219), rgb(0, 131, 176))' },
                { id: 1, title: 'Scheduled Inventories', onClick: '/joblist/scheduled', key: '', count: dashboardDatas?.jobInventories?.find(job => job.status === 'scheduled')?.count || 0, icon: <FaClipboardList size={40} />, background: 'linear-gradient(to right, rgb(153 56 68), rgb(244, 92, 67))' },
                { id: 3, title: 'Inventories in QC', onClick: '/joblist/ready_to_qc', key: '', count: dashboardDatas?.jobInventories?.find(job => job.status === 'ready_to_qc')?.count || 0, icon: <FaClipboardList size={40} />, background: 'linear-gradient(to right,rgb(230, 92, 0), rgb(249, 212, 35))' },
                { id: 4, title: 'Ready to Deliver', onClick: '/joblist/ready_to_deliver', key: '', count: dashboardDatas?.jobInventories?.find(job => job.status === 'ready_to_deliver')?.count || 0, icon: <FaTruck size={40} />, background: 'linear-gradient(to right,rgb(218, 68, 83), rgb(137, 33, 107))' },
                { id: 4, title: 'Delivered', onClick: '/joblist/delivered', key: '', count: dashboardDatas?.jobInventories?.find(job => job.status === 'delivered')?.count || 0, icon: <CheckCircleOutlined size={40} />, background: 'linear-gradient(to right,rgb(92 64 67), rgb(33 137 136))' },
                { id: 8, title: 'Assigned Projects', onClick: '/staffjoblist/assign_staff', key: '', count: dashboardDatas?.assignedProjects || 0, icon: <FaBoxOpen size={40} />, background: 'linear-gradient(to right, rgb(25 57 225), rgb(133 152 152))' },
                { id: 9, title: 'QC Projects', onClick: '/staffjoblist/assign_to_qc', key: '', count: dashboardDatas?.inventoriesQC || 0, icon: <FaClipboardList size={40} />, background: 'linear-gradient(to right, rgb(245 88 88), rgb(108 100 100)) ' },
            ];

            setCardData(updatedCardData); // Update the local state with new data
        }
        else {
            const updatedCardData = [
                { id: 1, title: 'Assigned Projects', onClick: '/staffjoblist/assign_staff', key: '', count: staffDashboardDatas?.assingedProjects, icon: <FaBoxOpen size={40} />, background: 'linear-gradient(to right, rgb(40, 48, 72), rgb(133, 147, 152))' },
                { id: 2, title: 'QC Projects', onClick: '/staffjoblist/assign_to_qc', key: '', count: staffDashboardDatas?.inventoriesQC, icon: <FaClipboardList size={40} />, background: 'linear-gradient(to right,rgb(230, 92, 0), rgb(249, 212, 35))' },
            ];
            setCardData(updatedCardData); // Update the local state with new data
        }
    }, [dashboardDatas, staffDashboardDatas, roleId]);

    console.log(dashboardDatas, 'dashboardDatas');


    // useEffect(() => {
    //     dispatch(getDashboardData({}))
    // }, [])

    return (
        // dashboardDatas ? 
        <Card className='mt-2' style={{ height: '85vh' }}>
            <h5><b><center>{`Welcome ${localStorage.getItem('username')} !`}</center></b></h5>
            <div className={roleId === '1' || roleId === '2' && localStorage.getItem('category_type') === '2' || roleId === '2' && localStorage.getItem('category_type') === '1' ? 'dashboard-container four-cards' : 'dashboard-container two-cards'}>
                {cardData.map((card, index) => (
                    <div className="dashboard-card" key={index} style={{ background: card.background, cursor: 'pointer' }} onClick={() => {
                        localStorage.setItem('activeKeyValue', card?.key)
                        navigate(card?.onClick)
                    }}>
                        <div className="card-content">
                            <div className="icon-section">
                                {card.icon}
                            </div>
                            <div className="text-section">
                                <h3><b>{card.title}</b></h3>
                                <p>{card.count}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Card>
        // :
        // <Loader loading={true}/>
    );
};

export default Dashboard;

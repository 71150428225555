import { FullscreenOutlined } from "@ant-design/icons";
import {
   Button,
   Card,
   Checkbox,
   Col,
   DatePicker,
   Flex,
   Image,
   Input,
   InputNumber,
   Modal,
   Row,
   Select,
   Space,
   Typography,
} from "antd";
import { Pannellum } from "pannellum-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaCrop } from "react-icons/fa6";
import { MdOutlineImageNotSupported } from "react-icons/md";
import { RiImageAddFill } from "react-icons/ri";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import imageUrl from "../../assets/images/xs/panorama.jpg";
import ButtonComponent from "../../fields/ButtonComponent";
import { addBatchItems } from "../../store/reducer/roomSlice";
import Loader from "../../fields/loader/Loader";
import PageHeader from "../../view/pageheader";
import { getPanoList } from "../../store/reducer/buildThreeSixty";
import { assestURL } from "../../services/config";
import _ from "lodash";

const TourViewer = () => {
   const { TextArea } = Input;
   const { Title } = Typography;
   const dispatch = useDispatch();
   const [cropConfig, setCropConfig] = useState({ unit: "px", width: 0, height: 0, x: 25, y: 25 });
   const [crop, setCrop] = useState(cropConfig);
   const [completedCrop, setCompletedCrop] = useState(null);
   const [croppedImageUrl, setCroppedImageUrl] = useState(null);
   const imageRef = useRef(null);
   const previewCanvasRef = useRef(null);
   const panoramaRef = useRef(null);
   const containerRef = useRef(null);
   const [imageData, setImageData] = useState(null);
   const [isFullscreen, setIsFullscreen] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [modalImage, setModalImage] = useState("");
   const [lassoImageDetails, setLassoImageDetails] = useState({
      file_name: "lasso_image",
      binary_data: "",
      room_id: 2,
      is_lasso: 1,
   });
   const [selectedPano,setSelectedPano] = useState()
   const [imageUrl,setImageUrl] = useState('')
   const { Option } = Select;

   const roleID = localStorage.getItem('role_id')
   const { addBatchItemSuccess, addBatchItemsError, addBatchItemLoading } = useSelector(
      state => state.rooms
   );
   const { panoListData } = useSelector(state => state.buildThreeSixty)

   console.log(panoListData,selectedPano,'panoListData');
   

   useEffect(() => {
      if (!completedCrop || !previewCanvasRef.current || !imageRef.current) {
         return;
      }

      // const image = imageRef.current;
      // const canvas = previewCanvasRef.current;
      // const crop = completedCrop;

      // const scaleX = image.naturalWidth / image.width;
      // const scaleY = image.naturalHeight / image.height;
      // const pixelRatio = window.devicePixelRatio;

      // // Set canvas dimensions to screen size
      // const screenWidth = window.innerWidth;
      // const screenHeight = window.innerHeight;

      // canvas.width = screenWidth * pixelRatio;
      // canvas.height = screenHeight * pixelRatio;
      // canvas.style.width = `${screenWidth}px`;
      // canvas.style.height = `${screenHeight}px`;

      // const ctx = canvas.getContext("2d");
      // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

      // ctx.imageSmoothingQuality = "high";

      // ctx.drawImage(
      //    image,
      //    crop.x * scaleX,
      //    crop.y * scaleY,
      //    crop.width * scaleX,
      //    crop.height * scaleY,
      //    0,
      //    0,
      //    screenWidth,
      //    screenHeight
      // );

      // // Set the cropped image data URL
      // setCroppedImageUrl(canvas.toDataURL("image/png"));

      const image = imageRef.current;
      const canvas = previewCanvasRef.current;
      const crop = completedCrop;

      if (!image || !canvas || !crop) return;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio;

      // Calculate the aspect ratio of the crop area
      const cropAspectRatio = crop.width / crop.height;

      // Determine the new canvas dimensions based on the screen size and the crop aspect ratio
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Adjust canvas dimensions to maintain aspect ratio
      let newWidth, newHeight;

      if (screenWidth / screenHeight > cropAspectRatio) {
         newHeight = screenHeight * pixelRatio;
         newWidth = newHeight * cropAspectRatio;
      } else {
         newWidth = screenWidth * pixelRatio;
         newHeight = newWidth / cropAspectRatio;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;
      canvas.style.width = `${newWidth / pixelRatio}px`;
      canvas.style.height = `${newHeight / pixelRatio}px`;

      const ctx = canvas.getContext("2d");
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

      ctx.imageSmoothingQuality = "high";

      // Draw the cropped image on the canvas with the new dimensions
      ctx.drawImage(
         image,
         crop.x * scaleX,
         crop.y * scaleY,
         crop.width * scaleX,
         crop.height * scaleY,
         0,
         0,
         newWidth,
         newHeight
      );

      // Set the cropped image data URL
      setCroppedImageUrl(canvas.toDataURL("image/png"));

      console.log("Image rendered on canvas");
   }, [completedCrop]);

   useEffect(() => {
      if (addBatchItemSuccess || addBatchItemsError) {
         setCrop(cropConfig);
         setCompletedCrop(null);
         setCroppedImageUrl(null);
         setImageData(null);
         setIsModalOpen(false);
      }

      if (addBatchItemSuccess) {
         toast.success("Image added to room items.");
      }

      if (addBatchItemsError) {
         toast.error("Image not added to room items. Try Again");
      }
   }, [addBatchItemSuccess, addBatchItemsError]);

   useEffect(() => {
      setImageData("");
      panoramaRef.current.panorama.resize();
   }, [isFullscreen]);

   useEffect(() => {
      document.addEventListener("fullscreenchange", preventEscapeExitFullscreen);
      document.addEventListener("webkitfullscreenchange", preventEscapeExitFullscreen);
      document.addEventListener("mozfullscreenchange", preventEscapeExitFullscreen);
      document.addEventListener("MSFullscreenChange", preventEscapeExitFullscreen);
   }, []);

   function getData(data) {
      const payload = { ...data, id: Number(localStorage.getItem('selectedJobId'))};
      console.log("payload", payload);
      dispatch(getPanoList(payload));
   }

   useEffect(() => {
      getData()
   }, [])
   

   const handleImageLoaded = useCallback(image => {
      imageRef.current = image;
   }, []);

   const handleCompleteCrop = crop => {
      setCompletedCrop(crop);
   };

   const handleSave = () => {
      if (isFullscreen) {
         exitFullscreen();
         setIsFullscreen(false);
         setCrop(cropConfig);
      }
      setIsModalOpen(true);
      // if (previewCanvasRef.current) {
      //    // const link = document.createElement("a");
      //    // link.href = previewCanvasRef.current.toDataURL("image/png");
      //    // link.download = "cropped-image.png";
      //    // link.click();
      // }
   };

   const handleCancel = () => {
      setCrop(cropConfig);
      setCompletedCrop(null);
      setCroppedImageUrl(null);
      setImageData(null);
   };

   const handleFormSubmit = () => {
      if (croppedImageUrl) {
         let postData = [{ ...lassoImageDetails, binary_data: croppedImageUrl }];
         // console.log("postData", postData);
         dispatch(addBatchItems({ postData }));
      }
   };

   const preventEscapeExitFullscreen = event => {
      setImageData("");
      if (!document.fullscreenElement) {
         setIsFullscreen(false);
         // setCroppedImageUrl("");
         setCrop(cropConfig);
         panoramaRef.current.panorama.resize();
      }
   };

   const enableCropMode = () => {
      const img = panoramaRef.current.panorama
         .getRenderer()
         .render(
            (panoramaRef.current.panorama.getPitch() / 180) * Math.PI,
            (panoramaRef.current.panorama.getYaw() / 180) * Math.PI,
            (panoramaRef.current.panorama.getHfov() / 180) * Math.PI,
            { returnImage: true }
         );
      setImageData(img);
   };

   const enterFullscreen = () => {
      if (containerRef.current.requestFullscreen) {
         return containerRef.current.requestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) {
         return containerRef.current.mozRequestFullScreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
         return containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.msRequestFullscreen) {
         return containerRef.current.msRequestFullscreen();
      }
   };

   const exitFullscreen = () => {
      if (document.exitFullscreen) {
         return document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
         return document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
         return document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
         return document.msExitFullscreen();
      }
   };

   const handleFullscreenToggle = () => {
      if (!document.fullscreenElement) {
         // document.addEventListener("keydown", preventEscapeExitFullscreen);
         //  preventEscapeExitFullscreen();
         enterFullscreen().then(() => setIsFullscreen(true));
      } else {
         exitFullscreen().then(() => setIsFullscreen(false));
         // document.removeEventListener("keydown", preventEscapeExitFullscreen);
      }
   };

   useEffect(()=>{
      if(panoListData && panoListData.data && panoListData.data.length > 0){
         setImageUrl(assestURL + panoListData?.data?.[0].pano_image)
         setSelectedPano(`${panoListData?.data?.[0].id}`)
      }
   },[panoListData])

   const onPanoChange = (value) => {
      console.log(value,'value');
      let filteredData = _.find(panoListData && panoListData.data,{id:Number(value)})
      console.log(filteredData,'filteredData');
      setImageUrl(assestURL + filteredData?.pano_image)
      setSelectedPano(value)
   }
   console.log(imageUrl,'imageee');
   

   return (
      roleID === '3' ?
         <>
            <PageHeader
               HeaderText="360 View"
               // Breadcrumb={[{ name: type === 'add' ? 'Projects' : 'Projects' }]}
               backButtonUrl={`/photographer/jobs`}
            />
            <Loader loading={addBatchItemLoading} />
            <div
               ref={containerRef}
               style={{
                  position: "relative",
                  width: "100%",
                  height: isFullscreen ? "100vh" : "75vh",
               }}
            >
               <div
                  className={imageData ? "d-none" : ""}
                  // ref={containerRef}
                  style={{
                     position: "relative",
                     width: "100%",
                     height: "100%",
                     //   height: isFullscreen ? "100vh" : "70vh",
                  }}
               >
                  <Pannellum
                     ref={panoramaRef}
                     width="100%"
                     height="100%"
                     image={imageUrl}
                     // pitch={180}
                     // yaw={180}
                     // hfov={180}
                     // vaov={180}
                     showFullscreenCtrl={false}
                     autoLoad
                     mouseZoom
                     mouseDrag
                  />
                  <Space
                     style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: 1000,
                     }}
                  >
                     <Select
                        style={{ width: 200 }}
                        defaultActiveFirstOption
                        value={selectedPano}
                        onChange={onPanoChange}
                        >
                           {panoListData && panoListData.data && panoListData.data.map((item)=>{
                              return (
                                 <Option key={item.id}>{item?.pano_name}</Option>
                              )
                           })}
                        </Select>
                     
                     <Button
                        type="text"
                        className="bg-white"
                        icon={<FullscreenOutlined className="fs-5" />}
                        onClick={handleFullscreenToggle}
                     />
                  </Space>
               </div>
               <div
                  className={!imageData ? "d-none" : ""}
                  style={{
                     position: "relative",
                     width: "100%",
                     height: "100%",
                     //   height: isFullscreen ? "100vh" : "70vh",
                  }}
               >
                  <ReactCrop
                     crop={crop}
                     onChange={newCrop => setCrop(newCrop)}
                     onImageLoaded={handleImageLoaded}
                     onComplete={handleCompleteCrop}
                     style={{ height: "100%", width: "100%" }}
                  >
                     <img ref={imageRef} src={imageData} alt="Room" height="100%" width="100%" />
                  </ReactCrop>
                  <Space
                     style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: 1000,
                     }}
                  >
                     {isFullscreen && (
                        <>
                           {croppedImageUrl && (
                              <Button
                                 shape="round"
                                 className="bg-white text-success border-0"
                                 icon={<RiImageAddFill size={"1.2em"} />}
                                 onClick={handleSave}
                              >
                                 Save
                              </Button>
                           )}
                           <Button
                              shape="round"
                              className="bg-white text-danger border-0"
                              icon={<MdOutlineImageNotSupported size={"1.2em"} />}
                              onClick={handleCancel}
                           >
                              Cancel
                           </Button>
                        </>
                     )}
                  </Space>
                  <div style={{ display: "none" }}>
                     <canvas ref={previewCanvasRef} />
                  </div>
                  <Modal
                     title={
                        <Title level={4} className="ms-4 ps-1">
                           Item Information
                        </Title>
                     }
                     open={isModalOpen}
                     style={{ top: 10 }}
                     width={800}
                     onCancel={() => setIsModalOpen(false)}
                     footer={null}
                     destroyOnClose
                  >
                     <Card
                        styles={{ body: { paddingTop: 5 } }}
                        className="px-1 pt-0 mt-0"
                        bordered={false}
                     >
                        <Row gutter={16} className="pe-2">
                           <Col span={8}>
                              {/* <img
                              src={croppedImageUrl}
                              alt="Cropped"
                              style={{ maxWidth: "250px", height: "auto" }}
                           /> */}
                              <Image src={croppedImageUrl} width={250} alt="Cropped" />
                           </Col>
                           <Col
                              span={16}
                              className="px-2 overflow-y-scroll"
                              style={{ maxHeight: "80vh", scrollbarWidth: "thin" }}
                           >
                              <Flex gap={10} vertical className="mt-2">
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Room Name :</label>
                                    <Select
                                       className="input-box-size"
                                       // options={roomList}
                                       placeholder="Room Name"
                                       name="room_name"
                                    />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Item Name :</label>
                                    <Input
                                       className="input-box-size"
                                       placeholder="Item Name"
                                       name="item_name"
                                    // onChange={e => {
                                    //    onItemValueChange(e);
                                    // }}
                                    />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Client's Estimate($) :</label>
                                    <Input
                                       className="input-box-size"
                                       placeholder="Client Estimate"
                                       name="client_value_range"
                                    // onChange={e => {
                                    //    onItemValueChange(e);
                                    // }}
                                    />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>AI Estimate($) :</label>
                                    <Input
                                       className="input-box-size"
                                       placeholder="AI Estimate"
                                       name="google_value_range"
                                    // onChange={e => {
                                    //    onItemValueChange(e);
                                    // }}
                                    />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Quantity :</label>
                                    <div className="input-box-size">
                                       <InputNumber className="w-100" placeholder="Qty" />
                                    </div>
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Category :</label>
                                    <Select
                                       className="input-box-size"
                                       // disabled={true}
                                       // options={selectDropDown}
                                       placeholder="Category"
                                    />
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Condition :</label>
                                    <Select
                                       className="input-box-size"
                                       // disabled={true}
                                       // options={itemCondition}
                                       placeholder="Condition"
                                    />
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Date of Purchase :</label>
                                    <DatePicker
                                       className="input-box-size"
                                       format={'MM-DD-YYYY'}
                                    // disabled={true}
                                    // disabledDate={disableFutureDates}
                                    />
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Model :</label>
                                    <Input className="input-box-size" placeholder="Model" />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Serial Number :</label>
                                    <Input
                                       className="input-box-size"
                                       // disabled={true}
                                       placeholder="Serial Number"
                                    />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Assign to :</label>
                                    <div className="input-box-size">
                                       <Select
                                          className="input-box-size"
                                          // disabled={true}
                                          // options={photographerList}
                                          placeholder="Create/Assign Heir"
                                          mode="multiple"
                                          style={{ width: "100%" }}
                                       />
                                    </div>
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>General Notes :</label>
                                    <TextArea
                                       className="input-box-size"
                                       // disabled={true}
                                       style={{ resize: "none" }}
                                       rows={6}
                                    />
                                 </Flex>
                                 <Flex justify="right">
                                    <Checkbox>Appraise</Checkbox>
                                    <Checkbox>Donate</Checkbox>
                                    <Checkbox>Sell</Checkbox>
                                 </Flex>
                                 <Flex className="my-1" gap={10} justify="flex-end">
                                    <ButtonComponent
                                       style={{ backgroundColor: "#f7931e", color: "white" }}
                                       buttonName="View Documents"
                                       icon={<i className="fa fa-file-text" aria-hidden="true"></i>}
                                    />
                                    <ButtonComponent
                                       style={{ backgroundColor: "#f7931e", color: "white" }}
                                       buttonName="Save"
                                       icon={<i className="fa fa-hdd-o" aria-hidden="true"></i>}
                                       onClick={handleFormSubmit}
                                    />
                                    <ButtonComponent
                                       style={{ backgroundColor: "#6E6E6E", color: "white" }}
                                       buttonName="Clear"
                                       icon={<i className="fa fa-refresh" aria-hidden="true"></i>}
                                    />
                                 </Flex>
                              </Flex>
                           </Col>
                        </Row>
                     </Card>
                  </Modal>
               </div>
            </div>
         </>
         :
         <Card
            extra={
               <Space>
                  {!imageData ? (
                     <Button
                        shape="round"
                        style={{ backgroundColor: "#f7931e", color: "white" }}
                        icon={<FaCrop size={"1.2em"} />}
                        onClick={enableCropMode}
                     >
                        Lasso
                     </Button>
                  ) : (
                     <>
                        {croppedImageUrl && (
                           <Button
                              shape="round"
                              className="bg-success text-white"
                              icon={<RiImageAddFill size={"1.2em"} />}
                              onClick={handleSave}
                           >
                              Save
                           </Button>
                        )}
                        <Button
                           shape="round"
                           className="bg-danger text-white"
                           icon={<MdOutlineImageNotSupported size={"1.2em"} />}
                           onClick={handleCancel}
                        >
                           Cancel
                        </Button>
                     </>
                  )}
               </Space>
            }
         >
            <Loader loading={addBatchItemLoading} />
            <div
               ref={containerRef}
               style={{
                  position: "relative",
                  width: "100%",
                  height: isFullscreen ? "100vh" : "70vh",
               }}
            >
               <div
                  className={imageData ? "d-none" : ""}
                  // ref={containerRef}
                  style={{
                     position: "relative",
                     width: "100%",
                     height: "100%",
                     //   height: isFullscreen ? "100vh" : "70vh",
                  }}
               >
                  <Pannellum
                     ref={panoramaRef}
                     width="100%"
                     height="100%"
                     image={imageUrl}
                     // pitch={180}
                     // yaw={180}
                     // hfov={180}
                     // vaov={180}
                     showFullscreenCtrl={false}
                     autoLoad
                     mouseZoom
                     mouseDrag
                  />
                  <Space
                     style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: 1000,
                     }}
                  >
                     {isFullscreen && (
                        <Button
                           className="text-bg-primary border-0"
                           shape="round"
                           // style={{ backgroundColor: "#f7931e", color: "white" }}
                           icon={<FaCrop size={"1.2em"} />}
                           onClick={enableCropMode}
                        >
                           Lasso
                        </Button>
                     )}
                     <Select
                        style={{ width: 200 }}
                        defaultActiveFirstOption
                        value={"Dining"}
                        options={[
                           { value: "Kitchen", label: "Kitchen" },
                           { value: "Bedroom", label: "Bedroom" },
                           { value: "Dining", label: "Dining" },
                        ]}
                     />
                     <Button
                        type="text"
                        className="bg-white"
                        icon={<FullscreenOutlined className="fs-5" />}
                        onClick={handleFullscreenToggle}
                     />
                  </Space>
               </div>
               <div
                  className={!imageData ? "d-none" : ""}
                  style={{
                     position: "relative",
                     width: "100%",
                     height: "100%",
                     //   height: isFullscreen ? "100vh" : "70vh",
                  }}
               >
                  <ReactCrop
                     crop={crop}
                     onChange={newCrop => setCrop(newCrop)}
                     onImageLoaded={handleImageLoaded}
                     onComplete={handleCompleteCrop}
                     style={{ height: "100%", width: "100%" }}
                  >
                     <img ref={imageRef} src={imageData} alt="Room" height="100%" width="100%" />
                  </ReactCrop>
                  <Space
                     style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: 1000,
                     }}
                  >
                     {isFullscreen && (
                        <>
                           {croppedImageUrl && (
                              <Button
                                 shape="round"
                                 className="bg-white text-success border-0"
                                 icon={<RiImageAddFill size={"1.2em"} />}
                                 onClick={handleSave}
                              >
                                 Save
                              </Button>
                           )}
                           <Button
                              shape="round"
                              className="bg-white text-danger border-0"
                              icon={<MdOutlineImageNotSupported size={"1.2em"} />}
                              onClick={handleCancel}
                           >
                              Cancel
                           </Button>
                        </>
                     )}
                  </Space>
                  <div style={{ display: "none" }}>
                     <canvas ref={previewCanvasRef} />
                  </div>
                  <Modal
                     title={
                        <Title level={4} className="ms-4 ps-1">
                           Item Information
                        </Title>
                     }
                     open={isModalOpen}
                     style={{ top: 10 }}
                     width={800}
                     onCancel={() => setIsModalOpen(false)}
                     footer={null}
                     destroyOnClose
                  >
                     <Card
                        styles={{ body: { paddingTop: 5 } }}
                        className="px-1 pt-0 mt-0"
                        bordered={false}
                     >
                        <Row gutter={16} className="pe-2">
                           <Col span={8}>
                              {/* <img
                              src={croppedImageUrl}
                              alt="Cropped"
                              style={{ maxWidth: "250px", height: "auto" }}
                           /> */}
                              <Image src={croppedImageUrl} width={250} alt="Cropped" />
                           </Col>
                           <Col
                              span={16}
                              className="px-2 overflow-y-scroll"
                              style={{ maxHeight: "80vh", scrollbarWidth: "thin" }}
                           >
                              <Flex gap={10} vertical className="mt-2">
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Room Name :</label>
                                    <Select
                                       className="input-box-size"
                                       // options={roomList}
                                       placeholder="Room Name"
                                       name="room_name"
                                    />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Item Name :</label>
                                    <Input
                                       className="input-box-size"
                                       placeholder="Item Name"
                                       name="item_name"
                                    // onChange={e => {
                                    //    onItemValueChange(e);
                                    // }}
                                    />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Client's Estimate($) :</label>
                                    <Input
                                       className="input-box-size"
                                       placeholder="Client Estimate"
                                       name="client_value_range"
                                    // onChange={e => {
                                    //    onItemValueChange(e);
                                    // }}
                                    />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>AI Estimate($) :</label>
                                    <Input
                                       className="input-box-size"
                                       placeholder="AI Estimate"
                                       name="google_value_range"
                                    // onChange={e => {
                                    //    onItemValueChange(e);
                                    // }}
                                    />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Quantity :</label>
                                    <div className="input-box-size">
                                       <InputNumber className="w-100" placeholder="Qty" min={1}/>
                                    </div>
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Category :</label>
                                    <Select
                                       className="input-box-size"
                                       // disabled={true}
                                       // options={selectDropDown}
                                       placeholder="Category"
                                    />
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Condition :</label>
                                    <Select
                                       className="input-box-size"
                                       // disabled={true}
                                       // options={itemCondition}
                                       placeholder="Condition"
                                    />
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Date of Purchase :</label>
                                    <DatePicker
                                       className="input-box-size"
                                    // disabled={true}
                                    // disabledDate={disableFutureDates}
                                    />
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Model :</label>
                                    <Input className="input-box-size" placeholder="Model" />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Serial Number :</label>
                                    <Input
                                       className="input-box-size"
                                       // disabled={true}
                                       placeholder="Serial Number"
                                    />
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Assign to :</label>
                                    <div className="input-box-size">
                                       <Select
                                          className="input-box-size"
                                          // disabled={true}
                                          // options={photographerList}
                                          placeholder="Create/Assign Heir"
                                          mode="multiple"
                                          style={{ width: "100%" }}
                                       />
                                    </div>
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>General Notes :</label>
                                    <TextArea
                                       className="input-box-size"
                                       // disabled={true}
                                       style={{ resize: "none" }}
                                       rows={6}
                                    />
                                 </Flex>
                                 <Flex justify="right">
                                    <Checkbox>Appraise</Checkbox>
                                    <Checkbox>Donate</Checkbox>
                                    <Checkbox>Sell</Checkbox>
                                 </Flex>
                                 <Flex className="my-1" gap={10} justify="flex-end">
                                    <ButtonComponent
                                       style={{ backgroundColor: "#f7931e", color: "white" }}
                                       buttonName="View Documents"
                                       icon={<i className="fa fa-file-text" aria-hidden="true"></i>}
                                    />
                                    <ButtonComponent
                                       style={{ backgroundColor: "#f7931e", color: "white" }}
                                       buttonName="Save"
                                       icon={<i className="fa fa-hdd-o" aria-hidden="true"></i>}
                                       onClick={handleFormSubmit}
                                    />
                                    <ButtonComponent
                                       style={{ backgroundColor: "#6E6E6E", color: "white" }}
                                       buttonName="Clear"
                                       icon={<i className="fa fa-refresh" aria-hidden="true"></i>}
                                    />
                                 </Flex>
                              </Flex>
                           </Col>
                        </Row>
                     </Card>
                  </Modal>
               </div>
            </div>
         </Card>
   );
};

export default TourViewer;

import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import CardComponent from '../../fields/CardComponent';
import { useLocation, useParams } from 'react-router-dom';
import { getClientUserProjectType } from '../../store/reducer/portalUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getClientDashboardJobsData, getClientDashboardReviewData, loginSelector } from '../../store/reducer/login';
import PageHeader from '../../view/pageheader';

am4core.useTheme(am4themes_animated);

const PieCharts = () => {
    const chart1Ref = useRef(null);
    const chart2Ref = useRef(null);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const client_id = params.get('client_id');
    const project_type = params.get('project_type');
    const projectID = localStorage.getItem('pu_project_id')
    const { portalUserProjectType } = useSelector(state => state.portalUser)
    const clientId = localStorage.getItem('client_id')
    const dispatch = useDispatch();
    const { clientDashboardJobsDatas, clientDashboardReviewDatas } = useSelector(loginSelector);
    const [valuationArray, setValuationArray] = useState([])
    const [reviewComplete, setReviewComplete] = useState([])
    const [reviewCompletePercentage, setReviewCompletePercentage] = useState(0)
    const [valuationCompletePercentage, setValuationCompletePercentage] = useState(0)
    const [valuation, setValuation] = useState(localStorage.getItem('valuation'))
    const { id } = useParams()

    console.log(clientDashboardJobsDatas, clientDashboardReviewDatas, 'clientDashboardJobsDatas');
    console.log(valuation,'valuation');
    

    React.useEffect(() => {
        if (client_id && project_type) {
            // Store in localStorage or perform any action with the params
            localStorage.setItem('client_id', client_id);
            localStorage.setItem('project_type', project_type);
        }
    }, [client_id, project_type]);

    useEffect(() => {
        if (clientDashboardJobsDatas && clientDashboardJobsDatas.roomAverages && clientDashboardJobsDatas.roomAverages.length > 0) {
            const totalPercentage = clientDashboardJobsDatas.roomAverages.reduce((sum, room) => sum + room.percentage, 0);
            const overallPercentage = totalPercentage / clientDashboardJobsDatas.roomAverages.length;
            setValuationArray(clientDashboardJobsDatas.roomAverages)
            setValuationCompletePercentage(overallPercentage)  
        }
        else {
            setValuationArray([])
        }
    }, [clientDashboardJobsDatas])

    console.log(clientDashboardJobsDatas,'clientDashboardJobsDatas');
    

    useEffect(() => {
        if (clientDashboardReviewDatas && clientDashboardReviewDatas.roomPercentages && clientDashboardReviewDatas.roomPercentages.length > 0) {
            const totalPercentage = clientDashboardReviewDatas.roomPercentages.reduce((sum, room) => sum + room.percentage, 0);
            const overallPercentage = totalPercentage / clientDashboardReviewDatas.roomPercentages.length;
            setReviewCompletePercentage(overallPercentage)
            setReviewComplete(clientDashboardReviewDatas.roomPercentages)
        }
        else {
            setReviewComplete([])
        }
    }, [clientDashboardReviewDatas])
    useLayoutEffect(() => {
        let chart1 = am4core.create('chartdiv1', am4charts.PieChart3D);
        let chart2 = am4core.create('chartdiv2', am4charts.PieChart3D);

        chart1.hiddenState.properties.opacity = 0;
        chart2.hiddenState.properties.opacity = 0;

        // Assign data
        chart1.data = reviewComplete;
        chart2.data = valuationArray;

        // Function to generate random shades of a color
        const getRandomShade = (baseColor) => {
            // Modify lightness of the color randomly
            const shade = am4core.color(baseColor).brighten(Math.random() * 0.5 - 0.25); // ±25% lightness
            return shade;
        };

        // Define base colors for orange, grey, and blue
        const baseColors = [
            "#FFA500", // orange
            "#808080", // grey
            "#0000FF", // blue
        ];

        // Generate random shades for both charts based on dynamic data length
        const colorPalette1 = Array(chart1.data.length)
            .fill()
            .map(() => getRandomShade(baseColors[Math.floor(Math.random() * baseColors.length)]));
        const colorPalette2 = Array(chart2.data.length)
            .fill()
            .map(() => getRandomShade(baseColors[Math.floor(Math.random() * baseColors.length)]));

        // Apply dynamic colors to charts
        chart1.colors.list = colorPalette1;
        chart2.colors.list = colorPalette2;

        // Configure chart1 series
        let pieSeries1 = chart1.series.push(new am4charts.PieSeries3D());
        pieSeries1.dataFields.value = 'percentage';
        pieSeries1.dataFields.category = 'room_name';
        pieSeries1.labels.template.text = "{category}: {value.percent.formatNumber('#.#')}%";
        pieSeries1.labels.template.radius = am4core.percent(-40);
        pieSeries1.labels.template.fill = am4core.color("white");
        pieSeries1.labels.template.disabled = true;
        pieSeries1.slices.template.tooltipText = "{category} : {percentage} % ";

        // Configure chart2 series
        let pieSeries2 = chart2.series.push(new am4charts.PieSeries3D());
        pieSeries2.dataFields.value = 'percentage';
        pieSeries2.dataFields.category = 'room_name';
        pieSeries2.labels.template.text = "{category}: {value.percent.formatNumber('#.#')}%";
        pieSeries2.labels.template.radius = am4core.percent(-40);
        pieSeries2.labels.template.fill = am4core.color("white");
        pieSeries2.labels.template.disabled = true;
        pieSeries2.slices.template.tooltipText = "{category} : {percentage} % ";

        // Add legends
        chart1.legend = new am4charts.Legend();
        chart2.legend = new am4charts.Legend();

        chart1.logo.disabled = true;
        chart2.logo.disabled = true;

        chart1Ref.current = chart1;
        chart2Ref.current = chart2;

        return () => {
            chart1.dispose();
            chart2.dispose();
        };
    }, [reviewComplete, valuationArray]);


    useEffect(() => {
        dispatch(getClientUserProjectType({ project_id: Number(projectID) }));
    }, [projectID])

    useEffect(() => {
        if (id) {
            const decodedId = atob(id)
            dispatch(getClientDashboardJobsData({ id: Number(clientId), job_id: decodedId }));
            dispatch(getClientDashboardReviewData({ id: Number(clientId), job_id: decodedId }));
        }
    }, [clientId, id])

    console.log(valuationArray?.length === 0 && reviewComplete?.length === 0, 'valuationArray?.length === 0 && reviewComplete?.length === 0');


    return (
        <div className="container mt-2">
            <PageHeader
                HeaderText="Dashboard"
                backButtonUrl={`/clientportal/home`}
            />
            <CardComponent>
                <center><h6><b>Value of Selected Items</b></h6></center>

                {(valuationArray?.length === 0 && reviewComplete?.length === 0) ||
                    (Number(reviewCompletePercentage) === 0 && Number(clientDashboardJobsDatas?.overallAverage) === 0) ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '350px',
                        textAlign: 'center'
                    }}>
                        <h5><strong>No Data Found</strong></h5>
                    </div>
                    :
                    <div className="row">
                        {valuation === 'true' && valuationArray?.length > 0 ? (
                            <>
                                <div className="col-md-6">
                                    <center>
                                        <h6>Valuation Complete</h6>
                                        <h4><b>{Number(valuationCompletePercentage).toFixed(1)} %</b></h4>
                                        <div id="chartdiv2" style={{ width: "100%", height: "350px" }}></div>
                                    </center>
                                </div>
                                <div className="col-md-6">
                                    {reviewCompletePercentage > 0 ? (
                                        <>
                                            <center>
                                                <h6>Inventory Review Complete</h6>
                                                <h4><b>{Number(reviewCompletePercentage).toFixed(1)} %</b></h4>
                                            </center>
                                            <div id="chartdiv1" style={{ width: "100%", height: "350px" }}></div>
                                        </>
                                    ) : (
                                        <h6 style={{ marginTop: '130px', textAlign: 'center' }}>No Data Found</h6>
                                    )}
                                </div>
                            </>
                        ) : valuation === 'true' && valuationArray?.length === 0 ? (
                            <>
                                <div className="col-md-6">
                                    <center><h6><b>No Data Found</b></h6></center>
                                </div>
                                <div className="col-md-6">
                                    {reviewCompletePercentage > 0 ? (
                                        <>
                                            <center>
                                                <h6>Inventory Review Complete</h6>
                                                <h4><b>{Number(reviewCompletePercentage).toFixed(1)} %</b></h4>
                                            </center>
                                            <div id="chartdiv1" style={{ width: "100%", height: "350px" }}></div>
                                        </>
                                    ) : (
                                        <h6 style={{ marginTop: '130px', textAlign: 'center' }}>No Data Found</h6>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="col-md-12">
                                {reviewCompletePercentage > 0 ? (
                                    <>
                                        <center>
                                            <h6>Inventory Review Complete</h6>
                                            <h4><b>{Number(reviewCompletePercentage).toFixed(1)} %</b></h4>
                                        </center>
                                        <div id="chartdiv1" style={{ width: "100%", height: "350px" }}></div>
                                    </>
                                ) : (
                                    <h6 style={{ marginTop: '130px', textAlign: 'center' }}>No Data Found</h6>
                                )}
                            </div>
                        )}
                    </div>
                }
            </CardComponent>
        </div>
    );
};

export default PieCharts;

import React from 'react'
import { Checkbox } from 'antd';


const CheckboxComponent = ({ name, options, value, onChange, validator, touchedFields, validationRules }) => {

    return (
        <>
            <Checkbox.Group name={name} options={options} value={value}
                onChange={onChange}
            />
            {(validationRules[name] || touchedFields[name]) && (
                <div style={{ color: 'red' }}>
                    {validator.current.message(name, value, validationRules[name])}
                </div>
            )}
        </>
    )
}

export default CheckboxComponent
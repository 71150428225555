import {
   DeleteOutlined, FullscreenOutlined, CloseCircleFilled, SearchOutlined, EditOutlined, DoubleRightOutlined, DoubleLeftOutlined, HomeOutlined, PlusOutlined, UploadOutlined, CheckOutlined, PlusCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, FileTextOutlined
} from "@ant-design/icons";
import {
   Button, Card, Checkbox, Col, DatePicker, Divider, Flex, Image, Input, InputNumber, Pagination, Row, Select, Space, Tabs, Typography, message, Upload, Collapse,
   Modal, Empty
} from "antd";
import { useEffect, useRef, useState } from "react";
import googleIcon from "../../assets/images/googlelens.png";
import ImageGallery from "react-image-gallery";
import ButtonComponent from "../../fields/ButtonComponent";
import PageHeader from "../../view/pageheader";
import "./Rooms.css";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useDropzone } from 'react-dropzone';
import { addBatchItems, addSequenceItems, getRoomData, addAdditionalSequenceItems, editItemImage, deleteItemImage, updateSequenceImage, updateRoom, resetSuccessFlag, deleteRoomItem } from "../../store/reducer/roomSlice";
import { fetchAndSetPhotographerPermissionIds, getPhotographerList } from "../../store/reducer/photographerSlice";
import { useDispatch, useSelector } from "react-redux";
import { searchProduct } from "../../store/reducer/googleSearch";
import { useParams, useNavigate, Link } from "react-router-dom";
import { assestURL } from "../../services/config";
import { toast } from "react-toastify";
import ModalComponent from "../../fields/ModalComponent";
import _ from "lodash";
import moment from "moment";
import { getJobData } from "../../store/reducer/jobSlice";
import Loader from "../../fields/loader/Loader";
import Swal from 'sweetalert2';
import { Breadcrumb } from 'antd';
import { getProjectData } from "../../store/reducer/projectSlice";
import Compressor from "compressorjs";
import { addAssignedTo, clientClaimDetails, getAssignTo, getClientClaimDetails, resetSuccessFlag as portalUserResetSuccessFlag, getDocuments, addDocuments, deleteDocuments } from "../../store/reducer/portalUserSlice";
import dayjs from "dayjs";
import { IoReturnUpForwardOutline } from "react-icons/io5";
import { getItemCategoriesList } from "../../store/reducer/itemCategoriesSlice";
import PDFViewer from "../ClientPortal/PDFViewer";

const Rooms = () => {
   const { TextArea } = Input;
   const { RangePicker } = DatePicker;
   const { Title } = Typography;
   const { TabPane } = Tabs;
   const { Dragger } = Upload;
   const { Panel } = Collapse;
   const { Option } = Select;

   const dispatch = useDispatch();
   const { project_id, job_id, roomid } = useParams();
   const navigate = useNavigate();
   const clientUserId = localStorage.getItem('client_id')
   //Selectors
   const { getAddedJobData } = useSelector((state) => state.job)
   const { photographerListData, photographerPermissionIds } = useSelector(state => state.photographer);
   const { clientUserRooms, getClaimDetails, clientClaimDetailsSuccess, addAssignedToSuccess, getAssignedToData, getDocumentsData, addDocumentSuccess, addDocumentError, addDocumentErrorMessage, deleteDocumentSuccess, deleteDocumentError, deleteDocumentErrorMessage } = useSelector(state => state.portalUser)
   const { getAddedProjectData } = useSelector((state) => state.project)
   const { getItemDatas, addSequenceItemSuccess, addBatchItemSuccess, addBatchItemLoading, addSequenceItemLoading, getRoomLoading, addAdditionalSequenceItemsuccess, editItemImageSuccess, deleteItemImageSuccess, editItemImageLoading, updateItemImageSuccess, updateRoomSuccess, deleteRoomItemSuccess } = useSelector(state => state.rooms)
   const { googleSearchResult, googleSearchLoading } = useSelector(state => state.googleSearch)
   const [activeTab, setActiveTab] = useState(1);
   const [imagesData, setImagesData] = useState([]);
   const [imageData, setImageData] = useState([]);
   const [isAddItem, setIsAddItem] = useState(false);
   const [currentImageIndex, setCurrentImageIndex] = useState(0);
   const galleryRef = useRef(null);
   const [currentPage, setCurrentPage] = useState(1);
   const [fileList, setFileList] = useState([]);
   const [sequenceItemsFileList, setSequenceItemsList] = useState([]);
   const [selectDropDown, setSelectDropDown] = useState([
      {
         value: 1,
         label: "Option 1",
      },
      {
         value: 2,
         label: "Option 2",
      },
      {
         value: 3,
         label: "Option 3",
      },
   ]);
   const [itemCondition, setItemCondition] = useState([
      {
         value: 1,
         label: "Excellent",
      },
      {
         value: 2,
         label: "Good",
      },
      {
         value: 3,
         label: "Fair",
      },
      {
         value: 4,
         label: "Poor",
      },
      {
         value: 5,
         label: "Refurbished",
      },
      {
         value: 6,
         label: "Repair",
      }
   ])
   const [itemClaimReasons, setItemClaimReasons] = useState([
      {
         value: 1,
         label: "Pre-Marriage",
      },
      {
         value: 2,
         label: "Gift",
      },
      {
         value: 3,
         label: "Inherited",
      },
      {
         value: 4,
         label: "Bought During Marriage",
      },
      {
         value: 5,
         label: "Other - See Notes",
      },
   ])
   const [itemDocuments, setItemDocuments] = useState([]);
   const [isAddDocument, setIsAddDocument] = useState(false);
   const [selectedDocument, setSelectedDocument] = useState({ id: "", path: "" });
   console.log(googleSearchResult, 'googleSearchResult');
   const [batchFileList, setBatchFileList] = useState([]);
   const [sequenceFileList, setSequenceFileList] = useState([]);
   const [additionalSequenceFileList, setAdditionalSequenceFileList] = useState([]);
   const [batchItemsColumnOrder, setBatchItemsColumnOrder] = useState([]);
   const [sequenceItemsColumnOrder, setSequenceItemsColumnOrder] = useState([]);
   const [additionalSequenceItemsColumnOrder, setAdditionalSequenceItemsColumnOrder] = useState([]);
   const [photographerList, setPhotographerList] = useState([])
   const [disableBatchItems, setDisableBatchItems] = useState(false)
   const [disableSequenceItems, setDisableSequenceItems] = useState(false)
   const [preview, setPreview] = useState([])
   const [imagesBinaryData, setImagesBinaryData] = useState(null);
   const [roomList, setRoomList] = useState([])
   const BATCH_MAX_FILES = 30;
   const SEQUENCE_MAX_FILES = 7;
   const [imageBinaryData, setImageBinaryData] = useState(null);
   const [isFullscreen, setIsFullscreen] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isPreviewModal, setIsPreviewModal] = useState(false)
   const [isGoogleModal, setIsGoogleModal] = useState(false)
   const [itemDetails, setItemDetails] = useState({
      room_id: '',
      name: '',
      client_value_range: '',
      google_value_range: '',
      item_id: '',
      portal_user_id: '',
      item_condition: '',
      category: '',
      purchase_date: '',
      model: '',
      serial_number: '',
      manufacturer: '',
      quantity: 1,
      distribute: 0,
      claimed_as: '',
      distribute_to: '',
      dispose_by: 0,
      comment: '',
      is_admin: 1
   })
   console.log(itemDetails, 'itemDetails');

   const [selectedRoom, setSelectedRoom] = useState([]);
   const fileInputRef = useRef(null);
   const [sequenceId, setSequenceId] = useState({
      sequence_id: '',
      serial_no: '',
      item_id: '',
      main_image: false
   })
   const [filteredPrices, setFilteredPrices] = useState([]);
   const [mean, setMean] = useState(null);
   const [googleItemsWithPrice, setGoogleItemsWithPrice] = useState([]);
   const [selectedGoogleItemsPrice, setSelectedGoogleItemsPrice] = useState([]);
   const [selectedGoogleItemsIds, setSelectedGoogleItemsIds] = useState([]);
   const [selectAllGoogleItems, setSelectAllGoogleItems] = useState([]);
   const roleID = localStorage.getItem('role_id')
   const { itemCategoriesListData } = useSelector(state => state.itemCategories);
   const [assignedTo, setAssignedTo] = useState([])
   const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);
   const [isSelectAllRoomsModal, setIsSelectAllRoomsModal] = useState(false);
   const [isSearchModal, setIsSearchModal] = useState(false);
   const [checkedValues, setCheckedValues] = useState([]);
   const [documentFileList, setDocumentFileList] = useState([]);
   const [currentIndex, setCurrentIndex] = useState(0);


   const [updateSequenceArray, setUpdateSequenceArray] = useState([])
   const [assignToTextBox, setAssignToTextBox] = useState({
      assignTextInput: false,
      assignTextInputValue: ''
   })
   console.log(googleSearchResult, 'meanmean');
   console.log(roomList, 'roomList');
   console.log(selectedRoom, 'selectedRoom');
   console.log(getClaimDetails, 'getClaimDetails');
   console.log(getItemDatas, 'getItemDatas');
   console.log(currentPage, 'currentPage');
   console.log(photographerPermissionIds, isAddItem, 'photographerPermissionIds,isAddItem');
   console.log(getItemDatas?.data, 'getItemDatas?.data');



   // Function to read file as binary data
   const readFileAsBinaryString = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.onload = () => {
            resolve(reader.result);
         };
         reader.onerror = (error) => {
            reject(error);
         };
         reader.readAsBinaryString(file);
      });
   };
   console.log(batchFileList, 'batchFileList');
   console.log(imageData, 'imageData');
   console.log(deleteItemImageSuccess, 'deleteItemImageSuccess');

   useEffect(() => {
      if (googleSearchResult && googleSearchResult.length && googleSearchResult[0].records?.length) {
         const itemsWithPrice = googleSearchResult[0].records.filter(item => item.price?.extracted_value ? true : false);
         const selectAll = itemsWithPrice.map(item => item.position);

         setGoogleItemsWithPrice([...itemsWithPrice]);
         setSelectAllGoogleItems([...selectAll]);
      }
   }, [googleSearchResult])

   const calculateGoogleAIEstimate = () => {

      console.log(selectedGoogleItemsPrice, 'selectedGoogleItemsPrice===');
      if (selectedGoogleItemsPrice.length) {
         // Step 1: Sort prices
         const sortedPrices = [...selectedGoogleItemsPrice].sort((a, b) => a - b);

         // Step 2: Calculate Q1 and Q3
         const Q1 = calculatePercentile(sortedPrices, 0.25);
         const Q3 = calculatePercentile(sortedPrices, 0.75);
         const IQR = Q3 - Q1;

         // Step 3: Define bounds
         const lowerBound = Q1 - 1.5 * IQR;
         const upperBound = Q3 + 1.5 * IQR;

         // Step 4: Filter values within the bounds
         const filtered = sortedPrices.filter(price => price >= lowerBound && price <= upperBound);

         // Step 5: Calculate mean of filtered values
         const meanValue = filtered.reduce((acc, curr) => acc + curr, 0) / filtered.length;

         console.log(meanValue, 'meanValue==');

         setFilteredPrices(filtered);
         // setMean(meanValue.toFixed(2));
         setItemDetails({
            ...itemDetails,
            google_value_range: meanValue.toFixed(2)
         })
         setSelectedGoogleItemsIds([]);
         setSelectedGoogleItemsPrice([]);
         setGoogleItemsWithPrice([]);
         setIsGoogleModal(false)
      } else {
         setIsGoogleModal(false)
      }
   };

   // Function to calculate percentile
   const calculatePercentile = (arr, percentile) => {
      const index = (arr.length - 1) * percentile;
      const lower = Math.floor(index);
      const upper = Math.ceil(index);
      const weight = index - lower;
      return arr[lower] * (1 - weight) + arr[upper] * weight;
   };

   const OnselectAllimages = () => {
      console.log('working');
      setIsSelectAllRoomsModal(true);

      if (getItemDatas) {
         const datas = getItemDatas && getItemDatas?.data || []
         let filteredgoogleSearchItem = datas && datas.map(filter => {
            return ({ item_id: filter.id, image_url: assestURL + filter.item_image, allItems: 1 })
         })
         console.log(filteredgoogleSearchItem, 'filteredgoogleSearchItem==');

         const postData = {
            filteredgoogleSearchItem
         };

         console.log(postData, 'postData'); // Log the postData to ensure it is correct

         // Dispatch the action to search for the product
         dispatch(searchProduct({ postData }));

      }
   }


   const googleOnchange = (item) => {
      // Prepare data to send in the request
      const postData = {
         filteredgoogleSearchItem: [{
            item_id: item.item_id,
            image_url: item.original,
            allItems: 0
         }]
      };

      // Dispatch the action to search for the product
      dispatch(searchProduct({ postData }));

      setGoogleItemsWithPrice([]);
      setSelectAllGoogleItems([]);

      // Open the Google modal
      setIsGoogleModal(true);
   };

   const handleCardClick = (encodedUrl) => {
      console.log(encodedUrl, 'url-workinf=');
      window.open(encodedUrl, '_blank');

      // navigate(`${encodedUrl}`)
   };

   useEffect(() => {
      if (addSequenceItemSuccess === true || addBatchItemSuccess === true || addAdditionalSequenceItemsuccess === true || editItemImageSuccess === true) {
         dispatch(getRoomData({ room_id: atob(roomid) }));
         toast.success('Items Added Successfully !')
         toast.dismiss()
         setDisableBatchItems(false)
         setDisableSequenceItems(false)
         setBatchItemsColumnOrder([])
         setBatchFileList([])
         setSequenceFileList([])
         setSequenceItemsColumnOrder([])
         setImageBinaryData([])
      }
      if (addAdditionalSequenceItemsuccess) {
         setIsPreviewModal(false)
         setAdditionalSequenceFileList([])
         setAdditionalSequenceItemsColumnOrder([])
      }

   }, [addSequenceItemSuccess, addBatchItemSuccess, addAdditionalSequenceItemsuccess, editItemImageSuccess, roomid])

   useEffect(() => {
      if (deleteItemImageSuccess) {
         toast.success('Item Deleted Successfully !')
         toast.dismiss()
         dispatch(getRoomData({ room_id: atob(roomid) }));
      }
      if (updateItemImageSuccess === true) {
         toast.success('Sequence Changes Updated Successfully !')
         toast.dismiss()
         dispatch(getRoomData({ room_id: atob(roomid) }));
         setIsModalOpen(false)
      }
      if (updateRoomSuccess) {
         toast.success('Room Updated Successfully !')
         dispatch(resetSuccessFlag({ flag: updateRoomSuccess }));
      }
      if (deleteRoomItemSuccess) {
         toast.success('Item Deleted Successfully !')
         toast.dismiss()
         dispatch(resetSuccessFlag({ flag: 'deleteRoomItemSuccess' }));
         dispatch(getRoomData({ room_id: atob(roomid) }));
      }
   }, [deleteItemImageSuccess, updateItemImageSuccess, updateRoomSuccess, deleteRoomItemSuccess, roomid])


   useEffect(() => {
      if (getItemDatas && getItemDatas?.data && getItemDatas?.data.length > 0) {
         setImageData(getItemDatas?.data || [])
      }
   }, [getItemDatas])

   useEffect(() => {
      // Dispatch the thunk to fetch and set photographerPermissionIds
      dispatch(fetchAndSetPhotographerPermissionIds());
   }, [dispatch]);

   useEffect(() => {
      if (project_id) {
         const decodedId = atob(project_id); // Use a different variable name
         dispatch(getProjectData({ id: decodedId }));
         dispatch(getItemCategoriesList({ id: decodedId }));
      }
   }, [project_id, dispatch]);

   useEffect(() => {
      if (imageData && imageData.length > 0) {
         console.log(imageData[currentImageIndex], 'imageData[currentImageIndex]');
         const getSequenceItems = imageData && imageData[currentImageIndex] && imageData[currentImageIndex]?.sequenceImages && imageData[currentImageIndex]?.sequenceImages.length > 0
            ? _.orderBy(imageData[currentImageIndex]?.sequenceImages, ['serial_no'], ['asc']).map((item, index) => ({
               ...item,
               slot_number: index + 1
            }))
            : [];

         console.log(getSequenceItems, 'getSequenceItems');
         setUpdateSequenceArray(getSequenceItems);
      }
   }, [imageData, currentImageIndex]);

   console.log(getAddedJobData, 'getAddedJobData');

   useEffect(() => {
      if (getClaimDetails && getClaimDetails?.itemClaim && Object.keys(getClaimDetails?.itemClaim).length > 0) {
         setItemDetails({
            ...itemDetails,
            room_id: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.room_items.id ? getClaimDetails?.itemClaim?.room_items.id : '',
            name: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.room_items.name,
            client_value_range: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.room_items.client_value_range,
            // item_id: getClaimDetails && getClaimDetails?.item_id,
            portal_user_id: 1,
            item_condition: getClaimDetails && getClaimDetails?.itemClaim && Number(getClaimDetails?.itemClaim?.item_condition),
            category: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.category,
            purchase_date: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.purchase_date,
            model: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.model,
            serial_number: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.serial_number,
            manufacturer: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.manufacturer,
            quantity: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.quantity ? getClaimDetails?.itemClaim?.quantity : 1,
            comment: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.comment,
            distribute: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.distribute,
            distribute_to: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.distribute_to,
            claimed_as: getClaimDetails && getClaimDetails?.itemClaim && getClaimDetails?.itemClaim?.claimed_as
         })
         setCheckedValues(getClaimDetails?.itemClaim?.dispose_by ? getClaimDetails?.itemClaim?.dispose_by.split(',').map(Number) : [])
      }
      else {
         setItemDetails({
            ...itemDetails,
            name: '',
            client_value_range: '',
            item_condition: '',
            category: '',
            purchase_date: '',
            model: '',
            serial_number: '',
            manufacturer: '',
            quantity: 1,
            comment: '',
            distribute: 0,
            distribute_to: '',
            claimed_as: ''
         })
         setCheckedValues([])
      }
   }, [getClaimDetails, currentImageIndex])

   useEffect(() => {
      if (clientClaimDetailsSuccess === true) {
         toast.success('Item Details Added Successfully !')
         dispatch(portalUserResetSuccessFlag({ flag: 'clientClaimDetailsSuccess' }));
      }
   }, [clientClaimDetailsSuccess])

   useEffect(() => {
      if (getAddedJobData) {
         let temp = []
         for (let i in getAddedJobData?.rooms) {
            temp.push({
               label: getAddedJobData?.rooms[i].room_name,
               value: getAddedJobData?.rooms[i].id

            })
         }
         setRoomList(temp)
         let roomName = _.find(getAddedJobData.rooms, { id: Number(atob(roomid)) })
         console.log(roomName, 'roomName');
         setSelectedRoom([`${roomName?.id}`])
         setItemDetails({
            ...itemDetails,
            room_name: roomName?.room_name
         })
      }
   }, [getAddedJobData, roomid])

   useEffect(() => {
      if (imageData && imageData.length > 0) {
         console.log(imageData[currentImageIndex].google_value_range, 'imageData[currentImageIndex].google_value_range');

         setIsAddItem(true)
         setItemDetails({
            ...itemDetails,
            google_value_range: imageData[currentImageIndex].google_value_range
         })
      }
      else {
         setIsAddItem(false)
         setItemDetails({
            ...itemDetails,
            google_value_range: ''
         })
      }
   }, [imageData, currentImageIndex])

   useEffect(() => {
      console.log(roomid, 'room_id123');
      const decodedRoomId = atob(roomid); // Use a different variable name
      dispatch(getRoomData({ room_id: decodedRoomId }));
      const decodedJobId = atob(job_id); // Use a different variable name
      dispatch(getJobData({ id: decodedJobId }));
      dispatch(getPhotographerList({}))
   }, [roomid, job_id])

   const itemsPerPage = 1;

   const thumbnailColHeight = 0.90 * window.screen.height;

   const handlePrev = () => {
      if (currentImageIndex > 0) {
         setCurrentImageIndex(currentImageIndex - 1);
      }
   };

   const handleNext = () => {
      if (currentImageIndex < imagesData.length - 1) {
         setCurrentImageIndex(currentImageIndex + 1);
      }
   };


   // Handle adding the new value to the assignedTo array
   const handleAddAssignedTo = () => {
      if (assignToTextBox.assignTextInputValue.trim()) {
         const newAssignedTo = {
            value: assignedTo.length + 1,
            label: assignToTextBox.assignTextInputValue,
         };
         console.log(newAssignedTo, 'newAssignedTo');
         let decodedProjectId = atob(project_id)
         let decodedJobId = atob(job_id)
         let postData = {
            item_id: Number(itemDetails.item_id),
            room_id: Number(itemDetails.room_id),
            job_id: Number(decodedJobId),
            assignto_name: assignToTextBox.assignTextInputValue,
            project_id: Number(decodedProjectId)
         }
         dispatch(addAssignedTo({ postData }))
         setAssignedTo([...assignedTo, newAssignedTo]);
         setAssignToTextBox({ assignTextInputValue: '' }); // Clear the input box after adding
         setAssignToTextBox(false); // Hide the input box after adding
      } else {
         toast.error("Please enter a value before adding.");
      }
   };


   const handleDelete = (item) => {
      console.log(item, 'item');

      // Retrieve the current sequence images or an empty array if none exist, and create a shallow copy
      let currentItem = imageData[currentImageIndex]?.sequenceImages?.length > 0
         ? [...imageData[currentImageIndex].sequenceImages] // Create a shallow copy
         : [];

      // Sort currentItem by serial_no in ascending order
      currentItem = currentItem.sort((a, b) => a.serial_no - b.serial_no);

      // Find the index of the image to check if it's the first image
      let findIfItsFirstImg = _.findIndex(currentItem, { id: item.sequence_id });
      console.log(findIfItsFirstImg, 'findIfItsFirstImg');

      // Show confirmation dialog
      Swal.fire({
         title: 'Are you sure?',
         text: "You won't be able to revert this!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#7066e0',
         cancelButtonColor: '#6e7881',
         confirmButtonText: 'Yes',
         cancelButtonText: 'No'
      }).then((result) => {
         if (result.isConfirmed) {
            // Prepare the postData for deletion
            let postData = {
               item_id: item.item_id,
               sequence_id: item.sequence_id,
               serial_no: item.serial_no,
               main_image: findIfItsFirstImg === 0 ? true : false // Check if it's the first image
            };
            console.log(postData, 'postData');

            // Dispatch the delete action (or any other logic for deletion)
            dispatch(deleteItemImage({ postData }));
         }
      });
   };

   const handleItemDelete = (item) => {
      console.log(item, 'itemDel');

      // Show confirmation dialog
      Swal.fire({
         title: 'Are you sure?',
         text: "You won't be able to revert this!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#7066e0',
         cancelButtonColor: '#6e7881',
         confirmButtonText: 'Yes',
         cancelButtonText: 'No'
      }).then((result) => {
         if (result.isConfirmed) {
            let postData = {
               item_id: item.id // Send only the item_id for deleting the entire item
            };
            let id = item.id
            console.log(postData, 'postData for deleting item');

            // Dispatch the delete action for the item
            dispatch(deleteRoomItem({ id }));
         }
      });
   };

   const replaceImage = (item) => {
      // Retrieve the current sequence images or an empty array if none exist
      let currentItem = imageData[currentImageIndex]?.sequenceImages?.length > 0
         ? [...imageData[currentImageIndex].sequenceImages] // Create a shallow copy of the array
         : [];

      // Sort currentItem by serial_no in ascending order
      currentItem = currentItem.sort((a, b) => a.serial_no - b.serial_no);

      console.log(currentItem, 'sorted currentItem');
      console.log(item, 'item');

      // Check if the first image's id matches the item.sequence_id after sorting
      let isFirstImage = currentItem[0]?.id === item.sequence_id;

      console.log(isFirstImage, item.sequence_id, 'isFirstImage');

      // Set sequenceId with the updated values and the check for the first image
      setSequenceId({
         ...sequenceId,
         sequence_id: item?.sequence_id,
         serial_no: item?.serial_no,
         item_id: item?.item_id,
         main_image: isFirstImage
      });

      // Trigger the file input click
      fileInputRef.current.click();
   };

   const handleFileChange = (event) => {
      const file = event.target.files[0];
      console.log(file, 'file');
      if (file) {
         const reader = new FileReader();

         reader.onload = async (e) => {
            const binaryData = e.target.result;
            console.log(binaryData, 'binaryData');
            // Construct formData with the binary data
            const formData = new FormData();
            formData.append('file', file);
            formData.append('binary_data', binaryData);

            console.log(formData, 'formData');

            let postData = {
               sequence_id: Number(sequenceId.sequence_id),
               serial_no: Number(sequenceId.serial_no),
               item_id: Number(sequenceId.item_id),
               binary_data: binaryData,
               file_name: file.name,
               main_image: sequenceId.main_image
            }
            console.log(postData, 'postData');

            dispatch(editItemImage({ postData }))

            try {
               // const response = await fetch('your_api_endpoint', {
               //     method: 'POST',
               //     body: formData,
               // });

               // const result = await response.json();

               // if (response.ok) {
               //     updateImageInGallery(result, editImageIndex.currentImageIndex, editImageIndex.index);
               // } else {
               //     console.error('API error:', result);
               //     message.error('Failed to upload image');
               // }
            } catch (error) {
               console.error('File upload error:', error);
               message.error('Failed to upload image');
            }
         };

         reader.readAsDataURL(file);
      }
   };

   const handlePreviewDelete = (item) => {
      // Find the specific slot_number to be deleted
      const slotNumberToDelete = item.slot_number;

      setAdditionalSequenceItemsColumnOrder(prev => {
         // Find the index of the item with the given slot_number
         const indexToDelete = prev.findIndex(sequenceItem => sequenceItem.slot_number === slotNumberToDelete);

         // If the item is found, create a new array with the item removed
         if (indexToDelete !== -1) {
            const updatedSequenceItems = [...prev];
            updatedSequenceItems.splice(indexToDelete, 1);
            return updatedSequenceItems;
         }

         // If the item is not found, return the previous state
         return prev;
      });
   };


   const handlePaginationChange = page => {
      console.log(page, 'page');
      setCurrentPage(page)
      dispatch(getRoomData({ room_id: atob(roomid), page: page }));
   };

   // Function to compress an image
   const compressImage = (file) => {
      return new Promise((resolve, reject) => {
         new Compressor(file, {
            quality: 0.6,  // Adjust quality (1 is max quality, 0 is lowest)
            maxWidth: 2500, // Resize the image if necessary (optional)
            success: (compressedResult) => {
               resolve(compressedResult); // Resolve with compressed image
            },
            error: (err) => {
               reject(err); // Reject on error
            },
         });
      });
   };

   //Functions of Batch Items
   // const handleBatchDrop = (acceptedFiles) => {
   //    let type = 'batch'
   //    const totalFiles = batchFileList.length + acceptedFiles.length;
   //    if (totalFiles > BATCH_MAX_FILES) {
   //       message.error(`You can only upload up to ${BATCH_MAX_FILES} files in total.`);
   //       return;
   //    }

   //    const newFileList = [...batchFileList, ...acceptedFiles];
   //    setPreview(acceptedFiles.map(file => Object.assign(file, {
   //       preview: URL.createObjectURL(file),
   //    })));
   //    setBatchFileList(newFileList);
   //    setDisableSequenceItems(true);

   //    readFiles(newFileList, type);
   // };
   // const handleSequenceDrop = (acceptedFiles) => {
   //    let type = 'sequence'
   //    const totalFiles = sequenceFileList.length + acceptedFiles.length;
   //    if (totalFiles > SEQUENCE_MAX_FILES) {
   //       message.error(`You can only upload up to ${SEQUENCE_MAX_FILES} files in total.`);
   //       return;
   //    }

   //    const newFileList = [...sequenceFileList, ...acceptedFiles];
   //    setPreview(acceptedFiles.map(file => Object.assign(file, {
   //       preview: URL.createObjectURL(file),
   //    })));
   //    setSequenceFileList(newFileList);
   //    setDisableBatchItems(true);

   //    readFiles(newFileList, type);
   // };
   // const handleAdditionalSequenceDrop = (acceptedFiles) => {
   //    let type = 'addionalSequence'
   //    const totalFiles = additionalSequenceFileList.length + acceptedFiles.length;
   //    if (totalFiles > SEQUENCE_MAX_FILES) {
   //       message.error(`You can only upload up to ${SEQUENCE_MAX_FILES} files in total.`);
   //       return;
   //    }

   //    const newFileList = [...additionalSequenceFileList, ...acceptedFiles];
   //    setPreview(acceptedFiles.map(file => Object.assign(file, {
   //       preview: URL.createObjectURL(file),
   //    })));
   //    setAdditionalSequenceFileList(newFileList);
   //    setIsPreviewModal(true)
   //    readFiles(newFileList, type);
   // };

   const handleBatchDrop = async (acceptedFiles) => {
      let type = 'batch';
      const totalFiles = batchFileList.length + acceptedFiles.length;

      if (totalFiles > BATCH_MAX_FILES) {
         message.error(`You can only upload up to ${BATCH_MAX_FILES} files in total.`);
         return;
      }

      try {
         // Compress each accepted file
         const compressedFiles = await Promise.all(
            acceptedFiles.map(file => compressImage(file))
         );

         const newFileList = [...batchFileList, ...compressedFiles];

         setPreview(compressedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
         })));
         setBatchFileList(newFileList);
         setDisableSequenceItems(true);

         // Pass the compressed files to readFiles function
         readFiles(newFileList, type);
      } catch (error) {
         console.error("Error compressing images: ", error);
      }
   };

   const handleSequenceDrop = async (acceptedFiles) => {
      let type = 'sequence';
      const totalFiles = sequenceFileList.length + acceptedFiles.length;

      if (totalFiles > SEQUENCE_MAX_FILES) {
         message.error(`You can only upload up to ${SEQUENCE_MAX_FILES} files in total.`);
         return;
      }

      try {
         // Compress each accepted file
         const compressedFiles = await Promise.all(
            acceptedFiles.map(file => compressImage(file))
         );

         const newFileList = [...sequenceFileList, ...compressedFiles];

         setPreview(compressedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
         })));
         setSequenceFileList(newFileList);
         setDisableBatchItems(true);

         // Pass the compressed files to readFiles function
         readFiles(newFileList, type);
      } catch (error) {
         console.error("Error compressing images: ", error);
      }
   };

   // Handle Additional Sequence Drop with Image Compression
   const handleAdditionalSequenceDrop = async (acceptedFiles) => {
      let type = 'addionalSequence';
      const totalFiles = additionalSequenceFileList.length + acceptedFiles.length;

      if (totalFiles > SEQUENCE_MAX_FILES) {
         message.error(`You can only upload up to ${SEQUENCE_MAX_FILES} files in total.`);
         return;
      }

      try {
         // Compress each accepted file
         const compressedFiles = await Promise.all(
            acceptedFiles.map(file => compressImage(file))
         );

         const newFileList = [...additionalSequenceFileList, ...compressedFiles];

         setPreview(compressedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
         })));
         setAdditionalSequenceFileList(newFileList);
         setIsPreviewModal(true);

         // Pass the compressed files to readFiles function
         readFiles(newFileList, type);
      } catch (error) {
         console.error("Error compressing images: ", error);
      }
   };


   //additional image with duplicate condition
   // const handleAdditionalSequenceDrop = (acceptedFiles) => {
   //    const totalFiles = additionalSequenceFileList.length + acceptedFiles.length;
   //    if (totalFiles > SEQUENCE_MAX_FILES) {
   //       message.error(`You can only upload up to ${SEQUENCE_MAX_FILES} files in total.`);
   //       return;
   //    }

   //    const existingFileNames = new Set(additionalSequenceItemsColumnOrder.map(file => file.file_name));
   //    const filteredAcceptedFiles = acceptedFiles.filter(file => !existingFileNames.has(file.name));

   //    if (filteredAcceptedFiles.length !== acceptedFiles.length) {
   //       message.warning('Some files were duplicates and have been ignored.');
   //    }

   //    const newFileList = [...additionalSequenceFileList, ...filteredAcceptedFiles];
   //    setPreview(filteredAcceptedFiles.map(file => Object.assign(file, {
   //       preview: URL.createObjectURL(file),
   //    })));
   //    setAdditionalSequenceFileList(newFileList);

   //    readFiles(newFileList);
   // };

   console.log(additionalSequenceFileList, 'additionalSequenceFileList');

   const readFiles = (fileList, type) => {
      const promises = fileList.map(file => {
         return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onabort = () => reject('file reading was aborted');
            reader.onerror = () => reject('file reading has failed');
            reader.onload = () => {
               resolve({ binaryStr: reader.result, name: file.name });
            };
            reader.readAsDataURL(file);
         });
      });


      Promise.all(promises)
         .then(results => {
            const imagesData = results.map((data, index) => ({
               original: data.binaryStr,
               thumbnail: data.binaryStr,
               name: data.name,
               id: index + 1
            }))
            if (type === 'batch') {
               const newArray = fileList.map((fileObj, index) => ({
                  // id: index + 1,
                  preview: fileObj.preview,
                  // slot_number: index + 1,
                  file_name: results[index] ? results[index].name : results.name,
                  binary_data: results[index] ? results[index].binaryStr : results.binaryStr,
                  room_id: atob(roomid),
                  is_lasso: 0
               }));
               console.log(newArray, 'newArray');
               setIsAddItem(!isAddItem)
               let postData = newArray
               console.log(postData, 'postData11');
               dispatch(addBatchItems({ postData }));
            }
            if (type === 'sequence') {
               const newArray = fileList.map((fileObj, index) => ({
                  // id: index + 1,
                  preview: fileObj.preview,
                  slot_number: index + 1,
                  file_name: results[index] ? results[index].name : results.name,
                  binary_data: results[index] ? results[index].binaryStr : results.binaryStr,
                  room_id: atob(roomid),
               }));
               setIsAddItem(!isAddItem)
               let postData = newArray
               console.log(postData, 'postt');
               dispatch(addSequenceItems({ postData }))
            }
            if (type === 'addionalSequence') {
               let temp = []
               for (let i in fileList) {
                  temp.push({
                     item_id: imageData && imageData[currentImageIndex] && imageData[currentImageIndex]?.id,
                     slot_number: Number(i) + 1,
                     file_name: results[i] ? results[i].name : results.name,
                     max_length: imageData && imageData[currentImageIndex] && imageData[currentImageIndex].sequenceImages && imageData[currentImageIndex].sequenceImages.length,
                     binary_data: results[i] ? results[i].binaryStr : results.binaryStr,
                     room_id: atob(roomid)
                  })
               }
               let postData = temp
               console.log(postData, 'postData');
               dispatch(addAdditionalSequenceItems({ postData }))
            }
            setImageBinaryData(imagesData);
         })
         .catch(error => console.error('Error reading files:', error));
   };

   const { getRootProps: getBatchRootProps, getInputProps: getBatchInputProps } = useDropzone({
      onDrop: handleBatchDrop,
      multiple: true,
      disabled: disableBatchItems
   });

   const { getRootProps: getSequenceRootProps, getInputProps: getSequenceInputProps } = useDropzone({
      onDrop: handleSequenceDrop,
      multiple: true,
      disabled: disableSequenceItems
   });

   const { getRootProps: getAdditionalSequenceRootProps, getInputProps: getAdditionalSequenceInputProps } = useDropzone({
      onDrop: handleAdditionalSequenceDrop,
      multiple: true,
      disabled: false
   });

   const onDragEndBatch = (result) => {
      if (!result.destination) {
         return; // Dragged outside the list
      }

      const newbatchItemsColumnOrder = Array.from(batchItemsColumnOrder);
      const [removed] = newbatchItemsColumnOrder.splice(result.source.index, 1);
      newbatchItemsColumnOrder.splice(result.destination.index, 0, removed);

      // Update the position property based on the new order
      newbatchItemsColumnOrder.forEach((column, index) => {
         column.slot_number = index + 1;
      });

      setBatchItemsColumnOrder(newbatchItemsColumnOrder);
   };

   const onDragEndSequence = (result) => {
      if (!result.destination) {
         return; // Dragged outside the list
      }

      const newSequenceItemsColumnOrder = Array.from(sequenceItemsColumnOrder);
      const [removed] = newSequenceItemsColumnOrder.splice(result.source.index, 1);
      newSequenceItemsColumnOrder.splice(result.destination.index, 0, removed);

      // Update the position property based on the new order
      newSequenceItemsColumnOrder.forEach((column, index) => {
         column.slot_number = index + 1;
      });

      setSequenceItemsColumnOrder(newSequenceItemsColumnOrder);
   };

   const onDragEndAddionalSequence = (result) => {
      if (!result.destination) {
         return; // Dragged outside the list
      }

      const newAdditionalItemsColumOrder = Array.from(additionalSequenceItemsColumnOrder);
      const [removed] = newAdditionalItemsColumOrder.splice(result.source.index, 1);
      newAdditionalItemsColumOrder.splice(result.destination.index, 0, removed);

      // Update the position property based on the new order
      newAdditionalItemsColumOrder.forEach((column, index) => {
         column.slot_number = index + 1;
      });

      setAdditionalSequenceItemsColumnOrder(newAdditionalItemsColumOrder);
   };

   const onDragEndCompleteSequence = (result) => {
      if (!result.destination) {
         return; // Dragged outside the list
      }

      const newAdditionalItemsColumOrder = Array.from(updateSequenceArray);
      const [removed] = newAdditionalItemsColumOrder.splice(result.source.index, 1);
      newAdditionalItemsColumOrder.splice(result.destination.index, 0, removed);

      // Update the position property based on the new order
      newAdditionalItemsColumOrder.forEach((column, index) => {
         column.slot_number = index + 1;
      });

      setUpdateSequenceArray(newAdditionalItemsColumOrder);
   };

   // const onDragEndAddionalSequence = (result) => {
   //    if (!result.destination) {
   //      return; // Dragged outside the list
   //    }

   //    const newAdditionalItemsColumOrder = Array.from(additionalSequenceItemsColumnOrder);
   //    const [removed] = newAdditionalItemsColumOrder.splice(result.source.index, 1);
   //    newAdditionalItemsColumOrder.splice(result.destination.index, 0, removed);

   //    // Update the position property based on the new order
   //    newAdditionalItemsColumOrder.forEach((column, index) => {
   //      column.slot_number = index + 1;
   //    });

   //    setAdditionalSequenceItemsColumnOrder(newAdditionalItemsColumOrder);
   //  };

   useEffect(() => {
      if (((imageBinaryData && imageBinaryData.length > 0) && (batchFileList && batchFileList.length > 0))) {
         const newArray = batchFileList.map((fileObj, index) => ({
            // id: index + 1,
            preview: fileObj.preview,
            // slot_number: index + 1,
            file_name: imageBinaryData[index] ? imageBinaryData[index].name : imageBinaryData.name,
            binary_data: imageBinaryData[index] ? imageBinaryData[index].original : imageBinaryData.original,
            room_id: atob(roomid)
         }));
         console.log(newArray, 'newArray');
         setBatchItemsColumnOrder(newArray)
         //  setNewArray(newArray);
      }
   }, [imageBinaryData, batchFileList, roomid]);

   useEffect(() => {
      let projectId = atob(project_id)
      dispatch(getAssignTo({ id: Number(projectId) }))
   }, [project_id, addAssignedToSuccess])

   useEffect(() => {
      if (addAssignedToSuccess === true) {
         toast.success('Added Successfully !')
      }
   }, [addAssignedToSuccess])

   useEffect(() => {
      if (((imageBinaryData && imageBinaryData.length > 0) && (sequenceFileList && sequenceFileList.length > 0))) {
         const newArray = sequenceFileList.map((fileObj, index) => ({
            preview: fileObj.preview,
            slot_number: index + 1,
            file_name: imageBinaryData[index] ? imageBinaryData[index].name : imageBinaryData.name,
            binary_data: imageBinaryData[index] ? imageBinaryData[index].original : imageBinaryData.original,
            room_id: atob(roomid)
         }));
         console.log(newArray, 'newArray');
         setSequenceItemsColumnOrder(newArray)
         //  setNewArray(newArray);
      }
   }, [imageBinaryData, sequenceFileList]);

   // useEffect(() => {
   //    if (imageData[currentImageIndex] && imageData[currentImageIndex].sequenceImages.length > 0) {
   //       const updatedSequenceImages = imageData[currentImageIndex].sequenceImages.map((image, index) => ({
   //          ...image,
   //          slot_number: index + 1
   //       }));
   //       setAdditionalSequenceItemsColumnOrder(updatedSequenceImages);
   //    } else {
   //       setAdditionalSequenceItemsColumnOrder([]);
   //    }
   // }, [currentImageIndex, imageData]);

   useEffect(() => {
      if (((imageBinaryData && imageBinaryData.length > 0) && (additionalSequenceFileList && additionalSequenceFileList.length > 0))) {
         const newArray = additionalSequenceFileList.map((fileObj, index) => ({
            preview: fileObj.preview,
            slot_number: index + 1,
            file_name: imageBinaryData[index] ? imageBinaryData[index].name : imageBinaryData.name,
            binary_data: imageBinaryData[index] ? imageBinaryData[index].original : imageBinaryData.original,
            room_id: roomid
         }));
         console.log(newArray, 'newArray');
         setAdditionalSequenceItemsColumnOrder(prev => [...prev, ...newArray]);

      }
   }, [imageBinaryData, additionalSequenceFileList, roomid, currentImageIndex, imageData]);

   useEffect(() => {
      if (photographerListData && photographerListData.data && photographerListData.data.length > 0) {
         let activeStaffList = _.filter(photographerListData.data, { status: 1 })
         if (activeStaffList) {
            const options = activeStaffList.map((item) => ({
               value: `${item.id}`,
               label: `${item.first_name} ${item.last_name}`
            }));
            console.log(options, 'options');
            setPhotographerList(options)
            // setClientIds(options)
         }
      }
   }, [photographerListData])

   const containerStyle = {
      display: 'flex',
      overflowX: 'auto',
      padding: '5px',
      '&::-webkit-scrollbar': {
         width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
         backgroundColor: '#888',
      },
      '&::-webkit-scrollbar-track': {
         backgroundColor: '#f0f0f0',
      },
      '&::-webkit-scrollbar-corner': {
         backgroundColor: '#fff',
      },
   };

   const columnStyle = {
      border: '1px solid darkgrey',
      padding: '3px',
      margin: '3px',
      minWidth: '200px',
      height: '140px',
      boxShadow: '5px 5px 10px 5px #888888',
      marginRight: '10px',
   };

   const uploadCardHeaderStyleProp = { backgroundColor: "#f7931e", color: "white" };

   useEffect(() => {
      const handleKeyDown = (event) => {
         if (isFullscreen && event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
         }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
         window.removeEventListener('keydown', handleKeyDown);
      };
   }, [isFullscreen]);

   // useEffect(() => {
   //    if (_.orderBy(getItemDatas?.data[currentImageIndex]?.sequenceImages).length === 2) {
   //       galleryRef.current.pause(); // Pause to prevent any auto-play overlap.
   //       galleryRef.current.slideToIndex(currentIndex);
   //    }
   // }, [currentIndex]);


   console.log(isFullscreen, 'isFullscreen');

   const handleScreenChange = (fullScreenStatus) => {
      setIsFullscreen(fullScreenStatus);
   };

   const handleSave = () => {
      setIsModalOpen(false);
      // let postData = addRoomName;
      // dispatch(addRoom({ postData }));
      // setAddRoomName({ room_name: "" });
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      // setAddRoomName({ room_name: "" });
   };

   const handleGoogleCancel = () => {
      setSelectedGoogleItemsPrice([]);
      setSelectedGoogleItemsIds([]);
      setIsGoogleModal(false);
   };
   const handlePreviewCancel = () => {
      setIsPreviewModal(false)
      setAdditionalSequenceFileList([])
      setAdditionalSequenceItemsColumnOrder([])
      setImageBinaryData([])
   }

   const saveSequenceChanges = () => {
      let currentItem = imageData[currentImageIndex].sequenceImages && imageData[currentImageIndex].sequenceImages.length > 0 ? imageData[currentImageIndex].sequenceImages : []
      let findIfItsFirstImg = _.findIndex(currentItem, { id: updateSequenceArray[0].id })
      console.log(findIfItsFirstImg, 'findIfItsFirstImg');
      let temp = []
      for (let i in updateSequenceArray) {
         temp.push({
            sequence_id: Number(updateSequenceArray[i].id),
            item_id: Number(updateSequenceArray[i].item_id),
            slot_number: Number(updateSequenceArray[i].slot_number),
            main_image: updateSequenceArray[i].slot_number === 1 && findIfItsFirstImg != 0 ? true : false
         })
      }
      console.log(temp, 'temp');
      let postData = {}
      postData.image_sequence = temp
      dispatch(updateSequenceImage({ postData }))
   }

   const disableFutureDates = current => {
      // Can not select days after today
      return current && current > moment().endOf('day');
   };

   const handleBatchItemDelete = (column) => {
      const imageBinaryDataIndex = imageBinaryData.findIndex(item => item.name === column.path)
      const itemIndex = batchFileList.findIndex(item => item.path === column.path);
      console.log(itemIndex, column.file_name, batchFileList, 'sds');
      if (itemIndex !== -1) {
         // Remove the item from batchFileList using the same index
         const updatedFileList = [...batchFileList];
         updatedFileList.splice(itemIndex, 1);
         setBatchFileList(updatedFileList);
      }
      if (imageBinaryDataIndex !== -1) {
         // Remove the item from batchFileList using the same index
         const updatedImageFileList = [...imageBinaryData];
         updatedImageFileList.splice(imageBinaryDataIndex, 1);
         setImageBinaryData(updatedImageFileList);
      }
   };

   // const handleSequenceItemDelete = (column) => {
   //    // const updatedItems = sequenceItemsColumnOrder.filter(item => item.slot_number !== column.slot_number);
   //    // setBatchItemsColumnOrder(updatedItems);

   //    const updatedImageBinaryData = imageBinaryData.filter(file => file.name !== column.file_name)
   //    setImageBinaryData(updatedImageBinaryData)

   //    const updatedFileList = sequenceFileList.filter(file => file.path !== column.file_name);
   //    setSequenceFileList(updatedFileList);
   // };

   const handleSequenceItemDelete = (column) => {
      const slotNumber = column.slot_number;

      // Find the index of the image to be deleted in the sequenceItemsColumnOrder
      const imageIndex = sequenceItemsColumnOrder.findIndex(item => item.slot_number === slotNumber);

      if (imageIndex !== -1) {
         // Get the file_name of the image to be deleted
         const fileNameToDelete = sequenceItemsColumnOrder[imageIndex].file_name;

         // Remove the specific image from sequenceItemsColumnOrder
         const updatedItems = [...sequenceItemsColumnOrder];
         updatedItems.splice(imageIndex, 1);
         setSequenceItemsColumnOrder(updatedItems);

         // Remove the corresponding image from imageBinaryData and sequenceFileList
         const updatedImageBinaryData = imageBinaryData.filter((file, index) => index !== imageIndex);
         setImageBinaryData(updatedImageBinaryData);

         const updatedFileList = sequenceFileList.filter((file, index) => index !== imageIndex);
         setSequenceFileList(updatedFileList);
      }
   };



   const handleSaveImages = () => {
      let temp = []
      for (let i in additionalSequenceItemsColumnOrder) {
         temp.push({
            item_id: imageData && imageData[currentImageIndex] && imageData[currentImageIndex]?.id,
            slot_number: Number(i) + 1,
            file_name: additionalSequenceItemsColumnOrder[i].file_name,
            max_length: imageData && imageData[currentImageIndex] && imageData[currentImageIndex].sequenceImages && imageData[currentImageIndex].sequenceImages.length,
            binary_data: additionalSequenceItemsColumnOrder[i].binary_data,
            room_id: atob(roomid)
         })
      }

      let postData = temp
      dispatch(addAdditionalSequenceItems({ postData }))
      console.log(temp, 'tempSave');
   }


   useEffect(() => {
      if (galleryRef.current) {
         galleryRef.current.slideToIndex(currentIndex);
      }
   }, [currentIndex]);

   const handleSlide = (index) => {
      setCurrentIndex(index);
   };


   const onItemValueChange = (e) => {
      const { name, value } = e.target;
      setItemDetails(prevState => ({
         ...prevState,
         [name]: value
      }));
   }

   const onCheckboxChange = (checkedValue, isChecked) => {
      if (isChecked) {
         // Add the value to the checkedValues array
         setCheckedValues([...checkedValues, checkedValue]);
      } else {
         // Remove the value from the checkedValues array
         setCheckedValues(checkedValues.filter(val => val !== checkedValue));
      }
   };

   const onRoomChange = (value) => {
      setSelectedRoom(value)
   }

   // useEffect(() => {
   //    if (imageData && imageData.length > 0) {
   //       console.log(imageData, 'imageData');
   //       let itemId = imageData[currentImageIndex]?.id
   //       console.log(itemId,'itemId');

   //       dispatch(getClientClaimDetails({ id: Number(itemId), user_id: 1, manager: 0 }));
   //       dispatch(getDocuments({ user_id: 1, item_id: Number(itemId) }));
   //    }
   // }, [imageData, currentImageIndex])

   const itemDetailsOnSave = () => {
      let postData = itemDetails
      postData.item_id = imageData && imageData[currentImageIndex] && imageData[currentImageIndex]?.id
      postData.portal_user_id = 1
      postData.room_id = Number(selectedRoom)
      postData.dispose_by = checkedValues.join(',');
      delete postData.item_name;
      delete postData.room_name;
      delete postData.item_image;
      console.log(postData, 'postData');
      dispatch(clientClaimDetails({ postData }))
   }

   const itemDetailsOnClear = () => {
      setItemDetails({
         ...itemDetails,
         room_id: '',
         name: '',
         client_value_range: '',
         google_value_range: '',
         item_id: '',
         portal_user_id: '',
         item_condition: '',
         category: '',
         purchase_date: '',
         model: '',
         serial_number: '',
         manufacturer: '',
         quantity: 1,
         distribute: 0,
         claimed_as: '',
         distribute_to: '',
         dispose_by: 0,
         comment: ''
      })
   }

   const handleQuantityChange = (newValue) => {
      console.log('Changed value: ', newValue);
      setItemDetails({
         ...itemDetails,
         quantity: newValue
      });
   };


   const onDateChange = (date, dateString) => {
      setItemDetails({
         ...itemDetails,
         purchase_date: date ? dayjs(dateString) : null
      });
   };



   const onSelectChange = (value, name) => {
      setItemDetails(prevState => ({
         ...prevState,
         [name]: Number(value)
      }));
   };

   const handleFullScreenToggle = () => {
      if (galleryRef.current) {
         galleryRef.current.toggleFullScreen();
      }
   };

   const showModal = () => {
      setIsDocumentModalVisible(true);
   };

   useEffect(() => {
      if (getDocumentsData?.length) {
         console.log("DocumentsData ===>", getDocumentsData);

         setItemDocuments([...getDocumentsData]);
         setSelectedDocument({ id: getDocumentsData[0].id, path: getDocumentsData[0].pdf_path });
         setIsAddDocument(false);
      } else {
         setItemDocuments([]);
         setIsAddDocument(true);
         setSelectedDocument({ id: "", path: "" });
      }
   }, [getDocumentsData, isDocumentModalVisible])

   useEffect(() => {
      if (addDocumentSuccess) {
         toast.success("Documents added successfully");
         setIsAddDocument(false);
         let itemId = imageData[currentImageIndex]?.id;
         dispatch(getDocuments({ user_id: 1, item_id: Number(itemId) }));
      }

      if (addDocumentError) {
         toast.error(addDocumentErrorMessage);
      }

      setDocumentFileList([]);

   }, [addDocumentSuccess, addDocumentError, addDocumentErrorMessage])

   useEffect(() => {
      if (deleteDocumentSuccess) {
         toast.success("Documents deleted successfully");
         setIsAddDocument(false);
         let itemId = imageData[currentImageIndex]?.id;
         dispatch(getDocuments({ user_id: 1, item_id: Number(itemId) }));
      }

      if (deleteDocumentError) {
         toast.error(deleteDocumentErrorMessage);
      }

      setDocumentFileList([]);

   }, [deleteDocumentSuccess, deleteDocumentError, deleteDocumentErrorMessage])

   const handleDocumentCancel = () => {
      setIsDocumentModalVisible(false);
      setSelectedDocument({ id: "", path: "" });
      setIsAddDocument(false);
      setDocumentFileList([]);
   };

   const handleUpload = async () => {
      if (documentFileList.length === 0) {
         message.error('Please select a PDF file before uploading.');
         return;
      }

      const formData = new FormData();

      // Calculate the total file size in MB
      const file_size = documentFileList.reduce((total, file) => total + file.size / 1000000, 0);

      if (file_size > 5) {
         message.error("Please check file size. Maximum allowed size is 5MB.");
         return;
      }

      const pdfFiles = documentFileList.map(file => file.originFileObj);

      // // Append files to FormData
      // documentFileList.forEach(file => formData.append('pdf', file));

      // Append files to FormData
      pdfFiles.forEach(file => formData.append('pdf', file));

      // Append additional data to FormData
      formData.append('item_id', imageData[currentImageIndex].id);
      formData.append('room_id', imageData[currentImageIndex].room_id);
      formData.append('portal_user_id', 1);
      formData.append('is_admin', 1);

      // Dispatch the action to upload documents
      dispatch(addDocuments({ formData }));
   };

   const handleDocumentDelete = () => {
      if (selectedDocument?.id) {
         dispatch(deleteDocuments({ id: selectedDocument.id }));
      }
   }

   // Function to Antd File Upload onchange
   const handleDocumentChange = ({ file, fileList }) => {

      console.log("fileLIst", fileList);


      // Ensure only PDF files are accepted
      const nonPdfFiles = fileList.filter(file => file.originFileObj.type !== 'application/pdf');
      if (nonPdfFiles.length > 0) {
         message.error('You can only upload PDF files!');
         return;
      }

      // // Extract and store only the PDF files
      // const pdfFiles = fileList.map(file => file.originFileObj);

      // // Update the documentFileList with the selected PDF files
      // setDocumentFileList(pdfFiles);

      setDocumentFileList(fileList);
   };

   const handleRoomChange = (value) => {
      if (value.includes('all')) {
         // If "All" is selected, set all room IDs
         setSelectedRoom(roomList.map(room => `${room.value}`));
      } else {
         // Else, set only the selected room IDs
         setSelectedRoom(value);
      }
   };

   const updateAssignedTo = () => {
      const updatedAssignedTo = [
         {
            value: 0,
            label: "Un-Assigned",
         },
         ...getAssignedToData.map(item => ({
            value: item.id,
            label: item.assignto_name,
         }))
      ];

      setAssignedTo(updatedAssignedTo);
   };

   // You can call this function inside a useEffect or on some event
   useEffect(() => {
      updateAssignedTo();
   }, [getAssignedToData]); // This runs when getAssignedToData changes

   console.log(getAssignedToData, 'getAssignedToData');

   console.log(getAddedProjectData, 'getAddedProjectData');

   return (
      <div className="pe-2 mb-3">
         <div className="col-md-12">
            <div className="row">
               <div className="col-md-7">
                  <PageHeader
                     HeaderText={localStorage.getItem('job_name') ? localStorage.getItem('job_name') : 'TakeStock Room Details'}
                  // Breadcrumb={[{ name: "TakeStock Room Details/room" }]}
                  />
                  {roleID === '3' ?
                     <div className="rooms-breadcrumb">
                        <Breadcrumb
                           separator=">"
                           items={[
                              {
                                 title: <HomeOutlined />,
                                 href: '/photographer/dashboard'
                              },
                              {
                                 title: getAddedJobData?.job_number,
                                 href: '/photographer/jobs',
                              },
                              {
                                 title: itemDetails.room_name,
                              },
                           ]}
                        />
                     </div>
                     :
                     <div className="rooms-breadcrumb">
                        <Breadcrumb
                           separator=">"
                           items={[
                              {
                                 title: <HomeOutlined />,
                                 href: '/dashboard'
                              },
                              {
                                 title: getAddedProjectData?.project_name,
                                 href: `/editproject/${btoa(getAddedProjectData?.id)}/${btoa(getAddedProjectData?.client_id)}`,
                              },
                              {
                                 title: getAddedJobData?.job_number,
                                 href: `/projects/editjob/${project_id}/${btoa(getAddedProjectData?.client_id)}/${job_id}`,
                              },
                              {
                                 title: itemDetails.room_name,
                              },
                           ]}
                        />
                     </div>
                  }
               </div>
               {((roleID === '3') && (photographerPermissionIds && photographerPermissionIds.length > 0 && photographerPermissionIds.includes(2))) ?
                  <div className="col-md-5 d-flex justify-content-end">
                     <Space size={"middle"} className="me-1 mt-3">
                        {isAddItem === true && updateSequenceArray.length > 1 ?
                           <Button
                              style={uploadCardHeaderStyleProp}
                              shape="round"
                              icon={<i className="fa fa-file-image-o" aria-hidden="true" />}
                              onClick={() => setIsModalOpen(true)}
                           >
                              Sequence Items
                           </Button> : null}
                        {imageData && imageData.length > 0 ?
                           <>
                              <Button
                                 style={uploadCardHeaderStyleProp}
                                 shape="round"
                                 icon={<i className="fa fa-file-image-o" aria-hidden="true" />}
                                 onClick={() => setIsAddItem(!isAddItem)}
                              >
                                 {isAddItem === true ? 'Add items' : 'View Room Items'}
                              </Button>
                              <Checkbox className="me-1" onClick={(e) => {
                                 if (e.target.checked) {
                                    let postData = {
                                       is_completed: 1,
                                       job_id: Number(atob(job_id))
                                    }
                                    dispatch(updateRoom({ postData, updateID: Number(atob(roomid)) }));
                                 }
                                 else {
                                    let postData = {
                                       is_completed: 0,
                                       job_id: Number(atob(job_id))
                                    }
                                    dispatch(updateRoom({ postData, updateID: Number(atob(roomid)) }));
                                 }
                              }}>Room Completed</Checkbox>
                           </>
                           : null}

                     </Space>
                  </div>
                  :
                  ((roleID === '3') && (photographerPermissionIds && photographerPermissionIds.length > 0 && photographerPermissionIds.includes(2) === false)) ?
                     <div className="col-md-5 d-flex justify-content-end">
                        <Space size={"middle"} className="me-5 mt-3">
                           <Checkbox className="me-1" onClick={(e) => {
                              if (e.target.checked) {
                                 let postData = {
                                    is_completed: 1,
                                    job_id: Number(atob(job_id))
                                 }
                                 dispatch(updateRoom({ postData, updateID: Number(atob(roomid)) }));
                              }
                              else {
                                 let postData = {
                                    is_completed: 0,
                                    job_id: Number(atob(job_id))
                                 }
                                 dispatch(updateRoom({ postData, updateID: Number(atob(roomid)) }));
                              }
                           }}>Room Completed</Checkbox>
                        </Space>
                     </div>
                     :
                     <div className="col-md-5 d-flex justify-content-end">
                        <Space size={"middle"} className="me-1 mt-3">
                           <Button
                              style={uploadCardHeaderStyleProp}
                              shape="round"
                              icon={<i className="fa fa-file-image-o" aria-hidden="true" />}
                              onClick={OnselectAllimages}
                           >
                              Select All Items
                           </Button>
                           <Modal
                              open={isSelectAllRoomsModal}
                              closeIcon={<CloseCircleFilled className="fs-4 text-secondary" />}
                              footer={[
                                 <Button
                                    shape="round"
                                    type="primary"
                                    className="bg-secondary"
                                    key="cancel"
                                    onClick={() => setIsSelectAllRoomsModal(false)}>
                                    Close
                                 </Button>
                              ]}
                              onCancel={() => setIsSelectAllRoomsModal(false)}
                           >
                              <label className="fs-5">
                                 Total items searched : <span className="fw-normal">{imageData?.length}</span>
                              </label>
                              <div className="text-center mt-2">
                                 <p>To see the search results status for all the Room items.<br />
                                    Click on the Google Reports button below.</p>
                                 <Link to="/googlelogs">
                                    <Button
                                       style={uploadCardHeaderStyleProp}
                                       shape="round"
                                       icon={
                                          <IoReturnUpForwardOutline
                                             className="fs-4"
                                             style={{ marginTop: -2 }} />
                                       }
                                    >
                                       Google Reports
                                    </Button>
                                 </Link>
                              </div>
                           </Modal>
                           {isAddItem === true && imageData && imageData[currentImageIndex] && imageData[currentImageIndex].sequenceImages && imageData[currentImageIndex].sequenceImages?.length > 1 ?
                              <Button
                                 style={uploadCardHeaderStyleProp}
                                 shape="round"
                                 icon={<i className="fa fa-file-image-o" aria-hidden="true" />}
                                 onClick={() => setIsModalOpen(true)}
                              >
                                 Sequence Items
                              </Button> : null}
                           {imageData && imageData.length > 0 ?
                              <>
                                 <Button
                                    style={uploadCardHeaderStyleProp}
                                    shape="round"
                                    icon={<i className="fa fa-file-image-o" aria-hidden="true" />}
                                    onClick={() => setIsAddItem(!isAddItem)}
                                 >
                                    {isAddItem === true ? 'Add items' : 'View Room Items'}
                                 </Button>
                                 <Checkbox className="me-1" onClick={(e) => {
                                    if (e.target.checked) {
                                       let postData = {
                                          is_completed: 1,
                                          job_id: Number(atob(job_id))
                                       }
                                       dispatch(updateRoom({ postData, updateID: Number(atob(roomid)) }));
                                    }
                                    else {
                                       let postData = {
                                          is_completed: 0,
                                          job_id: Number(atob(job_id))
                                       }
                                       dispatch(updateRoom({ postData, updateID: Number(atob(roomid)) }));
                                    }
                                 }}>Room Completed</Checkbox>
                              </>
                              : null}

                        </Space>
                     </div>
               }
               {/* <div className="col-md-5">
                  <Space size={"middle"} className="me-1 mt-3">
                     {isAddItem === true && updateSequenceArray.length > 0 ?
                        <Button
                           style={uploadCardHeaderStyleProp}
                           shape="round"
                           icon={<i className="fa fa-file-image-o" aria-hidden="true" />}
                           onClick={() => setIsModalOpen(true)}
                        >
                           Update
                        </Button> : null}
                     {imageData && imageData.length > 0 ?
                        <>
                           <Button
                              style={uploadCardHeaderStyleProp}
                              shape="round"
                              icon={<i className="fa fa-file-image-o" aria-hidden="true" />}
                              onClick={() => setIsAddItem(!isAddItem)}
                           >
                              {isAddItem === true ? 'Add items' : 'View Room Items'}
                           </Button>
                           <Checkbox className="me-1">Room Completed</Checkbox>
                        </>
                        : null}

                  </Space>
               </div> */}
            </div>
         </div>
         <Loader loading={addBatchItemLoading || addSequenceItemLoading || getRoomLoading || editItemImageLoading} />
         {/* <Card styles={{ body: { padding: "15px 10px" } }}>
            <Flex className="ps-3 mt-2" justify="space-between">
               <Space>
                  <Image src={getAddedJobData?.front_image ? assestURL + getAddedJobData?.front_image : null} width={120} height={100} />
                  <Space direction="vertical" className="ms-3">
                     <h5>{getAddedJobData?.job_name ? getAddedJobData?.job_name : 'Inventory Name'}</h5>
                     <h5>{getAddedJobData?.address_one}</h5>
                  </Space>
               </Space>

            </Flex>
         </Card> */}
         {isAddItem === true ?
            <Row gutter={[10, 10]} className='mt-3'>
               <Col span={roleID === '3' ? 24 : 15} style={{ marginTop: roleID === '3' ? '10px' : '0px' }}>
                  <Card className="h-100" styles={{ body: { paddingLeft: 10, paddingRight: 5 } }}>
                     <Row gutter={12} className="ms-2">
                        <Col span={roleID === '3' ? 4 : 6}>
                           <div
                              style={{ height: thumbnailColHeight, scrollbarWidth: "thin" }}
                              className="px-2 overflow-y-scroll"
                           >
                              {imageData.map((image, index) => (
                                 <div
                                    key={index}
                                    className="mb-2 mt-1 px-1"
                                    style={{
                                       borderRadius: '10px',
                                       boxShadow: currentImageIndex === index
                                          ? '0 0 12px 5px #FF5F1F'
                                          : 'none',
                                       cursor: "pointer",
                                       padding: currentImageIndex === index ? '2px' : '0',
                                       display: 'flex',
                                       textAlign: 'center',
                                       justifyContent: 'center'
                                    }}
                                    onClick={() => {
                                       setCurrentImageIndex(index)
                                       let itemId = imageData[index]?.id
                                       dispatch(getRoomData({ room_id: atob(roomid) }));
                                       dispatch(getClientClaimDetails({ id: Number(itemId), user_id: 1, manager: 0 }));
                                       dispatch(getDocuments({ user_id: 1, item_id: Number(itemId) }));
                                       setItemDetails({
                                          ...itemDetails,
                                          room_id: '',
                                          name: '',
                                          client_value_range: '',
                                          google_value_range: imageData && imageData[index].google_value_range,
                                          item_id: '',
                                          portal_user_id: '',
                                          item_condition: '',
                                          category: '',
                                          purchase_date: '',
                                          model: '',
                                          serial_number: '',
                                          manufacturer: '',
                                          quantity: 1,
                                          distribute: 0,
                                          claimed_as: '',
                                          distribute_to: '',
                                          dispose_by: 0,
                                          comment: ''
                                       })
                                    }}
                                 >
                                    <img
                                       src={assestURL + image.item_image}
                                       // src={image.item_image != null ? assestURL + image.item_image : image.sequenceImages[0].sequence_image}
                                       alt=""
                                       style={{ height: roleID === '3' ? '150px' : '107px', borderRadius: '3px', backgroundColor: 'white' }}
                                    />
                                 </div>
                              ))}
                           </div>
                           {/* <Pagination
                        showSizeChanger={false}
                        size="small"
                        className="text-center mt-3 mb-2"
                        current={currentPage}
                        pageSize={itemsPerPage}
                        total={imagesData.length}
                        onChange={handlePaginationChange}
                     /> */}
                        </Col>
                        <Col span={roleID === '3' ? 20 : 18} className="p-0 m-0 pe-1">
                           <div className="w-100 h-100 py-2 bg-secondary-subtle">
                              <ImageGallery
                                 ref={galleryRef}
                                 slideDuration={300}
                                 additionalClass="my-0 mx-auto"
                                 showFullscreenButton={false}
                                 items={_.orderBy(getItemDatas?.data[currentImageIndex]?.sequenceImages, ['serial_no'], ['asc']).map((image, index) => ({
                                    original: assestURL + image.sequence_image,
                                    thumbnail: assestURL + image.sequence_image,
                                    sequence_id: image.id,
                                    serial_no: image?.serial_no,
                                    item_id: image.item_id,
                                    id: imageData && imageData[currentImageIndex]?.id,
                                    displayIndex: index + 1
                                 })) || []}
                                 showThumbnails={true}
                                 onScreenChange={(isFull) => {
                                    setIsFullscreen(isFull);
                                    handleScreenChange(isFull);
                                 }}
                                 onSlide={handleSlide}
                                 renderItem={(item, index) => (
                                    <div className="image-gallery-image" key={item.sequence_id}>
                                       {isFullscreen ?
                                          <img
                                             src={item.original}
                                             alt=""
                                             loading="lazy"
                                             style={{ objectFit: 'contain', width: '1365px', height: '650px' }}
                                          />
                                          :
                                          <img
                                             src={item.original}
                                             alt=""
                                             // style={{ objectFit: 'contain', width: '375px', height: '250px' }}
                                             style={{ objectFit: 'contain', maxHeight: 500 }}
                                          />
                                       }

                                       <div className={roleID === '3' ? `image-gallery-icons-photographer` : 'image-gallery-icons'}>
                                          <Button
                                             icon={<DeleteOutlined />}
                                             onClick={() => handleItemDelete(item)}
                                             danger
                                             style={{ marginRight: "65px" }}
                                          >Delete Item</Button>
                                          <Button
                                             icon={<FullscreenOutlined />}
                                             onClick={handleFullScreenToggle}
                                             style={{ marginRight: "10px" }}
                                          />
                                          {isFullscreen === false && (roleID !== '3' || (roleID === '3' && photographerPermissionIds && photographerPermissionIds.length > 0 && photographerPermissionIds.includes(3))) ?
                                             <>
                                                <div {...getAdditionalSequenceRootProps()} style={{ border: '1px solid green', borderRadius: '7px', backgroundColor: 'white', color: 'green', height: '32px', marginRight: '10px', width: '32px' }} >
                                                   <input {...getAdditionalSequenceInputProps()} />
                                                   <p className="ant-upload-drag-icon mt-2">
                                                      <PlusOutlined />
                                                   </p>
                                                </div>
                                                <Button
                                                   icon={<EditOutlined />}
                                                   onClick={() => replaceImage(item)}
                                                   style={{ marginRight: "10px", color: 'blue' }}
                                                />
                                                {_.orderBy(getItemDatas?.data[currentImageIndex]?.sequenceImages).length > 1 ?
                                                   <Button
                                                      icon={<DeleteOutlined />}
                                                      onClick={() => handleDelete(item)}
                                                      danger
                                                      style={{ marginRight: "10px" }}
                                                   /> : null
                                                }
                                                {roleID === '3' ? null :
                                                   <div style={{ borderRadius: '7px', backgroundColor: 'white', color: 'green', height: '32px', marginRight: '10px', width: '32px' }} >
                                                      <img src={googleIcon} onClick={() => {
                                                         googleOnchange(item)
                                                      }}></img>
                                                   </div>
                                                }
                                             </>
                                             : null}
                                       </div>
                                    </div>
                                 )}
                                 renderLeftNav={(onClick, disabled) => (
                                    <button
                                       type="button"
                                       className={`image-gallery-icon image-gallery-left-nav`}
                                       aria-label="Previous Slide"
                                       disabled={currentIndex === 0}
                                       style={{ cursor: currentIndex === 0 ? 'not-allowed' : 'pointer' }}
                                       onClick={onClick}
                                    >
                                       {/* <svg className="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="6 0 12 24">
                                       <polyline points="15 18 9 12 15 6"></polyline>
                                    </svg> */}
                                       <DoubleLeftOutlined style={{ fontSize: '40px' }} />
                                    </button>
                                 )}
                                 renderRightNav={(onClick, disabled) => (
                                    <button
                                       type="button"
                                       className={`image-gallery-icon image-gallery-right-nav`}
                                       aria-label="Next Slide"
                                       disabled={currentIndex === _.orderBy(getItemDatas?.data[currentImageIndex]?.sequenceImages).length - 1}
                                       onClick={onClick}
                                       style={{ cursor: currentIndex === _.orderBy(getItemDatas?.data[currentImageIndex]?.sequenceImages).length - 1 ? 'not-allowed' : 'pointer' }}
                                    >
                                       {/* <svg className="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="6 0 12 24">
                                       <polyline points="9 6 15 12 9 18"></polyline>
                                    </svg> */}
                                       <DoubleRightOutlined />

                                    </button>
                                 )}
                                 renderThumbInner={(item, indexNumber) => (
                                    <div key={indexNumber} className="custom-thumb-inner">
                                       <img
                                          src={item.thumbnail}
                                          alt=""
                                          style={{ width: '100%', height: '60px', objectFit: 'contain' }}
                                       />
                                       <div className="serial-no mt-2"><strong>{item.displayIndex}</strong></div>
                                    </div>
                                 )}
                              />
                              <input
                                 type="file"
                                 accept="image/*"
                                 style={{ display: 'none' }}
                                 ref={fileInputRef}
                                 onChange={handleFileChange}
                              />
                           </div>
                        </Col>
                        <div className="pagination-container">
                           {/* Showing entries text */}
                           <Pagination
                              showSizeChanger={false}
                              size="small"
                              className="text-center mt-3 mb-2"
                              current={currentPage}
                              pageSize={10}
                              total={getItemDatas?.counts}
                              onChange={handlePaginationChange}
                           />
                           <div className="entries-text">
                              {getItemDatas?.counts > 0 ? (
                                 <p>
                                    Showing {(currentPage - 1) * 10 + 1} to{' '}
                                    {Math.min(currentPage * 10, getItemDatas?.counts)} of {getItemDatas?.counts} entries
                                 </p>
                              ) : (
                                 <p>No entries available</p>
                              )}
                           </div>

                           {/* Pagination */}

                        </div>
                     </Row>
                  </Card>
                  {/* </div> */}
               </Col>
               <Col span={roleID === '3' ? 0 : 9}>
                  <Card styles={{ body: { padding: 6 } }}>
                     <Flex gap={10} vertical>
                        <Flex gap={10} justify="right" align="center">
                           <label>Room Name :</label>
                           <Select
                              className="input-box-size"
                              placeholder="Room Name"
                              name='room_name'
                              value={selectedRoom}
                              disabled={false}
                              onChange={handleRoomChange}
                           >
                              {roomList && roomList.length > 0 && roomList.map((item) => {
                                 return (
                                    <Option key={item.value}>{item?.label}</Option>
                                 )
                              })}
                           </Select>
                           {/* <Input className="input-box-size" placeholder="Room Name" /> */}
                        </Flex>
                        <Flex gap={10} justify="right" align="center">
                           <label>Item Name :</label>
                           <Input className="input-box-size" placeholder="Item Name" name="name" value={itemDetails.name} onChange={(e) => { onItemValueChange(e) }} />
                        </Flex>
                        <Flex gap={10} justify="right" align="center">
                           <label>Client's Estimate($) :</label>
                           <Input
                              className="input-box-size"
                              placeholder="Client Estimate"
                              name="client_value_range"
                              type="number"
                              step="0.01"
                              value={itemDetails.client_value_range}
                              onChange={(e) => {
                                 // Update the value as the user types (without formatting)
                                 onItemValueChange({ target: { name: e.target.name, value: e.target.value } });
                              }}
                              onBlur={(e) => {
                                 // Format the value to two decimal places when the user leaves the input
                                 const value = parseFloat(e.target.value) || 0;
                                 onItemValueChange({ target: { name: e.target.name, value: value.toFixed(2) } });
                              }}
                           />
                        </Flex>
                        <Flex gap={10} justify="right" align="center">
                           <label>AI Estimate($) :</label>
                           <Input className="input-box-size" placeholder="AI Estimate" name="google_value_range" value={itemDetails.google_value_range} onChange={(e) => { onItemValueChange(e) }} />
                        </Flex>
                        <Flex gap={10} justify="right" align="center">
                           <label>Quantity :</label>
                           <div className="input-box-size">
                              <InputNumber placeholder="Qty" min={1} name="quantity" value={itemDetails.quantity} onChange={handleQuantityChange} />
                           </div>
                           {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                        </Flex>
                        <Flex gap={10} justify="right" align="center">
                           <label>Category :</label>
                           <Select
                              className="input-box-size"
                              placeholder="Category"
                              value={itemDetails.category ? `${itemDetails.category}` : null}
                              onChange={(value) => onSelectChange(value, 'category')}
                           >
                              {itemCategoriesListData?.data && itemCategoriesListData?.data.length > 0 &&
                                 [...itemCategoriesListData.data] // Create a shallow copy of the array
                                    .sort((a, b) => a.category_name.localeCompare(b.category_name)) // Sort in ascending order
                                    .map((item) => {
                                       return (
                                          <Option key={item.id}>
                                             {item?.category_name}
                                          </Option>
                                       );
                                    })}
                           </Select>
                           {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                        </Flex>
                        <Flex gap={10} justify="right" align="center">
                           <label>Condition :</label>
                           <Select
                              className="input-box-size"
                              options={itemCondition}
                              placeholder="Condition"
                              value={itemDetails.item_condition ? itemDetails.item_condition : null}
                              onChange={(value) => onSelectChange(value, 'item_condition')}
                           />
                           {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                        </Flex>
                        <Flex gap={10} justify="right" align="center">
                           <label>Date of Purchase :</label>
                           <DatePicker format={'MM-DD-YYYY'} className="input-box-size" disabledDate={disableFutureDates} onChange={onDateChange} name="purchase_date" value={itemDetails.purchase_date ? dayjs(itemDetails.purchase_date) : null} />
                           {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                        </Flex>
                        <Flex gap={10} justify="right" align="center">
                           <label>Model :</label>
                           <Input className="input-box-size" placeholder="Model" name="model" value={itemDetails.model} onChange={(e) => { onItemValueChange(e) }} />
                        </Flex>
                        <Flex gap={10} justify="right" align="center">
                           <label>Serial Number :</label>
                           <Input className="input-box-size" placeholder="Serial Number" name="serial_number" value={itemDetails.serial_number} onChange={(e) => { onItemValueChange(e) }} />
                        </Flex>
                        {/* {portalUserProjectType === 1 || portalUserProjectType === 3 ? */}
                        <>
                           <Flex gap={10} justify="right" align="center">
                              <label>Assign to :</label>
                              <div className="input-box-size">
                                 <Select
                                    className="input-box-size"
                                    placeholder="Create/Assign Heir"
                                    style={{ width: '60%' }}
                                    value={itemDetails.distribute_to ? `${itemDetails.distribute_to}` : null}
                                    onChange={(value) => onSelectChange(value, 'distribute_to')}
                                 // onChange={(value) => onChange(value, 'item_condition')}
                                 >
                                    {assignedTo && assignedTo.length > 0 && assignedTo.map((item) => {
                                       return (
                                          <Option key={item.value}>{item?.label}</Option>
                                       )
                                    })}
                                 </Select>
                                 <PlusCircleOutlined size={20} onClick={(e) => {
                                    setAssignToTextBox({
                                       ...assignToTextBox,
                                       assignTextInput: true
                                    })
                                 }} style={{ marginLeft: '10px', fontSize: '25px', color: 'green' }}
                                 />
                                 <CloseCircleOutlined onClick={(e) => {
                                    setAssignToTextBox({
                                       ...assignToTextBox,
                                       assignTextInput: false
                                    })
                                 }} style={{ marginLeft: '10px', fontSize: '25px', color: 'red' }}
                                 />
                              </div>
                              {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                           </Flex>
                           {assignToTextBox.assignTextInput === true ?
                              <div style={{ border: '1px dashed #d9d9d9', padding: '16px', borderRadius: '4px' }}>
                                 <Flex gap={10} justify="right" align="center">
                                    <label>Enter Name to Add </label>
                                    <div className="input-box-size">
                                       <Input className="input-box-size" placeholder="Enter Name to Add" name="assignTextInputValue" value={assignToTextBox.assignTextInputValue} style={{ width: '80%' }} onChange={(e) => {
                                          setAssignToTextBox({
                                             ...assignToTextBox,
                                             assignTextInputValue: e.target.value
                                          })
                                       }} />
                                       <CheckCircleOutlined onClick={handleAddAssignedTo} style={{ marginLeft: '10px', fontSize: '25px', color: 'green' }}
                                       />
                                    </div>
                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                 </Flex></div> : null}
                        </>
                        {/* : null} */}
                        <Flex gap={10} justify="right" align="center">
                           <label>Do you want this item: </label>
                           <div className="input-box-size">
                              <Checkbox name="distribute" value={itemDetails.distribute} checked={itemDetails.distribute === 0 ? false : true} onChange={(e) => {
                                 if (e.target.checked) {
                                    setItemDetails({
                                       ...itemDetails,
                                       distribute: 1
                                    })
                                 }
                                 else {
                                    setItemDetails({
                                       ...itemDetails,
                                       distribute: 0
                                    })
                                 }
                              }}></Checkbox>
                              <Select
                                 className="input-box-size mr-3"
                                 placeholder="Reason For Claim"
                                 style={{ width: '80%', marginLeft: '10px' }}
                                 value={itemDetails.claimed_as ? `${itemDetails.claimed_as}` : null}
                                 onChange={(value) => onSelectChange(value, 'claimed_as')}
                              >
                                 {itemClaimReasons && itemClaimReasons.length > 0 && itemClaimReasons.map((item) => {
                                    return (
                                       <Option key={item.value}>{item?.label}</Option>
                                    )
                                 })}
                              </Select>
                           </div>
                           {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                        </Flex>
                        <Flex gap={10} justify="right" align="center">
                           <label>General Notes :</label>
                           <TextArea
                              className="input-box-size" name="comment"
                              style={{ resize: "none" }}
                              rows={6} value={itemDetails.comment} onChange={(e) => { onItemValueChange(e) }}
                           />
                        </Flex>
                        <Flex justify="right">
                           <Checkbox
                              value={1}
                              checked={checkedValues.includes(1)}
                              onChange={(e) => onCheckboxChange(1, e.target.checked)}
                           >
                              Appraise
                           </Checkbox>
                           <Checkbox
                              value={2}
                              checked={checkedValues.includes(2)}
                              onChange={(e) => onCheckboxChange(2, e.target.checked)}
                           >
                              Donate
                           </Checkbox>
                           <Checkbox
                              value={3}
                              checked={checkedValues.includes(3)}
                              onChange={(e) => onCheckboxChange(3, e.target.checked)}
                           >
                              Sell
                           </Checkbox>
                        </Flex>
                        {/* {demoMode === true ? null : */}
                        <Flex className="my-1" gap={10} justify="flex-end">
                           <ButtonComponent
                              style={{ backgroundColor: "#f7931e", color: "white" }}
                              buttonName={itemDocuments.length ? "View Documents" : "Add Documents"}
                              icon={itemDocuments.length ? <FileTextOutlined /> : <UploadOutlined />}
                              onClick={showModal}
                           />
                           <ButtonComponent
                              style={{ backgroundColor: "#f7931e", color: "white" }}
                              buttonName="Save"
                              onClick={itemDetailsOnSave}
                              icon={<i className="fa fa-hdd-o" aria-hidden="true"></i>}
                           />
                           <ButtonComponent
                              style={{ backgroundColor: "#6E6E6E", color: "white" }}
                              buttonName="Clear"
                              onClick={itemDetailsOnClear}
                              icon={<i className="fa fa-refresh" aria-hidden="true"></i>}
                           />
                        </Flex>
                        {/* } */}
                     </Flex>
                     <Modal
                        title="Documents"
                        visible={isDocumentModalVisible}
                        onCancel={handleDocumentCancel}
                        style={{ top: 20, height: 400 }}
                        width={"80%"}
                        footer={[
                           <Button key="cancel" onClick={handleDocumentCancel}>
                              Cancel
                           </Button>,
                           <Button
                              key="upload"
                              type="primary"
                              icon={<UploadOutlined />}
                              onClick={handleUpload}
                              disabled={documentFileList.length === 0} // Disable upload button if no file is selected
                           >
                              Upload
                           </Button>,
                        ]}
                     >
                        {/* <input type="file" name="filefield"
                                            onChange={handleDocumentChange}
                                            multiple="multiple" /> */}
                        <Flex gap={10} justify="flex-start">
                           <Button
                              type="primary"
                              shape="round"
                              style={{ backgroundColor: "#f7931e" }}
                              onClick={() => {
                                 setIsAddDocument(true);
                                 setSelectedDocument({ id: "", path: "" });
                              }}>Add</Button>
                           {isAddDocument && itemDocuments?.length > 0 && <Button
                              type="primary"
                              shape="round"
                              style={{ backgroundColor: "#f7931e" }}
                              onClick={() => {
                                 setIsAddDocument(false);
                                 setSelectedDocument({ id: getDocumentsData[0]?.id, path: getDocumentsData[0]?.pdf_path });
                                 setDocumentFileList([]);
                              }}>Cancel</Button>}
                           {selectedDocument.id &&
                              <Button
                                 type="primary"
                                 style={{ backgroundColor: "#f7931e" }}
                                 shape="round"
                                 onClick={handleDocumentDelete}>Delete</Button>}
                        </Flex>
                        <div className={isAddDocument ? "w-50 mt-3 py-3" : "d-none"}>
                           <Upload
                              multiple
                              beforeUpload={() => false} // Prevent auto-upload
                              onChange={handleDocumentChange}
                              fileList={documentFileList}
                              accept="application/pdf" // Restrict to PDF files
                           >
                              <Button icon={<UploadOutlined />}>Select PDF File</Button>
                           </Upload>
                        </div>
                        {!isAddDocument &&
                           <Row className="mt-3">
                              <Col span={6}>
                                 <ol className="pe-2">
                                    {itemDocuments?.map(item => <li key={item.id} className="ps-1 py-1 fw-semibold rounded-2" style={{ backgroundColor: selectedDocument.path === item.pdf_path ? "#f7931e" : "", cursor: "pointer" }} onClick={() => setSelectedDocument({ id: item.id, path: item.pdf_path })
                                    }>{item.file_name}</li>)}
                                 </ol>
                              </Col>
                              <Col span={18} style={{ height: "60vh" }}>
                                 {selectedDocument.id &&
                                    <PDFViewer pdfUrl={assestURL.substring(0, assestURL.length - 1) + selectedDocument.path} />
                                 }
                              </Col>
                           </Row>}
                     </Modal>
                  </Card>
               </Col>
            </Row>
            :
            roleID === '3' && (photographerPermissionIds && photographerPermissionIds.length > 0 && photographerPermissionIds.includes(2) === false) ?
               <Card className="mt-2" style={{ height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <center style={{ fontSize: '16px', fontWeight: 'bold' }}>
                     No Items associated with {itemDetails.room_name}!
                  </center>
               </Card> :
               <>
                  <br />
                  <Collapse
                     bordered={false}
                     defaultActiveKey={['1']}
                     expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                  >
                     <Panel key="1" style={{ backgroundColor: 'white' }} header={
                        <strong>Upload Room Items</strong>
                     }
                     >
                        <Row gutter={[16, 16]}
                        // className={`p-3 ${!isAddItem && "d-none"}`}
                        >
                           <Col span={12}>
                              <Card
                                 title={
                                    <Space size={"middle"}>
                                       {/* <i className="fa fa-upload fa-1x" aria-hidden="true" /> */}
                                       <span>Upload Batch Items</span>
                                    </Space>
                                 }
                                 className="flex-grow-1"
                                 styles={{
                                    header: uploadCardHeaderStyleProp,
                                    body: { border: "thin solid #E0E0E0", borderTop: "none" },
                                 }}
                              >
                                 <div {...getBatchRootProps()} style={{ border: disableBatchItems === true ? '2px dashed grey' : '2px dashed #0087F7', padding: '20px', marginBottom: '20px', height: '160px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', cursor: disableBatchItems === true ? 'not-allowed' : 'default' }}>
                                    <input {...getBatchInputProps()} />
                                    <p className="ant-upload-drag-icon">
                                       <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                       Drag and drop images or click to select them from a file.<br />
                                       Select up to 30 images at once
                                    </p>
                                    <strong>Drop your files here</strong>
                                 </div>
                              </Card>
                           </Col>
                           <Col span={12}>
                              <Card
                                 title={
                                    <Space size={"middle"}>
                                       {/* <i className="fa fa-upload fa-2x" aria-hidden="true" /> */}
                                       <span>Upload Sequence Items</span>
                                    </Space>
                                 }
                                 styles={{
                                    header: uploadCardHeaderStyleProp,
                                    body: { border: "thin solid #E0E0E0", borderTop: "none" },
                                 }}
                              >
                                 <div {...getSequenceRootProps()} style={{ border: disableSequenceItems === true ? '2px dashed grey' : '2px dashed #0087F7', padding: '20px', marginBottom: '20px', height: '160px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', cursor: disableSequenceItems === true ? 'not-allowed' : 'default' }}>
                                    <input {...getSequenceInputProps()} />
                                    <p className="ant-upload-drag-icon">
                                       <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                       Drag and drop images or click to select them from a file.<br />
                                       Select up to 7 images at once
                                    </p>
                                    <strong>Drop your files here</strong>
                                 </div>
                              </Card>
                           </Col>
                        </Row>
                     </Panel>
                  </Collapse>
                  <br />
                  {/* {batchItemsColumnOrder?.length > 0 ?
                  <Collapse
                     bordered={false}
                     defaultActiveKey={['1']}
                     expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                  >
                     <Panel key="1" style={{ backgroundColor: 'white' }} header={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer' }}>
                           <div>
                              <><strong>Updated Images of Batch Items</strong> <p>(Total Number of Images : <strong>{batchFileList.length > 0 ? batchFileList.length : 0}</strong>)</p></>
                           </div>
                           {batchFileList && batchFileList.length > 0 ?
                              <div>
                                 <ButtonComponent
                                    style={{ backgroundColor: "#f7931e", color: "white" }}
                                    buttonName="Submit Changes"
                                    onClick={() => {
                                       setIsAddItem(!isAddItem)
                                       let postData = batchItemsColumnOrder
                                       console.log(postData, 'postData11');
                                       dispatch(addBatchItems({ postData }));
                                    }}
                                    icon={<CheckOutlined />}
                                 />
                              </div>
                              : null}
                        </div>
                     }
                     >
                        <DragDropContext onDragEnd={false}>
                           <Droppable droppableId="columns" direction="horizontal" type="COLUMN">
                              {(provided) => (
                                 <div ref={provided.innerRef} {...provided.droppableProps} style={containerStyle}>
                                    {batchFileList.map((column, index) => (
                                       <Draggable key={column.slot_number} draggableId={String(column.slot_number)} index={index} isDragDisabled={true}>
                                          {(provided) => (
                                             <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <Card className="rooms" style={columnStyle}>
                                                   <div className="col-md-12">
                                                      <div className="row">
                                                         <div className="col-md-12">
                                                            <div className="image-preview" style={{ textAlign: 'center' }}>
                                                               <img
                                                                  src={column?.preview}
                                                                  alt={column.name}
                                                                  style={{ width: '50%', height: '80px', objectFit: 'contain' }}
                                                               />
                                                            </div>
                                                            <div className="image-details" style={{ marginTop: '8px' }}>
                                                               {/* <Button
                                                                  type="danger"
                                                                  icon={<DeleteOutlined />}
                                                                  onClick={() => handleBatchItemDelete(column)}
                                                                  style={{ position: 'absolute', top: '8px', left: '8px', color: 'red', backgroundColor: 'white' }}
                                                               /> */}
                  {/* <p style={{ fontWeight: 'bold', fontSize: '14px', margin: '0' }}>{column.path}</p>
                                                               <Button
                                                                  type="danger"
                                                                  icon={<DeleteOutlined />}
                                                                  onClick={() => handleBatchItemDelete(column)}
                                                                  style={{ color: 'red', backgroundColor: 'white', position: 'absolute', top: '8px', right: '1px' }}
                                                               >
                                                               </Button>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </Card>
                                             </div>
                                          )}
                                       </Draggable>
                                    ))}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>
                        </DragDropContext>

                     </Panel>
                  </Collapse >
                  : null} */}
                  <br />
                  {
                     sequenceItemsColumnOrder?.length > 0 ?
                        <Collapse
                           bordered={false}
                           defaultActiveKey={['1']}
                           expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        >
                           <Panel key="1" style={{ backgroundColor: 'white' }} header={
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer' }}>
                                 <div>
                                    <strong>Sequence Items</strong>
                                 </div>
                                 {sequenceFileList && sequenceFileList.length > 0 ?
                                    <div>
                                       <ButtonComponent
                                          style={{ backgroundColor: "#f7931e", color: "white" }}
                                          buttonName="Submit Changes"
                                          icon={<CheckOutlined />}
                                          onClick={() => {
                                             setIsAddItem(!isAddItem)
                                             let postData = sequenceItemsColumnOrder
                                             console.log(postData, 'postt');
                                             dispatch(addSequenceItems({ postData }))
                                          }}
                                       />
                                    </div>
                                    : null}
                              </div>
                           }
                           >
                              <DragDropContext onDragEnd={onDragEndSequence}>
                                 <Droppable droppableId="columns" direction="horizontal" type="COLUMN">
                                    {(provided) => (
                                       <div ref={provided.innerRef} {...provided.droppableProps} style={containerStyle}>
                                          {sequenceItemsColumnOrder.map((column, index) => (
                                             <Draggable key={column.slot_number} draggableId={String(column.slot_number)} index={index}>
                                                {(provided) => (
                                                   <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                      {/* <div style={{ border: '1px solid #ccc', padding: '8px', margin: '8px', minWidth: '200px', backgroundColor: '#f0f0f0' }}> */}
                                                      <Card className="rooms" style={columnStyle}>
                                                         <div className="col-md-12">
                                                            <div className="row">
                                                               <div className="col-md-12">
                                                                  <div className="image-preview" style={{ textAlign: 'center' }}>
                                                                     <img
                                                                        src={column?.preview}
                                                                        alt={column.file_name}
                                                                        style={{ width: '50%', height: '80px', objectFit: 'contain' }}
                                                                     />
                                                                  </div>
                                                                  <div className="image-details" style={{ marginTop: '8px' }}>
                                                                     {/* <Button style={{ backgroundColor: '#49c5b6', height: '22px', width: '15px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="round">{index + 1}</Button> */}
                                                                     <Button
                                                                        type="danger"
                                                                        icon={<DeleteOutlined />}
                                                                        onClick={() => handleSequenceItemDelete(column)}
                                                                        style={{ position: 'absolute', top: '8px', left: '8px', color: 'red', backgroundColor: 'white' }}
                                                                     />
                                                                     <p style={{ fontWeight: 'bold', fontSize: '14px', margin: '0' }}>{column.file_name}</p>
                                                                     <Button
                                                                        // type="danger"
                                                                        // icon={<DeleteOutlined />}
                                                                        // onClick={() => handleDelete(column)}
                                                                        style={{ backgroundColor: '#f7931e', position: 'absolute', top: '8px', right: '1px' }}
                                                                     >{index + 1}</Button>

                                                                  </div>
                                                               </div>
                                                               {/* <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                   <Button style={{ backgroundColor: '#49c5b6', height: '22px', width: '15px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="round">{index + 1}</Button>
                                                </div> */}
                                                            </div>
                                                         </div>
                                                      </Card>
                                                      {/* </div> */}
                                                   </div>
                                                )}
                                             </Draggable>
                                          ))}
                                          {provided.placeholder}
                                       </div>
                                    )}
                                 </Droppable>
                              </DragDropContext>
                           </Panel>
                        </Collapse>
                        : null
                  }
               </>
         }
         <ModalComponent
            title="Upload Sequence Images"
            centered
            open={isModalOpen}
            onCancel={handleCancel}
            width="750px"
            footer={null}>
            {/* <div {...getAdditionalSequenceRootProps()} style={{ border: disableBatchItems === true ? '2px dashed grey' : '2px dashed #0087F7', padding: '20px', marginBottom: '20px', height: '160px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', cursor: disableBatchItems === true ? 'not-allowed' : 'default' }} >
               <input {...getAdditionalSequenceInputProps()} />
               <p className="ant-upload-drag-icon">
                  <UploadOutlined />
               </p>
               <p className="ant-upload-text">
                  Drag and drop multiple images of the same item or click to select them from a file. <br />
                  Drag or select up to 7 images at once.
               </p>
               <strong>Drop your files here</strong>
            </div>
            <Divider /> */}
            <DragDropContext onDragEnd={onDragEndCompleteSequence}>
               <Droppable droppableId="columns" direction="horizontal" type="COLUMN">
                  {(provided) => (
                     <div ref={provided.innerRef} {...provided.droppableProps} style={containerStyle}>
                        {updateSequenceArray.map((column, index) => (
                           <Draggable key={column.slot_number} draggableId={String(column.slot_number)} index={index}>
                              {(provided) => (
                                 <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    {/* <div style={{ border: '1px solid #ccc', padding: '8px', margin: '8px', minWidth: '200px', backgroundColor: '#f0f0f0' }}> */}
                                    <Card className="rooms" style={columnStyle}>
                                       <div className="col-md-12">
                                          <div className="row">
                                             <div className="col-md-12">
                                                <div className="image-preview" style={{ textAlign: 'center' }}>
                                                   <img
                                                      src={column?.sequence_image ? assestURL + column?.sequence_image : ''}
                                                      alt={column.sequence_image}
                                                      style={{ width: '100%', height: '125px' }}
                                                   />
                                                </div>
                                                <div className="image-details" style={{ marginTop: '8px' }}>
                                                   {/* <Button style={{ backgroundColor: '#49c5b6', height: '22px', width: '15px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="round">{index + 1}</Button> */}
                                                   <p style={{ fontWeight: 'bold', fontSize: '14px', margin: '0' }}>{ }</p>
                                                   <Button
                                                      // type="danger"
                                                      // icon={<DeleteOutlined />}
                                                      onClick={() => handleDelete(column)}
                                                      style={{ backgroundColor: '#f7931e', position: 'absolute', top: '8px', right: '1px' }}
                                                   >{index + 1}</Button>

                                                </div>
                                             </div>
                                             {/* <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                   <Button style={{ backgroundColor: '#49c5b6', height: '22px', width: '15px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="round">{index + 1}</Button>
                                                </div> */}
                                          </div>
                                       </div>
                                    </Card>
                                    {/* </div> */}
                                 </div>
                              )}
                           </Draggable>
                        ))}
                        {provided.placeholder}
                     </div>
                  )}
               </Droppable>
            </DragDropContext>
            <Divider />
            <div className="d-flex align-items-center justify-content-center">
               <ButtonComponent style={{ backgroundColor: '#f7931e', color: 'white' }}
                  onClick={saveSequenceChanges}
                  buttonName="Save Sequence Changes" icon={<i class="fa fa-file-text" aria-hidden="true"></i>} />&nbsp;
            </div>
         </ModalComponent>
         <ModalComponent
            title="Google Search Result"
            centered
            open={isGoogleModal}
            onCancel={handleGoogleCancel}
            width="90%"
            footer={null}>
            <Loader loading={googleSearchLoading} />
            <Flex justify="space-between" align="center">
               <Button
                  shape="round"
                  className="my-3 border-0"
                  style={{ backgroundColor: '#f7931e', color: 'white', }}
               >
                  <Checkbox checked={googleItemsWithPrice.length === selectedGoogleItemsPrice.length} disabled={googleItemsWithPrice?.length == 0} onChange={(e) => {
                     if (e.target.checked) {
                        let items = googleSearchResult && googleSearchResult.length && googleSearchResult[0].records && googleSearchResult[0].records.map(item => {
                           return item.price?.extracted_value ? item.price?.extracted_value : 0;
                        });
                        setSelectedGoogleItemsPrice([...items]);
                        setSelectedGoogleItemsIds([...selectAllGoogleItems]);
                     } else {
                        setSelectedGoogleItemsPrice([]);
                        setSelectedGoogleItemsIds([]);
                     }
                  }}>Select All</Checkbox>
               </Button>
               <Space size={"small"}>
                  <ButtonComponent style={{ backgroundColor: '#f7931e', color: 'white' }}
                     onClick={calculateGoogleAIEstimate}
                     buttonName="Save" icon={<i class="fa fa-file-text" aria-hidden="true"></i>} />
                  < ButtonComponent style={{ backgroundColor: '#f7931e', color: 'white' }}
                     onClick={handleGoogleCancel}
                     buttonName="Cancel" icon={<i class="fa fa-file-text" aria-hidden="true"></i>} />
               </Space>
            </Flex>
            <Row gutter={10}>
               <Col span={4}>
                  {googleSearchResult[0]?.image_url &&
                     <Image src={googleSearchResult[0].image_url} preview={false} style={{ maxHeight: 200 }} />
                  }
               </Col>
               <Col span={20} className="overflow-y-auto" style={{ maxHeight: 550, scrollbarWidth: "thin" }}>
                  <Row gutter={[10, 10]} justify={googleItemsWithPrice?.length == 0 ? "center" : "start"}>
                     {googleSearchResult?.length > 0 && googleSearchResult[0]?.records?.length > 0 && googleSearchResult[0].records.map((item, index) => (<Col sm={10} md={8} xl={6}>
                        <Card type="inner" className="h-100" title={`ITEM ${index + 1}`}
                           extra={item?.price?.extracted_value && <Checkbox checked={selectedGoogleItemsIds.includes(item.position)} onChange={(e) => {
                              if (e.target.checked) {
                                 setSelectedGoogleItemsIds(prev => [...prev, item.position]);
                                 setSelectedGoogleItemsPrice(prev => [...prev, item.price?.extracted_value]);
                              } else {
                                 let ids = selectedGoogleItemsIds.filter(r => r !== item.position);
                                 let items = selectedGoogleItemsPrice.filter(g => {
                                    if (g.position !== item.position) {
                                       return g.price?.extracted_value;
                                    }
                                 });
                                 setSelectedGoogleItemsIds([...ids]);
                                 setSelectedGoogleItemsPrice([...items]);
                              }
                           }} />}>
                           <div className="text-center">
                              <Image src={item.thumbnail} onClick={() => handleCardClick(item?.link)} height={130} />
                           </div>
                           <Divider />
                           <p className="mb-1"><span className="fw-bold">Price :</span> $ {item.price?.extracted_value}</p>
                           <p className="fw-bold mb-1">Description :</p>
                           <p>{item?.title}</p>
                        </Card>
                     </Col>))}
                     {!googleSearchLoading && googleItemsWithPrice?.length == 0 && <Empty
                        description={
                           <Typography.Title level={5}>
                              No results found
                           </Typography.Title>
                        }
                     />
                     }
                  </Row>
               </Col>
            </Row>
         </ModalComponent >
      </div >
   );
};

export default Rooms;

import { Card, Checkbox, Flex, Image, Avatar } from "antd";
import React from "react";
import { FaUser } from "react-icons/fa";
import { SiGooglelens } from "react-icons/si";
import googleLensIcon from "../../assets/images/googlelens.png";

const ReportItemCard = ({
   id,
   title,
   selectedItems,
   onChange,
   imageUrl,
   isAIEstimate,
   isClientEstimte,
}) => {
   return (
      <Card
         type="inner"
         size="small"
         title={title}
         style={{ maxHeight: 230 }}
         className="h-100"
         styles={{ header: { backgroundColor: "#e9ecef" } }}
         // styles={{ header: { backgroundColor: "#C0C0C0" }, body: { border: "thin solid #C0C0C0" } }}
         // className="h-100"
         //  style={{ height: 300 }}
         extra={<Checkbox key={id} checked={selectedItems[id]} onChange={onChange} />}
      >
         <div className="w-100 text-center">
            <Image
               src={imageUrl}
               preview={false}
               //    height={150}
               style={{ height: 110 }}
            />
         </div>
         <Flex justify="space-around" className="mt-4">
            {isAIEstimate && (
               <div>
                  <Avatar src={<img src={googleLensIcon} alt="google lens icon" />} />
               </div>
            )}
            {isClientEstimte && (
               <div>
                  <FaUser className="fs-5" />
               </div>
            )}
         </Flex>
      </Card>
   );
};

export default ReportItemCard;

import React, { useEffect, useState } from "react";
import { Table, Button, Tooltip, Space, Card, Select } from "antd";
import { HolderOutlined } from "@ant-design/icons";
import moment from "moment";
// import SwalComponent from "./SwalComponent"; // Assuming you have a SwalComponent
import { Link, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../view/pageheader";
import { useDispatch, useSelector } from "react-redux";
import { clearData, getDashboardJobsData, getStaffDashboardData, getStaffDashboardJobsData, loginSelector, updateJobStatus } from "../store/reducer/login";
import { deleteJob, jobStatus } from "../store/reducer/jobSlice";
import SwalComponent from "../fields/SwalComponent";
import TableComponent from "../fields/TableComponent";
import { getPhotographerList } from "../store/reducer/photographerSlice";
import _ from 'lodash'
import SelectComponent from "../fields/SelectComponent";
import { toast } from "react-toastify";

const DashboardJobList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { type } = useParams();
    const { dashboardJobsDatas,jobStatusResponse } = useSelector(loginSelector);
    const { photographerListData } = useSelector(state => state.photographer);
    const roleID = localStorage.getItem('role_id'); // Assuming roleID
    const staffId = localStorage.getItem('staff_id')
    const category_type = '3'; // Assuming category type
    const projectDetails = { project_id: 123, project_number: "PN-456" }; // Dummy project details
    const clientID = "client-789"; // Dummy client ID
    const jobStatusArray = [
        {
            label: 'Scheduled',
            value: 'scheduled'
        },
        {
            label: 'Ready to Edit/Build',
            value: 'ready_to_edit_or_build'
        },
        {
            label: 'Ready to QC',
            value: 'ready_to_qc'
        },
        {
            label: 'Ready to Deliver',
            value: 'ready_to_deliver'
        },
        {
            label: 'Delivered',
            value: 'delivered'
        }
    ]
    console.log(photographerListData,'photographerListData');
    

    const dataSource = [
        {
            key: "1",
            job_name: "Inventory Count",
            job_number: "JOB-001",
            search_count: 5,
            status: "Scheduled",
            job_date: "2024-08-28",
            due_date: "2024-09-05",
            assign_staff_name: "John Doe",
            job_id: "job-123",
        },
        {
            key: "2",
            job_name: "QC Inspection",
            job_number: "JOB-002",
            search_count: 10,
            status: "In Progress",
            job_date: "2024-08-30",
            due_date: null,
            assign_staff_name: "Jane Smith",
            job_id: "job-124",
        },
    ];
    console.log(dashboardJobsDatas,'dashboardJobsDatas');
    


    const onChange = (record,value, name) => {
        console.log(record,value, name, 'changes');
        let postData = {
            status:value
        }
        dispatch(updateJobStatus({ postData, job_id: record?.id }));
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const columns = [
        {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text, record, index) => (
                <>
                    &nbsp;<span>{index + 1}</span>
                </>
            ),
            sorter: false,
        },
        {
            title: "Job Name",
            dataIndex: "job_name",
            align: "center",
            sorter: true,
            render: (record) => <span>{record ? record : "-"}</span>,
        },
        {
            title: "Job #",
            dataIndex: "job_number",
            align: "center",
            sorter: true,
            render: (text, record) => (
                <Link to={`/projects/editjob/${btoa(record?.project_id)}/${btoa(record?.project?.client_id)}/${btoa(record?.id)}`}>
                    <span>{record.job_number}</span>
                </Link>
            ),
        },
        {
            title: "Search Count",
            dataIndex: "search_count",
            align: "center",
            sorter: true,
            render: (record) => <span>{record || 0}</span>,
        },
        {
            title: "Status",
            dataIndex: "status",
            align: "center",
            sorter: true,
            render: (text, record) => {
                return (
                    <Select
                    name="status"
                    value={record.status ? record.status : null}
                    placeholder="Choose status"
                    onChange={(value) => onChange(record,value, 'status')}
                    onSearch={onSearch}
                    options={jobStatusArray}
                    style={{ width: '80%' }}
                />
                )
            },
        },
        {
            title: "Job Date",
            dataIndex: "job_date",
            align: "center",
            sorter: true,
            render: (text, record) => (
                <span>{moment(record.job_date).format("MM/DD/YY")}</span>
            ),
        },
        {
            title: "Due Date",
            dataIndex: "due_date",
            align: "center",
            sorter: true,
            render: (text, record) => {
                return (
                    <span>
                        {record.due_date === null
                            ? "-"
                            : moment(record.due_date).format("MM/DD/YY")}
                    </span>
                );
            },
        },
        {
            title: "Assigned To",
            dataIndex: "assign_staff_name",
            align: "center",
            sorter: true,
            render: (text, record) => {
                // If status is "scheduled"
                if (record.status === "scheduled") {
                    // Split inventory_lead and get the IDs
                    const inventoryLeadIds = record.inventory_lead ? record.inventory_lead.split(',') : [];
    
                    // Accessing photographerListData.data for the array of photographers
                    const inventoryLeadNames = photographerListData?.data
                        ?.filter((photographer) => inventoryLeadIds.includes(photographer.id.toString()))
                        .map((photographer) => `${photographer.first_name} ${photographer.last_name}`)
                        .join(', ');
                    console.log(inventoryLeadNames,'inventoryLeadNames');
                    
                    return (
                        <Tooltip title={inventoryLeadNames ? inventoryLeadNames : "No photographer assigned"}>
                            <span>{inventoryLeadIds.length} {inventoryLeadIds.length === 1 ? 'person' : 'people'}</span>
                        </Tooltip>
                    );
                }
                
                // If status is not "scheduled"
                return <span>{record.assign_staff_name ? record.assign_staff_name : "-"}</span>;
            },
        },
        {
            title: "Action",
            align: "center",
            render: (text, record) => (
                <Space size="large" align="center">
                    <Tooltip title="Edit">
                        <span
                            className="fs-6"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                                console.log(record, "record")
                                localStorage.setItem('project_number', record.project_number);
                                navigate(
                                    `/project/editjob/${btoa(record.project_id)}/${btoa(record?.project?.client_id)}/${btoa(
                                        record.id
                                    )}`
                                );
                            }}
                        >
                            <i className="icon-pencil" />
                        </span>
                    </Tooltip>
                    {roleID === '2' && category_type === '3' ? null :
                        <SwalComponent
                            message="Are you sure you want to delete the job?"
                            onClickDispatch={deleteJob}
                            id={record.id}
                        />}
                </Space>
            ),
        },
    ];

    const getData = (payload = {}) => {
        console.log(payload,'payload');
        
        if (roleID === '1') {
            dispatch(getDashboardJobsData({ type: type,payload }))
        }
        else {
            dispatch(getStaffDashboardJobsData({ type: type, id: staffId,payload }))
        }
    }

    useEffect(() => {
        getData({})
    }, [])

    useEffect(()=>{
        if(jobStatusResponse === true){
            toast.success('Job Status Updated Successfully !')
            getData({})
            clearData()
        }
    },[jobStatusResponse])

    useEffect(() => {
        dispatch(getPhotographerList({}));
    }, [])

    console.log(dashboardJobsDatas, 'dashboardJobsDatas');

    return (
        <>
            <PageHeader 
            HeaderText="Jobs List" 
            backButtonUrl={`/dashboard`}
            />
            <Card>
                <TableComponent
                    columns={columns}
                    dataSource={dashboardJobsDatas?.data}
                    TotalDataCount={dashboardJobsDatas?.count}
                    onChangeDispatch={getData}
                />
            </Card>
        </>
    )
};

export default DashboardJobList;

import { Card, Col, Divider, Flex, Image, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { assestURL } from "../../services/config";
import { formatToDollars } from "../../utils/formatToDollars";

const FullViewCard = ({ sourceData, selectedFields, mainIndex, subIndex, itemCategories }) => {
   const portalUserID = localStorage.getItem("client_id");
   const [reportFieldsSchema] = useState({
      display_name: "UserName",
      name: "Item Name",
      client_value_range: "Client's Estimate",
      google_value_range: "AI Estimate",
      category: "Category",
      quantity: "Quantity",
      distribute_to: "Assign To",
      item_condition: "Condition",
      purchase_date: "Purchase Date",
      dispose_by: "Appraise",
      distribute: "Do you want this item",
      claimed_as: "Reason for Claim",
      model: "Model",
      serial_number: "Serial Number",
      notes: "Notes",
   });
   const imageContainerRef = useRef(null);
   const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
   const [sequenceImages, setSequenceImages] = useState([]);

   const [itemClaimReasons] = useState({
      1: "Pre-Marriage",
      2: "Gift",
      3: "Inherited",
      4: "Bought During Marriage",
      5: "Other - See Notes",
   });

   const [itemCondition] = useState({
      1: "Excellent",
      2: "Good",
      3: "Fair",
      4: "Poor",
      5: "Refurbished",
      6: "Repair",
   });

   // console.log("sourceData", sourceData);
   // console.log("portallUserID", portalUserID);

   useEffect(() => {
      const containerWidth = imageContainerRef.current.offsetWidth;
      const containerHeight = imageContainerRef.current.offsetHeight;

      console.log("container", containerHeight, containerWidth);

      const calculateImageDimensions = () => {
         // const numImages = cardData.length;
         const numImages = sourceData?.sequenceImages?.length;

         // Calculate the number of columns and rows to fit images within the container
         const columns = Math.ceil(Math.sqrt(numImages * (containerWidth / containerHeight)));
         const rows = Math.ceil(numImages / columns);

         // Calculate individual image dimensions
         const imageWidth = Math.floor(containerWidth / columns);
         const imageHeight = Math.floor(containerHeight / rows);

         setImageDimensions({ width: imageWidth, height: imageHeight });
      };

      if (imageDimensions.width === 0 || imageDimensions.height === 0) {
         calculateImageDimensions();
      }
   }, []);

   useEffect(() => {
      if (sourceData?.sequenceImages?.length > 1) {
         let images = [...sourceData?.sequenceImages];
         // let remove = images.splice(1);
         // console.log("remove", remove);

         let remove = images.filter(element => element.serial_no !== 1);

         setSequenceImages([...remove]);
      } else {
         setSequenceImages([]);
      }
   }, []);

   // Define inline styles as JavaScript objects
   const tableStyle = {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "20px",
      tableLayout: "fixed",
   };

   const cellStyle = {
      padding: "5px",
   };

   const labelStyle = {
      textAlign: "right",
      verticalAlign: "top",
      // width: 150
   };

   const valueStyle = {
      textAlign: "left",
      verticalAlign: "top",
   };

   // Function to create table rows from the data object
   const createTableRows = (fields, source) => {
      let data = {};
      const userData = source?.item_claim?.find(
         item => item.portal_user_id == Number(portalUserID)
      );

      if (userData?.portal_user_id) {
         const claimReason = itemClaimReasons[userData?.claimed_as];
         let formatData = {
            ...reportFieldsSchema,
            display_name: userData?.portalusers?.display_name,
            name: source.name,
            client_value_range: formatToDollars(userData?.estimated_value),
            google_value_range: formatToDollars(source.google_value_range),
            category: itemCategories[userData?.category],
            quantity: userData?.quantity,
            distribute_to: userData?.distribute_to,
            item_condition: itemCondition[userData?.item_condition],
            purchase_date: userData?.purchase_date
               ? new Date(userData?.purchase_date).toLocaleDateString()
               : "-",
            dispose_by: (userData?.dispose_by?.includes(1) && "Yes") || "No",
            distribute: userData?.distribute == 1 ? "Yes" : userData?.distribute == 0 ? "No" : "",
            claimed_as: claimReason,
            notes: userData?.comment,
            model: userData?.model,
            serial_number: userData?.serial_number,
         };

         data = formatData;
      } else {
         data = {
            ...sourceData,
            client_value_range: formatToDollars(0),
            google_value_range: formatToDollars(source?.google_value_range),
         };
      }

      // const keys = Object.keys(fields);
      const keys = fields;
      const rows = [];

      for (let i = 0; i < keys.length; i += 2) {
         const key1 = keys[i];
         const value1 = data[key1];
         const key2 = keys[i + 1];
         const value2 = data[key2];

         rows.push(
            <tr key={i}>
               <th style={{ ...cellStyle, ...labelStyle }}>{reportFieldsSchema[key1]} :</th>
               <td style={{ ...cellStyle, ...valueStyle }}>{value1}</td>
               {key2 ? (
                  <>
                     <th style={{ ...cellStyle, ...labelStyle }}>{reportFieldsSchema[key2]} :</th>
                     <td style={{ ...cellStyle, ...valueStyle }}>{value2}</td>
                  </>
               ) : (
                  <td colSpan="2" style={cellStyle} /> // Empty cell if there's no second label
               )}
            </tr>
         );
      }

      return rows;
   };

   return (
      <Card type="inner" className="my-2 reports-item">
         <Row
            data-main-index={mainIndex}
            data-sub-index={subIndex}
            gutter={12}
            className="w-100 pdf-image-container"
            style={{ height: window.screen.height * 0.4, maxHeight: window.screen.height * 0.4 }}
         >
            <Col span={12} className="h-100">
               <Flex className="h-100" justify="center" align="center">
                  <Image
                     src={assestURL + sourceData.item_image}
                     preview={false}
                     height="100%"
                     style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                     }}
                  />
               </Flex>
            </Col>
            <Col span={12}>
               <Row ref={imageContainerRef} gutter={[10, 10]} className="w-100 h-100" wrap>
                  {sequenceImages?.map((img, index) => (
                     <Col
                        key={index}
                        style={{
                           width: `${imageDimensions.width}px`,
                           height: `${imageDimensions.height}px`,
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           //  backgroundColor: "#f0f0f0",
                        }}
                     >
                        <Image
                           src={assestURL + img.sequence_image}
                           style={{
                              maxWidth: "100%",
                              maxHeight: `${imageDimensions.height}px`,
                              objectFit: "contain",
                           }}
                           preview={false}
                        />
                     </Col>
                  ))}
               </Row>
            </Col>
         </Row>
         <Divider />
         <div className="pdf-table-container">
            <table style={tableStyle} className="fs-6">
               <tbody>{createTableRows(selectedFields, sourceData)}</tbody>
            </table>
         </div>
      </Card>
   );
};

export default FullViewCard;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPanoItems, hotspotView, panoDelete, panoList, panoSort } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "panos";

// Async thunk for adding Pano items
export const addPanoItems = createAsyncThunk(
    `${namespace}/addPanoItems`,
    async ({ postData }, { rejectWithValue }) => {
        try {

            console.log(postData, 'postdataa==');
            const formData = new FormData();

            for (let i = 0; i < postData.length; i++) {
                const item = postData[i];
                formData.append(`Pano_images[${i}][binary_data]`, item.binary_data);
                formData.append(`Pano_images[${i}][file_name]`, item.file_name);
                formData.append(`Pano_images[${i}][room_id]`, item.room_id);
                formData.append(`Pano_images[${i}][job_id]`, item.jobid);
            }
            console.log(formData, 'formData');
            const data = await createPanoItems(formData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getPanoList = createAsyncThunk(
    `${namespace}/getPanoList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "", id = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await panoList(query, page, limit, sortby, order, id);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const saveHotspotView = createAsyncThunk(
    `${namespace}/saveHotspotView`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            console.log(postData, 'postData');
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await hotspotView({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const panoSequence = createAsyncThunk(
    `${namespace}/panoSequence`,
    async ({ datas }, { rejectWithValue }) => {
        try {
            console.log(datas, 'postData');
            let payload = EncryptDecrypt.encryptdata(datas, secretKey);
            console.log(payload, 'payload');
            const data = await panoSort({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const deletePano = createAsyncThunk(
    `${namespace}/jobDelete`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await panoDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const buildThreeSixty = createSlice({
    name: namespace,
    initialState: {
        addPanoItemLoading: false,
        addPanoItemsuccess: false,
        addPanoItemsError: false,
        addPanoItemErrorMessage: "",
        panoListData: [],
        panoListLoading: false,
        panoListSuccess: false,
        panoListError: false,
        hotspotViewSuccess: false,
        hotpotViewError: false,
        hotspotLoading: false,
        panoSequenceSuccess: false,
        panoSequenceError: false,
        panoDeleteSuccess: false,
        panoDeleteError:false
    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle add Pano Items
            .addCase(addPanoItems.pending, (state) => {
                state.addPanoItemLoading = true;
                state.addPanoItemsuccess = false;
                state.addPanoItemsError = false;
                state.addPanoItemErrorMessage = "";
            })
            .addCase(addPanoItems.fulfilled, (state, action) => {
                state.addPanoItemLoading = false;
                state.addPanoItemsuccess = true;
            })
            .addCase(addPanoItems.rejected, (state, action) => {
                state.addPanoItemLoading = false;
                state.addPanoItemsError = true;
                state.addPanoItemErrorMessage = action.error.message;
            })

            .addCase(getPanoList.pending, (state) => {
                state.panoListLoading = true;
                state.panoListSuccess = false;
                state.panoListError = false;
            })
            .addCase(getPanoList.fulfilled, (state, action) => {
                const data = action.payload;
                state.panoListData = data;
                state.panoListLoading = false;
                state.panoListSuccess = true;
            })
            .addCase(getPanoList.rejected, (state, action) => {
                state.panoListLoading = false;
                state.panoListError = true;
            })

            .addCase(saveHotspotView.pending, (state) => {
                state.hotspotLoading = true;
                state.hotspotViewSuccess = false;
                state.hotpotViewError = false;
            })
            .addCase(saveHotspotView.fulfilled, (state, action) => {
                state.hotspotLoading = false;
                state.hotspotViewSuccess = true;
                state.hotpotViewError = false;
            })
            .addCase(saveHotspotView.rejected, (state, action) => {
                state.hotspotLoading = false;
                state.hotpotViewError = true;
            })

            .addCase(panoSequence.pending, (state) => {
                state.panoSequenceSuccess = false;
                state.panoSequenceError = false;
            })
            .addCase(panoSequence.fulfilled, (state, action) => {
                state.panoSequenceSuccess = true;
                state.panoSequenceError = false;
            })
            .addCase(panoSequence.rejected, (state, action) => {
                state.panoSequenceError = true;
            })
            
            .addCase(deletePano.pending, (state) => {
                state.panoDeleteError = false;
                state.panoDeleteSuccess = false;
            })
            .addCase(deletePano.fulfilled, (state, action) => {
                state.panoDeleteSuccess = true;
                state.panoDeleteError = false;
            })
            .addCase(deletePano.rejected, (state, action) => {
                state.panoDeleteError = true;
            });
    },
});

export const { resetSuccessFlag } = buildThreeSixty.actions;

export const buildThreeSixtySelector = (state) => state[namespace];

export default buildThreeSixty.reducer;
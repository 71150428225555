import React, { useState } from 'react';
import { Card, Input, Divider } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const RoomCards = ({ id, room_name, totalRooms, onEdit, onDelete, onUpdate, onClick, isCompleted }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedRoomName, setEditedRoomName] = useState(room_name);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        onUpdate({ room_name: editedRoomName }, id);
        setIsEditing(false);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedRoomName(room_name);
    };

    return (
        <Card className='rooms' style={{ width: 280, height: '80px', margin: '5px 0', padding: '10px', borderRadius: '8px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {isEditing ? (
                    <Input
                        value={editedRoomName}
                        onChange={(e) => setEditedRoomName(e.target.value)}
                        style={{ width: '80%' }}
                    />
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <a
                            href='javascript:;'
                            style={{ fontSize: '16px', fontWeight: 'bold', cursor: 'pointer', flexGrow: 1 }}
                            onClick={onClick}
                        >
                            {room_name}
                        </a>
                        
                        {/* Conditionally render the CheckOutlined icon if isCompleted is true */}
                        {isCompleted && (
                            <CheckOutlined style={{ color: 'green', marginLeft: '10px',fontSize:'18px' }} />
                        )}
                    </div>
                )}
                <Divider type="vertical" />
            </div>

            {/* This section will always display the number of items */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
                <span style={{ fontSize: '14px' }}>Number of Items: {totalRooms}</span>

                {/* Conditionally render the edit and delete buttons based on onUpdate and onDelete */}
                {(onUpdate || onDelete) && (
                    <div style={{ display: 'flex' }}>
                        {!isEditing ? (
                            <>
                                {onUpdate && (
                                    <div className="icon-wrapper" onClick={handleEditClick}>
                                        <EditOutlined style={{ cursor: 'pointer' }} className="edit-icon" />
                                    </div>
                                )}
                                {onDelete && (
                                    <div className="icon-wrapper">
                                        <DeleteOutlined className="delete-icon" onClick={() => onDelete(id)} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="icon-wrapper">
                                    <CheckOutlined style={{ color: 'green', cursor: 'pointer' }} onClick={handleSaveClick} />
                                </div>
                                <div className="icon-wrapper">
                                    <CloseOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={handleCancelClick} />
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </Card>
    );
};

export default RoomCards;

import { EyeInvisibleOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import {
   Button,
   Card,
   Checkbox,
   Col,
   DatePicker,
   Form,
   Radio,
   Row,
   Space,
   Typography,
   Input,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import InputComponent from "../../fields/InputComponent";
import SelectComponent from "../../fields/SelectComponent";
import Loader from "../../fields/loader/Loader";
import {
   addPortalUser,
   getPortalUserData,
   updatePortalUser,
   getPortalUserList,
   getPortalUserJobs,
} from "../../store/reducer/portalUserSlice";
import { getProjectData } from "../../store/reducer/projectSlice";
import PageHeader from "../../view/pageheader";
import { TAG_COLOURS } from "../../utils/constants";

const AddPortalUser = () => {
   const { Title } = Typography;
   const {
      portalUserListData,
      addPortalUserLoading,
      addPortalUserError,
      addPortalUserSuccess,
      getAddedData,
      updatePortalUserErrorMessage,
      updatePortalUserSuccess,
      updatePortalUserError,
      addPortalUserErrorMessage,
      portalUserJobs,
   } = useSelector(state => state.portalUser);
   const { getAddedProjectData } = useSelector(state => state.project);
   const { pu_id, project_number, project_id, client_id } = useParams();
   const { pathname } = useLocation();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [dateFormat] = useState("YYYY/MM/DD");
   const [isEditPortalUser, setIsEditPortalUser] = useState(false);
   const [pageTitle, setPageTitle] = useState("Add Portal User");
   const [triggerValidator, setTriggerValidator] = useState(false);
   const [selectAllJobsData, setSelectAllJobsData] = useState({});
   const [availableIconTags, setAvailableIconTags] = useState(Object.keys(TAG_COLOURS));
   const [projectType, setProjectType] = useState("");
   const [status] = useState([
      {
         value: 1,
         label: "Active",
      },
      {
         value: 0,
         label: "Inactive",
      },
   ]);

   const [portalUserTypesSchema] = useState([
      {
         value: 1,
         label: "Read & Write",
      },
      {
         value: 2,
         label: "Judge / Executor",
      },
      {
         value: 3,
         label: "Read-only",
      },
      {
         value: 4,
         label: "Manager",
      },
   ]);

   const [portalUserTypes, setPortalUserTypes] = useState([]);

   const [userDetailSchema] = useState({
      role_id: 4,
      user_id: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      display_name: "",
      username: "",
      login_expires_on: "",
      pu_type: 1,
      icon_type: 0,
      claimed_user: 0,
      jobs: [],
      rooms: [],
      tours: [],
      status: 1,
      project_id: atob(project_id),
   });

   const [userDetailsArray, setUserDetailsArray] = useState({ ...userDetailSchema });

   useEffect(() => {
      const payload = { id: atob(project_id) };
      dispatch(getPortalUserList(payload));
      dispatch(getProjectData({ id: atob(project_id) }));
      dispatch(getPortalUserJobs({ id: atob(project_id) }));
      // getProjectData();
   }, []);

   useEffect(() => {
      // console.log("portalUSerJobs", portalUserJobs);

      if (portalUserJobs) {
         const jobs = portalUserJobs.map(job => ({ job_id: job.id }));
         const rooms = portalUserJobs.flatMap(item =>
            item.rooms.map(room => ({ job_id: room.job_id, room_id: room.room_id }))
         );
         const tours = portalUserJobs.flatMap(item =>
            item.rooms.map(room =>
               room.pano_datas.map(tour => ({ job_id: tour.job_id, tour_id: tour.pano_id }))
            )
         );

         const data = {
            jobs,
            rooms,
            tours: tours.flat(1),
         };

         setSelectAllJobsData(data);

         if (!isEditPortalUser) {
            setUserDetailsArray({
               ...userDetailsArray,
               ...data,
            });
         }
      }
   }, [portalUserJobs]);

   useEffect(() => {
      const validateID = ["undefined", "null", ""];
      setIsEditPortalUser(pathname.includes("edit-portaluser"));
      const ID = pu_id ? atob(pu_id) : "";
      if (
         ID !== null &&
         ID !== undefined &&
         !validateID.includes(ID) &&
         !Number.isNaN(Number(ID))
      ) {
         dispatch(getPortalUserData({ id: ID }));
      }
   }, [pu_id]);

   useEffect(() => {
      // console.log("getUserData", getAddedData);

      if (isEditPortalUser) {
         const data = { ...getAddedData };
         delete data.trash;
         delete data.token;
         setPageTitle("Edit Portal User");
         setUserDetailsArray({
            ...userDetailsArray,
            id: getAddedData.id,
            role_id: 4,
            user_id: getAddedData.user_id,
            first_name: getAddedData.first_name,
            last_name: getAddedData.last_name,
            email: getAddedData.email,
            password: "",
            confirm_password: "",
            display_name: getAddedData.display_name,
            username: getAddedData.username,
            login_expires_on: getAddedData.login_expires_on,
            pu_type: getAddedData.pu_type,
            icon_type: getAddedData.icon_type,
            claimed_user: getAddedData.claimed_user,
            jobs: getAddedData.portaluser_jobs,
            rooms: getAddedData.portaluser_room,
            tours: getAddedData.portaluser_tour,
            status: getAddedData.status,
         });
         setTriggerValidator(!triggerValidator);
      } else {
         setUserDetailsArray({
            ...userDetailSchema,
         });
      }
   }, [isEditPortalUser, getAddedData]);

   useEffect(() => {
      if (addPortalUserSuccess) {
         toast.success("User added Successfully");
         navigate(`/project/portalusers/${project_number}/${project_id}/${client_id}`);
      }
      if (addPortalUserError) {
         toast.error(addPortalUserErrorMessage);
      }

      if (updatePortalUserSuccess) {
         toast.success("User updated successfully");
         navigate(`/project/portalusers/${project_number}/${project_id}/${client_id}`);
         // navigate(`/projects/portalusers/${btoa(atob(project_number))}/${btoa(atob(project_id))}`);
      }

      if (updatePortalUserError) {
         toast.error(updatePortalUserErrorMessage);
      }
   }, [addPortalUserSuccess, addPortalUserError, updatePortalUserSuccess, updatePortalUserError]);

   useEffect(() => {
      if (portalUserListData?.data && getAddedProjectData?.project_type) {
         const filterUsedTags = portalUserListData.data.map(item => item.icon_type);

         if (filterUsedTags?.length) {
            let availableTags = Object.keys(TAG_COLOURS).filter(
               item => !filterUsedTags.includes(Number(item))
            );

            setAvailableIconTags(availableTags);

            // console.log(Object.keys(TAG_COLOURS));
            // console.log(filterUsedTags);
            // console.log("availableTags", availableTags);
         }
      }

      if (getAddedProjectData?.project_type) {
         setProjectType(getAddedProjectData.project_type);
         // console.log("getAddedProjectData", getAddedProjectData);
      }
   }, [portalUserListData, getAddedProjectData]);

   useEffect(() => {
      // console.log("projectType", projectType);
      // console.log("projectData", getAddedProjectData);

      if (projectType && !isEditPortalUser) {
         const isManagerExist = portalUserListData.data.some(item => item.pu_type === 4);
         let filterUsers = [];

         if (isManagerExist) {
            // console.log("isManagerExist");
            filterUsers = portalUserTypesSchema.filter(item => item.value !== 4);
         } else {
            filterUsers = [...portalUserTypesSchema];
         }

         // console.log("filteredUser", filterUsers);

         if (projectType === 1 || projectType === 2) {
            setPortalUserTypes(filterUsers);
         } else {
            let pu_types = filterUsers.filter(item => item.value !== 2);
            setPortalUserTypes(pu_types);
         }
      } else if (projectType) {
         if (projectType === 1 || projectType === 2) {
            setPortalUserTypes([...portalUserTypesSchema]);
         } else {
            let pu_types = portalUserTypesSchema.filter(item => item.value !== 2);
            setPortalUserTypes(pu_types);
         }
      }
   }, [projectType]);

   const validator = useRef(
      new SimpleReactValidator({
         className: "validation-message",
         messages: {
            // required: "Please enter :attribute",
            email: "Email should contain @ and .",
            alpha: ":attribute should contain only alphabets",
            alpha_num: "Please select :attribute",
            regex: ":attribute format is invalid",
            in: "Confirm password does not match with password field",
            min: ":attribute must be at least :min characters",
            confirmed: "Passwords do not match",
            strong_password:
               "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character",
            no_spaces: "Spaces are not allowed in the :attribute.",
         },
         validators: {
            strong_password: {
               // name the validator
               message:
                  "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character",
               rule: (val, params, validator) => {
                  return (
                     validator.helpers.testRegex(
                        val,
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                     ) && params.indexOf(val) === -1
                  );
               },
               // required: true, // This is an optional parameter. If true, the field will be required.
            },
            no_spaces: {
               message: "Spaces are not allowed in the :attribute.",
               rule: val => !/\s/.test(val), // Checks for spaces
            },
         },
      })
   );

   const validationRules = {
      first_name: `required`,
      last_name: `alpha_num`,
      display_name: `required`,
      username: `required|no_spaces|alpha_num`,
      email: `required|email`,
      password: !isEditPortalUser ? `required|strong_password` : `strong_password`,
      confirm_password: !isEditPortalUser
         ? `required|in:${userDetailsArray.password}`
         : `in:${userDetailsArray.password}`,
      login_expires_on: `alpha`,
      status: `required|alpha_num`,
      pu_type: `required|alpha_num`,
      icon_type:
         userDetailsArray.pu_type === 2 || userDetailsArray.pu_type === 4
            ? `alpha_num`
            : `required|alpha_num`,
      claimed_user: `required|alpha_num`,
   };

   const handleOnChange = e => {
      const { name, value } = e.target;
      setUserDetailsArray(prevState => ({
         ...prevState,
         [name]: value,
      }));
   };

   const handleSelectOnChange = (name, value) => {
      if (name === "pu_type" && value === 2) {
         setUserDetailsArray(prevState => ({
            ...prevState,
            [name]: value,
            // icon_type: 0,
         }));
         return;
      }

      console.log(name, value);

      setUserDetailsArray(prevState => ({
         ...prevState,
         [name]: value,
      }));
   };

   const handleSubmit = e => {
      e.preventDefault();
      // console.log("userData", userDetailsArray);
      if (validator.current.allValid()) {
         let postData = userDetailsArray;
         // console.log(postData, "postt");
         if (isEditPortalUser) {
            const updateID = atob(pu_id);
            dispatch(updatePortalUser({ postData, updateID }));
         } else {
            delete postData.user_id;
            dispatch(addPortalUser({ postData }));
         }
      } else {
         validator.current.showMessages();
         setTriggerValidator(!triggerValidator);
      }
   };

   const handleSelectAllJobs = e => {
      if (e.target.checked) {
         setUserDetailsArray(prevState => ({
            ...prevState,
            ...selectAllJobsData,
         }));
      } else {
         setUserDetailsArray(prevState => ({
            ...prevState,
            jobs: [],
            rooms: [],
            tours: [],
         }));
      }
   };

   const handleCheckBoxOnChange = (data, key, checked = false) => {
      let values;
      const objKey =
         key === "jobs"
            ? "job_id"
            : key === "rooms"
            ? "room_id"
            : key === "tours"
            ? "tour_id"
            : false;

      if (objKey !== false) {
         if (key === "jobs") {
            if (checked) {
               const selected = { [objKey]: data.id };
               values = [...userDetailsArray.jobs, selected];
               let filterRooms = userDetailsArray.rooms.filter(room => room.job_id !== data.id);
               let roomsData = data.rooms.map(room => ({
                  job_id: room.job_id,
                  room_id: room.room_id,
               }));
               setUserDetailsArray(prevState => ({
                  ...prevState,
                  [key]: values,
                  rooms: [...filterRooms, ...roomsData],
               }));
            } else {
               console.log("unchecked", checked, "data", data);
               let filterJobs = userDetailsArray.jobs.filter(job => job.job_id !== data.id);
               let filterRooms = userDetailsArray.rooms.filter(room => room.job_id !== data.id);
               let filterTours = userDetailsArray.tours.filter(tour => tour.job_id !== data.id);

               // console.log("filteredTours", filterTours);

               setUserDetailsArray(prevState => ({
                  ...prevState,
                  [key]: [...filterJobs],
                  rooms: [...filterRooms],
                  tours: [...filterTours],
               }));
            }
            return;
         }

         if (key === "rooms") {
            if (checked) {
               const selected = { job_id: data.job_id, [objKey]: data.room_id };
               values = [...userDetailsArray.rooms, selected];
               let isJobChecked = userDetailsArray.jobs.some(job => job.job_id === data.job_id);
               let addJob;
               if (isJobChecked) {
                  setUserDetailsArray(prevState => ({
                     ...prevState,
                     [key]: values,
                  }));
               } else {
                  addJob = { job_id: data.job_id };
                  setUserDetailsArray(prevState => ({
                     ...prevState,
                     [key]: values,
                     jobs: [...userDetailsArray.jobs, addJob],
                  }));
               }
            } else {
               let filterData = userDetailsArray.rooms.filter(
                  room => room[objKey] !== data[objKey]
               );
               let isJobChecked = filterData.some(job => job.job_id === data.job_id);

               if (isJobChecked) {
                  setUserDetailsArray(prevState => ({
                     ...prevState,
                     [key]: [...filterData],
                  }));
               } else {
                  let filterJobs = userDetailsArray.jobs.filter(job => job.job_id !== data.job_id);
                  let filterTours = userDetailsArray.jobs.filter(
                     tour => tour.job_id !== data.job_id
                  );
                  setUserDetailsArray(prevState => ({
                     ...prevState,
                     [key]: [...filterData],
                     jobs: [...filterJobs],
                     tours: [...filterTours],
                  }));
               }
            }
            return;
         }

         if (key === "tours") {
            // console.log("tourData", data);
            values = { job_id: data.job_id, [objKey]: data.pano_id };
            const checkJobSelected = userDetailsArray.jobs.some(job => job.job_id === data.job_id);

            if (!checkJobSelected) {
               toast.warning("Please select the job first");
               return;
            }

            if (checked) {
               values = [...userDetailsArray.tours, values];
            } else {
               let filterData = userDetailsArray.tours.filter(
                  tour => tour[objKey] !== values[objKey]
               );
               values = [...filterData];
            }

            setUserDetailsArray(prevState => ({
               ...prevState,
               [key]: values,
            }));
         }
      }
   };

   const headerStyleProp = { backgroundColor: "#f7931e", color: "white" };

   return (
      <div className="pe-2 mb-3">
         <Loader loading={addPortalUserLoading} />
         <PageHeader
            HeaderText={pageTitle}
            Breadcrumb={[{ name: pageTitle }]}
            backButtonUrl={`/project/portalusers/${project_number}/${project_id}/${client_id}`}
         />
         <Card title={`${pageTitle} : ${atob(project_number)}`}>
            <Row gutter={16}>
               <Col span={8}>
                  <Card type="inner">
                     <Form>
                        <div>
                           <label htmlFor="first_name">First Name</label>
                           <span className="m-1" style={{ color: "red" }}>
                              *
                           </span>
                           <InputComponent
                              name="first_name"
                              placeholder="First Name"
                              type="text"
                              icon={<UserOutlined />}
                              value={userDetailsArray.first_name}
                              onChange={handleOnChange}
                              validator={validator}
                              // touchedFields={touchedFields}
                              validationRules={validationRules}
                           />
                        </div>
                        <div className="mt-2">
                           <label htmlFor="last_name">Last Name</label>
                           <InputComponent
                              name="last_name"
                              placeholder="Last Name"
                              type="text"
                              icon={<UserOutlined />}
                              value={userDetailsArray.last_name}
                              onChange={handleOnChange}
                              validator={validator}
                              // touchedFields={touchedFields}
                              validationRules={validationRules}
                           />
                        </div>
                        <div className="mt-2">
                           <label htmlFor="display_name">Display Name</label>
                           <span className="m-1" style={{ color: "red" }}>
                              *
                           </span>
                           <InputComponent
                              name="display_name"
                              placeholder="Display Name"
                              type="text"
                              icon={<UserOutlined />}
                              value={userDetailsArray.display_name}
                              onChange={handleOnChange}
                              validator={validator}
                              // touchedFields={touchedFields}
                              validationRules={validationRules}
                           />
                        </div>
                        <div className="mt-2">
                           <label htmlFor="user_name">User Name</label>
                           <span className="m-1" style={{ color: "red" }}>
                              *
                           </span>
                           <InputComponent
                              name="username"
                              placeholder="User Name"
                              type="text"
                              icon={<UserOutlined />}
                              value={userDetailsArray.username}
                              onChange={handleOnChange}
                              validator={validator}
                              // touchedFields={touchedFields}
                              validationRules={validationRules}
                           />
                        </div>
                        <div className="mt-2">
                           <label htmlFor="email">Email</label>
                           <span className="m-1" style={{ color: "red" }}>
                              *
                           </span>
                           <InputComponent
                              name="email"
                              placeholder="Email Address"
                              type="text"
                              icon={<MailOutlined />}
                              value={userDetailsArray.email}
                              onChange={handleOnChange}
                              validator={validator}
                              // touchedFields={touchedFields}
                              validationRules={validationRules}
                           />
                        </div>
                        <div className="mt-2">
                           <label htmlFor="password">Password</label>
                           {!isEditPortalUser && (
                              <span className="m-1" style={{ color: "red" }}>
                                 *
                              </span>
                           )}
                           {/* <InputComponent
                              name="password"
                              placeholder="Password"
                              type="text"
                              icon={<EyeInvisibleOutlined />}
                              value={userDetailsArray.password}
                              onChange={addEditUserDetails}
                              validator={validator}
                              // touchedFields={touchedFields}
                              validationRules={validationRules}
                           /> */}
                           <Input.Password
                              autoComplete="new-password"
                              //   type={type}
                              placeholder={"Enter password"}
                              name={"password"}
                              style={{
                                 padding: "5px",
                              }}
                              value={userDetailsArray.password}
                              onChange={handleOnChange}
                              suffix={<EyeInvisibleOutlined />}
                           />
                           {validationRules["password"] && (
                              <div style={{ color: "red" }}>
                                 {validator.current.message(
                                    "password",
                                    userDetailsArray.password,
                                    validationRules["password"]
                                 )}
                              </div>
                           )}
                        </div>
                        <div className="mt-2">
                           <label htmlFor="confirm_password">Confirm Password</label>
                           {!isEditPortalUser && (
                              <span className="m-1" style={{ color: "red" }}>
                                 *
                              </span>
                           )}
                           {/* <InputComponent
                              name="confirm_password"
                              placeholder="Confirm Password"
                              type="text"
                              icon={<EyeInvisibleOutlined />}
                              value={userDetailsArray.confirm_password}
                              onChange={addEditUserDetails}
                              validator={validator}
                              // touchedFields={touchedFields}
                              validationRules={validationRules}
                           /> */}
                           <Input.Password
                              autoComplete="new-password"
                              //   type={type}
                              placeholder={"Confirm Password"}
                              name={"confirm_password"}
                              style={{
                                 padding: "5px",
                              }}
                              value={userDetailsArray.confirm_password}
                              onChange={handleOnChange}
                              suffix={<EyeInvisibleOutlined />}
                           />
                           {validationRules["confirm_password"] && (
                              <div style={{ color: "red" }}>
                                 {validator.current.message(
                                    "confirm_password",
                                    userDetailsArray.confirm_password,
                                    validationRules["confirm_password"]
                                 )}
                              </div>
                           )}
                        </div>
                        <div className="mt-2">
                           <label htmlFor="login_expires">Login Expires On</label>
                           <br />
                           <DatePicker
                              // allowClear={false}
                              name="login_expires_on"
                              className="w-100"
                              value={
                                 userDetailsArray.login_expires_on
                                    ? dayjs(userDetailsArray.login_expires_on, dateFormat)
                                    : ""
                              }
                              // onChange={addEditUserDetails}
                              onChange={(date, dateString) => {
                                 setUserDetailsArray(prevState => ({
                                    ...prevState,
                                    login_expires_on: dateString,
                                 }));
                              }}
                              // validator={validator}
                              // touchedFields={touchedFields}
                              // validationRules={validationRules}
                           />
                        </div>
                        <div className="mt-2">
                           <label htmlFor="status">Status</label>
                           <span className="m-1" style={{ color: "red" }}>
                              *
                           </span>
                           <br />
                           <SelectComponent
                              name="status"
                              placeholder="Status"
                              options={status}
                              value={userDetailsArray.status}
                              onChange={value => handleSelectOnChange("status", value)}
                              validator={validator}
                              // touchedFields={touchedFields}
                              validationRules={validationRules}
                           />
                        </div>
                        <div className="mt-2">
                           <label htmlFor="type">Type</label>
                           <span className="m-1" style={{ color: "red" }}>
                              *
                           </span>
                           <br />
                           <SelectComponent
                              name="pu_type"
                              placeholder="Type"
                              options={portalUserTypes}
                              value={userDetailsArray.pu_type}
                              onChange={value => handleSelectOnChange("pu_type", value)}
                              validator={validator}
                              // touchedFields={touchedFields}
                              validationRules={validationRules}
                           />
                        </div>
                        <div
                           className={
                              userDetailsArray.pu_type && userDetailsArray.pu_type !== 2
                                 ? "mt-2"
                                 : "d-none"
                           }
                        >
                           <label htmlFor="icon_tag">Icon Tag</label>
                           {!isEditPortalUser && userDetailsArray.pu_type !== 4 && (
                              <span className="m-1" style={{ color: "red" }}>
                                 *
                              </span>
                           )}
                           <Card type="inner" style={{ minHeight: 30 }}>
                              <Radio.Group
                                 disabled={isEditPortalUser}
                                 name="icon_type"
                                 value={userDetailsArray.icon_type}
                                 onChange={handleOnChange}
                                 validator={validator}
                                 // touchedFields={touchedFields}
                                 validationRules={validationRules}
                              >
                                 {isEditPortalUser
                                    ? Object.keys(TAG_COLOURS).map((item, index) => (
                                         <Radio
                                            key={item}
                                            value={Number(item)}
                                            className={`${index > 5 ? "mt-2" : ""}`}
                                         >
                                            <i
                                               className="fa fa-tag fs-4 mt-2"
                                               style={{
                                                  color: TAG_COLOURS[item],
                                               }}
                                               aria-hidden="true"
                                            ></i>
                                         </Radio>
                                      ))
                                    : availableIconTags.map((item, index) => (
                                         <Radio
                                            key={item}
                                            value={Number(item)}
                                            className={`${index > 5 ? "mt-2" : ""}`}
                                         >
                                            <i
                                               className="fa fa-tag fs-4 mt-2"
                                               style={{
                                                  color: TAG_COLOURS[item],
                                               }}
                                               aria-hidden="true"
                                            ></i>
                                         </Radio>
                                      ))}
                              </Radio.Group>
                           </Card>
                           <div style={{ color: "red" }}>
                              {validator.current.message(
                                 "icon_type",
                                 userDetailsArray.icon_type,
                                 validationRules["icon_type"]
                              )}
                           </div>
                        </div>
                        {(projectType === 1 || projectType === 2) && (
                           <div className="mt-3 mb-1">
                              <label htmlFor="show_claimed_user">Show claimed user</label>
                              <span className="m-1" style={{ color: "red" }}>
                                 *
                              </span>
                              <Card type="inner">
                                 <Radio.Group
                                    name="claimed_user"
                                    value={userDetailsArray.claimed_user}
                                    onChange={handleOnChange}
                                    validator={validator}
                                    // touchedFields={touchedFields}
                                    validationRules={validationRules}
                                 >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={0}>No</Radio>
                                 </Radio.Group>
                              </Card>
                              <div style={{ color: "red" }}>
                                 {validator.current.message(
                                    "claimed_user",
                                    userDetailsArray.claimed_user,
                                    validationRules["claimed_user"]
                                 )}
                              </div>
                           </div>
                        )}
                        <Button
                           block
                           type="text"
                           shape="round"
                           className="mt-2"
                           style={{ backgroundColor: "#f7931e", color: "white" }}
                           icon={<i className="fa fa-hdd-o" aria-hidden="true"></i>}
                           onClick={handleSubmit}
                        >
                           Save
                        </Button>
                     </Form>
                  </Card>
               </Col>
               <Col span={16}>
                  <Row gutter={16}>
                     <Col span={8}>
                        <Card
                           title={
                              <span>
                                 <i className="fa fa-briefcase me-2" aria-hidden="true" />
                                 JOBS
                              </span>
                           }
                           styles={{
                              header: headerStyleProp,
                              body: { padding: 10, paddingLeft: 15 },
                           }}
                           style={{ minHeight: "35vh" }}
                        >
                           <Space direction="vertical">
                              <Checkbox
                                 className="mb-1"
                                 onChange={handleSelectAllJobs}
                                 checked={portalUserJobs.length === userDetailsArray?.jobs?.length}
                              >
                                 All Jobs
                              </Checkbox>
                              <Checkbox.Group
                                 name="jobs"
                                 value={userDetailsArray["jobs"]?.map(({ job_id }) => job_id)}
                              >
                                 <Space direction="vertical">
                                    {portalUserJobs.map(job => (
                                       <Checkbox
                                          key={job.id}
                                          className="fw-normal"
                                          value={job.id}
                                          onChange={e =>
                                             handleCheckBoxOnChange(job, "jobs", e.target.checked)
                                          }
                                       >
                                          {job.job_number}
                                       </Checkbox>
                                    ))}
                                 </Space>
                              </Checkbox.Group>
                           </Space>
                        </Card>
                     </Col>
                     <Col span={8}>
                        <Card
                           title={
                              <span>
                                 <i className="fa fa-building me-2" aria-hidden="true" />
                                 ROOMS
                              </span>
                           }
                           styles={{
                              header: headerStyleProp,
                              body: { padding: 10, paddingLeft: 15 },
                           }}
                           style={{ minHeight: "35vh" }}
                        >
                           <Space direction="vertical">
                              <Checkbox.Group
                                 name="rooms"
                                 value={userDetailsArray["rooms"]?.map(({ room_id }) => room_id)}
                              >
                                 {portalUserJobs?.map((job, index) => {
                                    return (
                                       <Space key={job.id} direction="vertical" className="w-100">
                                          <p
                                             className={`fw-bold mb-1 ${index > 0 && "mt-2"}`}
                                          >{`${job.job_name} - ${job.job_number}`}</p>
                                          {job.rooms.map(room => (
                                             <Checkbox
                                                key={room.room_id}
                                                className="fw-normal"
                                                value={room.room_id}
                                                onChange={e =>
                                                   handleCheckBoxOnChange(
                                                      room,
                                                      "rooms",
                                                      e.target.checked
                                                   )
                                                }
                                             >
                                                {room.room_name}
                                             </Checkbox>
                                          ))}
                                       </Space>
                                    );
                                 })}
                              </Checkbox.Group>
                           </Space>
                        </Card>
                     </Col>
                     <Col span={8}>
                        <Card
                           title={
                              <span>
                                 {/* <i className="fa fa-location-arrow me-2" aria-hidden="true" /> */}
                                 <i className="fa fa-globe me-2" aria-hidden="true"></i>
                                 360 View
                              </span>
                           }
                           styles={{
                              header: headerStyleProp,
                              body: { padding: 10, paddingLeft: 15 },
                           }}
                           style={{ minHeight: "35vh" }}
                        >
                           <Space direction="vertical">
                              <Checkbox.Group
                                 name="tours"
                                 value={userDetailsArray["tours"]?.map(({ tour_id }) => tour_id)}
                              >
                                 {portalUserJobs?.map((job, index) => {
                                    return (
                                       <Space key={job.id} direction="vertical" className="w-100">
                                          <p
                                             className={`fw-bold mb-1 ${index > 0 && "mt-2"}`}
                                          >{`${job.job_name} - ${job.job_number}`}</p>
                                          {job.rooms?.map(pano => {
                                             return pano?.pano_datas.map(tour => (
                                                <Checkbox
                                                   key={tour.pano_id}
                                                   className="fw-normal"
                                                   value={tour.pano_id}
                                                   onChange={e =>
                                                      handleCheckBoxOnChange(
                                                         tour,
                                                         "tours",
                                                         e.target.checked
                                                      )
                                                   }
                                                >
                                                   {tour.pano_name}
                                                </Checkbox>
                                             ));
                                          })}
                                       </Space>
                                    );
                                 })}
                              </Checkbox.Group>
                           </Space>
                        </Card>
                     </Col>
                  </Row>
               </Col>
            </Row>
         </Card>
      </div>
   );
};

export default AddPortalUser;

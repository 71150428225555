import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { portalUserList, createPortalUser, editPortalUser, getPortalUserDataByID, updatePortalUserStatus, portalUserDelete, getPortalUserJobDetails, getClientUserJobsById, getClientUserRoomsById, getClientUserPanosById, updateClientClaimDetails, getClientClaimDetailsById, getExecutorUserList, getExecutorClaimList, executorClaimSettle, addItemDocuments, getItemDocuments, getClientUserProjectById, createAssignedTo, getAssignedToById, deleteItemDocuments, getDemoClientUserJobsById, getDemoClientUserRoomsById } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "portalUser";

// Async thunk for fetching the Portal User list
export const getPortalUserList = createAsyncThunk(
    `${namespace}/getPortalUserList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "", id = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await portalUserList(query, page, limit, sortby, order, id);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a Portal User
export const addPortalUser = createAsyncThunk(
    `${namespace}/addPortalUser`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await createPortalUser({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for fetching Portal User data by ID
export const getPortalUserData = createAsyncThunk(
    `${namespace}/getPortalUserById`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await getPortalUserDataByID(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for fetching Portal User data by ID
export const getPortalUserJobs = createAsyncThunk(
    `${namespace}/getPortalUserJobs`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await getPortalUserJobDetails(id);
            console.log("Jobs Response", JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)));

            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating Portal User data
export const updatePortalUser = createAsyncThunk(
    `${namespace}/UpdatePortalUser`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editPortalUser({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const portalUserStatus = createAsyncThunk(
    `${namespace}/UpdateStatus`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await updatePortalUserStatus({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const deletePortalUser = createAsyncThunk(
    `${namespace}/portalUserDelete`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await portalUserDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

////======== Client Module =======/////

export const getClientUserJobs = createAsyncThunk(
    `${namespace}/getClientUserJobs`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await getClientUserJobsById(id);
            console.log("Jobs Response", JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)));

            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getDemoClientUserJobs = createAsyncThunk(
    `${namespace}/getDemoClientUserJobs`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await getDemoClientUserJobsById(id);
            console.log("Jobs Response", JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)));

            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getClientUserRooms = createAsyncThunk(
    `${namespace}/getClientUserRooms`,
    async ({ query = "", page = 1, limit = 10, sortby = "", order = "", id = "", job_id = "" }, { rejectWithValue }) => {
        try {
            const data = await getClientUserRoomsById(query, page, limit, sortby, order,id, job_id);
            console.log("Jobs Response", JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)));

            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getDemoClientUserRooms = createAsyncThunk(
    `${namespace}/getDemoClientUserRooms`,
    async ({ job_id }, { rejectWithValue }) => {
        try {
            const data = await getDemoClientUserRoomsById(job_id);
            console.log("Jobs Response", JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)));

            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getClientUserProjectType = createAsyncThunk(
    `${namespace}/getClientUserProjectType`,
    async ({ project_id }, { rejectWithValue }) => {
        try {
            const data = await getClientUserProjectById(project_id);
            console.log("Jobs Response", JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)));

            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getClientUserPanos = createAsyncThunk(
    `${namespace}/getClientUserPanos`,
    async ({ job_id, room_id }, { rejectWithValue }) => {
        try {
            const data = await getClientUserPanosById(job_id, room_id);
            console.log("Jobs Response", JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)));

            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const clientClaimDetails = createAsyncThunk(
    `${namespace}/clientClaimDetails`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await updateClientClaimDetails({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getClientClaimDetails = createAsyncThunk(
    `${namespace}/getClientClaimDetails`,
    async ({ id, user_id, manager }, { rejectWithValue }) => {
        try {
            const data = await getClientClaimDetailsById(id, user_id, manager);
            console.log("Jobs Response", JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)));
 
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for get executor user list by project id
export const executorUserList = createAsyncThunk(
    `${namespace}/getExecutorUserList`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await getExecutorUserList(id);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for get executor claim list by user id
export const executorClaimList = createAsyncThunk(
    `${namespace}/getExecutorClaimList`,
    // async ({ user_id, claimType }, { rejectWithValue }) => {
    async ({ page = 0, limit = 0, sortby = "", order = "", user_id = "", claimType = "" }, { rejectWithValue }) => {
        try {
            const data = await getExecutorClaimList(page, limit, sortby, order, user_id, claimType);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a Portal User
export const executorClaim = createAsyncThunk(
    `${namespace}/executorClaimSettle`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await executorClaimSettle({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const addDocuments = createAsyncThunk(
    `${namespace}/addDocuments`,
    async ({ formData }, { rejectWithValue }) => {
        try {
            const data = await addItemDocuments(formData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const deleteDocuments = createAsyncThunk(
    `${namespace}/deleteDocuments`,
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            const data = await deleteItemDocuments(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for get documents
export const getDocuments = createAsyncThunk(
    `${namespace}/getDocuments`,
    async ({ user_id, item_id }, { rejectWithValue }) => {
        try {
            const data = await getItemDocuments(user_id, item_id);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

//get assign to details 
export const getAssignTo = createAsyncThunk(
    `${namespace}/getAssignTo`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await getAssignedToById(id);
            console.log("Jobs Response", JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)));

            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
            // return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const addAssignedTo = createAsyncThunk(
    `${namespace}/addAssignedTo`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await createAssignedTo({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const portalUserSlice = createSlice({
    name: namespace,
    initialState: {
        portalUserListData: [],
        portalUserListLoading: false,
        addPortalUserLoading: false,
        addPortalUserSuccess: false,
        addPortalUserError: false,
        addPortalUserErrorMessage: false,
        getAddedData: [],
        updateData: [],
        updatePortalUserSuccess: false,
        updatePortalUserError: false,
        updatePortalUserErrorMessage: "",
        deletePortalUserLoading: false,
        deletePortalUserSuccess: false,
        deletePortalUserError: false,
        deletePortalUserErrorMessage: "",
        portalUserStatusLoading: false,
        portalUserStatusSuccess: false,
        portalUserStatusError: false,
        portalUserStatusErrorMessage: "",
        portalUserJobs: [],
        clientUserJobs: [],
        clientUserJobsSuccess: false,
        clientUserJobsError: false,
        clientUserRoomLoading: false,
        clientUserRooms: [],
        demoClientUserRooms:[],
        clientCount:0,
        portalUserProjectType: {},
        clientProjType: '',
        clientUserRoomSuccess: false,
        clientUserRoomError: false,
        clientUserPanos: [],
        clientUserPanosSuccess: false,
        clientUserPanosError: false,
        clientClaimDetailsSuccess: false,
        getClaimDetails: [],
        executorUserListData: [],
        executorUserListDataLoading: false,
        executorUserSuccess: false,
        executorUserError: false,
        executorUserErrorMessage: "",
        executorClaimListData: [],
        executorClaimListDataLoading: false,
        executorClaimSuccess: false,
        executorClaimError: false,
        executorClaimErrorMessage: "",
        executorClaimSettleSuccess: false,
        executorClaimSettleError: false,
        executorClaimSettleErrorMessage: "",
        addDocumentSuccess: false,
        addDocumentError: false,
        addDocumentErrorMessage: "",
        deleteDocumentSuccess : false,
        deleteDocumentError : false,
        deleteDocumentErrorMessage : "",
        getDocumentsData: [],
        getDocumentsSuccess: false,
        getDocumentsError: false,
        getDocumentsErrorMessage: "",
        getAssignedToData: [],
        addAssignedToSuccess: false,
        addAssigneToError: false,
        demoClientUserJobs : false,
        demoClientUserJobsSuccess : false,
        demoClientUserJobsError : false

    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle getProjectList
            .addCase(getPortalUserList.pending, (state) => {
                state.portalUserListLoading = true;
                state.addPortalUserLoading = false;
                state.addPortalUserSuccess = false;
                state.addPortalUserError = false;
                state.addPortalUserErrorMessage = "";
                state.addedProject = null;
                state.getAddedData = [];
                state.updateData = [];
                state.updatePortalUserSuccess = false;
                state.updatePortalUserError = false;
                state.updatePortalUserErrorMessage = "";
                state.deletePortalUserLoading = false;
                state.deletePortalUserSuccess = false;
                state.deletePortalUserError = false;
                state.deletePortalUserErrorMessage = "";
                state.portalUserStatusLoading = false;
                state.portalUserStatusSuccess = false;
                state.portalUserStatusError = false;
                state.portalUserStatusErrorMessage = "";
            })
            .addCase(getPortalUserList.fulfilled, (state, action) => {
                const data = action.payload;
                state.portalUserListData = data;
                state.portalUserListLoading = false;
            })
            .addCase(getPortalUserList.rejected, (state) => {
                state.portalUserListLoading = false;
            })

            // Handle addProject
            .addCase(addPortalUser.pending, (state) => {
                state.addPortalUserLoading = true;
                state.addPortalUserError = null;
                state.addPortalUserErrorMessage = "";
                state.addedProject = null;
                state.addPortalUserSuccess = false;
            })
            .addCase(addPortalUser.fulfilled, (state, action) => {
                state.addPortalUserLoading = false;
                state.addPortalUserSuccess = true;
            })
            .addCase(addPortalUser.rejected, (state, action) => {
                const payload = action.payload.data;
                state.addPortalUserLoading = false;
                state.addPortalUserError = true;
                state.addPortalUserErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;
                state.addPortalUserSuccess = false;
            })

            // Handle getProjectData by ID
            .addCase(getPortalUserData.pending, (state) => { })
            .addCase(getPortalUserData.fulfilled, (state, action) => {
                const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                state.getAddedData = data.response;
            })
            .addCase(getPortalUserData.rejected, (state) => { })

            // Handle updateProject
            .addCase(updatePortalUser.pending, (state) => {
                state.updatePortalUserSuccess = false;
            })
            .addCase(updatePortalUser.fulfilled, (state, action) => {
                state.updateData = action.payload;
                state.updatePortalUserSuccess = true;
            })
            .addCase(updatePortalUser.rejected, (state, action) => {
                const payload = action.payload.data;
                state.updatePortalUserSuccess = false;
                state.updatePortalUserError = true;
                // state.updatePortalUserErrorMessage = action.error.message;
                state.updatePortalUserErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;
            })

            // Update Portal User status
            .addCase(portalUserStatus.pending, state => {
                state.portalUserStatusLoading = true;
                state.portalUserStatusSuccess = false;
                state.portalUserStatusError = false;
                state.portalUserStatusErrorMessage = "";
                state.deleteprojectLoading = false;
                state.deletePortalUserError = false;
                state.deletePortalUserSuccess = false;
                state.deletePortalUserErrorMessage = "";
            })
            .addCase(portalUserStatus.fulfilled, (state, action) => {
                state.portalUserStatusSuccess = true;
                state.portalUserStatusLoading = false;
            })
            .addCase(portalUserStatus.rejected, (state, action) => {
                state.portalUserStatusError = true;
                state.portalUserStatusLoading = false;
                state.portalUserStatusErrorMessage = action.error.message;
            })

            // Handle Deleteproject
            .addCase(deletePortalUser.pending, state => {
                state.deletePortalUserLoading = true;
                state.deletePortalUserError = false;
                state.deletePortalUserSuccess = false;
                state.deletePortalUserErrorMessage = "";
                state.portalUserStatusLoading = false;
                state.portalUserStatusSuccess = false;
                state.portalUserStatusError = false;
                state.portalUserStatusErrorMessage = "";
            })
            .addCase(deletePortalUser.fulfilled, (state, action) => {
                state.deletePortalUserSuccess = true;
                state.deletePortalUserLoading = false;
            })
            .addCase(deletePortalUser.rejected, (state, action) => {
                state.deletePortalUserError = true;
                state.deletePortalUserLoading = false;
                state.deletePortalUserErrorMessage = action.error.message;
            })

            // Handle Deleteproject
            .addCase(getPortalUserJobs.pending, state => {
                state.executorUserListData = [];
                state.executorUserError = false;
                state.executorUserErrorMessage = "";
                state.executorUserSuccess = false;
            })
            .addCase(getPortalUserJobs.fulfilled, (state, action) => {
                const data = action.payload.response;
                state.portalUserJobs = data;
            })
            .addCase(getPortalUserJobs.rejected, (state, action) => {
            })

            //CLient Module
            .addCase(getClientUserJobs.pending, state => {
            })
            .addCase(getClientUserJobs.fulfilled, (state, action) => {
                const data = action.payload.responses;
                state.clientUserJobs = data;
                state.clientUserJobsSuccess = true
            })
            .addCase(getClientUserJobs.rejected, (state, action) => {
                state.clientUserJobsSuccess = false
                state.clientUserJobsError = true
            })
           
            .addCase(getDemoClientUserJobs.pending, state => {
            })
            .addCase(getDemoClientUserJobs.fulfilled, (state, action) => {
                const data = action.payload.responses;
                state.demoClientUserJobs = data;
                state.demoClientUserJobsSuccess = true
            })
            .addCase(getDemoClientUserJobs.rejected, (state, action) => {
                state.demoClientUserJobsSuccess = false
                state.demoClientUserJobsError = true
            })

            .addCase(getClientUserRooms.pending, state => {
                state.clientUserRoomLoading = true
            })
            .addCase(getClientUserRooms.fulfilled, (state, action) => {
                const data = action.payload;
                console.log(action.payload, 'action.payload.responses');
                state.clientUserRooms = data?.data;
                state.clientCount = data?.counts;
                state.clientUserRoomLoading = false
                state.clientProjType = action.payload?.projectType?.project?.project_type
                state.clientUserRoomSuccess = true
            })
            .addCase(getClientUserRooms.rejected, (state, action) => {
                state.clientUserRoomSuccess = false
                state.clientUserRoomError = true
            })
            
            .addCase(getDemoClientUserRooms.pending, state => {
                state.clientUserRoomLoading = true
            })
            .addCase(getDemoClientUserRooms.fulfilled, (state, action) => {
                const data = action.payload;
                console.log(action.payload, 'action.payload.responses');
                state.demoClientUserRooms = action.payload?.responses;
                state.clientCount = data?.counts;
                state.clientUserRoomLoading = false
                state.clientProjType = action.payload?.projectType?.project?.project_type
                state.clientUserRoomSuccess = true
            })
            .addCase(getDemoClientUserRooms.rejected, (state, action) => {
                state.clientUserRoomSuccess = false
                state.clientUserRoomError = true
            })

            .addCase(getClientUserProjectType.pending, state => {
            })
            .addCase(getClientUserProjectType.fulfilled, (state, action) => {
                const data = action.payload.responses;
                console.log(action.payload, 'action.payload.responses');
                state.portalUserProjectType = action.payload.response?.project_type;
            })
            .addCase(getClientUserProjectType.rejected, (state, action) => {
            })

            .addCase(getClientUserPanos.pending, state => {
            })
            .addCase(getClientUserPanos.fulfilled, (state, action) => {
                const data = action.payload.responses;
                state.clientUserPanos = data;
                state.clientUserPanosSuccess = true
            })
            .addCase(getClientUserPanos.rejected, (state, action) => {
                state.clientUserPanosSuccess = false
                state.clientUserPanosError = true
            })

            .addCase(clientClaimDetails.pending, state => {
            })
            .addCase(clientClaimDetails.fulfilled, (state, action) => {
                state.clientClaimDetailsSuccess = true
            })
            .addCase(clientClaimDetails.rejected, (state, action) => {
            })

            .addCase(getClientClaimDetails.pending, state => {
            })
            .addCase(getClientClaimDetails.fulfilled, (state, action) => {
                const data = action.payload?.response;
                console.log(data,action,'datassssss===');
                state.getClaimDetails = data
            })
            .addCase(getClientClaimDetails.rejected, (state, action) => {
            })


            // Handle executor users list
            .addCase(executorUserList.pending, state => {
                state.executorUserListDataLoading = true;
                state.executorUserListData = [];
                state.executorUserError = false;
                state.executorUserErrorMessage = "";
                state.executorUserSuccess = false;
            })
            .addCase(executorUserList.fulfilled, (state, action) => {
                const data = action.payload.response;
                state.executorUserSuccess = true;
                state.executorUserListData = data;
                state.executorUserListDataLoading = false;
            })
            .addCase(executorUserList.rejected, (state, action) => {
                state.executorUserSuccess = false;
                state.executorUserError = true;
                state.executorUserErrorMessage = action.error.message;
                state.executorUserListDataLoading = false;
            })

            // Handle executor claim list
            .addCase(executorClaimList.pending, state => {
                state.executorClaimListDataLoading = true;
                state.executorClaimListData = [];
                state.executorClaimError = false;
                state.executorClaimErrorMessage = "";
                state.executorClaimSuccess = false;
                state.executorClaimSettleSuccess = false;
                state.executorClaimSettleError = false;
                state.executorClaimSettleErrorMessage = "";
            })
            .addCase(executorClaimList.fulfilled, (state, action) => {
                const data = action.payload;
                console.log("claimList", data, action);
                state.executorClaimListDataLoading = false;

                state.executorClaimSuccess = true;
                state.executorClaimListData = data;
            })
            .addCase(executorClaimList.rejected, (state, action) => {
                state.executorClaimSuccess = false;
                state.executorClaimError = true;
                state.executorClaimErrorMessage = action.error.message;
                state.executorClaimListDataLoading = false;
            })

            // Handle executor claim settle
            .addCase(executorClaim.pending, (state) => {
                state.executorClaimSettleError = false;
                state.executorClaimSettleErrorMessage = "";
                state.executorClaimSettleSuccess = false;
            })
            .addCase(executorClaim.fulfilled, (state, action) => {
                state.executorClaimSettleSuccess = true;
            })
            .addCase(executorClaim.rejected, (state, action) => {
                const payload = action.payload.data;
                state.executorClaimSettleError = true;
                state.executorClaimSettleErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;;
            })

            // Handle executor claim settle
            .addCase(addDocuments.pending, (state) => {
                state.addDocumentSuccess = false;
                state.addDocumentError = false;
                state.addDocumentErrorMessage = "";
            })
            .addCase(addDocuments.fulfilled, (state, action) => {
                state.addDocumentSuccess = true;
                console.log("action", action);

            })
            .addCase(addDocuments.rejected, (state, action) => {
                const payload = action.payload.data;
                console.log("action", action);
                state.addDocumentError = true;
                state.addDocumentErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;;
            })

            // Handle document delete
            .addCase(deleteDocuments.pending, (state) => {
                state.deleteDocumentSuccess = false;
                state.deleteDocumentError = false;
                state.deleteDocumentErrorMessage = "";
            })
            .addCase(deleteDocuments.fulfilled, (state, action) => {
                state.deleteDocumentSuccess = true;
                console.log("action", action);

            })
            .addCase(deleteDocuments.rejected, (state, action) => {
                const payload = action.payload.data;
                console.log("action", action);
                state.deleteDocumentError = true;
                state.deleteDocumentErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;;
            })

            // Handle Get documents list
            .addCase(getDocuments.pending, state => {
                state.getDocumentsData = [];
                state.getDocumentsError = false;
                state.getDocumentsErrorMessage = "";
                state.getDocumentsSuccess = false;
                state.addDocumentSuccess = false;
                state.addDocumentError = false;
                state.addDocumentErrorMessage = "";
                state.deleteDocumentSuccess = false;
                state.deleteDocumentError = false;
                state.deleteDocumentErrorMessage = "";
            })
            .addCase(getDocuments.fulfilled, (state, action) => {
                const data = action.payload.response;
                console.log("documents", data);

                state.getDocumentsSuccess = true;
                state.getDocumentsData = data;
            })
            .addCase(getDocuments.rejected, (state, action) => {
                state.getDocumentsSuccess = false;
                state.getDocumentsError = true;
                state.getDocumentsErrorMessage = action.error.message;
            })

            .addCase(getAssignTo.pending, state => {

            })
            .addCase(getAssignTo.fulfilled, (state, action) => {
                console.log(action.payload.response, 'action.payload.response');

                const data = action.payload.response;
                state.getAssignedToData = data;
            })
            .addCase(getAssignTo.rejected, (state, action) => {

            })

            .addCase(addAssignedTo.pending, state => {
                state.addAssigneToError = false;
                state.addAssignedToSuccess = false;
            })
            .addCase(addAssignedTo.fulfilled, (state, action) => {
                state.addAssigneToError = false;
                state.addAssignedToSuccess = false;
            })
            .addCase(addAssignedTo.rejected, (state, action) => {
                state.addAssigneToError = true;
                state.addAssignedToSuccess = false;
            })
    },
});

export const { resetSuccessFlag } = portalUserSlice.actions;

export const portalUserSelector = (state) => state[namespace];

export default portalUserSlice.reducer;

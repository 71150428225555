import { SyncOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComponent from "../fields/ButtonComponent";
import SearchComponent from "../fields/SearchComponent";
import SwalComponent from "../fields/SwalComponent";
import TableComponent from "../fields/TableComponent";
import {
   deleteStaff,
   getStaffList,
   staffStatus
} from "../store/reducer/staffSlice";
import PageHeader from "../view/pageheader";
import Loader from "../fields/loader/Loader";

const Staff = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   //Selectors
   const {
      staffListData,
      staffListLoading,
      deleteStaffLoading,
      deleteStaffSuccess,
      deleteStaffError,
      deleteStaffErrorMessage,
      staffStatusLoading,
      staffStatusSuccess,
      staffStatusError,
      staffStatusErrorMessage,
   } = useSelector(state => state.staff);
   const { count: totalCount, data: tableData } = staffListData;
   const [categoryType] = useState({
      1: "Admin",
      2: "Management",
      3: "Production",
   });
   const roleID = localStorage.getItem('role_id')
   const category_type = localStorage.getItem('category_type')

   function getData(payload = {}) {
      console.log(payload,'payload');
      dispatch(getStaffList(payload));
   }

   useEffect(() => {
      getData({});

      if (deleteStaffSuccess) {
         toast.success("Staff deleted successfully");
      }

      if (deleteStaffError) {
         toast.error(deleteStaffErrorMessage);
      }

      if (staffStatusSuccess) {
         toast.success("Staff status updated successfully");
      }

      if (staffStatusError) {
         toast.error(staffStatusErrorMessage);
      }
   }, [deleteStaffError, deleteStaffSuccess, staffStatusSuccess, staffStatusError]);

   const columns = [
      {
         title: "Name",
         key: "first_name",
         align: "center",
         render: record => `${record.first_name} ${record.last_name}`,
         sorter: true,
      },
      {
         title: "City",
         dataIndex: "city",
         key: "city",
         align: "center",
         sorter: true,
      },
      {
         title: "State",
         dataIndex: "state",
         key: "state",
         align: "center",
         sorter: true,
      },
      {
         title: "Zip",
         dataIndex: "zip_code",
         key: "zip_code",
         align: "center",
         sorter: true,
      },
      {
         title: "Category",
         key: "category_type",
         align: "center",
         render: record => categoryType[record["category_type"]],
         sorter: true,
      },
      {
         title: "Status",
         key: "status",
         align: "center",
         render: record => (
            <SwalComponent
               message="Are you sure you want to change the staff status?"
               onClickDispatch={staffStatus}
               id={record.id}
               status={true}
               statusValue={record.status}
               postData={{ status: record.status === 1 ? 0 : 1, user_id: record.user_id }}
            />
         ),
         sorter: true,
      },
      {
         title: "Action",
         align: "center",
         render: (record) => {
            if (roleID === '2' && category_type === '2') {
               // Render only the edit action
               return (
                  <Space size="large" align="center">
                     <span className="fs-6" style={{ color: "blue", cursor: "pointer" }}>
                        <Tooltip title="Edit">
                           <i
                              className="icon-pencil"
                              onClick={() => {
                                 console.log(record, "record");
                                 navigate(`/editstaff/${btoa(record?.id)}`);
                              }}
                           ></i>
                        </Tooltip>
                     </span>
                  </Space>
               );
            }

            // Render the full action column (edit and delete)
            return (
               <Space size="large" align="center">
                  <span className="fs-6" style={{ color: "blue", cursor: "pointer" }}>
                     <Tooltip title="Edit">
                        <i
                           className="icon-pencil"
                           onClick={() => {
                              console.log(record, "record");
                              navigate(`/editstaff/${btoa(record?.id)}`);
                           }}
                        ></i>
                     </Tooltip>
                  </span>
                  <SwalComponent
                     message="Are you sure you want to delete the staff?"
                     onClickDispatch={deleteStaff}
                     id={record.id}
                  />
               </Space>
            );
         },
      },
   ];

   return (
      <div>
         <Loader loading={staffListLoading || deleteStaffLoading || staffStatusLoading} />
         <div className="container-fluid">
            <PageHeader HeaderText="Staff List" Breadcrumb={[{ name: "Staff List" }]} />
            <Card
               title="Staff List"
               extra={
                  <Space size={"middle"} className="my-3">
                     <SearchComponent
                        placeHolder="Search Staff"
                        onChange={e => {
                           const payloadData = {
                              query: e.target.value,
                           };
                           getData(payloadData);
                        }}
                     />
                      {roleID === '2' && category_type === '2' ? null :
                     <Link to="/addstaff">
                        <ButtonComponent
                           style={{ backgroundColor: "#f7931e", color: "white" }}
                           onClick={null}
                           buttonName="Add Staff"
                           icon={<i className="fa fa-user-plus" aria-hidden="true"></i>}
                        />
                     </Link>}
                     {/* <Tooltip title="Refresh">
                        <Button
                           shape="circle"
                           icon={
                              <SyncOutlined
                                 spin={staffListLoading || deleteStaffLoading || staffStatusLoading}
                              />
                           }
                           onClick={getData}
                        ></Button>
                     </Tooltip> */}
                  </Space>
               }
            >
               <TableComponent
                  columns={columns}
                  dataSource={tableData}
                  TotalDataCount={totalCount}
                  onChangeDispatch={getData}
               />
            </Card>
         </div>
      </div>
   );
};

export default Staff;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { roomsList, createRoom, editRoom, getRoomDataByID, updateRoomStatus, roomDelete, createBatchItems, createSequenceItems, getRoomItemDataById, additionalSequenceItems, editImage, deleteImage, sequenceImage, deleteItem, getClientPortalRoomItemDataById } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "rooms";

// Async thunk for fetching the Portal User list
export const getRoomsList = createAsyncThunk(
    `${namespace}/getRoomsList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "",id="" },
        { rejectWithValue }
    ) => {
        try {
            const data = await roomsList(query, page, limit, sortby, order,id);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a Room
export const addRoom = createAsyncThunk(
    `${namespace}/addRoom`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await createRoom({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating Room data
export const updateRoom = createAsyncThunk(
    `${namespace}/updateRoom`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            console.log(postData, 'postData');
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editRoom({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for deleting a Room
export const deleteRoom = createAsyncThunk(
    `${namespace}/roomDelete`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            const data = await roomDelete(deleteID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding batch items
export const addBatchItems = createAsyncThunk(
    `${namespace}/addBatchItems`,
    async ({ postData }, { rejectWithValue }) => {
        try {

            console.log(postData, 'postdataa==');
            const formData = new FormData();

            for (let i = 0; i < postData.length; i++) {
                const item = postData[i];
                formData.append(`batch_images[${i}][binary_data]`, item.binary_data);
                formData.append(`batch_images[${i}][file_name]`, item.file_name);
                formData.append(`batch_images[${i}][room_id]`, item.room_id);
                formData.append(`batch_images[${i}][is_lasso]`, item.is_lasso);
            }

            const data = await createBatchItems(formData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding sequence items
export const addSequenceItems = createAsyncThunk(
    `${namespace}/addSequenceItems`,
    async ({ postData }, { rejectWithValue }) => {
        try {

            console.log(postData, 'postdataa==');
            const formData = new FormData();

            for (let i = 0; i < postData.length; i++) {
                const item = postData[i];
                formData.append(`sequence_images[${i}][binary_data]`, item.binary_data);
                formData.append(`sequence_images[${i}][file_name]`, item.file_name);
                formData.append(`sequence_images[${i}][slot_number]`, item.slot_number);
                formData.append(`sequence_images[${i}][room_id]`, item.room_id);
                // formData.append(`sequence_images[${i}][item_id]`, item.item_id);
            }

            const data = await createSequenceItems(formData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

//Room Items
export const getRoomData = createAsyncThunk(
    `${namespace}/get/roomData`,
    async ({ query = "", page = 1, limit = 10, sortby = "", order = "",room_id }, { rejectWithValue }) => {
        try {
            console.log(room_id, 'room_id');
            const data = await getRoomItemDataById(query, page, limit, sortby, order,room_id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getClientPortalRoomData = createAsyncThunk(
    `${namespace}/getClientPortalRoomData`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            console.log(postData, 'postData');
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await getClientPortalRoomItemDataById({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

//Update Sequence Items 
export const addAdditionalSequenceItems = createAsyncThunk(
    `${namespace}/addAdditionalSequenceItems`,
    async ({ postData }, { rejectWithValue }) => {
       
        try {

            console.log(postData, 'postdataa==');
            const formData = new FormData();

            for (let i = 0; i < postData.length; i++) {
                const item = postData[i];
                formData.append(`sequence_images[${i}][binary_data]`, item.binary_data);
                formData.append(`sequence_images[${i}][file_name]`, item.file_name);
                formData.append(`sequence_images[${i}][slot_number]`, item.slot_number);
                formData.append(`sequence_images[${i}][item_id]`, item.item_id);
                formData.append(`sequence_images[${i}][max_length]`, item.max_length);
                formData.append(`sequence_images[${i}][room_id]`, item.room_id);
            }

            const data = await additionalSequenceItems(formData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

//Edit Image 
export const editItemImage = createAsyncThunk(
    `${namespace}/editImage`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            console.log(postData,'postData');
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editImage({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const deleteItemImage = createAsyncThunk(
    `${namespace}/deleteItemImage`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await deleteImage({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const deleteRoomItem = createAsyncThunk(
    `${namespace}/deleteRoomItem`, // Change the name accordingly
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            const data = await deleteItem(id); // Call the API to delete the item
            console.log("deleteRoomItem result: ", data);
            return data;
        } catch (error) {
            console.log("deleteRoomItem error: ", error);
            return rejectWithValue(error.response);
        }
    }
);

export const updateSequenceImage = createAsyncThunk(
    `${namespace}/updateSequence`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        console.log(postData,'postData');
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await sequenceImage({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const rooms = createSlice({
    name: namespace,
    initialState: {
        roomsListData: [],
        roomsListLoading: false,
        addRoomLoading: false,
        addRoomSuccess: false,
        addRoomError: false,
        addRoomErrorMessage: "",
        updateRoomSuccess: false,
        updateRoomError: false,
        updateRoomErrorMessage: "",
        deleteRoomLoading: false,
        deleteRoomSuccess: false,
        deleteRoomError: false,
        deleteRoomErrorMessage: "",
        deleteRoomItemSuccess:false,
        deleteRoomItemError:false,
        addBatchItemLoading: false,
        addBatchItemSuccess: false,
        addBatchItemsError: false,
        addBatchItemErrorMessage: "",
        addSequenceItemLoading: false,
        addSequenceItemSuccess: false,
        addSequenceItemsError: false,
        addSequenceItemErrorMessage: "",
        getItemDatas: null,
        getRoomLoading:false,
        updateSequenceItemLoading: false,
        addAdditionalSequenceItemsuccess: false,
        addAdditionalSequenceItemsError: false,
        updateSequenceItemErrorMessage: "",
        editItemImageLoading:false,
        editItemImageSuccess:false,
        editItemImageError:false,
        deleteItemImageLoading:false,
        deleteItemImageSuccess:false,
        deleteItemImageError:false,
        updateItemImageLoading:false,
        updateItemImageSuccess:false,
        updateItemImageError:false,
        getClientPortalItemDatas:[],
        getClientPortalLoading:false,
        getClientPortalSuccess:false,
        getClientPortalError:false

    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle getRoomsList
            .addCase(getRoomsList.pending, (state) => {
                state.roomsListLoading = true;
            })
            .addCase(getRoomsList.fulfilled, (state, action) => {
                const data = action.payload;
                state.roomsListData = data;
                state.roomsListLoading = false;
            })
            .addCase(getRoomsList.rejected, (state) => {
                state.roomsListLoading = false;
            })


            .addCase(getRoomData.pending, (state) => {
                state.getRoomLoading = true
            })

            .addCase(getRoomData.fulfilled, (state, action) => {
                state.getRoomLoading = false
                console.log(action.payload.data,'action.payload.data');
                const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                state.getItemDatas = data;
            })

            .addCase(getRoomData.rejected, (state) => {
                state.getRoomLoading = false

            })

            .addCase(getClientPortalRoomData.pending, (state) => {
                state.getClientPortalLoading = true
                state.getClientPortalSuccess = false
                state.getClientPortalError = false
            })

            .addCase(getClientPortalRoomData.fulfilled, (state, action) => {
                state.getClientPortalLoading = false
                console.log(action.payload.data,'action.payload.data');
                const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                state.getClientPortalItemDatas = data;
                state.getClientPortalSuccess = true
                state.getClientPortalError = false
            })

            .addCase(getClientPortalRoomData.rejected, (state) => {
                state.getClientPortalLoading = false
                state.getClientPortalSuccess = false
                state.getClientPortalError = true
            }) 

            // Handle add Room
            .addCase(addRoom.pending, (state) => {
                state.addRoomLoading = true;
                state.addRoomError = false;
                state.addRoomSuccess = false;
            })
            .addCase(addRoom.fulfilled, (state, action) => {
                state.addRoomLoading = false;
                state.addRoomSuccess = true;
            })
            .addCase(addRoom.rejected, (state, action) => {
                console.log(action,'action');
                state.addRoomLoading = false;
                state.addRoomError = true;
                if(action.payload.data.error){
                    state.addRoomErrorMessage = action.payload.data.error;
                }
                else{
                state.addRoomErrorMessage = action.error.message;
                }
            })

            // Handle update Room
            .addCase(updateRoom.pending, (state) => {
                state.updateRoomSuccess = false;
                state.updateRoomError = false;
                state.updateRoomErrorMessage = "";
            })
            .addCase(updateRoom.fulfilled, (state, action) => {
                state.updateRoomSuccess = true;
            })
            .addCase(updateRoom.rejected, (state, action) => {
                state.updateRoomError = true;
                if(action.payload.data.error){
                    state.updateRoomErrorMessage = action.payload.data.error;
                }
                else{
                state.updateRoomErrorMessage = action.error.message;
                }
            })

            // Handle delete Room
            .addCase(deleteRoom.pending, (state) => {
                state.deleteRoomLoading = true;
                state.deleteRoomError = false;
                state.deleteRoomSuccess = false;
                state.deleteRoomErrorMessage = "";
            })
            .addCase(deleteRoom.fulfilled, (state, action) => {
                state.deleteRoomSuccess = true;
                state.deleteRoomLoading = false;
            })
            .addCase(deleteRoom.rejected, (state, action) => {
                state.deleteRoomError = true;
                state.deleteRoomLoading = false;
                state.deleteRoomErrorMessage = action.error.message;
            })

            .addCase(deleteRoomItem.pending, (state) => {
                state.deleteRoomItemSuccess = false;
                state.deleteRoomItemError = false;
            })
            .addCase(deleteRoomItem.fulfilled, (state, action) => {
                state.deleteRoomItemSuccess = true;
                state.deleteRoomItemError = false;
            })
            .addCase(deleteRoomItem.rejected, (state, action) => {
                state.deleteRoomItemSuccess = false;
                state.deleteRoomItemError = true;
            })

            // Handle add Batch Items
            .addCase(addBatchItems.pending, (state) => {
                state.addBatchItemLoading = true;
                state.addBatchItemSuccess = false;
                state.addBatchItemsError = false;
                state.addBatchItemErrorMessage = "";
            })
            .addCase(addBatchItems.fulfilled, (state, action) => {
                state.addBatchItemLoading = false;
                state.addBatchItemSuccess = true;
            })
            .addCase(addBatchItems.rejected, (state, action) => {
                state.addBatchItemLoading = false;
                state.addBatchItemsError = true;
                state.addBatchItemErrorMessage = action.error.message;
            })

            // Handle add Sequence Items
            .addCase(addSequenceItems.pending, (state) => {
                state.addSequenceItemLoading = true;
                state.addSequenceItemSuccess = false;
                state.addSequenceItemsError = false;
                state.addSequenceItemErrorMessage = "";
            })
            .addCase(addSequenceItems.fulfilled, (state, action) => {
                state.addSequenceItemLoading = false;
                state.addSequenceItemSuccess = true;
            })
            .addCase(addSequenceItems.rejected, (state, action) => {
                state.addSequenceItemLoading = false;
                state.addSequenceItemsError = true;
                state.addSequenceItemErrorMessage = action.error.message;
            })

            // Handle update Sequence Items
            .addCase(addAdditionalSequenceItems.pending, (state) => {
                state.updateSequenceItemLoading = true;
                state.addAdditionalSequenceItemsuccess = false;
                state.addAdditionalSequenceItemsError = false;
                state.updateSequenceItemErrorMessage = "";
            })
            .addCase(addAdditionalSequenceItems.fulfilled, (state, action) => {
                state.updateSequenceItemLoading = false;
                state.addAdditionalSequenceItemsuccess = true;
            })
            .addCase(addAdditionalSequenceItems.rejected, (state, action) => {
                state.updateSequenceItemLoading = false;
                state.addAdditionalSequenceItemsError = true;
                state.updateSequenceItemErrorMessage = action.error.message;
            })

            // Handle edit item image
            .addCase(editItemImage.pending, (state) => {
                state.editItemImageLoading = true;
                state.editItemImageError = false;
                state.editItemImageSuccess = false;
            })
            .addCase(editItemImage.fulfilled, (state, action) => {
                state.editItemImageLoading = false;
                state.editItemImageError = false;
                state.editItemImageSuccess = true;
            })
            .addCase(editItemImage.rejected, (state, action) => {
                state.editItemImageLoading = false;
                state.editItemImageError = true;
                // state.updateSequenceItemErrorMessage = action.error.message;
            })

            .addCase(deleteItemImage.pending, (state) => {
                state.deleteItemImageLoading = true;
                state.deleteItemImageError = false;
                state.deleteItemImageSuccess = false;
            })
            .addCase(deleteItemImage.fulfilled, (state, action) => {
                state.deleteItemImageLoading = false;
                state.deleteItemImageError = false;
                state.deleteItemImageSuccess = true;
            })
            .addCase(deleteItemImage.rejected, (state, action) => {
                state.deleteItemImageLoading = false;
                state.deleteItemImageError = true;
                // state.updateSequenceItemErrorMessage = action.error.message;
            })
            
            .addCase(updateSequenceImage.pending, (state) => {
                state.updateItemImageLoading = true;
                state.updateItemImageError = false;
                state.updateItemImageSuccess = false;
            })
            .addCase(updateSequenceImage.fulfilled, (state, action) => {
                state.updateItemImageLoading = false;
                state.updateItemImageError = false;
                state.updateItemImageSuccess = true;
            })
            .addCase(updateSequenceImage.rejected, (state, action) => {
                state.updateItemImageLoading = false;
                state.updateItemImageError = true;
                // state.updateSequenceItemErrorMessage = action.error.message;
            });
    },
});

export const { resetSuccessFlag } = rooms.actions;

export const roomsSelector = (state) => state[namespace];

export default rooms.reducer;

import { Divider, message, Tooltip, Input, Select, Table, Button, Modal, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadOutlined, CloseOutlined, ArrowRightOutlined, EditOutlined, CheckOutlined, HolderOutlined } from "@ant-design/icons";
import CardComponent from '../../fields/CardComponent';
import ButtonComponent from '../../fields/ButtonComponent';
import ModalComponent from '../../fields/ModalComponent';
import Compressor from 'compressorjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getJobData } from '../../store/reducer/jobSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addRoom, resetSuccessFlag } from '../../store/reducer/roomSlice';
import { toast } from 'react-toastify'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import SwalComponent from '../../fields/SwalComponent';
import { addPanoItems, deletePano, getPanoList, panoSequence, saveHotspotView } from '../../store/reducer/buildThreeSixty';
import { resetSuccessFlag as resetThreeSixtySuccessFlag } from '../../store/reducer/buildThreeSixty';
import PageHeader from '../../view/pageheader';
import { getProjectData } from '../../store/reducer/projectSlice';

const PanoramaAddEdit = () => {
    const { Option } = Select;
    const { client_id, project_id, job_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { getAddedJobData } = useSelector((state) => state.job)

    const [batchFileList, setBatchFileList] = useState([]);
    const [preview, setPreview] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRoomModalOpen, setRoomIsModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [binaryDataArray, setBinaryDataArray] = useState([])
    const [isEditing, setIsEditing] = useState(null); // State to manage editing row
    const [editValue, setEditValue] = useState(''); // State to manage edit input value
    const [addRoomName, setAddRoomName] = useState({
        room_name: '',
        job_id: Number(atob(job_id)),
        project_id:Number(atob(project_id))
    })
    const [onDrag, setOnDrag] = useState(false)
    const [dataSource, setDataSource] = useState();
    const [addPanos, setAddPanos] = useState(false)
    const [roomCounters, setRoomCounters] = useState({}); // To track the count for each room
    const { addRoomSuccess } = useSelector(state => state.rooms)
    const { panoListData, addPanoItemsuccess, hotspotViewSuccess, hotpotViewError, panoSequenceSuccess, panoSequenceError, panoDeleteSuccess, panoDeleteError } = useSelector(state => state.buildThreeSixty)
    const { getAddedProjectData } = useSelector(state => state.project);
    const BATCH_MAX_FILES = 4;
    const MAX_FILE_SIZE_MB = 2;
    const MIN_WIDTH = 5000;
    const MAX_WIDTH = 8000;
    const MIN_HEIGHT = 2500;
    const MAX_HEIGHT = 4000;

    console.log(preview, batchFileList, 'batchFileList');
    console.log(binaryDataArray, 'binaryDataArray');
    console.log(getAddedJobData, 'getAddedJobData');
    console.log(hotspotViewSuccess, 'hotspotViewSuccess');
    console.log(getAddedProjectData, 'getAddedProjectData');

    const readFiles = (fileList, type) => {
        // You can customize this function based on your application's needs
        const promises = fileList.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onabort = () => reject('file reading was aborted');
                reader.onerror = () => reject('file reading has failed');
                reader.onload = () => {
                    resolve({ binaryStr: reader.result, name: file.name });
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(promises)
            .then(results => {
                const imagesData = results.map((data, index) => ({
                    original: data.binaryStr,
                    thumbnail: data.binaryStr,
                    name: data.name,
                    id: index + 1
                }));
                // Process imagesData or perform further actions
                console.log(imagesData);
                setImageData(imagesData)
                // setPreview(prev => [...prev, ...imagesData]);
            })
            .catch(error => console.error('Error reading files:', error));
    };

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6,
                maxWidth: 2500, // Resize the image
                success: (compressedResult) => {
                    resolve(compressedResult);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    };

    const validateFile = async (file) => {
        // if (file.size > 2 * 1024 * 1024) {
        //     throw new Error('File size must be under 2MB');
        // }
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        img.src = objectUrl;
        await new Promise((resolve, reject) => {
            img.onload = () => {
                URL.revokeObjectURL(objectUrl);
                if (img.width > 8000 || img.height > 4000 || img.width < 5000 || img.height < 2500) {
                    reject(new Error('Image dimensions must be between 5000x2500 and 8000x4000'));
                } else {
                    resolve();
                }
            };
            img.onerror = () => {
                reject(new Error('Error loading image'));
            };
        });
    };

    const handleBatchDrop = async (acceptedFiles) => {
        console.log('Accepted files:', acceptedFiles);

        // Validate files
        const validFilesPromises = acceptedFiles.map(file => validateFile(file).then(() => file).catch(err => {
            return { file, error: err.message };
        }));

        const results = await Promise.all(validFilesPromises);
        console.log('Results:', results);

        const validFiles = results.filter(result => !result.error);
        console.log('Valid Files:', validFiles);

        const invalidFiles = results.filter(result => result.error);
        console.log('Invalid Files:', invalidFiles);

        // Display error messages for invalid files
        if (invalidFiles.length > 0) {
            invalidFiles.forEach(({ file, error }) => {
                message.error(`File "${file.name}" is invalid: ${error}`);
            });
        }

        // Check for file limit
        if (validFiles.length + batchFileList.length > BATCH_MAX_FILES) {
            message.error(`You can only upload up to ${BATCH_MAX_FILES} files in total.`);
            return;
        }

        // Compress valid files
        const compressedFilesPromises = validFiles.map(file => {
            console.log('Compressing file:', file);
            return compressImage(file);
        });

        const compressedFiles = await Promise.all(compressedFilesPromises);

        const newFileList = [...batchFileList, ...compressedFiles];
        setBatchFileList(newFileList);

        // Generate previews and include file size
        try {
            const newPreviews = compressedFiles.map(file => {
                const preview = URL.createObjectURL(file);
                return {
                    preview,
                    name: file.name,
                    size: (file.size / 1024).toFixed(2) + ' KB'  // Convert size to KB and format
                };
            });
            setPreview(prev => [...prev, ...newPreviews]);
            readFiles(newFileList, 'batch');
        } catch (error) {
            console.error('Error creating object URL:', error);
        }
    };

    const { getRootProps: getBatchRootProps, getInputProps: getBatchInputProps } = useDropzone({
        onDrop: handleBatchDrop,
        multiple: true,
        maxFiles: BATCH_MAX_FILES,
        accept: 'image/*',
    });

    const handleRemoveImage = (index, event) => {
        event.stopPropagation(); // Stop the event from bubbling up to the dropzone
        console.log(batchFileList, preview, imageData, 'previewpreview');
        if (batchFileList && batchFileList.length === 1) {
            setBatchFileList([])
            setPreview([])
            setImageData([])
        }
        else {
            const newFileList = [...batchFileList];
            newFileList.splice(index, 1);
            setBatchFileList(newFileList);

            const newPreviewList = [...preview];
            newPreviewList.splice(index, 1);
            setPreview(newPreviewList);

            const image = [...imageData];
            image.splice(index, 1);
            setImageData(image);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        // setAddRoomName({ room_name: "" });
    };

    const handleEditName = (index, newName, fileExtension) => {
        const newTableData = [...binaryDataArray];
        newTableData[index].name = newName + '.' + fileExtension;
        setBinaryDataArray(newTableData);
    };

    const handleEditTableName = (index, newName, fileExtension) => {
        const newTableData = [...dataSource];
        newTableData[index].name = newName + '.' + fileExtension;
        setDataSource(newTableData);
    };

    // const handleRoomChange = (index, value) => {
    //     if (value === 'add') {
    //         setRoomIsModalOpen(true);
    //     } else {
    //         const newTableData = [...binaryDataArray];
    //         newTableData[index].room = value;
    //         setBinaryDataArray(newTableData);
    //     }
    // };

    // Create a room ID to room name mapping
    const roomIdToName = rooms.reduce((acc, room) => {
        acc[room.id] = room.room_name;
        return acc;
    }, {});

    const handleRoomChange = (index, value) => {
        console.log(value,'value');
        
        if (value === 'add') {
            // Handle adding a new room (e.g., open a modal)
            setRoomIsModalOpen(true);
        } else {
            // Copy the current data array to avoid mutating state directly
            const newTableData = [...binaryDataArray];

            // Update the selected room for the specific row
            newTableData[index].room = value;

            // Optionally, update the name with the new room name without any counters
            const roomName = roomIdToName[value];
            const newName = `${roomName}.jpg`;
            newTableData[index].name = newName;

            // Update the state with the modified data array
            setBinaryDataArray(newTableData);
        }
    };

    const handleSave = () => {
        // Update the binaryData with the new file names and room selections
        const updatedBinaryData = binaryDataArray.map((item, index) => ({
            ...item,
            name: tableData[index] ? tableData[index].name : item.name,
            room: tableData[index] ? tableData[index].room : item.room,
        }));
    
        // Check if every item has a room selected
        const allRoomsSelected = updatedBinaryData.every(item => item.room && item.room !== "select_room");
    
        if (!allRoomsSelected) {
            // Show error toast if any row has 'select_room' as value
            message.error('Please select a room for each row before saving.');
            return;
        }
    
        // Proceed with saving if all rooms are selected
        let temp = [];
        let decodedId = atob(job_id);
    
        if (updatedBinaryData && updatedBinaryData.length > 0) {
            for (let i in updatedBinaryData) {
                temp.push({
                    binary_data: updatedBinaryData[i].binaryData,
                    file_name: updatedBinaryData[i].name,
                    room_id: updatedBinaryData[i].room,
                    jobid: Number(decodedId)
                });
            }
        }
    
        if (temp && temp.length > 0) {
            let postData = temp;
            console.log(postData, 'postData00');
            dispatch(addPanoItems({ postData }));
        }
    };
    
    const handleRoomSave = () => {
        if(addRoomName.room_name != ''){
            let postData = addRoomName;
            dispatch(addRoom({ postData }));
            setAddRoomName({
                ...addRoomName,
                room_name: '',
                job_id: Number(atob(job_id))
            })
            setRoomIsModalOpen(false)
        }
        else{
            toast.error('Please enter room name')
        }
    };

    const handleRoomCancel = () => {
        setRoomIsModalOpen(false);
        setAddRoomName({
            ...addRoomName,
            room_name: '',
            job_id: Number(atob(job_id))
        })
    };

    useEffect(() => {
        if (addPanos === false) {
            setBinaryDataArray([])
            setBatchFileList([])
            setPreview([])
        }
    }, [addPanos])

    useEffect(() => {
        if (addPanoItemsuccess) {
            setIsModalOpen(false);
            setAddPanos(true)
            getData()
        }
    }, [addPanoItemsuccess])

    useEffect(() => {
        if (panoSequenceSuccess === true) {
            toast.success('Sequence Updated Successfully!', {
                autoClose: 3000  // Display for 3 seconds (3000 milliseconds)
            });
            dispatch(resetThreeSixtySuccessFlag({ flag: 'panoSequenceSuccess' }));
        }
    }, [panoSequenceSuccess]);

    useEffect(() => {
        if (panoDeleteSuccess === true) {
            toast.success('Pano Deleted Successfully!', {
                autoClose: 3000  // Display for 3 seconds (3000 milliseconds)
            });
            getData()
            dispatch(resetThreeSixtySuccessFlag({ flag: 'panoDeleteSuccess' }));
        }
    }, [panoDeleteSuccess]);


    useEffect(() => {
        if (getAddedJobData && getAddedJobData.rooms && getAddedJobData.rooms.length > 0) {
            setRooms(getAddedJobData.rooms)
        }
    }, [getAddedJobData])

    useEffect(() => {
        if (panoListData && panoListData?.data && panoListData?.data.length > 0) {
            setAddPanos(true)
            // setDataSource(panoListData?.data)
            const panoItems = panoListData?.data.map((item, i) => {
                return {
                    key: `${item?.id}`,
                    ...item
                }
            })
            setDataSource(panoItems)
            console.log(panoItems, 'panoItems');
        }
        else {
            setAddPanos(false)
        }
    }, [panoListData])

    const handlePanoClick = (record) => {
        navigate('/start/tour', { state: { record: record,clientId:client_id } });
    };

    const columns = [
        {
            title: 'Preview',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
                return <img src={record.preview} alt="" height={80} width={100} />
            },
            align: 'center'
        },
        {
            title: 'Pano',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
                const [nameWithoutExtension, ...extension] = text.split('.');
                const fileExtension = extension.join('.');
                console.log(fileExtension, 'fileExtension');
                return (
                    <div>
                        {/* {isEditing === index ? (
                            <Input
                                value={editValue}
                                onChange={(e) => setEditValue(e.target.value)}
                                style={{ width: '70%', marginRight: 8 }}
                                suffix={
                                    <Button
                                        shape="circle"
                                        icon={<CheckOutlined />}
                                        onClick={() => {
                                            handleEditName(index, editValue, fileExtension);
                                            setIsEditing(null);
                                        }}
                                    />
                                }
                                onBlur={() => {
                                    handleEditName(index, editValue, fileExtension);
                                    setIsEditing(null);
                                }}
                            />
                        ) : ( */}
                        <span>{text}</span>
                        {/* )o}
                        {isEditing !== index && (
                            <Buttn
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={() => {
                                    setIsEditing(index);
                                    setEditValue(nameWithoutExtension);
                                }}
                                style={{ marginLeft: 8 }}
                            />
                        )} */}
                    </div>
                );
            },
        },
        {
            title: 'Room',
            dataIndex: 'room',
            key: 'room',
            render: (text, record, index) => (
                <Select
                    // value={text}
                    defaultValue={"select_room"}
                    onChange={(value) => handleRoomChange(index, value)}
                    style={{ width: '100%' }}
                >
                    <Option value="select_room">Select Room</Option>
                    <Option value="add">Add Room</Option>
                    {rooms && rooms.map((items) => {
                        return (
                            <Option value={items.id}>{items.room_name}</Option>
                        )
                    })}
                </Select>
            ),
        },
    ];

    const handleTableRoomChange = (index, value, record) => {
        console.log(record, 'record');

        const newTableData = [...dataSource];
        const updatedRow = { ...newTableData[index] };

        // Get the room name based on the selected value (room ID)
        const selectedRoomName = rooms.find(room => room.id === value)?.room_name;

        // Extract the extension from the current pano_name
        const [nameWithoutExtension, ...extension] = record.pano_name.split('.');
        const fileExtension = extension.join('.'); // Dynamic extension from original pano_name

        // Update the room field and pano_name with the dynamic extension
        if (selectedRoomName) {
            updatedRow.pano_name = `${selectedRoomName}.${fileExtension}`;
        }

        updatedRow.room_id = value;

        // Replace the original row with the updated one
        newTableData[index] = updatedRow;

        let postData = {
            room_id: value,
            pano_name: `${selectedRoomName}.${fileExtension}` // Use dynamic extension
        };

        console.log(postData, 'postData');
        dispatch(saveHotspotView({ postData, updateID: record.id }));

        // Update the data source state
        setDataSource(newTableData);
        setBinaryDataArray(newTableData);
    };


    const tableColumns = [
        {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text, record, index) => (
                <>
                    <Button
                        type="text"
                        size="small"
                        icon={<HolderOutlined />}
                        style={{
                            cursor: 'move',
                        }}

                    /> &nbsp;<span>{index + 1}</span>
                </>
            ),
            sorter: false,

        },
        {
            title: 'Pano',
            dataIndex: 'pano_name',
            key: 'pano_name',
            render: (text, record, index) => {
                const [nameWithoutExtension, ...extension] = text.split('.');
                const fileExtension = extension.join('.');
                console.log(record, 'record');
                return (
                    <div>
                        {isEditing === index ? (
                            <Input
                                value={editValue}
                                onChange={(e) => setEditValue(e.target.value)}
                                style={{ width: '70%', marginRight: 8 }}
                                suffix={
                                    <Button
                                        shape="circle"
                                        icon={<CheckOutlined />}
                                        onClick={() => {
                                            handleEditTableName(index, editValue, fileExtension);
                                            setIsEditing(null);
                                        }}
                                    />
                                }
                                onBlur={() => {
                                    handleEditName(index, editValue, fileExtension);
                                    setIsEditing(null);
                                }}
                            />
                        ) : (
                            <span
                                style={{ cursor: 'pointer', color: 'blue' }}
                                onClick={() => handlePanoClick(record)}
                            >
                                {text}
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Link Pano to Room',
            dataIndex: 'room_id',
            key: 'room_id',
            render: (text, record, index) => (
                <Select
                    value={record.room_id ? record.room_id : "select_room"}
                    onChange={(value) => handleTableRoomChange(index, value, record)}
                    style={{ width: '100%' }}
                >
                    <Option value="select_room">Select Room</Option>
                    {rooms && rooms.map((items) => (
                        <Option key={items.id} value={items.id}>{items.room_name}</Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Action",
            align: "center",
            render: (text, record, index) => {
                const [nameWithoutExtension, ...extension] = record.pano_name.split('.');
                const fileExtension = extension.join('.');
                return (<Space size="large" align="center">
                    <SwalComponent
                        message="Are you sure you want to delete the Pano ?"
                        onClickDispatch={deletePano}
                        id={record.id}
                    />
                </Space>
                )
            },
        },
    ];

    useEffect(() => {
        if (hotspotViewSuccess === true) {
            toast.success('Room Updated Successfully!', {
                autoClose: 3000  // Display for 3 seconds (3000 milliseconds)
            });
            dispatch(resetThreeSixtySuccessFlag({ flag: 'hotspotViewSuccess' }));

        }
    }, [hotspotViewSuccess])

    useEffect(() => {
        if ((imageData && imageData.length > 0) && (preview && preview.length > 0)) {
            let temp = preview.map((item, index) => ({
                ...item,
                binaryData: imageData[index] ? imageData[index].original : null,
                room: null
            }));
            if (temp && temp.length > 0) {
                setBinaryDataArray(temp)
            }
            console.log(temp, 'temp');
        }
        else {
            setBinaryDataArray([])
        }
    }, [preview, imageData]);

    useEffect(() => {
        if (job_id) {
            const decodedId = atob(job_id); // Use a different variable name
            console.log(decodedId, 'decodedId');
            dispatch(getJobData({ id: decodedId }));
        }
    }, [job_id, dispatch]);


    useEffect(() => {
        const decodedId = project_id && atob(project_id);
        // if (id) {
        if (!isNaN(decodedId) && decodedId) {
            // const decodedId = atob(id); // Use a different variable name
            dispatch(getProjectData({ id: decodedId }));
        }
    }, [project_id, dispatch]);

    useEffect(() => {
        if (addRoomSuccess) {
            dispatch(resetSuccessFlag({ flag: 'addRoomSuccess' }));
            const decodedId = atob(job_id); // Use a different variable name
            dispatch(getJobData({ id: decodedId }));
            toast.success("Room added Successfully");
        }
    }, [addRoomSuccess])

    function getData(data) {
        const payload = { ...data, id: atob(job_id) };
        console.log("payload", payload);
        dispatch(getPanoList(payload));
    }

    useEffect(() => {
        getData()
    }, [])

    const Row = (props) => {
        const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
            id: props['data-row-key'],
        });
        const style = {
            ...props.style,
            transform: CSS.Translate.toString(transform),
            transition,
            cursor: 'move',
            ...(isDragging
                ? {
                    position: 'relative',
                    zIndex: 9999,
                }
                : {}),
        };
        return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );
    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setOnDrag(true)
            setDataSource((prev) => {
                const activeIndex = prev.findIndex((i) => i.key === active.id);
                const overIndex = prev.findIndex((i) => i.key === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const handleOnChange = (pagination, filters, sorter, extra) => {
        const payloadData = {
            query: "",
            page: pagination.current,
            limit: pagination.pageSize,
            sortby: sorter.order ? sorter.columnKey : "",
            order: sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : "",
        };
        console.log("payloadData", payloadData);
        // onChangeDispatch(payloadData);
        dispatch(getPanoList(payloadData))
    };

    const paginationSetup = {
        className: "me-3",
        size: "small",
        showSizeChanger: true,
        total: panoListData?.count,
        showTotal: (total, range) => {
            console.log(total, range, 'total, range');
            return `Showing ${range[0]} to ${range[1]} of ${total} entries`
        }
        // ${
        //       total !== tableData.length
        //          ? `( Filtered from ${tableData.length} total entries )`
        //          : ""
        //    }
    };

    return (
        <div className="container-fluid-staff">
            {client_id ?
                    <PageHeader
                        HeaderText={<><Link to={`/editproject/${btoa(getAddedProjectData?.id)}/${btoa(getAddedProjectData?.client_id)}`}>{getAddedProjectData?.project_number}
                        </Link>: Upload Panoramas</>}
                        // Breadcrumb={[{ name: type === 'add' ? 'Projects' : 'Projects' }]}
                        backButtonUrl={`/projects/editjob/${project_id}/${client_id}/${job_id}`}
                    />
               :
                <PageHeader
                    HeaderText={<b>{getAddedProjectData?.project_number} :  Upload Panoramas</b>}
                    // Breadcrumb={[{ name: type === 'add' ? 'Projects' : 'Projects' }]}
                    backButtonUrl={`/photographer/jobs`}
                />
            }
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
                {onDrag === true ?
                    <ButtonComponent
                        style={{
                            backgroundColor: "green",
                            color: "white",
                            marginLeft: '2px',
                            marginRight: '8px'  // Add marginRight to create space between buttons
                        }}
                        buttonName="Save Changes"
                        onClick={() => {
                            let datas = []
                            for (let i in dataSource) {
                                datas.push({
                                    id: dataSource[i].id,
                                    sequence_no: Number(i) + 1
                                })
                            }
                            dispatch(panoSequence({ datas: datas }));
                        }}
                        icon={<CheckOutlined style={{ color: 'white' }} />}
                    /> : null
                }
                <ButtonComponent
                    style={{ backgroundColor: '#f7931e', color: 'white' }}
                    buttonName={addPanos === false ? "View Panos" : 'Add Panos'}
                    onClick={() => {
                        setAddPanos(!addPanos)
                    }}
                    icon={<i className="fa fa-file-text" aria-hidden="true"></i>}
                />
            </div>
            {addPanos === false ?
                <CardComponent>
                    <span><center>Please drag and drop your panoramas here. They must be less than 8000 X 4000 and more than 5000 X 2500. Maximum of 4 at a time.</center></span><br />
                    <div {...getBatchRootProps()} className="dz-container">
                        <input {...getBatchInputProps()} />
                        {binaryDataArray.length > 0 ?
                            null :
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </p>}
                        <div className="dz-image">
                            {binaryDataArray.length > 0 ? (
                                binaryDataArray.map((file, index) => (
                                    <Tooltip key={index} title={`${file.name} - ${file.size}`}>
                                        <div className="dz-image-container">
                                            <img src={file.preview} alt="preview" />
                                            <div className="dz-image-overlay">
                                                <strong className='ml-2'>{file.name} - {file.size}</strong>
                                            </div>
                                            <CloseOutlined onClick={(event) => handleRemoveImage(index, event)} className="dz-close-icon" />
                                        </div>
                                    </Tooltip>
                                ))
                            ) : (
                                <strong>Drop your files here</strong>
                            )}
                        </div>
                    </div>
                    {binaryDataArray.length > 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ButtonComponent
                                style={{ backgroundColor: "#f7931e", color: "white" }}
                                buttonName="Next"
                                icon={<ArrowRightOutlined />}
                                onClick={() => setIsModalOpen(true)}
                            />
                        </div> : null}
                </CardComponent>
                :
                <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                    <SortableContext
                        // rowKey array
                        items={dataSource && dataSource.length > 0 ? dataSource.map((i) => i.key) : []}
                        strategy={verticalListSortingStrategy}
                    >
                        <Table
                            components={{
                                body: {
                                    row: Row,
                                },
                            }}
                            bordered
                            rowKey="key"
                            columns={tableColumns}
                            dataSource={dataSource && dataSource.length > 0 ? dataSource : []}
                            onChange={handleOnChange}
                            pagination={paginationSetup}
                        />
                    </SortableContext>
                </DndContext>

            }
            <ModalComponent
                title="Link Pano to Room"
                centered
                open={isModalOpen}
                onCancel={handleCancel}
                width="750px"
                footer={null}
            >
                <Divider />
                {/* <Tag color="cyan">cyan</Tag> */}
                <Table bordered columns={columns} dataSource={binaryDataArray} rowKey="preview" pagination={false} />
                <div className="d-flex align-items-center justify-content-center">
                    <ButtonComponent style={{ backgroundColor: '#f7931e', color: 'white' }}
                        buttonName="Save" onClick={handleSave} icon={<i className="fa fa-file-text" aria-hidden="true"></i>} />
                </div>
            </ModalComponent>
            <Modal
                destroyOnClose
                title="Add Rooms"
                open={isRoomModalOpen}
                onOk={handleRoomSave}
                okText="Save"
                okButtonProps={{ shape: "round", style: { backgroundColor: "#f7931e" } }}
                cancelButtonProps={{
                    shape: "round",
                    style: { backgroundColor: "#6E6E6E", color: "white" },
                }}
                onCancel={handleRoomCancel}
            >
                <Input
                    placeholder="Enter Room name"
                    value={addRoomName.room_name}
                    onChange={e => setAddRoomName({
                        ...addRoomName,
                        room_name: e.target.value
                    })}
                    onKeyDown={(e) => {
                        console.log(e,'eee');
                        if (e.key === 'Enter') {
                            handleSave();
                        }
                    }}
                />
            </Modal>

        </div>

    );
};

export default PanoramaAddEdit;

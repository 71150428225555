import React, { Fragment, useEffect, useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import PageHeader from '../../view/pageheader'
import CardComponent from '../../fields/CardComponent'
import InputComponent from '../../fields/InputComponent'
import { UserOutlined, CheckOutlined, HolderOutlined, UnorderedListOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import SelectComponent from '../../fields/SelectComponent';
import ButtonComponent from '../../fields/ButtonComponent';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Space, Collapse, Row, Col, Divider, Checkbox, Button, Input, Select } from 'antd';
import TableComponent from '../../fields/TableComponent';
import SwalComponent from '../../fields/SwalComponent';
import { CaretRightOutlined } from '@ant-design/icons';
import { addProject, updateProject, getProjectData, resetSuccessFlag, getProjectList } from '../../store/reducer/projectSlice';
import { getClientData, getClientList } from '../../store/reducer/clientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash'
import { deleteJob, getJobList, jobSequence } from '../../store/reducer/jobSlice';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import CardOrCollapse from '../../fields/CardOrCollapse';
import { textAlign } from '@mui/system';
import Loader from '../../fields/loader/Loader';
import { getPhotographerList } from '../../store/reducer/photographerSlice';
import { clearData, doLogin, loginSelector, resetJobStatusResponse, updateJobStatus } from '../../store/reducer/login';

const ManagedProject = () => {

    const { Panel } = Collapse;

    const [projectDetails, setProjectDetails] = useState({
        client_id: '',
        project_id: '',
        project_number: '',
        client_number: '',
        project_type: '',
        project_status: 1,
        project_name: '',
        takestock_rep: '',
        is_demo: 0
    });
    const [clientIds, setClientIds] = useState()
    const [dataSource, setDataSource] = useState([]);
    console.log(projectDetails, 'projectDetails');
    const [type, setType] = useState('add')
    const [onDrag, setOnDrag] = useState(false)
    const [touchedFields, setTouchedFields] = useState({});
    const [forceUpdate, setForceUpdate] = useState(0);
    const { photographerListData } = useSelector(state => state.photographer);

    const status = [
        {
            value: 1,
            label: 'Active',
        },
        {
            value: 2,
            label: 'Completed',
        },
        {
            value: 3,
            label: 'Cancelled',
        }
    ];
    const projectTypes = [
        {
            label: 'Homeowner',
            value: 3,
        },
        {
            label: 'Family Law',
            value: 2,
        },
        {
            label: 'Estate/Probate',
            value: 1,
        },
        {
            label: 'Commercial',
            value: 5,
        },
        {
            label: 'Storage',
            value: 6,
        },
        {
            label: 'Other',
            value: 7,
        }
    ];
    const jobStatusArray = [
        {
            label: 'Scheduled',
            value: 'scheduled'
        },
        {
            label: 'Ready to Edit/Build',
            value: 'ready_to_edit_or_build'
        },
        {
            label: 'Ready to QC',
            value: 'ready_to_qc'
        },
        {
            label: 'Ready to Deliver',
            value: 'ready_to_deliver'
        },
        {
            label: 'Delivered',
            value: 'delivered'
        }
    ]
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, client_id } = useParams();
    const [clientID, setClientID] = useState(
        client_id === "client" ? "" : client_id
    );
    const roleId = localStorage.getItem('role_id')


    // useEffect(() => {
    //     console.log("Test Client Id", atob(client_id));
    // }, [client_id])

    //Selectors
    const {
        addProjectSuccess,
        updateProjectSuccess,
        getAddedProjectData,
        projectListData,
        projectListLoading,
        addProjectLoading,
        getAddedProjectDataLoading, addProjectError, updateProjectError
    } = useSelector(state => state.project);
    const { clientListData, getAddedData } = useSelector(state => state.client)
    const { jobListData, jobSortSuccess, deleteJobSuccess } = useSelector(state => state.job)
    const { jobStatusResponse } = useSelector(loginSelector);
    const roleID = localStorage.getItem('role_id')
    const category_type = localStorage.getItem('category_type')
    const [linkCopied, setLinkCopied] = useState(false);
    const [inputValue, setInputValue] = useState('');


    console.log(jobListData, 'jobListData');
    console.log(getAddedData, 'getAddedData ');
    console.log(jobSortSuccess, 'jobSortSuccess');
    console.log(projectDetails, 'projectDetails');


    //Validation Rules & Validator
    const validator = useRef(new SimpleReactValidator({
        className: 'validation-message',
        messages: {
            required: 'Please enter :attribute',
            email: 'Email should contain @ and .',
            alpha: ':attribute should contain only alphabets',
            regex: ':attribute format is invalid',
            in: ':attribute should be one of the accepted values',
            min: ':attribute must be at least :min characters',
            confirmed: 'Passwords do not match'
        }
    }));

    const validationRules = {
        client_id: 'required|integer',
        project_id: 'integer',
        project_number: '',
        client_number: '',
        project_type: 'required|integer',
        project_status: 'required|integer',
        takestock_rep: 'required',
        project_name: 'required',
        is_demo: 'integer'
    };

    const onStatusChange = (record, value, name) => {
        console.log(record, value, name, 'changes');
        let postData = {
            status: value
        }
        dispatch(updateJobStatus({ postData, job_id: record?.job_id }));
    };

    const columns = [
        {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text, record, index) => (
                <>
                    <Button
                        type="text"
                        size="small"
                        icon={<HolderOutlined />}
                        style={{
                            cursor: 'move',
                        }}

                    /> &nbsp;<span>{index + 1}</span>
                </>
            ),
            sorter: false,

        },
        {
            title: "Job Name",
            // dataIndex: "job_name",
            align: "center",
            sorter: true,
            render: (record) => <span>{record["job_name"] ? record["job_name"] : "-"}</span>
        },
        {
            title: "Job #",
            dataIndex: "job_number",
            align: "center",
            render: (text, record) => <a href="javascript:;" onClick={() => {
                navigate(`/projects/editjob/${btoa(projectDetails.project_id)}/${clientID}/${btoa(record.job_id)}`)
                localStorage.setItem('project_type', projectDetails.project_type)
            }}>{record.job_number}</a>,
            sorter: true,
        },
        {
            title: "Search Count",
            dataIndex: false,
            align: "center",
            sorter: true,
            render: (text, record) => {
                let totalSearchCount = 0; // Initialize the count to zero
                console.log(record,'recordrooms');
                
                if (record.rooms && record.rooms.length > 0) {
                    record.rooms.forEach((room) => {
                        if (room && room.total_search_count != null) {
                            // Convert total_search_count to a number and add it to the total
                            totalSearchCount += Number(room.total_search_count) || 0;
                        }
                    });
                }

                console.log(totalSearchCount, 'totalSearchCount'); // Log the calculated totalSearchCount

                return <span>{totalSearchCount}</span>; // Display the totalSearchCount
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            align: "center",
            sorter: true,
            render: (text, record) => {
                return (
                    <Select
                        name="status"
                        value={record.status ? record.status : 'scheduled'}
                        placeholder="Choose status"
                        onChange={(value) => onStatusChange(record, value, 'status')}
                        onSearch={onSearch}
                        options={jobStatusArray}
                        style={{ width: '80%' }}
                    />
                )
            },
        },
        {
            title: "Job Date",
            dataIndex: "job_date",
            align: "center",
            render: (text, record) => (
                <span>{moment(record.job_date).format("MM/DD/YY")}</span>
            ),
            sorter: true,
        },
        {
            title: "Due Date",
            dataIndex: "due_date",
            align: "center",
            render: (text, record) => {
                return <span>{record.due_date === null ? '-' : moment(record.due_date).format("MM/DD/YY")}</span>
            },
            sorter: true,
        },
        {
            title: "Assigned To",
            dataIndex: "assign_staff_name",
            align: "center",
            sorter: true,
            render: (text, record) => {
                // If status is "scheduled"
                console.log(record, 'record');
                if (record.status === "scheduled") {
                    // Split inventory_lead and get the IDs
                    const inventoryLeadIds = record.inventory_lead ? record.inventory_lead.split(',') : [];

                    // Accessing photographerListData.data for the array of photographers
                    const inventoryLeadNames = photographerListData?.data
                        ?.filter((photographer) => inventoryLeadIds.includes(photographer.id.toString()))
                        .map((photographer) => `${photographer.first_name} ${photographer.last_name}`)
                        .join(', ');
                    console.log(inventoryLeadNames, 'inventoryLeadNames');

                    return (
                        <Tooltip title={inventoryLeadNames ? inventoryLeadNames : "No photographer assigned"}>
                            <span> {inventoryLeadIds.length === 1 ? inventoryLeadNames : `${inventoryLeadIds.length} people`}</span>
                        </Tooltip>
                    );
                }

                // If status is not "scheduled"
                return <span>{record.assign_staff_name ? record.assign_staff_name : "-"}</span>;
            },
        },
        {
            title: "Action",
            align: "center",
            render: (text, record) => (
                <Space size="large" align="center">
                    <Tooltip title="Edit">
                        <span
                            className="fs-6"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                                console.log(record, "record");
                                // Navigate to edit page
                                localStorage.setItem('project_number', projectDetails.project_number)
                                navigate(`/project/editjob/${btoa(projectDetails.project_id)}/${clientID}/${btoa(record.job_id)}`);
                            }}
                        >
                            <i className="icon-pencil" />
                        </span>
                    </Tooltip>
                    {roleID === '2' && category_type === '3' ? null :
                        <SwalComponent
                            message="Are you sure you want to delete the job?"
                            onClickDispatch={deleteJob}
                            id={record.job_id}
                        />}
                </Space>
            ),
        },
    ];

    const Row = (props) => {
        const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
            id: props['data-row-key'],
        });
        const style = {
            ...props.style,
            transform: CSS.Translate.toString(transform),
            transition,
            cursor: 'move',
            ...(isDragging
                ? {
                    position: 'relative',
                    zIndex: 9999,
                }
                : {}),
        };
        return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
    };

    //onChange
    const addEditProjectDetails = (e) => {
        const { name, value } = e.target;
        setProjectDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
        setTouchedFields(prevState => ({
            ...prevState,
            [name]: true
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validator.current.showMessages();
        // console.log("Validation Messages: ", validator.current.getMessages());
        console.log("Validation State: ", validator.current.allValid());
        if (validator.current.allValid()) {
            if (type === 'add') {
                let postData = { ...projectDetails };
                delete postData.project_number;
                delete postData.project_id;
                dispatch(addProject({ postData }));
            } else {
                let postData = { ...projectDetails };
                let updateID = atob(id);
                dispatch(updateProject({ postData, updateID }));
            }
        } else {
            validator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
        }
    };


    const reset = () => {
        setProjectDetails({
            ...projectDetails,
            client_id: '',
            project_id: '',
            project_number: '',
            client_number: '',
            project_type: '',
            project_status: '',
            project_name: '',
            takestock_rep: '',
            is_demo: 0
        })
    }

    const onChange = (value, name) => {
        setProjectDetails(prevState => ({
            ...prevState,
            [name]: value,
        }));

    };

    const checkboxOnChange = (e) => {
        console.log(e.target.value, 'eeee');
        setProjectDetails({
            ...projectDetails,
            is_demo: e.target.value === 0 ? 1 : 0
        })
    }

    const onSearch = (value) => {
        console.log('search:', value);
    };

    useEffect(() => {
        if (addProjectSuccess) {
            setProjectDetails({
                ...projectDetails,
                client_id: '',
                project_type: '',
                project_status: '',
                project_name: '',
                takestock_rep: '',
                is_demo: 1
            })
            toast.success('Project Added Successfully!');
            dispatch(resetSuccessFlag({ flag: 'addProjectSuccess' }));
            navigate(-1);

        }
        if (updateProjectSuccess) {
            // setProjectDetails({
            //     ...projectDetails,
            //     client_id: '',
            //     project_type: '',
            //     project_status: '',
            //     project_name: '',
            //     takestock_rep: '',
            //     is_demo: 1
            // })
            toast.success('Project Updated Successfully!');
            dispatch(resetSuccessFlag({ flag: 'updateProjectSuccess' }));
            // navigate(-1);
        }
        if (addProjectError) {
            toast.error(addProjectError);
            dispatch(resetSuccessFlag({ flag: 'addProjectError' }));
        }
        if (updateProjectError) {
            toast.error(updateProjectError);
            dispatch(resetSuccessFlag({ flag: 'updateProjectError' }));
        }
    }, [addProjectSuccess, updateProjectSuccess, addProjectError, updateProjectError, dispatch, navigate]);

    useEffect(() => {
        if (id) {
            const decodedId = id && atob(id);
            // if (id) {
            if (!isNaN(decodedId) && decodedId) {
                setType("edit");
                // const decodedId = atob(id); // Use a different variable name
                dispatch(getProjectData({ id: decodedId }));
            } else {
                setType("add");
            }
        }
    }, [id, dispatch]);

    console.log(deleteJobSuccess, 'deleteJobSuccess');

    useEffect(() => {
        if (deleteJobSuccess) {
            const decodedId = atob(id); // Use a different variable name
            dispatch(getProjectData({ id: decodedId }));
            toast.success('Job Deleted Successfully !')
            if (roleId === '2') {
                const decodedId = atob(id); // Use a different variable name
                dispatch(getJobList({ id: decodedId, type: 'staff', staffid: Number(localStorage.getItem('staff_id')) }));
            }
            else {
                const decodedId = atob(id); // Use a different variable name
                dispatch(getJobList({ id: decodedId, type: 'admin', staffid: '' }));
            }
        }
    }, [deleteJobSuccess, id, dispatch])

    useEffect(() => {
        const clientNumber = localStorage.getItem('client_number');
        console.log(clientNumber, 'clientNumber');
        if (clientNumber) {
            setProjectDetails({
                ...projectDetails,
                client_number: clientNumber
            })
        }
    }, []);

    useEffect(() => {
        // console.log("ClientID", atob(clientID));
        const decode = clientID && atob(clientID);

        if (client_id !== "client" && client_id) {
            const decodedId = atob(client_id); // Use a different variable name
            dispatch(getClientData({ id: decodedId }));
        }

        if (!isNaN(decode) && decode) {
            console.log("clientID", atob(clientID));
            dispatch(getClientData({ id: decode }));
        }
    }, [client_id, dispatch, clientID]);

    useEffect(() => {
        if (getAddedData) {
            setProjectDetails({
                ...projectDetails,
                client_id: getAddedData?.id,
                client_number: getAddedData?.client_number
            })

            if (client_id === "client") {
                setClientID(btoa(getAddedData.id));
            }
        }
    }, [getAddedData])

    useEffect(() => {
        if (getAddedProjectData && type === 'edit') {
            console.log("Update Projects Data");
            setProjectDetails({
                ...projectDetails,
                client_id: getAddedProjectData?.client_id,
                client_number: getAddedProjectData?.client_number,
                project_type: getAddedProjectData?.project_type,
                project_number: getAddedProjectData?.project_number,
                project_status: getAddedProjectData?.project_status,
                project_name: getAddedProjectData?.project_name,
                takestock_rep: getAddedProjectData?.takestock_rep,
                is_demo: getAddedProjectData?.is_demo,
                project_id: getAddedProjectData?.id
            })

            if (client_id === "client") {
                setClientID(btoa(getAddedProjectData.client_id));
            }
        }
    }, [getAddedProjectData, type])

    useEffect(() => {
        if (!projectDetails.project_type && getAddedProjectData.project_type) {
            console.log("projectDetailssss", projectDetails);
            setProjectDetails({
                ...projectDetails,
                client_id: getAddedProjectData?.client_id,
                client_number: getAddedProjectData?.client_number,
                project_type: getAddedProjectData?.project_type,
                project_number: getAddedProjectData?.project_number,
                project_status: getAddedProjectData?.project_status,
                project_name: getAddedProjectData?.project_name,
                takestock_rep: getAddedProjectData?.takestock_rep,
                is_demo: getAddedProjectData?.is_demo,
                project_id: getAddedProjectData?.id
            })
        }
    }, [projectDetails, getAddedProjectData])

    useEffect(() => {
        if (jobSortSuccess) {
            toast.success('Job Sequence Updated Successfully !');
            // dispatch(getJobList({}));
            if (roleId === '2') {
                const decodedId = atob(id); // Use a different variable name
                dispatch(getJobList({ id: decodedId, type: 'staff', staffid: Number(localStorage.getItem('staff_id')) }));
            }
            else {
                const decodedId = atob(id); // Use a different variable name
                dispatch(getJobList({ id: decodedId, type: 'admin', staffid: '' }));
            }
            const decodedId = atob(id); // Use a different variable name
            dispatch(getProjectData({ id: decodedId }));
        }
    }, [jobSortSuccess, id, dispatch])

    useEffect(() => {
        dispatch(getClientList({}))
        dispatch(getProjectList({}))
        dispatch(getPhotographerList({}))
    }, [])

    useEffect(() => {
        if (id) {
            if (roleId === '2') {
                const decodedId = atob(id); // Use a different variable name
                dispatch(getJobList({ id: decodedId, type: 'staff', staffid: Number(localStorage.getItem('staff_id')) }));
            }
            else {
                const decodedId = atob(id); // Use a different variable name
                dispatch(getJobList({ id: decodedId, type: 'admin', staffid: '' }));
            }
        }
    }, [id, roleId])

    useEffect(() => {
        if (jobStatusResponse === true) {
            toast.success('Job Status Updated Successfully !')
            if (roleId === '2') {
                const decodedId = atob(id); // Use a different variable name
                dispatch(getJobList({ id: decodedId, type: 'staff', staffid: Number(localStorage.getItem('staff_id')) }));
            }
            else {
                const decodedId = atob(id); // Use a different variable name
                dispatch(getJobList({ id: decodedId, type: 'admin', staffid: '' }));
            }
            dispatch(resetJobStatusResponse());
        }
    }, [id, roleId, jobStatusResponse])

    useEffect(() => {
        if (clientListData && clientListData.data && clientListData.data.length > 0) {
            const options = clientListData.data.map((item) => ({
                value: item.id,
                label: item.client_number
            }));
            console.log(options, 'options');
            setClientIds(options)
        }
    }, [clientListData])

    // Drag and Drop Table 

    console.log(getAddedProjectData, 'getAddedProjectData');


    //Data restructure
    useEffect(() => {
        console.log(jobListData, 'jobListData');

        if (jobListData && jobListData.data && jobListData.data.length > 0) {
            const jobItems = jobListData.data.map((item, i) => {
                return {
                    key: `${i + 1}`,
                    job_name: item.job_name,
                    job_number: item.job_number,
                    assign_staff_name: item.assign_staff_name,
                    job_id: item.id,
                    search_count: item.id,
                    status: item.status,
                    job_date: item.date,
                    due_date: item.due_date,
                    assign_to_staff: item.assign_to_staff_name,
                    inventory_lead: item.inventory_lead,
                    rooms: item.rooms
                }
            })
            console.log(jobItems, 'jobItems');
            setDataSource(jobItems)
        }
        else {
            setDataSource([])
        }
    }, [jobListData])

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );
    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setOnDrag(true)
            setDataSource((prev) => {
                const activeIndex = prev.findIndex((i) => i.key === active.id);
                const overIndex = prev.findIndex((i) => i.key === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const handleButtonClick = (e) => {
        e.preventDefault(); // Prevents the default behavior of the Link
        localStorage.setItem('client_id', projectDetails.client_id)
        localStorage.setItem('project_type', projectDetails.project_type)
        localStorage.setItem('project_id', projectDetails.project_id)
        console.log(projectDetails.project_type, 'projectDetails.project_type');
        window.open(`${process.env.PUBLIC_URL}/preview/clientportal/home/${btoa(projectDetails?.project_id)}`, '_blank');
    };

    console.log(process.env.PUBLIC_URL, 'process.env.PUBLIC_URL');


    const copyDemoLink = () => {
        // Ensure projectDetails is defined and contains the required properties
        if (projectDetails && projectDetails.client_id && projectDetails.project_type) {
            // Set items in localStorage
            localStorage.setItem('client_id', projectDetails.client_id);
            localStorage.setItem('project_id', projectDetails.project_id)
            localStorage.setItem('project_type', projectDetails.project_type);

            // Verify that the values are correctly stored in localStorage
            console.log('client_id:', localStorage.getItem('client_id'));
            console.log('project_type:', localStorage.getItem('project_type'));

            // Determine the path based on project_type
            const path = `${process.env.PUBLIC_URL}/preview/clientportal/home/${btoa(projectDetails?.project_id)}`;

            // Copy the path to the clipboard
            navigator.clipboard.writeText(path).then(() => {
                // Update the link text to "Copied!" and set it to green
                setLinkCopied(true);

                // Optionally reset the text after a few seconds
                setTimeout(() => {
                    setLinkCopied(false);
                }, 3000); // Reset after 3 seconds
            });
        } else {
            console.error('projectDetails is not defined or missing required properties.');
        }
    };

    const handleClear = (event) => {
        event.stopPropagation();
        setInputValue('');
        const decodedId = atob(id); // Use a different variable name
        dispatch(getJobList({ id: decodedId, type: 'staff', staffid: Number(localStorage.getItem('staff_id')), query: '' }));

    };


    const handleSearchChange = (e) => {
        e.stopPropagation();
        setInputValue(e.target.value);
        const decodedId = atob(id); // Use a different variable name
        dispatch(getJobList({ id: decodedId, type: 'staff', staffid: Number(localStorage.getItem('staff_id')), query: e.target.value }));

    };

    console.log(dataSource, 'dataSource');

    return (
        <div className="container-fluid-staff" >
            <Loader loading={addProjectLoading || projectListLoading || getAddedProjectDataLoading} />
            {location.pathname.startsWith('/editclient/') ? null :
                <PageHeader
                    HeaderText={type === 'add' ? 'Add Project' : 'Update Project'}
                    Breadcrumb={[{ name: type === 'add' ? 'Projects' : 'Projects' }]}
                    backButtonUrl={client_id === "client" ? `/projects` : `/editclient/${client_id}`}
                />
            }
            {/* <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
                <Panel key="1" style={{ backgroundColor: 'white', cursor: 'pointer' }}
                    header={ */}
            <CardOrCollapse type={type} titleName={'Add TakeStock Project Details'} panelHeader={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer' }}>
                    <div>
                        <strong>{type === 'add' ? 'Add TakeStock Project Details' : 'Edit TakeStock Project Details'}</strong>
                    </div>
                    <div>
                        {type === 'edit' && (
                            <div style={{ display: 'flex', gap: '16px' }}>
                                <Link to={`/project/item-categories/${id}/${clientID}`}>
                                    <ButtonComponent
                                        style={{ backgroundColor: "#f7931e", color: "white" }}
                                        buttonName="Item categories"
                                        icon={<UnorderedListOutlined />}
                                    />
                                </Link>
                                <Link to={`/project/portalusers/${btoa(projectDetails.project_number)}/${id}/${clientID}`}>
                                    <ButtonComponent
                                        style={{ backgroundColor: "#f7931e", color: "white" }}
                                        buttonName="Portal Users"
                                        icon={<i className="fa fa-users" aria-hidden="true"></i>}
                                    />
                                </Link>

                                <ButtonComponent
                                    style={{ backgroundColor: "#6E6E6E", color: "white" }}
                                    buttonName="Preview"
                                    icon={<i className="fa fa-eye" aria-hidden="true"></i>}
                                    onClick={handleButtonClick}
                                />

                            </div>
                        )}
                    </div>
                </div>
            }
            >
                {/* <Divider/> */}
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="client_id">Client ID</label>
                                <span className="m-1" style={{ color: 'red' }}>*</span><br />
                                {type === 'add' ?
                                    <a href="javascript:;" onClick={() => {
                                        navigate(`/editclient/${btoa(projectDetails.client_id)}`);
                                    }}>{projectDetails.client_number}</a>
                                    :
                                    <a href="javascript:;" onClick={() => {
                                        navigate(`/editclient/${btoa(projectDetails.client_id)}`);
                                    }}>{projectDetails.client_number}</a>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="project_type">Project Type</label>
                                <span className="m-1" style={{ color: 'red' }}>*</span><br />
                                <SelectComponent
                                    name="project_type"
                                    value={projectDetails.project_type ? projectDetails.project_type : null}
                                    placeholder="Select Project Type"
                                    onChange={(value) => onChange(value, 'project_type')}
                                    onSearch={onSearch}
                                    options={projectTypes}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                    disabled={type === 'edit' ? true : false}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="project_status">Project Status</label>
                            <span className="m-1" style={{ color: 'red' }}>*</span><br />
                            <SelectComponent
                                name="project_status"
                                value={projectDetails.project_status ? projectDetails.project_status : null}
                                placeholder="Select Project Status"
                                onChange={(value) => onChange(value, 'project_status')}
                                onSearch={onSearch}
                                options={status}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-2">
                    <div className="row">
                        <div className="col-md-4">
                            <div>
                                <label htmlFor="project_name">Project Name</label>
                                <span className="m-1" style={{ color: 'red' }}>*</span><br />
                                <InputComponent
                                    name="project_name"
                                    placeholder="Project Name"
                                    type="text"
                                    icon={<UserOutlined />}
                                    value={projectDetails.project_name}
                                    onChange={addEditProjectDetails}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="takestock_rep">TakeStock Rep</label>
                            <span className="m-1" style={{ color: 'red' }}>*</span><br />
                            <InputComponent
                                name="takestock_rep"
                                placeholder="TakeStock Rep"
                                type="text"
                                icon={<UserOutlined />}
                                value={projectDetails.takestock_rep}
                                onChange={addEditProjectDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="is_demo">Demo Project</label><br />
                            <Checkbox value={projectDetails.is_demo} checked={projectDetails.is_demo === 1 ? true : false} onChange={checkboxOnChange} /> &nbsp;
                            {type === 'edit' && projectDetails.is_demo === 1 ? (
                                <a href="javascript:;" onClick={copyDemoLink} style={{ color: linkCopied ? 'green' : 'initial' }}>
                                    {linkCopied ? 'Copied!' : 'Copy Demo Link'}
                                </a>
                            ) : (
                                ''
                            )}

                        </div>
                    </div>
                </div>
                {type === 'edit' ?
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="project_name">Project Number</label><br />
                                <strong>{projectDetails.project_number}</strong>
                            </div>
                        </div>
                    </div> : null}
                {roleID === '2' && category_type === '3' && type === 'add' ?
                    <div className='text-center mt-3'>
                        <ButtonComponent style={{ backgroundColor: '#f7931e', color: 'white' }} onClick={handleSubmit} buttonName={"Add Project"} icon={<i class="fa fa-file-text" aria-hidden="true"></i>} />&nbsp;
                        <ButtonComponent style={{ backgroundColor: '#6E6E6E', color: 'white' }} onClick={reset} buttonName="Reset" icon={<i class="fa fa-refresh" aria-hidden="true"></i>} />
                    </div>
                    :
                    roleID === '2' && category_type === '3' && type === 'edit' ? null :
                        <div className='text-center mt-3'>
                            <ButtonComponent style={{ backgroundColor: '#f7931e', color: 'white' }} onClick={handleSubmit} buttonName={type === 'add' ? "Add Project" : "Update Project"} icon={<i class="fa fa-file-text" aria-hidden="true"></i>} />&nbsp;
                            <ButtonComponent style={{ backgroundColor: '#6E6E6E', color: 'white' }} onClick={reset} buttonName="Reset" icon={<i class="fa fa-refresh" aria-hidden="true"></i>} />
                        </div>}
            </CardOrCollapse>
            {/* </Panel>
            </Collapse> */}
            {type === 'edit' ?
                <><div className='mt-2'>
                    <CardComponent
                        title={"Jobs"}
                        extra={
                            roleID === '2' && category_type === '3' ? null :
                                <Space size={"middle"} className="my-3">
                                    {onDrag === true ?
                                        <ButtonComponent
                                            style={{ backgroundColor: "green", color: "white" }}
                                            onClick={() => {
                                                let jobs = []
                                                for (let i in dataSource) {
                                                    jobs.push({
                                                        id: dataSource[i].job_id,
                                                        sequence_no: Number(i) + 1
                                                    })
                                                }
                                                dispatch(jobSequence({ jobs: jobs }));

                                                console.log(jobs, 'jobsss');
                                            }}
                                            buttonName="Save Changes"
                                            icon={<CheckOutlined style={{ color: 'white' }} />}
                                        /> : null
                                    }
                                    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                        <Input
                                            placeHolder="Search Jobs"
                                            className="rounded rounded-4"
                                            prefix={<SearchOutlined />}
                                            value={inputValue}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                            onChange={handleSearchChange}
                                            style={{ paddingRight: '30px' }} // Adjust padding to accommodate clear icon
                                        />
                                        {inputValue && (
                                            <CloseCircleOutlined
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                    zIndex: 1,
                                                    color: '#000', // Ensure the icon is visible
                                                }}
                                                onClick={handleClear}
                                            />
                                        )}
                                    </div>
                                    <Link to={`/project/addjob/${btoa(projectDetails.project_id)}/${client_id}`}>
                                        <ButtonComponent
                                            style={{ backgroundColor: "#f7931e", color: "white" }}
                                            onClick={() => {
                                                console.log(projectDetails.project_id, clientID, 'projectDetails.project_number');
                                                localStorage.setItem('client_id', projectDetails.client_id);
                                                localStorage.setItem('project_number', projectDetails.project_number)
                                            }}
                                            buttonName="Add Jobs"
                                            icon={<i className="fa fa-file-text" aria-hidden="true"></i>}
                                        />
                                    </Link>
                                </Space>
                        }
                    >
                        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                            <SortableContext
                                // rowKey array
                                items={dataSource.map((i) => i.key)}
                                strategy={verticalListSortingStrategy}
                            >
                                <Table
                                    components={{
                                        body: {
                                            row: Row,
                                        },
                                    }}
                                    rowKey="key"
                                    columns={columns}
                                    dataSource={dataSource}
                                />
                            </SortableContext>
                        </DndContext>
                    </CardComponent>
                </div>
                </> : null}
        </div>
    )
}

export default ManagedProject
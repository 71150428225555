import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/takeStock_logo.png";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
// import { ToastrContext } from "../utils/toastr";
import {
  doLogin,
  loginSelector,
} from "../store/reducer/login";
import { API_STATUS } from "../utils/constants";

function Login() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // const toastr = useContext(ToastrContext);
  const [loginDetails, setLoginDetails] = useState({ email: "", password: "" });
  const { loginData, loading } = useSelector(loginSelector);
  const [passwordType, setpasswordType] = useState(false);
  const roleID = localStorage.getItem('role_id')
  const portalUserType = localStorage.getItem('pu_type')
  const currentUrl = window.location.href;
  const demoMode = currentUrl.includes('preview')

  console.log(loginData, 'loginData');

  const onLoginSubmit = () => {
    let postData = loginDetails;
    dispatch(doLogin({ postData }));
  };

  useEffect(() => {
    console.log(loading, "loading")
    if (loading === API_STATUS.FULFILLED) {
      if (demoMode === false) {
        toast.dismiss();
        toast.success("Loggedin Successfully!");
      }
      if (roleID === '1' || roleID === '2') {
        window.location.href = "/dashboard";
      }
      else if (roleID === '3') {
        window.location.href = "/photographer/dashboard";
      }
      else if (roleID === '4') {
        if (portalUserType === "1") {
          window.location.href = "/clientportal/home";
        } else if (portalUserType === "2") {
          window.location.href = "/clientportal/executor/home";
        } else if (portalUserType === "3") {
          window.location.href = "/clientportal/user/home";
        } else if (portalUserType === "4") {
          window.location.href = "/clientportal/home";
        }
      }
    }
    if (loading === API_STATUS.REJECTED) {
      toast.dismiss();
      toast.error('Login Failed! Please check username and password');
      setLoginDetails({
        ...loginDetails,
        email: "",
        password: ""
      })
    }
  }, [loading, demoMode]);

  return (
    <div className="theme-cyan">
      <div className="page-loader-wrapper" style={{ display: 'none' }}>
        <div className="loader">
          <div className="m-t-30"><img src={Logo} width="auto" height="55" alt="Takestock" /></div>
          <p>Please wait...</p>
        </div>
      </div>
      <div className="hide-border">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                <img src={Logo} alt="Takestock" style={{ height: "55px", width: "auto", margin: "10px" }} />
              </div>
              <div className="card" onKeyDown={(e) => {
                console.log(e.key, 'e.key');
                if (e.key === 'Enter') {
                  onLoginSubmit();
                }
              }}>
                <div className="header">
                  <p className="lead">Login to your account</p>
                </div>
                <div className="body">
                  <div className="form-auth-small">
                    <div className="form-group">
                      <label className="control-label sr-only">Email</label>
                      <input
                        className="form-control"
                        id="signin-email"
                        placeholder="Email / User Name"
                        type="email"
                        value={loginDetails.email}
                        onChange={val => {
                          setLoginDetails({ ...loginDetails, email: val.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label sr-only">
                        Password
                      </label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          id="signin-password"
                          placeholder="Password"
                          type={(!passwordType) ? "password" : "text"}
                          value={loginDetails.password}
                          onChange={val => {
                            setLoginDetails({ ...loginDetails, password: val.target.value });
                          }}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" onClick={(e) => { setpasswordType(!passwordType) }}><i className={(!passwordType) ? "fa fa-eye" : "fa fa-eye-slash"} id="togglePassword" style={{ cursor: "pointer" }}></i></span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group clearfix">
                      <label className="fancy-checkbox element-left">
                        <input type="checkbox" />
                        <span>Remember me</span>
                      </label>
                    </div> */}
                    <a
                      className="btn btn-primary btn-lg btn-block"
                      href="javascript:void(0);"
                      onClick={onLoginSubmit}
                      onKeyDown={(e) => {
                        console.log(e.key, 'ekey');

                        if (e.key === 'Enter') {
                          onLoginSubmit();
                        }
                      }}
                    >Login</a>
                    <div className="bottom">
                      <span className="helper-text m-b-10">
                        <i className="fa fa-lock"></i>{" "}
                        <a href={`${process.env.PUBLIC_URL}/forgotpassword`}
                        >
                          Forgot password?
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Divider, Input, Modal, Row, Space, Spin } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useEffect, useRef, useState } from "react";
import { FaFileDownload, FaFileExport } from "react-icons/fa";
import { MdOutgoingMail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import Logo from "../../assets/images/takeStock_logo.png";
import Loader, { LoaderIcon } from "../../fields/loader/Loader";
import { getItemCategoriesList } from "../../store/reducer/itemCategoriesSlice";
import PageHeader from "../../view/pageheader";
import FullViewCard from "./FullViewCard";
import GridViewCard from "./GridViewCard";
import { formatToDollars } from "../../utils/formatToDollars";
import { toast } from "react-toastify";
import { emailShare, resetEmailShareState } from "../../store/reducer/reportsSlice";

const GeneratedReport = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const dispatch = useDispatch();
   const roleID = localStorage.getItem("role_id");
   const clientID = localStorage.getItem("client_id");
   const user_name = localStorage.getItem("username");
   const displayName = localStorage.getItem("display_name");
   const portalUserProjectID = localStorage.getItem("pu_project_id");
   const {
      data,
      selectedFields,
      reportType,
      selectedItems,
      projectType,
      portalUserType,
      gridReportType,
      returnUrl,
   } = location.state;
   const reportRef = useRef();
   const [isLoading, setLoading] = useState(false);
   const [reportItems, setReportItems] = useState([]);
   const {
      clientReportsData,
      clientReportsLoading,
      clientReportsSuccess,
      clientReportsError,
      clientReportsErrorMessage,
      emailShareLoading,
      emailShareSuccess,
      emailShareError,
      emailShareErrorMessage,
   } = useSelector(state => state.reports);
   const { count, rows } = clientReportsData;
   const { itemCategoriesListData } = useSelector(state => state.itemCategories);

   const [itemCategory, setItemCategory] = useState({});
   const [itemClaimReasons] = useState({
      1: "Pre-Marriage",
      2: "Gift",
      3: "Inherited",
      4: "Bought During Marriage",
      5: "Other - See Notes",
   });

   const [itemCondition] = useState({
      1: "Excellent",
      2: "Good",
      3: "Fair",
      4: "Poor",
      5: "Refurbished",
      6: "Repair",
   });

   const inventoryRoutes = {
      1: "/clientportal/home",
      2: "/clientportal/executor/home",
      3: "/clientportal/user/home",
      4: "/clientportal/home",
   };

   const [isShareModalOpen, setIsShareModalOpen] = useState(false);
   const [shareDetails, setShareDetails] = useState({
      name: "",
      email: "",
      message: "",
   });

   const { TextArea } = Input;

   useEffect(() => {
      const groupById = arr => {
         // console.log("arr", arr);

         const grouped = arr.reduce((acc, item) => {
            if (!acc[item.room_id]) {
               acc[item.room_id] = [];
            }
            acc[item.room_id].push(item);
            return acc;
         }, {});

         // Convert the grouped object to an array of arrays
         console.log("grouped values", Object.values(grouped));
         return Object.values(grouped);
      };

      // if (clientReportsData?.rows?.length) {
      //    const keys = Object.keys(selectedItems);
      //    const filterSelectedItems = clientReportsData?.rows?.filter(item =>
      //       keys.includes(String(item.id))
      //    );
      //    const groupedItems = groupById(filterSelectedItems);
      //    // setReportItems(filterSelectedItems);
      //    setReportItems(groupedItems);
      // }

      if (data?.length) {
         const keys = Object.keys(selectedItems);
         const filterSelectedItems = data.filter(item => keys.includes(String(item.id)));
         const groupedItems = groupById(filterSelectedItems);
         // setReportItems(filterSelectedItems);
         setReportItems(groupedItems);
      }
   }, [data]);

   useEffect(() => {
      dispatch(getItemCategoriesList({ id: portalUserProjectID }));
      console.log("SelectedFields", selectedFields);
   }, []);

   useEffect(() => {
      console.log("itemCategores", itemCategoriesListData);
      if (itemCategoriesListData?.data?.length) {
         const { data } = itemCategoriesListData;
         let categories = {};
         data.forEach(item => (categories[item.id] = item.category_name));
         setItemCategory(categories);
      }
   }, [itemCategoriesListData]);

   useEffect(() => {
      if (!emailShareLoading && emailShareSuccess) {
         toast.success("Email sent successfully");
         setIsShareModalOpen(false);
         dispatch(resetEmailShareState());
         setShareDetails({
            name: "",
            email: "",
            message: "",
         });
      }

      if (!emailShareLoading && emailShareError) {
         toast.error(emailShareErrorMessage);
         setIsShareModalOpen(false);
         dispatch(resetEmailShareState());
         setShareDetails({
            name: "",
            email: "",
            message: "",
         });
      }
   }, [emailShareSuccess, emailShareError, emailShareLoading]);

   const fullReportDownload = (isBlob = false) => {
      // const elements = document.getElementsByClassName("reports-item");
      const elements = document.getElementsByClassName("pdf-image-container");
      const pdf = new jsPDF();
      const logo = Logo; // Provide the path to your logo image
      const reportForName = `Report For : ${roleID === "4" ? displayName : user_name}`; // Provide your top content
      let inventoryName = "Inventory  : (Inventory Name)"; // Provide your top content
      let roomName = "Room: (Room Name)";
      let roomValue = "Room Value : (Total Room Value)";
      const date = new Date().toLocaleDateString(); // Current date
      let pdfPageCount = 0;
      const textWidthCalculation = [reportForName, inventoryName, roomValue];
      const textWidth =
         Math.max(...textWidthCalculation.map(item => Math.floor(pdf.getTextWidth(item)))) - 10;
      console.log("maximum", textWidth);
      let totalRoomValues = [];
      let summary = [];

      function calculteTotalRoomValues() {
         totalRoomValues = reportItems.map((item, index) => {
            return item.reduce((acc, value) => {
               let data = value.item_claim?.find(
                  claim => claim.portal_user_id === Number(clientID)
               );

               if (data?.estimated_value) {
                  acc += Number(data.estimated_value) || 0;
               } else {
                  acc += Number(value.google_value_range) || 0;
               }

               return acc;
            }, 0);
         });

         // roomValue = `Room Value : ${totalRoomValues[0]}`;
      }

      calculteTotalRoomValues();

      reportItems.forEach((item, index) => {
         summary.push({
            roomName: item[0]?.room?.room_name,
            itemsCount: item.length,
            total: totalRoomValues[index],
         });
      });

      console.log("summary", summary);

      let total = summary.reduce(
         (acc, current) => {
            return {
               ...acc,
               itemsCount: acc["itemsCount"] + current.itemsCount,
               total: acc["total"] + current.total,
            };
         },
         {
            roomName: "Total Value",
            itemsCount: 0,
            total: 0,
         }
      );

      summary.push(total);

      summary = summary.map(item => ({ ...item, total: formatToDollars(item.total) }));

      console.log("summary", summary);

      const reportSummary = (doc, startX = 5, startY = 35) => {
         pdf.addPage();

         const padding = 5;
         const pageWidth = pdf.internal.pageSize.getWidth();

         // Add logo and top content
         const logoWidth = 50;
         const logoHeight = 10;

         pdf.setFontSize(12);
         pdf.addImage(logo, "PNG", padding, padding, logoWidth, logoHeight); // Adjust the position and size as needed
         pdf.text(reportForName, pageWidth - padding - textWidth, padding / 2 + 5);

         // Add horizontal line after logo and top content
         const lineHeight = padding + logoHeight + 5;
         pdf.setLineWidth(0.5);
         pdf.setDrawColor("#155CA2");
         pdf.line(padding, lineHeight, pageWidth - padding, lineHeight);
         pdf.setFont("helvetica", "bold"); // Set font style to bold
         pdf.setFontSize(12); // Set font size to 16
         pdf.text("Report Summary", padding, lineHeight + 8);
         // pdf.text(roomValue, pageWidth - padding - (textWidth + 5), lineHeight + 8);

         const columns = [
            { title: "Breakdown by Room", dataKey: "roomName" },
            { title: "Item Count", dataKey: "itemsCount" },
            { title: "Value", dataKey: "total" },
            // { title: "AI Estimate", dataKey: "google_value" },
         ];

         const rows = summary;

         // Only pt supported (not mm or in)
         doc.autoTable(columns, rows, {
            startY: startY,
            margin: { left: startX, right: 5 },
            styles: {
               fontSize: 10,
               cellPadding: 1,
               halign: "center",
               lineWidth: 0.1, // Border thickness
               lineColor: [200, 200, 200], // Border color (black)
            },
            tableLineColor: [200, 200, 200], // Outer table border color (black)
            tableLineWidth: 0.1, // Outer table border thickness
            headStyles: {
               halign: "center", // Center-align all table headers
               fontStyle: "bold",
               fontSize: 11,
            },
            columnStyles: {
               id: { fillColor: 255 },
            },
            didParseCell: function (data) {
               // Check if it's the last row in the table
               const isLastRow = data.row.index === data.table.body.length - 1;
               if (isLastRow) {
                  data.cell.styles.fontStyle = "bold";
                  // data.cell.styles.cellPadding = { top: 2 }; // Apply bold style to last row
               }
            },
            theme: "plain",
            // tableWidth: "wrap",
            rowPageBreak: "avoid",
            // maxBodyHeight: maxHeight,
         });
         return;
      };

      const createTable = (doc, startY, maxHeight, mainIndex, subIndex) => {
         const labels = {
            display_name: "UserName",
            name: "Item Name",
            client_value_range: "Client's Estimate",
            google_value_range: "AI Estimate",
            category: "Category",
            quantity: "Quantity",
            distribute_to: "Assign To",
            item_condition: "Condition",
            purchase_date: "Purchase Date",
            dispose_by: "Appraise",
            distribute: "Do you want this item",
            claimed_as: "Reason for Claim",
            model: "Model",
            serial_number: "Serial Number",
            notes: "Notes",
         };

         let data = reportItems[mainIndex][subIndex];
         const claim = data?.item_claim?.find(item => item.portal_user_id === Number(clientID));

         if (claim?.portal_user_id) {
            const claimReason = itemClaimReasons[claim?.claimed_as];

            let dataValues = {
               ...data,
               google_value_range: formatToDollars(data?.google_value_range),
               display_name: claim?.portalusers?.display_name,
               distribute_to: claim?.distribute_to || "",
               quantity: claim?.quantity || "",
               category: itemCategory[claim?.category] || "",
               client_value_range: formatToDollars(claim?.estimated_value),
               item_condition: itemCondition[claim?.item_condition],
               model: claim?.model,
               serial_number: claim?.serial_number,
               dispose_by: claim?.dispose_by?.includes(1) ? "Yes" : "No",
               purchase_date: claim?.purchase_date
                  ? new Date(claim?.purchase_date).toLocaleDateString()
                  : "-",
               distribute: claim?.distribute == 1 ? "Yes" : claim?.distribute == 0 ? "No" : "",
               claimed_as: claimReason,
               notes: claim?.comment,
               model: claim?.model,
               serial_number: claim?.serial_number,
            };

            data = dataValues;
         } else {
            let dataValues = {
               ...data,
               google_value_range: formatToDollars(data?.google_value_range),
               client_value_range: formatToDollars(0),
            };

            data = dataValues;
         }

         const pageWidth = doc.internal.pageSize.width;

         // Define table columns
         const columns = [
            { header: "Label 1", dataKey: "label1" },
            { header: "Value 1", dataKey: "value1" },
            { header: "Label 2", dataKey: "label2" },
            { header: "Value 2", dataKey: "value2" },
         ];

         // Prepare table rows
         const rows = [];
         // const keys = Object.keys(labels);
         const keys = selectedFields;
         for (let i = 0; i < keys.length; i += 2) {
            const row = {
               label1: labels[keys[i]] + ":",
               value1: data[keys[i]],
               label2: i + 1 < keys.length ? labels[keys[i + 1]] + ":" : "",
               value2: i + 1 < keys.length ? data[keys[i + 1]] : "",
            };
            rows.push(row);
         }

         // Add the table
         doc.autoTable({
            columns,
            body: rows,
            startY: startY, // Position the table right after the image
            margin: { left: 10, right: 10 }, // Page margins
            styles: {
               fontSize: 8, // Reduced font size to fit within space
               cellPadding: 1.5, // Reduced padding to make table smaller
            },
            columnStyles: {
               label1: { cellWidth: (pageWidth - 30) / 4, halign: "right", fontStyle: "bold" }, // Bold labels and adjust width
               value1: { cellWidth: (pageWidth - 30) / 4 }, // Adjust width
               label2: { cellWidth: (pageWidth - 30) / 4, halign: "right", fontStyle: "bold" }, // Bold labels and adjust width
               value2: { cellWidth: (pageWidth - 30) / 4 }, // Adjust width
            },
            theme: "plain",
            showHead: "never", // Hide headers
            tableWidth: pageWidth - 20, // Make table width dynamic
            rowPageBreak: "avoid", // Avoid breaking rows across pages
            maxBodyHeight: maxHeight, // Limit table height
         });
      };

      const addElementToPdf = async (element1, element2, isLastElement) => {
         const element1_MainIndex = element1.getAttribute("data-main-index");
         const element1_SubIndex = element1.getAttribute("data-sub-index");
         const canvas1 = await html2canvas(element1, {
            scale: 2, // Adjust the scale to reduce image size if needed
            useCORS: true,
            logging: false,
         });
         const imgData1 = canvas1.toDataURL("image/jpeg", 0.8); // Use JPEG format with quality set to 0.8

         const canvas2 = element2
            ? await html2canvas(element2, {
                 scale: 2,
                 useCORS: true,
                 logging: false,
              })
            : null;
         const imgData2 = canvas2 ? canvas2.toDataURL("image/jpeg", 0.8) : null;

         const itemInfo = reportItems[element1_MainIndex][element1_SubIndex];
         inventoryName = `Inventory  : ${itemInfo?.room?.jobs?.job_name}`;
         roomName = `Room : ${itemInfo?.room?.room_name}`;
         roomValue = `Room Value : ${formatToDollars(totalRoomValues[element1_MainIndex])}`;

         if (pdfPageCount > 0) {
            pdf.addPage();
         }

         const padding = 5;
         const pageWidth = pdf.internal.pageSize.getWidth();
         const pageHeight = pdf.internal.pageSize.getHeight();

         const headerHeight = 40; // Space taken by header (logo and top content)
         const footerHeight = 30; // Space taken by footer (date and page number)
         const availableHeight = pageHeight - headerHeight - footerHeight; // Space between header and footer

         const halfAvailableHeight = availableHeight / 2; // Each image + table will take half of the available space
         const imgWidth = pageWidth - 2 * padding;
         const imgHeight = halfAvailableHeight / 2; // Image takes half of the half-available space

         // Add logo and top content
         const logoWidth = 50;
         const logoHeight = 10;

         pdf.setFontSize(12);
         pdf.addImage(logo, "PNG", padding, padding, logoWidth, logoHeight); // Adjust the position and size as needed
         pdf.text(reportForName, pageWidth - padding - textWidth, padding / 2 + 5);
         pdf.text(inventoryName, pageWidth - padding - textWidth, padding + logoHeight / 2 + 5);

         // Add horizontal line after logo and top content
         const lineHeight = padding + logoHeight + 5;
         pdf.setLineWidth(0.5);
         pdf.setDrawColor("#155CA2");
         pdf.line(padding, lineHeight, pageWidth - padding, lineHeight);
         pdf.text(roomName, padding, lineHeight + 8);
         // pdf.text(roomValue, pageWidth - padding - (textWidth + 5), lineHeight + 8);

         if (portalUserType !== "2" && portalUserType !== "3") {
            pdf.text(roomValue, pageWidth - (textWidth + 5), lineHeight + 8);
         }

         // Add the first image (element1)
         pdf.addImage(imgData1, "JPEG", padding, lineHeight + 15, imgWidth, imgHeight);

         // Add the first table below the first image, within the remaining half space
         createTable(
            pdf,
            lineHeight + 15 + imgHeight + 5,
            imgHeight - 15,
            element1_MainIndex,
            element1_SubIndex
         );

         // Add the second image (element2), if available, with some margin to avoid overlap with footer
         if (imgData2) {
            const element2_MainIndex = element2.getAttribute("data-main-index");
            const element2_SubIndex = element2.getAttribute("data-sub-index");

            const secondImageY = lineHeight + 25 + halfAvailableHeight; // Start the second image at half of the available height
            pdf.addImage(imgData2, "JPEG", padding, secondImageY, imgWidth, imgHeight);

            // Add the second table below the second image, within the remaining half space
            createTable(
               pdf,
               secondImageY + imgHeight + 5,
               imgHeight - 15,
               element2_MainIndex,
               element2_SubIndex
            );
         }

         // Ensure footer stays on the same page
         // if (isLastElement || pdfPageCount === 0) {
         pdf.line(
            padding,
            pageHeight - footerHeight + 15,
            pageWidth - padding,
            pageHeight - footerHeight + 15
         );
         pdf.text(`Date: ${date}`, padding, pageHeight - 5);
         pdf.text(`Page ${pdfPageCount + 1}`, pageWidth - padding - 20, pageHeight - 5);
         // }

         pdfPageCount++;
         if (isLastElement) {
            reportSummary(pdf);

            if (isBlob) {
               let blob = pdf.output("blob");
               console.log("blobbbb", isBlob, blob);
               sendEmail(blob);
               return;
            }

            pdf.save("Reports.pdf");
         }
      };

      const generatePdf = async () => {
         // for (let i = 0; i < elements.length; i += 2) {
         //    const element1 = elements[i];
         //    const element2 = i + 1 < elements.length ? elements[i + 1] : null;
         //    await addElementToPdf(element1, element2, i + 2 >= elements.length);
         // }

         try {
            setLoading(true); // Show loading indicator
            for (let i = 0; i < elements.length; i += 2) {
               const element1 = elements[i];
               const element2 = i + 1 < elements.length ? elements[i + 1] : null;
               await addElementToPdf(element1, element2, i + 2 >= elements.length);
            }
         } catch (error) {
            console.error("Error generating PDF", error);
         } finally {
            setLoading(false); // Hide loading indicator
         }
      };

      generatePdf();
   };

   const gridReportDownload = (isBlob = false) => {
      const elements = document.getElementsByClassName("pdf-image-container");
      const pdf = new jsPDF();
      const logo = Logo; // Provide the path to your logo image
      const reportForName = `Report For : ${roleID === "4" ? displayName : user_name}`; // Provide your top content
      let firstItem = reportItems[0][0];
      let inventoryName = `Inventory  : ${firstItem?.room?.jobs?.job_name}`; // Provide your top content
      let roomName = `Room : ${firstItem?.room?.room_name}`;
      let roomValue = `Room Value : (Total Room Value)`;
      const date = new Date().toLocaleDateString(); // Current date
      const padding = 5;
      const headerHeight = 25; // Height for header content
      const footerHeight = 20; // Height for footer content
      const rows = 2; // Number of rows
      const cols = 2; // Number of columns
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const elementWidth = (pageWidth - 3 * padding) / cols; // Width of each element
      const elementHeight = (pageHeight - headerHeight - footerHeight - 4 * padding) / rows; // Height of each element
      let pdfPageCount = 0;
      let currentPageY = headerHeight + padding; // Initial Y position for content on the page
      const textWidthCalculation = [reportForName, inventoryName, roomValue];
      const textWidth =
         Math.max(...textWidthCalculation.map(item => Math.floor(pdf.getTextWidth(item)))) - 10;
      let totalRoomValues = [];
      let summary = [];

      // Function to find every fifth element after the fourth element
      function findEveryFifthAfterFourth(arr) {
         const results = [];
         const startIndex = 4; // This is the 5th item in zero-based indexing

         for (let i = startIndex; i < arr.length; i += 4) {
            // results.push(Number(arr[i]));
            results.push(arr[i]);
         }

         return results;
      }

      function calculteTotalRoomValues() {
         totalRoomValues = reportItems.map((item, index) => {
            return item.reduce((acc, value) => {
               let data = value.item_claim?.find(
                  claim => claim.portal_user_id === Number(clientID)
               );

               if (data?.estimated_value) {
                  acc += Number(data.estimated_value) || 0;
               } else {
                  acc += Number(value.google_value_range) || 0;
               }

               return acc;
            }, 0);
         });

         reportItems.forEach((item, index) => {
            summary.push({
               roomName: item[0]?.room?.room_name,
               itemsCount: item.length,
               total: totalRoomValues[index],
            });
         });

         let total = summary.reduce(
            (acc, current) => {
               return {
                  ...acc,
                  itemsCount: acc["itemsCount"] + current.itemsCount,
                  total: acc["total"] + current.total,
               };
            },
            {
               roomName: "Total Value",
               itemsCount: 0,
               total: 0,
            }
         );

         summary.push(total);

         summary = summary.map(item => ({ ...item, total: formatToDollars(item.total) }));

         console.log("summary", summary);
         console.log("summary total", total);

         roomValue = `Room Value : ${formatToDollars(totalRoomValues[0])}`;
      }

      calculteTotalRoomValues();

      // const items = Object.keys(Array.from(elements));
      // const selectedItems = findEveryFifthAfterFourth(items);
      // console.log(selectedItems);

      const reportSummary = (doc, startX = 5, startY = 35) => {
         pdf.addPage();
         // Add logo and top content
         const logoWidth = 50;
         const logoHeight = 10;
         // const textWidth = pdf.getTextWidth(reportForName);

         pdf.setFontSize(12);
         pdf.addImage(logo, "PNG", padding, padding, logoWidth, logoHeight);
         // pdf.text(reportForName, pageWidth - padding - textWidth, padding + logoHeight / 2 + 5);
         pdf.text(reportForName, pageWidth - padding - textWidth, padding / 2 + 5);

         // Add horizontal line after logo and top content
         const lineHeight = padding + logoHeight + 5;
         pdf.setLineWidth(0.5);
         pdf.setDrawColor("#155CA2");
         pdf.line(padding, lineHeight, pageWidth - padding, lineHeight);
         currentPageY = lineHeight + padding;

         pdf.setFont("helvetica", "bold"); // Set font style to bold
         pdf.setFontSize(12); // Set font size to 16
         pdf.text("Report Summary", padding, lineHeight + 8);

         const columns = [
            { title: "Breakdown by Room", dataKey: "roomName" },
            { title: "Item Count", dataKey: "itemsCount" },
            { title: "Value", dataKey: "total" },
            // { title: "AI Estimate", dataKey: "google_value" },
         ];

         const rows = summary;

         // Only pt supported (not mm or in)
         doc.autoTable(columns, rows, {
            startY: startY,
            margin: { left: startX, right: padding },
            styles: {
               fontSize: 10,
               cellPadding: 1,
               halign: "center",
               lineWidth: 0.1, // Border thickness
               lineColor: [200, 200, 200], // Border color (black)
            },
            tableLineColor: [200, 200, 200], // Outer table border color (black)
            tableLineWidth: 0.1, // Outer table border thickness
            headStyles: {
               halign: "center", // Center-align all table headers
               fontStyle: "bold",
               fontSize: 11,
            },
            columnStyles: {
               id: { fillColor: 255 },
            },
            didParseCell: function (data) {
               // Check if it's the last row in the table
               const isLastRow = data.row.index === data.table.body.length - 1;
               if (isLastRow) {
                  data.cell.styles.fontStyle = "bold";
                  data.cell.styles.cellPadding = { top: 2 }; // Apply bold style to last row
               }
            },
            theme: "plain",
            // tableWidth: "wrap",
            rowPageBreak: "avoid",
            // maxBodyHeight: maxHeight,
         });
         return;
      };

      const addPageHeader = () => {
         // Add logo and top content
         const logoWidth = 50;
         const logoHeight = 10;
         // const textWidth = pdf.getTextWidth(reportForName);

         pdf.setFontSize(12);
         pdf.addImage(logo, "PNG", padding, padding, logoWidth, logoHeight);
         // pdf.text(reportForName, pageWidth - padding - textWidth, padding + logoHeight / 2 + 5);
         pdf.text(reportForName, pageWidth - padding - textWidth, padding / 2 + 5);
         pdf.text(inventoryName, pageWidth - padding - textWidth, padding + logoHeight / 2 + 5);

         // Add horizontal line after logo and top content
         const lineHeight = padding + logoHeight + 5;
         pdf.setLineWidth(0.5);
         pdf.setDrawColor("#155CA2");
         pdf.line(padding, lineHeight, pageWidth - padding, lineHeight);
         pdf.text(roomName, padding, lineHeight + 8);

         if (portalUserType !== "2" && portalUserType !== "3") {
            pdf.text(roomValue, pageWidth - (textWidth + 5), lineHeight + 8);
         }

         return lineHeight + padding; // Return Y position after header and line
      };

      const addPageFooter = () => {
         // Add horizontal line above footer
         pdf.line(
            padding,
            pageHeight - footerHeight - padding + 12,
            pageWidth - padding,
            pageHeight - footerHeight - padding + 12
         );

         // Add date and page number in the footer
         pdf.text(`Date: ${date}`, padding, pageHeight - footerHeight + 15);
         pdf.text(
            `Page ${pdfPageCount + 1}`,
            pageWidth - padding - 20,
            pageHeight - footerHeight + 15
         );
      };

      async function imageToDataUrlAndSize(imgElement) {
         return new Promise((resolve, reject) => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const img = new Image();
            img.crossOrigin = "Anonymous"; // Set CORS if needed
            img.src = imgElement.src;

            img.onload = () => {
               // Get the natural dimensions of the image
               const imgNaturalWidth = img.naturalWidth;
               const imgNaturalHeight = img.naturalHeight;

               // Set canvas size to match the image
               canvas.width = imgNaturalWidth;
               canvas.height = imgNaturalHeight;

               // Draw the image onto the canvas
               ctx.drawImage(img, 0, 0);

               // Convert canvas to a Base64-encoded data URL
               const dataUrl = canvas.toDataURL("image/jpeg");

               resolve({ dataUrl, imgNaturalWidth, imgNaturalHeight });
            };

            img.onerror = err => reject(err); // Handle any loading errors
         });
      }

      const addTableBelowImage = (
         doc,
         startX,
         startY,
         maxHeight,
         colWidth,
         mainIndex,
         subIndex
      ) => {
         // const data = reportItems[mainIndex][subIndex];
         const formatData = reportItems[mainIndex][subIndex];

         let estimateValue = Math.max(
            ...formatData?.item_claim?.map(c => Number(c.estimated_value))
         );

         const data = {
            ...formatData,
            google_value_range: formatToDollars(formatData?.google_value_range || 0),
            client_value_range: formatToDollars(
               Math.max(Number(formatData.google_value_range), estimateValue) || 0
            ),
            item_claim: formatData?.item_claim?.map(subItem => {
               return {
                  ...subItem,
                  estimated_value: formatToDollars(subItem.estimated_value || 0),
               };
            }),
         };

         console.log("exeData", data);

         if (projectType === 1 && Number(portalUserType) === 2 && roleID !== "1") {
            let userList = "";
            data?.item_claim?.forEach(
               item => (userList = userList + " " + item.portalusers.display_name + ",")
            );

            const labels = {
               label1: "Item Name",
               value1: data.name || "",
               label2: "Estimation Value",
               value2: data.client_value_range,
               label3: "Claimed By",
               value3: userList.trim(),
            };

            const rows = [
               { label: labels.label1, value: labels.value1 },
               { label: labels.label2, value: labels.value2 },
               { label: labels.label3, value: labels.value3 },
            ];
            doc.autoTable({
               startY: startY,
               margin: { left: startX, right: padding },
               styles: { fontSize: 8, cellPadding: 2 },
               body: rows.map(row => [row.label, row.value]),
               columnStyles: {
                  0: { cellWidth: colWidth / 3, halign: "left", fontStyle: "bold" },
                  1: { cellWidth: colWidth / 2, halign: "left" },
               },
               theme: "plain",
               tableWidth: colWidth,
               rowPageBreak: "avoid",
               maxBodyHeight: maxHeight,
            });
            return;
         }

         if ((projectType === 1 || projectType === 3) && Number(portalUserType) !== 2) {
            let claim = data?.item_claim?.find(item => item.portal_user_id === Number(clientID));

            const labels = {
               label1: "Item Name",
               value1: data.name || "",
               label2: "Client's Estimate",
               value2: claim?.estimated_value || "$0.00",
               label3: "AI Estimate",
               value3: data.google_value_range || "$0.00",
               label4: "Notes",
               value4: claim?.comment || "",
            };

            const rows = [
               { label: labels.label1, value: labels.value1 },
               { label: labels.label2, value: labels.value2 },
               { label: labels.label3, value: labels.value3 },
               { label: labels.label4, value: labels.value4 },
            ];
            doc.autoTable({
               startY: startY,
               margin: { left: startX, right: padding },
               styles: { fontSize: 8, cellPadding: 2 },
               body: rows.map(row => [row.label, row.value]),
               columnStyles: {
                  0: { cellWidth: colWidth / 3, halign: "left", fontStyle: "bold" },
                  1: { cellWidth: colWidth / 2, halign: "left" },
               },
               theme: "plain",
               tableWidth: colWidth,
               rowPageBreak: "avoid",
               maxBodyHeight: maxHeight,
            });
            return;
         }

         if (projectType === 2 && Number(portalUserType) === 2) {
            let googleValue = data.google_value_range;
            const rowsData = data?.item_claim?.map(item => {
               if (item.claimed_as) {
                  return {
                     ...item,
                     claimed_as: itemClaimReasons[item.claimed_as],
                     google_value: googleValue,
                     portal_user_name: item.portalusers.display_name,
                  };
               } else {
                  return item;
               }
            });

            const columns = [
               { title: "Claimed By", dataKey: "portal_user_name" },
               { title: "Claim Reason", dataKey: "claimed_as" },
               { title: "Client's Estimate", dataKey: "estimated_value" },
               { title: "AI Estimate", dataKey: "google_value" },
            ];
            const rows = rowsData;

            // Only pt supported (not mm or in)
            doc.autoTable(columns, rows, {
               startY: startY,
               margin: { left: startX, right: padding },
               styles: { fontSize: 7, cellPadding: 0.5, halign: "center" },
               headStyles: {
                  halign: "center", // Center-align all table headers
               },
               columnStyles: {
                  id: { fillColor: 255 },
               },
               theme: "plain",
               tableWidth: colWidth,
               rowPageBreak: "avoid",
               maxBodyHeight: maxHeight,
            });
            return;
         }

         if (projectType === 2 && Number(portalUserType) !== 2) {
            let claim = data?.item_claim?.find(item => item.portal_user_id === Number(clientID));
            const labels = {
               label1: "Item Name",
               value1: data.name || "",
               label2: "Client's Estimate",
               value2: claim?.estimated_value || "$0.00",
               label3: "AI Estimate",
               value3: data.google_value_range || "$0.00",
               label4: "Claim Reason",
               value4: itemClaimReasons[claim?.claimed_as] || "",
               label5: "Notes",
               value5: claim?.comment || "",
            };

            const rows = [
               { label: labels.label1, value: labels.value1 },
               { label: labels.label2, value: labels.value2 },
               { label: labels.label3, value: labels.value3 },
               { label: labels.label4, value: labels.value4 },
               { label: labels.label5, value: labels.value5 },
            ];
            doc.autoTable({
               startY: startY,
               margin: { left: startX, right: padding },
               styles: { fontSize: 8, cellPadding: 2 },
               body: rows.map(row => [row.label, row.value]),
               columnStyles: {
                  0: { cellWidth: colWidth / 3, halign: "left", fontStyle: "bold" },
                  1: { cellWidth: colWidth / 2, halign: "left" },
               },
               theme: "plain",
               tableWidth: colWidth,
               rowPageBreak: "avoid",
               maxBodyHeight: maxHeight,
            });
            return;
         }

         if (projectType > 2 || roleID !== "1") {
            let claim = data?.item_claim?.find(item => item.portal_user_id === Number(clientID));
            const labels = {
               label1: "Item Name",
               value1: data.name || "",
               label2: "Client's Estimate",
               value2: claim?.estimated_value || "$0.00",
               label3: "AI Estimate",
               value3: data.google_value_range || "$0.00",
               label4: "Notes",
               value4: claim?.comment || "",
            };

            const rows = [
               { label: labels.label1, value: labels.value1 },
               { label: labels.label2, value: labels.value2 },
               { label: labels.label3, value: labels.value3 },
               { label: labels.label4, value: labels.value4 },
            ];
            doc.autoTable({
               startY: startY,
               margin: { left: startX, right: padding },
               styles: { fontSize: 8, cellPadding: 2 },
               body: rows.map(row => [row.label, row.value]),
               columnStyles: {
                  0: { cellWidth: colWidth / 3, halign: "left", fontStyle: "bold" },
                  1: { cellWidth: colWidth / 2, halign: "left" },
               },
               theme: "plain",
               tableWidth: colWidth,
               rowPageBreak: "avoid",
               maxBodyHeight: maxHeight,
            });
            return;
         }

         if (roleID === "1" && gridReportType === "normal") {
            console.log("roleId", 1, "type", "normal");

            let claim = data?.item_claim?.find(item => item.portal_user_id === Number(clientID));
            const labels = {
               label1: "Item Name",
               value1: data.name || "",
               label2: "Client's Estimate",
               value2: data.client_value_range || "$0.00",
               label3: "AI Estimate",
               value3: data.google_value_range || "$0.00",
               label4: "Notes",
               value4: data.notes || "",
            };

            const rows = [
               { label: labels.label1, value: labels.value1 },
               { label: labels.label2, value: labels.value2 },
               { label: labels.label3, value: labels.value3 },
               { label: labels.label4, value: labels.value4 },
            ];
            doc.autoTable({
               startY: startY,
               margin: { left: startX, right: padding },
               styles: { fontSize: 8, cellPadding: 2 },
               body: rows.map(row => [row.label, row.value]),
               columnStyles: {
                  0: { cellWidth: colWidth / 3, halign: "left", fontStyle: "bold" },
                  1: { cellWidth: colWidth / 2, halign: "left" },
               },
               theme: "plain",
               tableWidth: colWidth,
               rowPageBreak: "avoid",
               maxBodyHeight: maxHeight,
            });
            return;
         }

         if (roleID === "1" && gridReportType === "contested") {
            let googleValue = data.google_value_range;
            const rowsData = data?.item_claim.map(item => {
               if (item.claimed_as) {
                  return {
                     ...item,
                     claimed_as: itemClaimReasons[item.claimed_as],
                     google_value: googleValue,
                     portal_user_name: item.portalusers.display_name,
                  };
               } else {
                  return item;
               }
            });

            const columns = [
               { title: "Claimed By", dataKey: "portal_user_name" },
               { title: "Claim Reason", dataKey: "claimed_as" },
               { title: "Client's Estimate", dataKey: "estimated_value" },
               { title: "AI Estimate", dataKey: "google_value" },
            ];
            const rows = rowsData;

            // Only pt supported (not mm or in)
            doc.autoTable(columns, rows, {
               startY: startY,
               margin: { left: startX, right: padding },
               styles: { fontSize: 7, cellPadding: 0.5, halign: "center" },
               headStyles: {
                  halign: "center", // Center-align all table headers
               },
               columnStyles: {
                  id: { fillColor: 255 },
               },
               theme: "plain",
               tableWidth: colWidth,
               rowPageBreak: "avoid",
               maxBodyHeight: maxHeight,
            });
            return;
         }
      };

      const addElementToPdf = async (element, index, isLastElement, isNewPage) => {
         const mainIndex = element.getAttribute("data-main-index");
         const subIndex = element.getAttribute("data-sub-index");
         // const canvas = await html2canvas(element, {
         //    scale: 2,
         //    useCORS: true,
         //    logging: false,
         // });

         // const imgData = canvas.toDataURL("image/jpeg", 0.8);

         const data = reportItems[mainIndex][subIndex];

         inventoryName = `Inventory  : ${data?.room?.jobs?.job_name}`;
         roomName = `Room : ${data?.room?.room_name}`;
         roomValue = `Room Value : ${formatToDollars(totalRoomValues[mainIndex])}`;

         const { dataUrl, imgNaturalWidth, imgNaturalHeight } = await imageToDataUrlAndSize(
            element.querySelector("img")
         );

         const row = Math.floor(subIndex / cols) % rows;
         const col = subIndex % cols;
         const x = padding + col * (elementWidth + padding) + 5;
         const y = currentPageY + row * (elementHeight + padding) + 10;

         // const items = Object.keys(Array.from(elements));
         const selectedItems = findEveryFifthAfterFourth(Object.keys(reportItems[mainIndex]));
         console.log("fifth", selectedItems, Object.keys(reportItems[mainIndex]));
         console.log("ReportItem ===>", reportItems);

         // Check if the current element fits within the current page
         if (
            y + elementHeight > pageHeight - footerHeight - padding ||
            selectedItems.includes(subIndex) ||
            isNewPage
         ) {
            addPageFooter();
            pdfPageCount++;
            pdf.addPage();
            currentPageY = addPageHeader(); // Reset Y position for the new page
         }

         const imgHeight = elementHeight * 0.5; // Allocate 60% of the element height for the image
         const tableY = y + imgHeight + padding;
         const tableHeight = elementHeight * 0.5 - padding; // Allocate 40% of the element height for the table

         // Calculate the new width and height to maintain aspect ratio
         const aspectRatio = imgNaturalWidth / imgNaturalHeight;
         const newWidth = imgHeight * aspectRatio;
         let imgWidth = newWidth >= elementWidth ? newWidth - 10 : newWidth;
         let xOffset = x + (elementWidth - newWidth) / 2;

         if (imgWidth > 100) {
            imgWidth = 100;
         }

         if (xOffset < 3) {
            xOffset = 3.5;
         }

         if (xOffset > 70 && xOffset < 106) {
            xOffset = 106;
         }

         console.log("img width", imgWidth);
         console.log("xoffset", xOffset);

         // Add the image
         // pdf.addImage(imgData, "JPEG", x, y, elementWidth, imgHeight);
         pdf.addImage(dataUrl, "JPEG", xOffset, y, imgWidth, imgHeight);
         // Add the table below the image
         addTableBelowImage(pdf, x, tableY, tableHeight, elementWidth, mainIndex, subIndex);

         if (isLastElement) {
            addPageFooter();
            pdfPageCount++;
            reportSummary(pdf);

            if (isBlob) {
               let blob = pdf.output("blob");
               console.log("blobbbb", isBlob, blob);
               sendEmail(blob);
               return;
            }

            pdf.save("Reports.pdf");
         }
      };

      currentPageY = addPageHeader(); // Initialize Y position for the first page

      // Array.from(elements).forEach((element, index) => {
      //    addElementToPdf(element, index, index === elements.length - 1);
      // });

      const generatePdf = async () => {
         try {
            setLoading(true); // Show loading indicator
            let arrIndex = "0";
            for (const [index, element] of Array.from(elements).entries()) {
               let isNewPage = false;
               let elementMainIndex = element.getAttribute("data-main-index");
               if (arrIndex !== elementMainIndex) {
                  arrIndex = elementMainIndex;
                  isNewPage = true;
               }

               await addElementToPdf(element, index, index === elements.length - 1, isNewPage);
            }
         } catch (error) {
            console.error("Error generating PDF", error);
         } finally {
            setLoading(false); // Hide loading indicator
         }
      };

      generatePdf();
   };

   const sendEmail = blob => {
      console.log("sendEmail Blob", blob);

      const formData = new FormData();
      formData.append("pdf", blob, "report.pdf");

      // Append additional data to FormData
      formData.append("name", shareDetails.name);
      formData.append("email", shareDetails.email);
      formData.append("message", shareDetails.message);

      dispatch(emailShare({ formData }));
   };

   const reportShare = () => {
      const { email, message } = shareDetails;

      function isValidEmail(email) {
         const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         const isStructureValid = emailPattern.test(email);
         const isMinLengthValid = email.length >= 5; // Set minimum length requirement
         return isStructureValid && isMinLengthValid;
      }

      if (!isValidEmail(email) || !message) {
         toast.error("Fill all the mandatory informations");
         return;
      }

      if (reportType === "full") {
         fullReportDownload(true);
      }

      if (reportType === "grid") {
         gridReportDownload(true);
      }

      // console.log("blob", blob);

      // const formData = new FormData();
      // formData.append("pdf", blob, "report.pdf");

      // // Append additional data to FormData
      // formData.append("name", shareDetails.name);
      // formData.append("email", shareDetails.email);
      // formData.append("message", shareDetails.message);
   };

   const handleDownloadPDF = () => {
      if (reportType === "full") {
         fullReportDownload();
      }

      if (reportType === "grid") {
         gridReportDownload();
      }
   };

   const exportToExcel = () => {
      setLoading(true);
      // Define the column names manually
      // const columns = ["ID", "Name", "Age", "Address"];

      let columns = [];
      let data = [];

      function downloadExcel(columns, data) {
         // Combine the column names and the data rows
         const worksheetData = [columns, ...data];

         // Create a new worksheet and add the combined data
         const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

         // Ensure the style library is enabled
         if (!XLSX.utils.sheet_set_style) {
            XLSX.utils.sheet_set_style = function (worksheet, cell, style) {
               if (!worksheet[cell]) worksheet[cell] = {};
               worksheet[cell].s = style;
            };
         }

         // Set styles for the column headers (first row)
         const range = XLSX.utils.decode_range(worksheet["!ref"]);
         for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell_address = XLSX.utils.encode_cell({ r: 0, c: C });
            if (worksheet[cell_address]) {
               // Apply bold and yellow background to header cells
               worksheet[cell_address].s = {
                  font: { bold: true }, // Set font to bold
                  alignment: { horizontal: "center", vertical: "center" }, // Center align
                  fill: {
                     // Set background color to yellow
                     patternType: "solid",
                     fgColor: { rgb: "FFFF00" }, // Yellow background color (hex code for yellow)
                  },
               };
            }
         }

         // Set the column widths dynamically based on the content length and column names
         const colWidths = columns.map((col, i) => {
            let maxLength = col.length; // Start with the column header length
            data.forEach(row => {
               if (row[i]) {
                  maxLength = Math.max(maxLength, row[i].toString().length); // Find the max length in the column
               }
            });
            return { wch: maxLength + 2 }; // Add padding to avoid ellipsis
         });

         worksheet["!cols"] = colWidths;

         const workbook = XLSX.utils.book_new();
         XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

         // Export to Excel
         XLSX.writeFile(workbook, "Reports.xlsx");

         setLoading(false);
      }

      const reportItemsMerge = reportItems.flat();

      if (reportType === "full" || portalUserType === "2" || portalUserType === "3") {
         const labels = {
            display_name: "UserName",
            name: "Item Name",
            client_value_range: "Client's Estimate",
            google_value_range: "AI Estimate",
            category: "Category",
            quantity: "Quantity",
            distribute_to: "Assign To",
            item_condition: "Condition",
            purchase_date: "Purchase Date",
            dispose_by: "Appraise",
            distribute: "Do you want this item",
            claimed_as: "Reason for Claim",
            model: "Model",
            serial_number: "Serial Number",
            notes: "Notes",
         };

         // let formatedColumnData = [...selectedFields];
         // formatedColumnData.unshift("display_name");
         // formatedColumnData.splice(5, 0, "distribute");
         // formatedColumnData.splice(6, 0, "claimed_as");

         // console.log("formatColumns", formatedColumnData);

         columns = selectedFields.map(item => labels[item]);
         // columns.unshift("UserName");
         // columns.splice(5, 0, "Do you want this?");
         // columns.splice(6, 0, "Reason for Claim");

         // console.log("columns", columns);

         if (portalUserType === "2" || portalUserType === "3") {
            reportItemsMerge.forEach(reportData => {
               let itemData = {};
               let itemName = reportData.name;
               let aiValue = reportData.google_value_range;

               reportData?.item_claim?.forEach(userData => {
                  const claimReason = itemClaimReasons[userData?.claimed_as];

                  let formatData = {
                     display_name: userData?.portalusers?.display_name || "-",
                     name: itemName || "-",
                     client_value_range: formatToDollars(userData?.estimated_value) || "-",
                     google_value_range: formatToDollars(aiValue) || "-",
                     category: itemCategory[userData?.category] || "-",
                     item_condition: itemCondition[userData?.item_condition] || "-",
                     claimed_as: claimReason || "-",
                     quantity: userData?.quantity || "-",
                     distribute_to: userData?.distribute_to || "-",
                     purchase_date: userData?.purchase_date
                        ? new Date(userData?.purchase_date).toLocaleDateString()
                        : "-",
                     dispose_by: userData?.dispose_by?.includes(1) ? "Yes" : "No",
                     distribute:
                        userData?.distribute == 1 ? "Yes" : userData?.distribute == 0 ? "No" : "-",
                     notes: userData?.comment || "-",
                     model: userData?.model || "-",
                     serial_number: userData?.serial_number || "-",
                  };

                  itemData = formatData;
                  let row = selectedFields.map(item => itemData[item]);
                  // let row = formatedColumnData.map(item => itemData[item]);
                  data.push(row);
               });
            });
         } else {
            reportItemsMerge.forEach(reportData => {
               let itemData = {};
               let userData = reportData?.item_claim?.find(
                  item => item.portal_user_id == Number(clientID)
               );

               if (userData?.portal_user_id) {
                  const claimReason = itemClaimReasons[userData?.claimed_as];
                  let formatData = {
                     display_name: userData?.portalusers?.display_name || "-",
                     name: reportData.name || "-",
                     client_value_range: formatToDollars(userData?.estimated_value) || "-",
                     google_value_range: formatToDollars(reportData.google_value_range) || "-",
                     category: itemCategory[userData?.category] || "-",
                     claimed_as: claimReason || "-",
                     quantity: userData?.quantity || "-",
                     distribute_to: userData?.distribute_to || "-",
                     item_condition: itemCondition[userData?.item_condition] || "-",
                     purchase_date: userData?.purchase_date
                        ? new Date(userData?.purchase_date).toLocaleDateString()
                        : "-",
                     dispose_by: userData?.dispose_by?.includes(1) ? "Yes" : "No",
                     distribute:
                        userData?.distribute == 1 ? "Yes" : userData?.distribute == 0 ? "No" : "-",
                     notes: userData?.comment || "-",
                     model: userData?.model || "-",
                     serial_number: userData?.serial_number || "-",
                  };

                  itemData = formatData;
                  let row = selectedFields.map(item => itemData[item]);
                  // let row = formatedColumnData.map(item => itemData[item]);
                  data.push(row);
               }
            });
         }

         downloadExcel(columns, data);
         return;
      }

      // if (projectType === 1 && Number(portalUserType) === 2 && roleID !== "1") {
      //    columns = ["Item Name", "Estimation Value", "Claimed By"];
      //    reportItems.forEach(item => {
      //       let name = "";
      //       item?.item_claim?.forEach(item => (name = name + " " + item.portalusers.display_name));
      //       let rows = [item.name, item.client_value_range, name];
      //       data.push(rows);
      //    });
      //    downloadExcel(columns, data);
      //    return;
      // }

      if (
         (projectType === 1 || projectType === 3) &&
         Number(portalUserType) !== 2 &&
         Number(portalUserType) !== 3
      ) {
         columns = ["Item Name", "Client's Estimate", "AI Estimate", "Notes"];
         reportItemsMerge.forEach(item => {
            let userData = item.item_claim?.find(item => item.portal_user_id === Number(clientID));
            if (userData?.portal_user_id) {
               let rows = [
                  item.name || "-",
                  formatToDollars(userData?.estimated_value) || "-",
                  formatToDollars(item.google_value_range) || "-",
                  item.notes || "-",
               ];
               data.push(rows);
            } else {
               let rows = [
                  item.name || "-",
                  "-",
                  formatToDollars(item.google_value_range) || "-",
                  item.notes || "-",
               ];
               data.push(rows);
            }
         });
         // reportItems.forEach(item => {
         //    let rows = [item.name, item.client_value_range, item.google_value_range, item.notes];
         //    data.push(rows);
         // });
         downloadExcel(columns, data);
         return;
      }

      // if (projectType === 2 && Number(portalUserType) === 2) {
      //    columns = ["Claimed By", "Claim Reason", "Client's Estimate", "AI Estimate"];
      //    reportItems.forEach(item => {
      //       let googleValue = item.google_value_range;
      //       item?.item_claim?.forEach(claim => {
      //          let rows = [
      //             claim.portalusers.display_name,
      //             itemClaimReasons[claim.claimed_as],
      //             claim.estimated_value,
      //             googleValue,
      //          ];
      //          data.push(rows);
      //       });
      //    });
      //    downloadExcel(columns, data);
      //    return;
      // }

      if (projectType === 2 && Number(portalUserType) !== 2 && Number(portalUserType) !== 3) {
         columns = ["Item Name", "Client's Estimate", "AI Estimate", "Claim Reason", "Notes"];
         reportItemsMerge.forEach(item => {
            let userData = item.item_claim?.find(item => item.portal_user_id === Number(clientID));
            if (userData?.portal_user_id) {
               let rows = [
                  item.name || "-",
                  formatToDollars(userData.estimated_value) || "-",
                  formatToDollars(item.google_value_range) || "-",
                  itemClaimReasons[item.claim_reason] || "-",
                  item.notes || "-",
               ];
               data.push(rows);
            } else {
               let rows = [
                  item.name || "-",
                  "-",
                  formatToDollars(item.google_value_range) || "-",
                  "-",
                  item.notes || "-",
               ];
               data.push(rows);
            }
         });
         // reportItems.forEach(item => {
         //    let rows = [
         //       item.name,
         //       item.client_value_range,
         //       item.google_value_range,
         //       item.claim_reason,
         //       item.notes,
         //    ];
         //    data.push(rows);
         // });
         downloadExcel(columns, data);
         return;
      }

      if (projectType > 2 || roleID !== "1") {
         columns = ["Item Name", "Client's Estimate", "AI Estimate", "Notes"];
         reportItemsMerge.forEach(item => {
            let userData = item.item_claim?.find(item => item.portal_user_id === Number(clientID));
            if (userData?.portal_user_id) {
               let rows = [
                  item.name || "-",
                  formatToDollars(userData.estimated_value) || "-",
                  formatToDollars(item.google_value_range) || "-",
                  item.notes || "-",
               ];
               data.push(rows);
            } else {
               let rows = [
                  item.name || "-",
                  "-",
                  formatToDollars(item.google_value_range) || "-",
                  item.notes || "-",
               ];
               data.push(rows);
            }
         });
         // reportItems.forEach(item => {
         //    let rows = [item.name, item.client_value_range, item.google_value_range, item.notes];
         //    data.push(rows);
         // });
         downloadExcel(columns, data);
         return;
      }

      // if (roleID === "1" && gridReportType === "normal") {
      //    columns = ["Item Name", "Client's Estimate", "AI Estimate", "Notes"];
      //    reportItems.forEach(item => {
      //       let userData = item.item_claim?.find(item => item.portal_user_id === Number(clientID));
      //       if (userData?.portal_user_id) {
      //          let rows = [
      //             item.name,
      //             userData.estimated_value,
      //             item.google_value_range,
      //             item.notes,
      //          ];
      //          data.push(rows);
      //       } else {
      //          let rows = [item.name, "", item.google_value_range, item.notes];
      //          data.push(rows);
      //       }
      //    });
      //    // reportItems.forEach(item => {
      //    //    let rows = [item.name, item.client_value_range, item.google_value_range, item.notes];
      //    //    data.push(rows);
      //    // });
      //    downloadExcel(columns, data);
      //    return;
      // }

      // if (roleID === "1" && gridReportType === "contested") {
      //    columns = ["Claimed By", "Claim Reason", "Client's Estimate", "AI Estimate"];
      //    reportItems.forEach(item => {
      //       let googleValue = item.google_value_range;
      //       item?.item_claim?.forEach(claim => {
      //          let rows = [
      //             claim.portalusers.display_name,
      //             itemClaimReasons[claim.claimed_as],
      //             claim.estimated_value,
      //             googleValue,
      //          ];
      //          data.push(rows);
      //       });
      //    });
      //    downloadExcel(columns, data);
      //    return;
      // }
   };

   return (
      <div>
         <Loader loading={(!isShareModalOpen && isLoading) || emailShareLoading} />
         <div className="container-fluid">
            <PageHeader
               HeaderText="Report Preview"
               Breadcrumb={[{ name: "Report Preview" }]}
               backButtonUrl={returnUrl || inventoryRoutes[portalUserType]}
               buttonName="Return to Inventory"
            />
            {/* {portalUserType !== "2" && portalUserType !== "3" && (
               <Breadcrumb
                  style={{ marginTop: -20 }}
                  className="mb-1 ms-1"
                  separator=">"
                  items={[
                     {
                        title: (
                           <>
                              <HomeOutlined />
                              <span
                                 className="p-1 rounded"
                                 style={{ cursor: "pointer" }}
                                 onMouseEnter={e =>
                                    e.currentTarget.classList.add(
                                       "text-black",
                                       "bg-secondary-subtle"
                                    )
                                 }
                                 onMouseLeave={e =>
                                    e.currentTarget.classList.remove(
                                       "text-black",
                                       "bg-secondary-subtle"
                                    )
                                 }
                              >
                                 Home
                              </span>
                           </>
                        ),
                        onClick: () => navigate("/clientportal/home"),
                     },
                     {
                        title: (
                           <span
                              className="p-1 rounded"
                              style={{ cursor: "pointer" }}
                              onMouseEnter={e =>
                                 e.currentTarget.classList.add("text-black", "bg-secondary-subtle")
                              }
                              onMouseLeave={e =>
                                 e.currentTarget.classList.remove(
                                    "text-black",
                                    "bg-secondary-subtle"
                                 )
                              }
                           >
                              Inventory
                           </span>
                        ),
                        onClick: () => navigate(returnUrl),
                     },
                     {
                        title: "Reports Preview",
                     },
                  ]}
               />
            )} */}
            <Card
               ref={reportRef}
               styles={{ body: { paddingTop: 10 } }}
               title={
                  <>
                     <span>
                        Report For :
                        <span className="fw-normal">{` ${
                           roleID === "4" ? displayName : user_name
                        }`}</span>
                     </span>
                     <Divider type="vertical" className="border-dark-subtle" />
                     {/* <br /> */}
                     <span>
                        Inventory :
                        <span className="fw-normal">{`${
                           reportItems.length > 0 && reportItems[0][0].room?.jobs?.job_name
                        }`}</span>
                     </span>
                  </>
               }
               extra={
                  <Space>
                     <Button
                        shape="round"
                        type="primary"
                        icon={<FaFileDownload style={{ marginTop: -2 }} />}
                        style={{ backgroundColor: "#f7931e" }}
                        onClick={handleDownloadPDF}
                     >
                        Download PDF
                     </Button>
                     <Button
                        shape="round"
                        type="primary"
                        icon={<FaFileExport style={{ marginTop: -2 }} />}
                        style={{ backgroundColor: "#f7931e" }}
                        onClick={exportToExcel}
                     >
                        Export
                     </Button>
                     <Button
                        shape="round"
                        type="primary"
                        icon={<MdOutgoingMail style={{ marginTop: -2 }} size={18} />}
                        style={{ backgroundColor: "#f7931e" }}
                        onClick={() => setIsShareModalOpen(true)}
                     >
                        Share
                     </Button>
                     <Modal
                        title="Share PDF"
                        open={isShareModalOpen}
                        width={"50%"}
                        onOk={reportShare}
                        onCancel={() => setIsShareModalOpen(false)}
                        confirmLoading={isLoading || emailShareLoading}
                        okButtonProps={{
                           type: "primary",
                           shape: "round",
                           style: { backgroundColor: "#f7931e" },
                        }}
                        cancelButtonProps={{
                           shape: "round",
                        }}
                        okText="Send PDF Catalog"
                        // footer={[
                        //    <Button
                        //       key="link"
                        //       type="primary"
                        //       shape="round"
                        //       style={{ backgroundColor: "#f7931e" }}
                        //       onClick={reportShare}
                        //    >
                        //       Send PDF Catalog
                        //    </Button>,
                        // ]}
                     >
                        <Loader loading={isLoading || emailShareLoading} />
                        <Divider className="mb-3 mt-1" />
                        <Row gutter={[15, 12]}>
                           <Col span={12}>
                              <label className="mb-1">Receipient Name</label>
                              <Input
                                 className=""
                                 value={shareDetails.name}
                                 placeholder="Receipient Name"
                                 onChange={e => {
                                    setShareDetails({
                                       ...shareDetails,
                                       name: e.target.value,
                                    });
                                 }}
                              />
                           </Col>
                           <Col span={12}>
                              <label className="mb-1">
                                 Receipient Email
                                 <span className="m-1" style={{ color: "red" }}>
                                    *
                                 </span>
                              </label>
                              <Input
                                 className=""
                                 value={shareDetails.email}
                                 placeholder="Receipient Email"
                                 onChange={e => {
                                    setShareDetails({
                                       ...shareDetails,
                                       email: e.target.value,
                                    });
                                 }}
                              />
                           </Col>
                           <Col span={24}>
                              <label className="mb-1">
                                 Message
                                 <span className="m-1" style={{ color: "red" }}>
                                    *
                                 </span>
                              </label>
                              <TextArea
                                 value={shareDetails.message}
                                 placeholder="Message"
                                 // disabled={true}
                                 rows={3}
                                 onChange={e => {
                                    setShareDetails({
                                       ...shareDetails,
                                       message: e.target.value,
                                    });
                                 }}
                              />
                           </Col>
                        </Row>
                     </Modal>
                  </Space>
               }
            >
               {reportType?.toLowerCase() === "grid" &&
                  reportItems?.map((item, mainIndex) => {
                     return (
                        <>
                           {/* <Divider className="border-2 border-primary">{item[0].room_id}</Divider> */}
                           {mainIndex > 0 && <Divider className="border-secondary-subtle" />}
                           <div className={`fs-6 mb-3 ${mainIndex > 0 && "ms-2"}`} key={mainIndex}>
                              <span>
                                 <b>Room : </b>
                                 <span>{`${item[0].room?.room_name}`}</span>
                              </span>
                              <Divider type="vertical" className="border-dark-subtle" />
                              {/* <br /> */}
                              {portalUserType !== "2" && portalUserType !== "3" && (
                                 <span>
                                    <b>Room Value : </b>
                                    <span>
                                       {formatToDollars(
                                          item.reduce((acc, value) => {
                                             console.log("item ===>", item);

                                             let data = value.item_claim?.find(
                                                claim => claim.portal_user_id === Number(clientID)
                                             );

                                             if (data?.estimated_value) {
                                                acc += Number(data.estimated_value) || 0;
                                             } else {
                                                acc += Number(value.google_value_range) || 0;
                                             }

                                             return acc;
                                          }, 0)
                                       )}
                                    </span>
                                 </span>
                              )}
                           </div>
                           <Row gutter={[10, 10]} wrap>
                              {item.map((source, subIndex) => (
                                 <Col lg={11} xxl={8} key={source.id}>
                                    <GridViewCard
                                       mainIndex={mainIndex}
                                       subIndex={subIndex}
                                       sourceData={source}
                                       projectType={projectType}
                                       portalUserType={portalUserType}
                                       roleID={roleID}
                                       reportType={gridReportType}
                                       clientID={clientID}
                                    />
                                 </Col>
                              ))}
                           </Row>
                        </>
                     );
                  })}
               {reportType?.toLowerCase() === "full" &&
                  reportItems?.map((item, mainIndex) => {
                     // <FullViewCard
                     //    dataIndex={index}
                     //    key={item.id}
                     //    sourceData={item}
                     //    selectedFields={selectedFields}
                     //    itemCategories={itemCategory}
                     // />;
                     return (
                        <>
                           {/* <Divider className="border-2 border-primary">{item[0].room_id}</Divider> */}
                           {mainIndex > 0 && <Divider className="border-secondary-subtle" />}
                           <div className={`fs-6 mb-3 ${mainIndex > 0 && "ms-2"}`}>
                              <span>
                                 <b>Room : </b>
                                 <span>{`${item[0].room?.room_name}`}</span>
                              </span>
                              <Divider type="vertical" className="border-dark-subtle" />
                              {/* <br /> */}
                              {portalUserType !== "2" && portalUserType !== "3" && (
                                 <span>
                                    <b>Room Value : </b>
                                    <span>
                                       {formatToDollars(
                                          item.reduce((acc, value) => {
                                             let data = value.item_claim?.find(
                                                claim => claim.portal_user_id === Number(clientID)
                                             );

                                             if (data?.estimated_value) {
                                                acc += Number(data.estimated_value) || 0;
                                             } else {
                                                acc += Number(value.google_value_range) || 0;
                                             }

                                             return acc;
                                          }, 0)
                                       )}
                                    </span>
                                 </span>
                              )}
                           </div>
                           {item.map((source, subIndex) => (
                              <FullViewCard
                                 mainIndex={mainIndex}
                                 subIndex={subIndex}
                                 key={source.id}
                                 sourceData={source}
                                 selectedFields={selectedFields}
                                 itemCategories={itemCategory}
                              />
                           ))}
                        </>
                     );
                  })}
            </Card>
         </div>
      </div>
   );
};

export default GeneratedReport;

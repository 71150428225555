
import { FullscreenOutlined, HomeOutlined,PlusCircleOutlined,CloseCircleOutlined,CheckCircleOutlined } from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Flex,
    Image,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Space,
    Typography,
} from "antd";
import { Pannellum } from "pannellum-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaCrop } from "react-icons/fa6";
import { MdOutlineImageNotSupported } from "react-icons/md";
import { RiImageAddFill } from "react-icons/ri";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import imageUrl from "../../assets/images/xs/panorama.jpg";
import ButtonComponent from "../../fields/ButtonComponent";
import { addBatchItems } from "../../store/reducer/roomSlice";
import Loader from "../../fields/loader/Loader";
import PageHeader from "../../view/pageheader";
import { getPanoList } from "../../store/reducer/buildThreeSixty";
import { assestURL } from "../../services/config";
import _ from "lodash";
import { addAssignedTo, clientClaimDetails, getAssignTo, getClientUserPanos, getClientUserProjectType, getClientUserRooms } from "../../store/reducer/portalUserSlice";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { getItemCategoriesList } from "../../store/reducer/itemCategoriesSlice";

const ClientTourViewer = () => {
    const { TextArea } = Input;
    const { Title } = Typography;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [cropConfig, setCropConfig] = useState({ unit: "px", width: 0, height: 0, x: 25, y: 25 });
    const [crop, setCrop] = useState(cropConfig);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const imageRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const panoramaRef = useRef(null);
    const containerRef = useRef(null);
    const [imageData, setImageData] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [lassoImageDetails, setLassoImageDetails] = useState({
        file_name: "lasso_image",
        binary_data: "",
        room_id: 2,
        is_lasso: 1,
    });
    const [selectedPano, setSelectedPano] = useState()
    const [panoDetails, setPanoDetails] = useState({
        pano_name: '',
        room_id: '',
        default_pitch:0,
        default_yaw:0,
        zoom:0
    })
    const [itemCondition, setItemCondition] = useState([
        {
            value: 1,
            label: "Excellent",
        },
        {
            value: 2,
            label: "Good",
        },
        {
            value: 3,
            label: "Fair",
        },
        {
            value: 4,
            label: "Poor",
        },
        {
            value: 5,
            label: "Refurbished",
        },
        {
            value: 6,
            label: "Repair",
        }
    ])
    const [imageUrl, setImageUrl] = useState('')
    const [selectedRoom, setSelectedRoom] = useState(0)
    const [selectedRoomName, setSelectedRoomName] = useState(0)
    const { Option } = Select;
    const [assignedTo, setAssignedTo] = useState([])
    const roleID = localStorage.getItem('role_id')
    const { addBatchItemSuccess, addBatchItemsError, addBatchItemLoading } = useSelector(
        state => state.rooms
    );
    const { itemCategoriesListData } = useSelector(state => state.itemCategories);

    const clientUserId = localStorage.getItem('client_id')
    const currentUrl = window.location.href;
    const demoMode = currentUrl.includes('preview')
    const { jobid, project_id, roomid } = useParams();
    const { panoListData } = useSelector(state => state.buildThreeSixty)
    const { clientUserPanos, clientUserRooms,addAssignedToSuccess,getAssignedToData,portalUserProjectType } = useSelector(state => state.portalUser)
    const [itemDetails, setItemDetails] = useState({
        room_id: '',
        name: '',
        client_value_range: '',
        google_value_range: '',
        item_id: '',
        portal_user_id: '',
        condition: '',
        category: '',
        date_of_purchase: '',
        model: '',
        serial_number: '',
        manufacturer: '',
        quantity: 0,
        general_notes: ''
    })
    const [assignToTextBox, setAssignToTextBox] = useState({
        assignTextInput: false,
        assignTextInputValue: ''
    })
    const [itemClaimReasons, setItemClaimReasons] = useState([
        {
            value: 1,
            label: "Pre-Marriage",
        },
        {
            value: 2,
            label: "Gift",
        },
        {
            value: 3,
            label: "Inherited",
        },
        {
            value: 4,
            label: "Bought During Marriage",
        },
        {
            value: 5,
            label: "Other - See Notes",
        },
    ])
    const [selectDropDown, setSelectDropDown] = useState([
        {
            value: 1,
            label: "Option 1",
        },
        {
            value: 2,
            label: "Option 2",
        },
        {
            value: 3,
            label: "Option 3",
        },
    ]);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imageRef.current) {
            return;
        }

        // const image = imageRef.current;
        // const canvas = previewCanvasRef.current;
        // const crop = completedCrop;

        // const scaleX = image.naturalWidth / image.width;
        // const scaleY = image.naturalHeight / image.height;
        // const pixelRatio = window.devicePixelRatio;

        // // Set canvas dimensions to screen size
        // const screenWidth = window.innerWidth;
        // const screenHeight = window.innerHeight;

        // canvas.width = screenWidth * pixelRatio;
        // canvas.height = screenHeight * pixelRatio;
        // canvas.style.width = `${screenWidth}px`;
        // canvas.style.height = `${screenHeight}px`;

        // const ctx = canvas.getContext("2d");
        // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

        // ctx.imageSmoothingQuality = "high";

        // ctx.drawImage(
        //    image,
        //    crop.x * scaleX,
        //    crop.y * scaleY,
        //    crop.width * scaleX,
        //    crop.height * scaleY,
        //    0,
        //    0,
        //    screenWidth,
        //    screenHeight
        // );

        // // Set the cropped image data URL
        // setCroppedImageUrl(canvas.toDataURL("image/png"));

        const image = imageRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        if (!image || !canvas || !crop) return;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const pixelRatio = window.devicePixelRatio;

        // Calculate the aspect ratio of the crop area
        const cropAspectRatio = crop.width / crop.height;

        // Determine the new canvas dimensions based on the screen size and the crop aspect ratio
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        // Adjust canvas dimensions to maintain aspect ratio
        let newWidth, newHeight;

        if (screenWidth / screenHeight > cropAspectRatio) {
            newHeight = screenHeight * pixelRatio;
            newWidth = newHeight * cropAspectRatio;
        } else {
            newWidth = screenWidth * pixelRatio;
            newHeight = newWidth / cropAspectRatio;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;
        canvas.style.width = `${newWidth / pixelRatio}px`;
        canvas.style.height = `${newHeight / pixelRatio}px`;

        const ctx = canvas.getContext("2d");
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

        ctx.imageSmoothingQuality = "high";

        // Draw the cropped image on the canvas with the new dimensions
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            newWidth,
            newHeight
        );

        // Set the cropped image data URL
        setCroppedImageUrl(canvas.toDataURL("image/png"));

        console.log("Image rendered on canvas");
    }, [completedCrop]);

    useEffect(() => {
        if (addBatchItemSuccess || addBatchItemsError) {
            setCrop(cropConfig);
            setCompletedCrop(null);
            setCroppedImageUrl(null);
            setImageData(null);
            setIsModalOpen(false);
        }

        if (addBatchItemSuccess) {
            toast.success("Image added to room items.");
            navigate(`/clientportal/${project_id}/${jobid}`)
        }

        if (addBatchItemsError) {
            toast.error("Image not added to room items. Try Again");
        }
    }, [addBatchItemSuccess, addBatchItemsError]);

    useEffect(() => {
        setImageData("");
        panoramaRef.current.panorama.resize();
    }, [isFullscreen]);

    useEffect(() => {
        document.addEventListener("fullscreenchange", preventEscapeExitFullscreen);
        document.addEventListener("webkitfullscreenchange", preventEscapeExitFullscreen);
        document.addEventListener("mozfullscreenchange", preventEscapeExitFullscreen);
        document.addEventListener("MSFullscreenChange", preventEscapeExitFullscreen);
    }, []);

    useEffect(() => {
        const decodedJobId = Number(atob(jobid))
        const decodedRommId = Number(atob(roomid))
        dispatch(getClientUserPanos({ job_id: decodedJobId, room_id: decodedRommId }));
    }, [clientUserId, jobid, roomid])

    useEffect(() => {
        let decodedId = project_id
        dispatch(getClientUserProjectType({ project_id: Number(decodedId) }));
    }, [project_id])

    const handleImageLoaded = useCallback(image => {
        imageRef.current = image;
    }, []);

    const handleCompleteCrop = crop => {
        setCompletedCrop(crop);
    };

    const handleSave = () => {
        if (isFullscreen) {
            exitFullscreen();
            setIsFullscreen(false);
            setCrop(cropConfig);
        }
        setIsModalOpen(true);
        // if (previewCanvasRef.current) {
        //    // const link = document.createElement("a");
        //    // link.href = previewCanvasRef.current.toDataURL("image/png");
        //    // link.download = "cropped-image.png";
        //    // link.click();
        // }
    };

    const handleCancel = () => {
        setCrop(cropConfig);
        setCompletedCrop(null);
        setCroppedImageUrl(null);
        setImageData(null);
    };

    const handleFormSubmit = () => {
        if (croppedImageUrl) {
            lassoImageDetails.room_id = atob(roomid)
            let postData = [{ ...lassoImageDetails, binary_data: croppedImageUrl }];
            console.log("postData", postData);
            dispatch(addBatchItems({ postData }));
        }
    };

    const preventEscapeExitFullscreen = event => {
        setImageData("");
        if (!document.fullscreenElement) {
            setIsFullscreen(false);
            // setCroppedImageUrl("");
            setCrop(cropConfig);
            panoramaRef.current.panorama.resize();
        }
    };

    const enableCropMode = () => {
        const img = panoramaRef.current.panorama
            .getRenderer()
            .render(
                (panoramaRef.current.panorama.getPitch() / 180) * Math.PI,
                (panoramaRef.current.panorama.getYaw() / 180) * Math.PI,
                (panoramaRef.current.panorama.getHfov() / 180) * Math.PI,
                { returnImage: true }
            );
        setImageData(img);
    };

    const enterFullscreen = () => {
        if (containerRef.current.requestFullscreen) {
            return containerRef.current.requestFullscreen();
        } else if (containerRef.current.mozRequestFullScreen) {
            return containerRef.current.mozRequestFullScreen();
        } else if (containerRef.current.webkitRequestFullscreen) {
            return containerRef.current.webkitRequestFullscreen();
        } else if (containerRef.current.msRequestFullscreen) {
            return containerRef.current.msRequestFullscreen();
        }
    };

    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            return document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            return document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            return document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            return document.msExitFullscreen();
        }
    };

    const handleFullscreenToggle = () => {
        if (!document.fullscreenElement) {
            // document.addEventListener("keydown", preventEscapeExitFullscreen);
            //  preventEscapeExitFullscreen();
            enterFullscreen().then(() => setIsFullscreen(true));
        } else {
            exitFullscreen().then(() => setIsFullscreen(false));
            // document.removeEventListener("keydown", preventEscapeExitFullscreen);
        }
    };

    console.log(clientUserPanos, 'clientUserPanos');


    useEffect(() => {
        if (clientUserPanos && clientUserPanos.length > 0) {
            setImageUrl(assestURL + clientUserPanos[0].pano_image)
            setSelectedPano(`${clientUserPanos[0].id}`)
            setPanoDetails({
                pano_name: clientUserPanos[0].pano_name,
                room_id: clientUserPanos[0].room_id,
                default_pitch:clientUserPanos[0].default_pitch,
                default_yaw:clientUserPanos[0].default_yaw,
                zoom:clientUserPanos[0].zoom
            })
        }
    }, [clientUserPanos])

    useEffect(() => {
        const decodedId = Number(atob(project_id))
        dispatch(getItemCategoriesList({ id: decodedId }));
    }, [project_id])


    const onPanoChange = (value) => {
        console.log(value, 'value');
        let filteredData = _.find(clientUserPanos && clientUserPanos, { id: Number(value) })
        console.log(filteredData, 'filteredData');
        setImageUrl(assestURL + filteredData?.pano_image)
        setPanoDetails({
            pano_name: filteredData?.pano_name,
            room_id: filteredData?.room_id
        })
        setSelectedPano(value)
    }

    const onItemValueChange = (e) => {
        const { name, value } = e.target;
        setItemDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }


    const handleQuantityChange = (newValue) => {
        console.log('Changed value: ', newValue);
        setItemDetails({
            ...itemDetails,
            quantity: newValue
        });
    };

    const itemDetailsOnSave = () => {
        let postData = itemDetails
        // postData.item_id = imageData && imageData[currentImageIndex] && imageData[currentImageIndex]?.id
        postData.portal_user_id = Number(clientUserId)
        console.log(postData, 'postData');
        dispatch(clientClaimDetails({ postData }))
    }

    const onSelectChange = (value, name) => {
        setItemDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onDateChange = (date, dateString) => {
        setItemDetails({
            ...itemDetails,
            date_of_purchase: date ? dayjs(dateString) : null
        });
    };

    const disableFutureDates = current => {
        // Can not select days after today
        return current && current > moment().endOf('day');
    };

    useEffect(() => {
        const decodedId = Number(atob(jobid))
        dispatch(getClientUserRooms({ id: Number(clientUserId), job_id: decodedId }));
    }, [clientUserId, jobid])

    useEffect(() => {
        if (clientUserRooms && clientUserRooms.length > 0) {
            setSelectedRoom(`${clientUserRooms[0].id}`)
            setSelectedRoomName(clientUserRooms[0].room_name)
        }
    }, [clientUserRooms])

    useEffect(() => {
        if (addAssignedToSuccess === true) {
            toast.success('Added Successfully !')
            let projectId = atob(project_id)
            dispatch(getAssignTo({ id: Number(projectId) }))
        }
        else {
            let projectId = atob(project_id)
            dispatch(getAssignTo({ id: Number(projectId) }))
        }
    }, [project_id, addAssignedToSuccess])

        // Handle adding the new value to the assignedTo array
        const handleAddAssignedTo = () => {
            if (assignToTextBox.assignTextInputValue.trim()) {
                const newAssignedTo = {
                    value: assignedTo.length + 1,
                    label: assignToTextBox.assignTextInputValue,
                };
                console.log(newAssignedTo, 'newAssignedTo');
                let decodedProjectId = atob(project_id)
                let decodedJobId = atob(jobid)
                let postData = {
                    item_id: itemDetails.item_id,
                    room_id: itemDetails.room_id,
                    job_id: Number(decodedJobId),
                    assignto_name: assignToTextBox.assignTextInputValue,
                    project_id: Number(decodedProjectId)
                }
                dispatch(addAssignedTo({postData}))
                setAssignedTo([...assignedTo, newAssignedTo]);
                setAssignToTextBox({ assignTextInputValue: '' }); // Clear the input box after adding
                setAssignToTextBox(false); // Hide the input box after adding
            } else {
                toast.error("Please enter a value before adding.");
            }
        };

    const updateAssignedTo = () => {
        const updatedAssignedTo = [
            {
                value: 0,
                label: "Un-Assigned",
            },
            ...getAssignedToData.map(item => ({
                value: item.id,
                label: item.assignto_name,
            }))
        ];
    
        setAssignedTo(updatedAssignedTo);
    };
    
    // You can call this function inside a useEffect or on some event
    useEffect(() => {
        updateAssignedTo();
    }, [getAssignedToData]); // This runs when getAssignedToData changes

    console.log(selectedPano, 'selectedPano');

    return (
        <div>
            <PageHeader
                HeaderText="Tour View"
            // Breadcrumb={[{ name: "TakeStock Room Details/room" }]}
            />
            <Breadcrumb className="mb-3"
                separator=">"
                items={[
                    {
                        title: (
                            <>
                                <HomeOutlined />
                                <span>Home</span>
                            </>
                        ),
                        href: demoMode === true ? '/preview/clientportal/home/:project_id' : '/clientportal/home'
                    },
                    {
                        title: localStorage.getItem('room_name') ? localStorage.getItem('room_name') : '',
                        href: demoMode === true ? `/preview/clientportal/${project_id}/${jobid}` : `/clientportal/${project_id}/${jobid}`
                    },
                    {
                        title: panoDetails.pano_name ? panoDetails.pano_name : '',
                    },
                ]}
            />
            <Card
                extra={
                    demoMode === true ? null :
                        <Space>
                            {!imageData ? (
                                <Button
                                    shape="round"
                                    style={{ backgroundColor: "#f7931e", color: "white" }}
                                    icon={<FaCrop size={"1.2em"} />}
                                    onClick={enableCropMode}
                                >
                                    Lasso
                                </Button>
                            ) : (
                                <>
                                    {croppedImageUrl && (
                                        <Button
                                            shape="round"
                                            className="bg-success text-white"
                                            icon={<RiImageAddFill size={"1.2em"} />}
                                            onClick={handleSave}
                                        >
                                            Save
                                        </Button>
                                    )}
                                    <Button
                                        shape="round"
                                        className="bg-danger text-white"
                                        icon={<MdOutlineImageNotSupported size={"1.2em"} />}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            )}
                        </Space>
                }
            >
                <Loader loading={addBatchItemLoading} />
                <div
                    ref={containerRef}
                    style={{
                        position: "relative",
                        width: "100%",
                        height: isFullscreen ? "100vh" : "70vh",
                    }}
                >
                    <div
                        className={imageData ? "d-none" : ""}
                        // ref={containerRef}
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            //   height: isFullscreen ? "100vh" : "70vh",
                        }}
                    >
                        <Pannellum
                            ref={panoramaRef}
                            width="100%"
                            height="100%"
                            image={imageUrl}
                            pitch={panoDetails.default_pitch ? Number(panoDetails.default_pitch) : 180}
                            yaw={panoDetails.default_yaw ? Number(panoDetails.default_yaw) : 180}
                            hfov={panoDetails.zoom ? Number(panoDetails.zoom) : 180}
                            // vaov={180}
                            showFullscreenCtrl={false}
                            autoLoad
                            mouseZoom
                            mouseDrag
                        />
                        <Space
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                zIndex: 1000,
                            }}
                        >
                            {isFullscreen && demoMode === false ? (
                                <Button
                                    className="text-bg-primary border-0"
                                    shape="round"
                                    // style={{ backgroundColor: "#f7931e", color: "white" }}
                                    icon={<FaCrop size={"1.2em"} />}
                                    onClick={enableCropMode}
                                >
                                    Lasso
                                </Button>
                            ) : null}
                            <Select
                                style={{ width: 200 }}
                                defaultActiveFirstOption
                                value={selectedPano}
                                onChange={onPanoChange}
                            >
                                {clientUserPanos && clientUserPanos.length > 0 && clientUserPanos.map((item) => {
                                    return (
                                        <Option key={item.id}>{item?.pano_name}</Option>
                                    )
                                })}
                            </Select>
                            <Button
                                type="text"
                                className="bg-white"
                                icon={<FullscreenOutlined className="fs-5" />}
                                onClick={handleFullscreenToggle}
                            />
                        </Space>
                    </div>
                    <div
                        className={!imageData ? "d-none" : ""}
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            //   height: isFullscreen ? "100vh" : "70vh",
                        }}
                    >
                        <ReactCrop
                            crop={crop}
                            onChange={newCrop => setCrop(newCrop)}
                            onImageLoaded={handleImageLoaded}
                            onComplete={handleCompleteCrop}
                            style={{ height: "100%", width: "100%" }}
                        >
                            <img ref={imageRef} src={imageData} alt="Room" height="100%" width="100%" />
                        </ReactCrop>
                        <Space
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                zIndex: 1000,
                            }}
                        >
                            {isFullscreen && (
                                <>
                                    {croppedImageUrl && (
                                        <Button
                                            shape="round"
                                            className="bg-white text-success border-0"
                                            icon={<RiImageAddFill size={"1.2em"} />}
                                            onClick={handleSave}
                                        >
                                            Save
                                        </Button>
                                    )}
                                    <Button
                                        shape="round"
                                        className="bg-white text-danger border-0"
                                        icon={<MdOutlineImageNotSupported size={"1.2em"} />}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            )}
                        </Space>
                        <div style={{ display: "none" }}>
                            <canvas ref={previewCanvasRef} />
                        </div>
                        <Modal
                            title={
                                <Title level={4} className="ms-4 ps-1">
                                    Item Information
                                </Title>
                            }
                            // open={isModalOpen}
                            style={{ top: 10 }}
                            width={800}
                            // onCancel={() => setIsModalOpen(false)}
                            footer={null}
                            destroyOnClose
                        >
                            <Card
                                styles={{ body: { paddingTop: 5 } }}
                                className="px-1 pt-0 mt-0"
                                bordered={false}
                            >
                                <Row gutter={16} className="pe-2">
                                    <Col span={8}>
                                        {/* <img
                              src={croppedImageUrl}
                              alt="Cropped"
                              style={{ maxWidth: "250px", height: "auto" }}
                           /> */}
                                        <Image src={croppedImageUrl} width={250} alt="Cropped" />
                                    </Col>
                                    <Col span={15}>
                                        <Card styles={{ body: { padding: 6 } }}>
                                            <Flex gap={10} vertical>
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>Room Name :</label>
                                                    <Select
                                                        className="input-box-size"
                                                        placeholder="Room Name"
                                                        name='room_name'
                                                        value={selectedRoom}
                                                        disabled={true}
                                                    >
                                                        {clientUserRooms && clientUserRooms.length > 0 && clientUserRooms.map((item) => {
                                                            return (
                                                                <Option key={item.id}>{item?.room_name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                    {/* <Input className="input-box-size" placeholder="Room Name" /> */}
                                                </Flex>
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>Item Name :</label>
                                                    <Input className="input-box-size" placeholder="Item Name" name="name" value={itemDetails.name} disabled={demoMode === true ? true : false} onChange={(e) => { onItemValueChange(e) }} />
                                                </Flex>
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>Client's Estimate($) :</label>
                                                    <Input className="input-box-size" placeholder="Client Estimate" name="client_value_range" value={itemDetails.client_value_range} onChange={(e) => { onItemValueChange(e) }} />
                                                </Flex>
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>AI Estimate($) :</label>
                                                    <Input className="input-box-size" placeholder="AI Estimate" name="google_value_range" value={itemDetails.google_value_range} onChange={(e) => { onItemValueChange(e) }} />
                                                </Flex>
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>Quantity :</label>
                                                    <div className="input-box-size">
                                                        <InputNumber placeholder="Qty" name="quantity" min={1} value={itemDetails.quantity} onChange={handleQuantityChange} />
                                                    </div>
                                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                                </Flex>
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>Category :</label>
                                                    <Select
                                                        className="input-box-size"
                                                        placeholder="Category"
                                                        value={itemDetails.category ? `${itemDetails.category}` : null}
                                                        onChange={(value) => onSelectChange(value, 'category')}
                                                    >
                                                        {itemCategoriesListData?.data && itemCategoriesListData?.data.length > 0 && itemCategoriesListData?.data.map((item) => {
                                                            return (
                                                                <Option key={item.id}>{item?.category_name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                                </Flex>
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>Condition :</label>
                                                    <Select
                                                        className="input-box-size"
                                                        options={itemCondition}
                                                        placeholder="Condition"
                                                        value={itemDetails.item_condition ? itemDetails.item_condition : null}
                                                        onChange={(value) => onSelectChange(value, 'item_condition')}
                                                    />
                                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                                </Flex>
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>Date of Purchase :</label>
                                                    <DatePicker format={'MM-DD-YYYY'} className="input-box-size" disabledDate={disableFutureDates} onChange={onDateChange} name="date_of_purchase" value={itemDetails.date_of_purchase ? dayjs(itemDetails.date_of_purchase) : null} />
                                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                                </Flex>
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>Model :</label>
                                                    <Input className="input-box-size" placeholder="Model" name="model" value={itemDetails.model} onChange={(e) => { onItemValueChange(e) }} />
                                                </Flex>
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>Serial Number :</label>
                                                    <Input className="input-box-size" placeholder="Serial Number" name="serial_number" value={itemDetails.serial_number} onChange={(e) => { onItemValueChange(e) }} />
                                                </Flex>
                                                {portalUserProjectType === 1 || portalUserProjectType === 3 ?
                                                    <>
                                                        <Flex gap={10} justify="right" align="center">
                                                            <label>Assign to :</label>
                                                            <div className="input-box-size">
                                                                <Select
                                                                    className="input-box-size"
                                                                    placeholder="Create/Assign Heir"
                                                                    style={{ width: '60%' }}
                                                                    value={itemDetails.distribute_to ? `${itemDetails.distribute_to}` : null} disabled={demoMode || itemDetails.is_admin === 1 ? true : false}
                                                                    onChange={(value) => onSelectChange(value, 'distribute_to')}
                                                                // onChange={(value) => onChange(value, 'item_condition')}
                                                                >
                                                                    {assignedTo && assignedTo.length > 0 && assignedTo.map((item) => {
                                                                        return (
                                                                            <Option key={item.value}>{item?.label}</Option>
                                                                        )
                                                                    })}
                                                                </Select>
                                                                <PlusCircleOutlined size={20} onClick={(e) => {
                                                                    if (demoMode === true) {
                                                                        e.preventDefault()
                                                                    }
                                                                    else {
                                                                        setAssignToTextBox({
                                                                            ...assignToTextBox,
                                                                            assignTextInput: true
                                                                        })
                                                                    }
                                                                }} disabled={demoMode || itemDetails.is_admin === 1 ? true : false} style={{ marginLeft: '10px', fontSize: '25px', color: 'green' }}
                                                                />
                                                                <CloseCircleOutlined onClick={(e) => {
                                                                    if (demoMode === true) {
                                                                        e.preventDefault()
                                                                    }
                                                                    else {
                                                                        setAssignToTextBox({
                                                                            ...assignToTextBox,
                                                                            assignTextInput: false
                                                                        })
                                                                    }
                                                                }} disabled={demoMode || itemDetails.is_admin === 1 ? true : false} style={{ marginLeft: '10px', fontSize: '25px', color: 'red' }}
                                                                />
                                                            </div>
                                                            {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                                        </Flex>
                                                        {assignToTextBox.assignTextInput === true ?
                                                            <div style={{ border: '1px dashed #d9d9d9', padding: '16px', borderRadius: '4px' }}>
                                                                <Flex gap={10} justify="right" align="center">
                                                                    <label>Enter Name to Add </label>
                                                                    <div className="input-box-size">
                                                                        <Input className="input-box-size" placeholder="Enter Name to Add" name="assignTextInputValue" value={assignToTextBox.assignTextInputValue} style={{ width: '80%' }} onChange={(e) => {
                                                                            setAssignToTextBox({
                                                                                ...assignToTextBox,
                                                                                assignTextInputValue: e.target.value
                                                                            })
                                                                        }} />
                                                                        <CheckCircleOutlined onClick={handleAddAssignedTo} style={{ marginLeft: '10px', fontSize: '25px', color: 'green' }}
                                                                        />
                                                                    </div>
                                                                    {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                                                </Flex></div> : null}
                                                    </> : null}
                                                {portalUserProjectType === 2 || portalUserProjectType === 1 ?
                                                    <Flex gap={10} justify="right" align="center">
                                                        <label>Do you want this item: </label>
                                                        <div className="input-box-size">
                                                            <Checkbox name="distribute" value={itemDetails.distribute} checked={itemDetails.distribute === 0 ? false : true} disabled={demoMode || itemDetails.is_admin === 1 ? true : false} onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setItemDetails({
                                                                        ...itemDetails,
                                                                        distribute: 1
                                                                    })
                                                                }
                                                                else {
                                                                    setItemDetails({
                                                                        ...itemDetails,
                                                                        distribute: 0
                                                                    })
                                                                }
                                                            }}></Checkbox>
                                                            {portalUserProjectType === 2 ?
                                                                <Select
                                                                    className="input-box-size mr-3"
                                                                    placeholder="Reason For Claim"
                                                                    style={{ width: '80%', marginLeft: '10px' }}
                                                                    value={itemDetails.claimed_as ? `${itemDetails.claimed_as}` : null} disabled={demoMode || itemDetails.is_admin === 1 ? true : false}
                                                                    onChange={(value) => onSelectChange(value, 'claimed_as')}
                                                                >
                                                                    {itemClaimReasons && itemClaimReasons.length > 0 && itemClaimReasons.map((item) => {
                                                                        return (
                                                                            <Option key={item.value}>{item?.label}</Option>
                                                                        )
                                                                    })}
                                                                </Select>
                                                                : null}
                                                        </div>
                                                        {/* <Input className="input-box-size" placeholder="Item Name" /> */}
                                                    </Flex> : null}
                                                <Flex gap={10} justify="right" align="center">
                                                    <label>General Notes :</label>
                                                    <TextArea
                                                        className="input-box-size" name="general_notes"
                                                        style={{ resize: "none" }}
                                                        rows={6} value={itemDetails.general_notes} onChange={(e) => { onItemValueChange(e) }}
                                                    />
                                                </Flex>
                                                <Flex justify="right">
                                                    <Checkbox>Appraise</Checkbox>
                                                    <Checkbox>Donate</Checkbox>
                                                    <Checkbox>Sell</Checkbox>
                                                </Flex>
                                                <Flex className="my-1" gap={10} justify="flex-end">
                                                    <ButtonComponent
                                                        style={{ backgroundColor: "#f7931e", color: "white" }}
                                                        buttonName="Save"
                                                        onClick={handleFormSubmit}
                                                        icon={<i className="fa fa-hdd-o" aria-hidden="true"></i>}
                                                    />
                                                    <ButtonComponent
                                                        style={{ backgroundColor: "#6E6E6E", color: "white" }}
                                                        buttonName="Clear"
                                                        icon={<i className="fa fa-refresh" aria-hidden="true"></i>}
                                                    />
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Modal>
                        <Modal
                            title={<Title level={4} className="ms-4 ps-1">Item Information</Title>}
                            open={isModalOpen}
                            style={{ top: 10 }}
                            width={800}
                            onCancel={() => setIsModalOpen(false)}
                            footer={null}
                            destroyOnClose
                        >
                            <Card
                                styles={{ body: { paddingTop: 5 } }}
                                className="px-1 pt-0 mt-0"
                                bordered={false}
                            >
                                <div className="pe-2">
                                    {/* Select Room Name */}
                                    {/* <div style={{ marginBottom: "16px" }}>
                                        <label>Room Name :</label>
                                        <Select
                                            className="input-box-size"
                                            placeholder="Room Name"
                                            name='room_name'
                                            value={selectedRoom}
                                            disabled={true}
                                            style={{ width: '100%' }}
                                        >
                                            {clientUserRooms && clientUserRooms.length > 0 && clientUserRooms.map((item) => (
                                                <Option key={item.id}>{item?.room_name}</Option>
                                            ))}
                                        </Select>
                                    </div> */}

                                    {/* Image */}
                                    <div style={{ marginBottom: "16px", textAlign: "center" }}>
                                        <Image src={croppedImageUrl} width={250} alt="Cropped" />
                                    </div>

                                    {/* Buttons */}
                                    <div style={{ textAlign: "right" }}>
                                        <ButtonComponent
                                            style={{ backgroundColor: "#f7931e", color: "white", marginRight: "8px" }}
                                            buttonName="Save"
                                            onClick={handleFormSubmit}
                                            icon={<i className="fa fa-hdd-o" aria-hidden="true"></i>}
                                        />
                                        <ButtonComponent
                                            style={{ backgroundColor: "#6E6E6E", color: "white" }}
                                            buttonName="Clear"
                                            icon={<i className="fa fa-refresh" aria-hidden="true"></i>}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Modal>

                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ClientTourViewer;

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { UserOutlined, MailOutlined, PhoneOutlined, EyeInvisibleOutlined, PlusOutlined } from '@ant-design/icons';
import PageHeader from '../../view/pageheader'
import CardComponent from '../../fields/CardComponent'
import InputComponent from '../../fields/InputComponent';
import SelectComponent from '../../fields/SelectComponent';
import { Image, Upload, message, Input } from 'antd';
import ImgCrop from 'antd-img-crop';
import CheckboxComponent from '../../fields/CheckboxComponent';
import ButtonComponent from '../../fields/ButtonComponent';
import { addPhotographer, getPhotographerData, resetSuccessFlag, updatePhotographer } from '../../store/reducer/photographerSlice';
import { getStatesList } from '../../store/reducer/statesListSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { icons } from 'antd/es/image/PreviewGroup';
import { assestURL } from '../../services/config';


const ManagedPhotographer = () => {
    const [photographerDetails, setPhotographerDetails] = useState({
        role_id: 3,
        user_id: '',
        first_name: '',
        last_name: '',
        // upload_photo: '',
        imgpath: '',
        image: '',
        email: '',
        password: '',
        confirm_password: '',
        address_one: '',
        address_two: '',
        city: '',
        state: '',
        zip_code: '',
        status: 1,
        paper_work: [],
        permissions: [1, 2, 3, 4, 5]
    });

    const [dataArray, setDataArray] = useState({
        permissions: [
            {
                permission_id: 1,
                permission_name: 'Create Room',
            },
            {
                permission_id: 2,
                permission_name: 'Upload Image',
            },
            {
                permission_id: 3,
                permission_name: 'Edit Room Images',
            },
            {
                permission_id: 4,
                permission_name: 'Upload Pano',
            },
            {
                permission_id: 5,
                permission_name: 'Upload Pano to Room',
            }
        ],
        paper_work: []
    })

    console.log(dataArray, 'dataArray');
    console.log(photographerDetails, 'photographerDetails');

    const [status, setStatus] = useState([
        {
            value: 1,
            label: 'Active',
        },
        {
            value: 0,
            label: 'Inactive',
        }
    ])
    const permissionsOptions = [
        {
            label: 'Create Room',
            value: 1,
        },
        {
            label: 'Upload Image',
            value: 2,
        },
        {
            label: 'Edit Room Images',
            value: 3,
        },
        {
            label: 'Upload Pano',
            value: 4,
        },
        {
            label: 'Upload Pano to Tour',
            value: 5,
        }
    ];
    const paperWorkCompletedOptions = [
        {
            label: 'NDA',
            value: 1,
        },
        {
            label: '1099 Contract',
            value: 2,
        },
        {
            label: 'W-9',
            value: 3,
        },
        {
            label: 'W/C Waiver',
            value: 4,
        }
    ]
    const [touchedFields, setTouchedFields] = useState({});
    const [, forceUpdate] = useState();  // Use state to trigger re-renders
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileListOnChange, setFileListOnChange] = useState([]);
    const [stateList, setStateList] = useState([]);
    const { addPhotographerSuccess, addPhotographerError, addedPhotgrapher, getDataById, updateData, updatePhotographerSuccess, photographerError } = useSelector((state) => state.photographer);

    const { statesListData, statesListLoading, statesError, statesErrorMessage } = useSelector((state) => state.states);

    console.log(getDataById, 'getDataById');
    console.log(previewImage, fileList, 'previewImage');
    console.log(fileList, 'fileList');

    const [type, setType] = useState('add')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    //Validation Rules & Validator
    const validator = useRef(new SimpleReactValidator({
        className: 'validation-message',
        messages: {
            required: 'Please enter :attribute',
            email: 'Email should contain @ and .',
            alpha: ':attribute should contain only alphabets',
            regex: ':attribute format is invalid',
            in: 'Confirm password does not match with password field',
            min: ':attribute must be at least :min characters',
            confirmed: 'Passwords do not match',
            strong_password: 'Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character'
        },
        validators: {
            strong_password: { // name the validator
                message: 'Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/) && params.indexOf(val) === -1;
                },
                required: true // This is an optional parameter. If true, the field will be required.
            }
        }
    }));

    const validationRules = {
        first_name: `required|alpha`,
        last_name: `alpha`,
        upload_photo: '',
        email: `required|email`,
        password: type === 'add' ? `required|strong_password` : photographerDetails.password === '' && type === 'edit' ? '' : `strong_password`, // use the custom validator
        confirm_password: type === 'add' ? `required|in:${photographerDetails.password}` : `in:${photographerDetails.password}`,
        address_one: `required`,
        address_two: '',
        city: `required`,
        state: `required`,
        zip_code: `required|numeric|min:5|max:12`,
        status: `required`,
        permissions: `required`,
        paper_work: ``
    };

    //onChange
    const addEditPhotographerDetails = (e) => {
        const { name, value } = e.target;
        setPhotographerDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
        setTouchedFields(prevState => ({
            ...prevState,
            [name]: true
        }));
    };

    const onChange = (value, name) => {
        setPhotographerDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        console.log(file, 'file');
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => {
        if (newFileList && newFileList.length === 0) {
            setPhotographerDetails({
                ...photographerDetails,
                image: '',
                imgpath: ''
            })
            setFileListOnChange([])
        }
        setFileListOnChange(newFileList)
        setFileList(newFileList)
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <UserOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload Image
            </div>
        </button>
    );

    const onCheckboxChange = (checkedValues) => {
        console.log(checkedValues, 'checkedValues');

        const temp = checkedValues.map((checkedValue) => {
            const option = permissionsOptions.find(opt => opt.value === checkedValue);
            return {
                permission_id: option.value,
                permission_name: option.label,
            };
        });

        const tempIds = checkedValues.map((checkedValue) => {
            const option = permissionsOptions.find(opt => opt.value === checkedValue);
            return option.value;
        });

        console.log(tempIds, 'tempIds');

        setDataArray({
            ...dataArray,
            permissions: temp
        });

        setPhotographerDetails(prevDetails => ({
            ...prevDetails,
            permissions: [...new Set(tempIds)]
        }));

        console.log(temp, 'temp');
    };

    const onCheckboxPaperChange = (checkedValues) => {
        console.log(checkedValues, 'checkedValues');

        const temp = checkedValues.map((checkedValue) => {
            const option = paperWorkCompletedOptions.find(opt => opt.value === checkedValue);
            return {
                permission_id: option.value,
                permission_name: option.label,
            };
        });

        const tempIds = checkedValues.map((checkedValue) => {
            const option = paperWorkCompletedOptions.find(opt => opt.value === checkedValue);
            return option.value;
        });

        console.log(tempIds, 'tempIds');

        setDataArray({
            ...dataArray,
            paper_work: temp
        });

        setPhotographerDetails(prevDetails => ({
            ...prevDetails,
            paper_work: tempIds
        }));

        console.log(temp, 'temp');
    };



    const handleSubmit = (e) => {
        e.preventDefault();

        if (photographerDetails.password === '' && type === 'edit') {
            delete validator.current.fields.password;
            delete validator.current.fields.confirm_password;
        }

        if (validator.current.allValid()) {
            photographerDetails.role_id = 3
            photographerDetails.imgpath = fileListOnChange && fileListOnChange.length > 0 ? fileListOnChange?.[0]?.name : fileList && fileList.length > 0 && fileList?.[0]?.name
            photographerDetails.image = fileListOnChange && fileListOnChange.length > 0 ? fileListOnChange?.[0]?.thumbUrl : fileList && fileList.length > 0 && fileList?.[0]?.thumbUrl != undefined ? fileList?.[0]?.thumbUrl : photographerDetails.image
            photographerDetails.permissions = dataArray.permissions
            photographerDetails.paper_work = photographerDetails.paper_work.toString()
            let postData = photographerDetails
            if (type === 'add') {
                delete postData.user_id
                dispatch(addPhotographer({ postData }));
            }
            else {
                let updateID = atob(id)
                console.log(updateID, 'updateID');
                dispatch(updatePhotographer({ postData, updateID }));
            }
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    };

    useEffect(() => {
        if (addPhotographerSuccess) {
            setPhotographerDetails({
                ...photographerDetails,
                role_id: 3,
                first_name: '',
                last_name: '',
                upload_photo: '',
                email: '',
                password: '',
                confirm_password: '',
                address_one: '',
                address_two: '',
                city: '',
                state: '',
                zip_code: '',
                status: 1,
                permissions: '',
                paper_work: ''
            })
            setTouchedFields({})
            toast.success('Photographer added Successfully!');
            dispatch(resetSuccessFlag({ flag: 'addPhotographerSuccess' }));
            navigate('/photographer');

        }
        if (updatePhotographerSuccess) {
            setPhotographerDetails({
                ...photographerDetails,
                role_id: 3,
                first_name: '',
                last_name: '',
                upload_photo: '',
                email: '',
                password: '',
                confirm_password: '',
                address_one: '',
                address_two: '',
                city: '',
                state: '',
                zip_code: '',
                status: 1,
                permissions: '',
                paper_work: ''
            })
            setTouchedFields({})
            toast.success('Photographer Updated Successfully!');
            dispatch(resetSuccessFlag({ flag: 'updatePhotographerSuccess' }));
            navigate('/photographer');

        }
        if (photographerError) {
            toast.error(photographerError)
        }
    }, [addPhotographerSuccess, updatePhotographerSuccess, photographerError, dispatch, navigate]);

    useEffect(() => {
        if (id) {
            setType('edit')
            const decodedId = atob(id); // Use a different variable name
            dispatch(getPhotographerData({ id: decodedId }));
        }
        else {
            setType('add')

        }
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(getStatesList({}));
        if (Object.entries(getDataById).length > 0 && type === 'edit') {
            setPhotographerDetails({
                ...photographerDetails,
                role_id: getDataById?.role_id,
                user_id: getDataById?.user_id,
                first_name: getDataById?.first_name,
                last_name: getDataById?.last_name,
                image: getDataById?.image ? getDataById?.image : '',
                email: getDataById?.email,
                address_one: getDataById?.address_one,
                address_two: getDataById?.address_two,
                password: '',
                city: getDataById?.city,
                state: getDataById?.state,
                confirm_password: '',
                zip_code: getDataById?.zip_code,
                status: getDataById?.status,
                permissions: getDataById.permissions.map(item => item.permission_id),
                paper_work: getDataById.paper_work ? getDataById.paper_work.split(',').map(Number) : []  // Assuming paper_work is a comma-separated string
            })
            setTouchedFields({})
        }
    }, [getDataById, type])

    useEffect(() => {
        if (statesListData.length) {
            const list = statesListData.map(item => ({
                value: item.state,
                label: item.state,
            }));
            setStateList([...list]);
        }
    }, [statesListData])

    console.log(photographerDetails, 'photographerDetails');

    const reset = () => {
        setPhotographerDetails({
            ...photographerDetails,
            role_id: 3,
            first_name: '',
            last_name: '',
            upload_photo: '',
            email: '',
            password: '',
            confirm_password: '',
            address_one: '',
            address_two: '',
            city: '',
            state: '',
            zip_code: '',
            status: 1,
            permissions: '',
            paper_work: ''
        })
        setTouchedFields({
            role_id: 3,
            first_name: false,
            last_name: false,
            upload_photo: false,
            email: false,
            password: false,
            confirm_password: false,
            address_one: false,
            address_two: false,
            city: false,
            state: false,
            zip_code: false,
            status: 1,
            permissions: false,
            paper_work: false
        })

    }

    useEffect(() => {
        if (photographerDetails.image) {
            const imageSrc = `${assestURL}${photographerDetails.image}`;
            setFileList([{ uid: '-1', name: 'image.png', status: 'done', url: imageSrc }]);
        }
    }, [photographerDetails]);

    return (
        <div className="container-fluid-photographer" >
            <PageHeader
                HeaderText={type === 'add' ? 'Add Inventory Team Member' : 'Update Inventory Team Member'}
                Breadcrumb={[{ name: type === 'add' ? 'Inventory Team' : ' Inventory Team' }]}
                backButtonUrl={`/photographer`}
            />
            <CardComponent>
                <div class="grid-container">
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="first_name">First Name</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="first_name"
                                placeholder="First Name"
                                type="text"
                                icon={<UserOutlined />}
                                value={photographerDetails.first_name}
                                onChange={addEditPhotographerDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="last_name">Last Name</label>
                            <InputComponent
                                name="last_name"
                                placeholder="Last Name"
                                type="text"
                                icon={<UserOutlined />}
                                value={photographerDetails.last_name}
                                onChange={addEditPhotographerDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item start-col-3 row-span-2">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <>
                                {/* <ImgCrop rotate> */}
                                <Upload
                                    className='photographer'
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    action="https://yourserver.com/upload"  // Replace with your actual upload URL
                                    maxCount={1}
                                    onRemove={(file) => {
                                        // Optionally, handle image removal logic
                                        message.success('Image removed');
                                        setPhotographerDetails({
                                            ...photographerDetails,
                                            image: ""
                                        })
                                    }}
                                >
                                    {fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                                {/* </ImgCrop> */}
                                {previewImage && (
                                    <Image
                                        style={{
                                            display: 'none',
                                            height: '150px',
                                            width: '150px'
                                        }}
                                        preview={{
                                            visible: previewOpen,
                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                        }}
                                        src={previewImage}
                                    />
                                )}
                            </>
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="password">Password</label>{type === 'add' ? <span className='m-1' style={{ color: 'red' }}>*</span> : null}
                            {/* <InputComponent
                                name="password"
                                placeholder="Password"
                                type="text"
                                icon={<EyeInvisibleOutlined />}
                                value={photographerDetails.password}
                                onChange={addEditPhotographerDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            /> */}
                            <Input.Password
                                autoComplete="new-password"
                                type={type}
                                placeholder={"Enter password"}
                                name={"password"}
                                style={{
                                    padding: "5px",
                                }}
                                value={photographerDetails.password}
                                onChange={addEditPhotographerDetails}
                                suffix={<EyeInvisibleOutlined />}
                            />
                            {(validationRules['password'] || touchedFields['password']) && (
                                <div style={{ color: "red" }}>
                                    {validator.current.message('password', photographerDetails.password, validationRules['password'])}
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="confirm_password">Confirm Password</label>{type === 'add' ? <span className='m-1' style={{ color: 'red' }}>*</span> : null}
                            {/* <InputComponent
                                name="confirm_password"
                                placeholder="Confirm Password"
                                type="text"
                                icon={<EyeInvisibleOutlined />}
                                value={photographerDetails.confirm_password}
                                onChange={addEditPhotographerDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            /> */}
                            <Input.Password
                                autoComplete="new-password"
                                type={type}
                                placeholder={"Confirm Password"}
                                name={"confirm_password"}
                                style={{
                                    padding: "5px",
                                }}
                                value={photographerDetails.confirm_password}
                                onChange={addEditPhotographerDetails}
                                suffix={<EyeInvisibleOutlined />}
                            />
                            {(validationRules['confirm_password'] || touchedFields['confirm_password']) && (
                                <div style={{ color: "red" }}>
                                    {validator.current.message('confirm_password', photographerDetails.confirm_password, validationRules['confirm_password'])}
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="email">Email</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="email"
                                placeholder="Email Id"
                                type="text"
                                icon={<MailOutlined />}
                                value={photographerDetails.email}
                                onChange={addEditPhotographerDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="address_one">Address 1</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="address_one"
                                placeholder="Address line 1"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={photographerDetails.address_one}
                                onChange={addEditPhotographerDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="address_two">Address 2</label>
                            <InputComponent
                                name="address_two"
                                placeholder="Address line 2"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={photographerDetails.address_two}
                                onChange={addEditPhotographerDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="city">City</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="city"
                                placeholder="City"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={photographerDetails.city}
                                onChange={addEditPhotographerDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="state">State</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <SelectComponent
                                name="state"
                                value={photographerDetails.state ? photographerDetails.state : null}
                                placeholder="Select State"
                                onChange={(value) => onChange(value, 'state')}
                                onSearch={onSearch}
                                options={stateList}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                            {/* <InputComponent
                                name="state"
                                placeholder="State"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={photographerDetails.state}
                                onChange={addEditPhotographerDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            /> */}
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="zip_code">Zip Code</label><span className='m-1' style={{ color: 'red' }}>*</span>
                            <InputComponent
                                name="zip_code"
                                placeholder="Zip Code"
                                type="text"
                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                value={photographerDetails.zip_code}
                                onChange={addEditPhotographerDetails}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>

                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="name">Status</label>
                            <span className="m-1" style={{ color: 'red' }}>*</span><br />
                            <SelectComponent
                                name="status"
                                value={photographerDetails.status}
                                placeholder="Please choose staff category_type"
                                onChange={(value) => onChange(value, 'status')}
                                onSearch={onSearch}
                                options={status}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules}
                            />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="permissions">Permissions</label><span className='m-1' style={{ color: 'red' }}>*</span><br />
                            <CheckboxComponent name="permissions" options={permissionsOptions} value={photographerDetails.permissions} onChange={(checkedValues) => { onCheckboxChange(checkedValues) }}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules} />
                        </div>
                    </div>
                    <div class="grid-item col-span-1">
                        <div>
                            <label htmlFor="paper_work">Paperwork Completed</label><br />
                            <CheckboxComponent name="paper_work" options={paperWorkCompletedOptions} value={photographerDetails.paper_work} onChange={onCheckboxPaperChange}
                                validator={validator}
                                touchedFields={touchedFields}
                                validationRules={validationRules} />
                        </div>
                    </div>
                </div>
                <div className='text-center mt-3'>
                    <ButtonComponent style={{ backgroundColor: '#f7931e', color: 'white' }} onClick={handleSubmit} buttonName={type === 'add' ? "Add Photographer" : "Update Photographer"} icon={<i class="fa fa-user-plus" aria-hidden="true"></i>} />&nbsp;
                    <ButtonComponent style={{ backgroundColor: '#6E6E6E', color: 'white' }} onClick={reset} buttonName="Reset" icon={<i class="fa fa-refresh" aria-hidden="true"></i>} />
                </div>
            </CardComponent>
        </div>
    )
}

export default ManagedPhotographer
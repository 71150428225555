export function formatToDollars(amount) {
   const number = Number(amount) || 0;

   // Check if the conversion resulted in NaN
   if (isNaN(number)) {
      return "$0.00";
   }

   return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
   }).format(amount);
}

import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Pannellum } from 'pannellum-react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, Button, Select } from 'antd';
import panorama from '../../assets/images/xs/panorama.jpg';

const ThreeSixtyView = () => {
    const [isCropMode, setIsCropMode] = useState(false);
    const [crop, setCrop] = useState({ unit: '%', width: 50, aspect: undefined });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState('');
    const [visible, setVisible] = useState(false);
    const [src,setSrc] = useState()
    const [binaryData, setBinaryData] = useState(null);
    const imgRef = useRef(null);

    // const handleCropClick = () => {
    //     setIsCropMode(true);
    // };

    const handleCropClick = () => {
        fetchImageAsBinaryData(panorama)
            .then(data => {
                setBinaryData(data);
                // Convert binary data to base64 and set as src
                const base64Data = arrayBufferToBase64(data);
                setSrc(`data:image/jpeg;base64,${base64Data}`);
                setIsCropMode(true);
            })
            .catch(err => console.error('Failed to fetch and convert image', err));
    };

    const fetchImageAsBinaryData = (imageSrc) => {
        return fetch(imageSrc)
            .then(response => response.arrayBuffer())
            .then(buffer => buffer);
    };

    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    const handleImageLoaded = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const handleCropComplete = (crop) => {
        setCompletedCrop(crop);
        makeClientCrop(crop);
    };

    const handleCropChange = (newCrop) => {
        setCrop(newCrop);
    };

    const makeClientCrop = async (crop) => {
        if (imgRef.current && crop.width && crop.height) {
            const croppedUrl = await getCroppedImg(imgRef.current, crop, 'newFile.jpeg');
            setCroppedImageUrl(croppedUrl);
        }
    };

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(croppedImageUrl);
                const fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            }, 'image/jpeg');
        });
    };

    const handleModalClose = () => {
        setVisible(false);
    };

    const handleModalOpen = () => {
        setVisible(true);
    };

    const filterOption = (input, option) => option.children.toLowerCase().includes(input.toLowerCase());

    console.log(src,'src');
    return (
        <div className="App">
            <div style={{ position: 'relative' }}>
                <Pannellum
                    width="100%"
                    height="500px"
                    image={panorama}
                    pitch={10}
                    yaw={180}
                    hfov={110}
                    autoLoad
                    mouseZoom
                    mouseDrag
                />
                <Select
                    showSearch
                    placeholder="Select Rooms"
                    optionFilterProp="children"
                    filterOption={filterOption}
                    style={{ width: '200px', position: 'absolute', top: '10px', left: '40px', zIndex: 1 }}
                />
                <Button
                    type="primary"
                    onClick={handleCropClick}
                    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}
                >
                    Crop
                </Button>

                {isCropMode && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '500px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <ReactCrop
                            src={src}
                            crop={crop}
                            onImageLoaded={handleImageLoaded}
                            onComplete={handleCropComplete}
                            onChange={handleCropChange}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </div>
                )}
            </div>

            {completedCrop && (
                <>
                    <Button type="primary" onClick={handleModalOpen} style={{ marginTop: '20px' }}>
                        Show Cropped Image
                    </Button>
                    <Modal
                        open={visible}
                        title="Cropped Image"
                        footer={null}
                        onCancel={handleModalClose}
                    >
                        <img alt="Cropped" style={{ width: '100%' }} src={croppedImageUrl} />
                    </Modal>
                </>
            )}
        </div>
    );
};

export default ThreeSixtyView;

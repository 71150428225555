import { Tooltip } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SwalComponent = ({
   message,
   onClickDispatch,
   id,
   postData = {},
   status = false,
   statusValue,
}) => {
   const MySwal = withReactContent(Swal);
   const dispatch = useDispatch();
   const [statusType] = useState({
      1: "Active",
      0: "Inactive",
   });

   const handleOnClick = () => {
      MySwal.fire({
         title: message,
         icon: "warning",
         showCancelButton: true,
         focusConfirm: false,
         confirmButtonText: "Yes",
         cancelButtonText: "No",
      }).then(result => {
         /* Read more about isConfirmed, isDenied below */

         if (status && result.isConfirmed) {
            dispatch(onClickDispatch({ postData, updateID: id }));
         } else if (!status && result.isConfirmed) {
            dispatch(onClickDispatch({ deleteID: id }));
         }
      });
   };

   return !status ? (
      <span
         className="fs-6"
         style={{ color: "red", cursor: "pointer" }}
         onClick={e => {
            e.preventDefault();
            handleOnClick();
         }}
      >
         <Tooltip title="Delete">
            <i className="icon-trash"></i>
         </Tooltip>
      </span>
   ) : (
      <span
         style={{ color: statusValue == "1" ? "green" : "red", cursor: "pointer" }}
         onClick={e => {
            e.preventDefault();
            handleOnClick();
         }}
      >
         {" "}
         {statusType[statusValue]}
      </span>
   );
};

export default SwalComponent;

import React, { useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { Flex, Space } from "antd";

const PDFViewer = ({ pdfUrl }) => {
   // Initialize plugins
   const zoomPluginInstance = zoomPlugin();
   const pageNavigationPluginInstance = pageNavigationPlugin();

   // Destructure controls from the plugins
   const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
   const { NumberOfPages, CurrentPageInput, GoToNextPageButton, GoToPreviousPage } =
      pageNavigationPluginInstance;

   return (
      <div style={{ height: "90%" }} className="mx-2">
         <Flex
            align="center"
            justify="center"
            gap={16}
            className="bg-secondary-subtle p-1 rounded-1 mb-1"
         >
            <Space>
               <span>Page:</span>
               <GoToPreviousPage />
               <span className="d-inline-block" style={{ width: "3rem" }}>
                  <CurrentPageInput />
               </span>
               of <NumberOfPages />
               <GoToNextPageButton />
            </Space>
            <Space>
               <ZoomOutButton />
               {/* <div>
                  <ZoomPopover />
               </div> */}
               <ZoomInButton />
            </Space>
         </Flex>
         <div className="h-100" style={{scrollbarWidth: "thin"}}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
               <Viewer
                  fileUrl={pdfUrl}
                  plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
               />
            </Worker>
         </div>
      </div>
   );
};

export default PDFViewer;

import React, { useState, useRef, useEffect } from 'react'
import LeftSideCard from '../../fields/LeftSideCard'
import { Image, message, Upload, DatePicker, Collapse, Modal, Input, Space, Skeleton, Typography, Breadcrumb } from 'antd';
import panorama from '../../assets/images/xs/panorama.jpg'
import CardComponent from '../../fields/CardComponent';
import SimpleReactValidator from 'simple-react-validator';
import ButtonComponent from '../../fields/ButtonComponent';
import { PhoneOutlined, UserOutlined, CaretRightOutlined, PlusOutlined, HomeOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import SelectComponent from '../../fields/SelectComponent';
import InputComponent from '../../fields/InputComponent';
import RoomCards from '../../fields/RoomCards';
import { addRoom, deleteRoom, getRoomsList, updateRoom } from '../../store/reducer/roomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TbView360Number } from "react-icons/tb";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getJobData, updateJob } from '../../store/reducer/jobSlice';
import { fetchAndSetPhotographerPermissionIds, getPhotographerJobsData, getPhotographerRoomsData, resetSuccessFlag } from '../../store/reducer/photographerSlice';
import dayjs from 'dayjs';
import { assestURL } from '../../services/config';
import { toast } from 'react-toastify';
import { resetSuccessFlag as resetRoomSuccessFlag } from '../../store/reducer/roomSlice';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from 'moment';

const PhotographerJobList = () => {
    const { Panel } = Collapse;
    const { Text } = Typography;
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [touchedFields, setTouchedFields] = useState({});
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileListOnChange, setFileListOnChange] = useState([]);
    const [addRoomName, setAddRoomName] = useState({
        room_name: '',
        job_id: '',
        project_id:''
        // Number(atob(job_id))
    })
    const [defaultActiveKey, setDefaultActiveKey] = useState('1')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [jobDetails, setJobDetails] = useState({
        project_number: '',
        status: 'scheduled',
        inventory_name: '',
        due_date: '',
        inventory_number: '',
        assign_staff: '',
        date: '',
        assign_to_qc: '',
        inventory_lead: '',
        addl_team_staff: '',
        addl_team_staff_2: '',
        comments: '',
        front_image: '',
        imgpath: '',
        project_id: '',
        job_address: 0,
        job_name: '',
        assign_staff_name: '',
        address_one: '',
        address_two: '',
        inventory_lead: '',
        city: '',
        state: '',
        zip_code: '',
        contact_name: '',
        contact_phone: '',
    })
    const [jobNum, setJobNum] = useState()
    const [jobID, setJobId] = useState()
    const [roomsArray, setRoomsArray] = useState()
    const user_name = localStorage.getItem("username")
    const permissionData = localStorage.getItem("photographer_data")
    const MySwal = withReactContent(Swal);

    console.log(selectedJobId, 'selectedJobId');
    console.log(roomsArray, 'roomsArray');
    console.log(jobDetails, 'jobDetails');
    console.log(fileList, 'fileList');


    //dispatch
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Selectors
    const { addRoomSuccess, addRoomError, roomsListData, updateRoomSuccess, deleteRoomSuccess } = useSelector(state => state.rooms)
    const { updateJobSuccess, getAddedJobData, updateJobError } = useSelector((state) => state.job)
    const { getPhotographerJobs, getPhotographerJobsSuccess, getPhotographerRooms, getPhotographerRoomSuccess, photographerPermissionIds } = useSelector((state) => state.photographer);
    const [inputValue, setInputValue] = useState('');

    const photographer_id = localStorage.getItem('photographer_id')
    const project_id = localStorage.getItem('projectId')
    const userId = localStorage.getItem('user_id')

    console.log(getPhotographerJobs?.length, getPhotographerRooms, getPhotographerRoomSuccess, 'getPhotographerJobs');
    console.log(photographerPermissionIds, 'photographerPermissionIds');

    //Validation Rules & Validator
    const validator = useRef(new SimpleReactValidator({
        className: 'validation-message',
        messages: {
            required: 'Please enter :attribute',
            email: 'Email should contain @ and .',
            alpha: ':attribute should contain only alphabets',
            regex: ':attribute format is invalid',
            in: ':attribute should be one of the accepted values',
            min: ':attribute must be at least :min characters',
            confirmed: 'Passwords do not match'
        }
    }));

    const validationRules = {
        due_date: '',
        address_one: '',
        address_two: '',
        city: '',
        state: '',
        zip_code: '',
        onsite_contact: '',
        phone: '',
        comments: '',
        front_image: '',
        contact_name: '',
        contact_phone: '',
    };

    //Image
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        console.log(file, 'file');
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileListOnChange(newFileList)
        setFileList(newFileList)
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {/* <PlusOutlined /><br /> */}
            <strong
                style={{
                    marginTop: 8,
                }}
            >
                Upload Front Image
            </strong>
        </button>
    );
    const handleSave = () => {
        if (addRoomName.room_name != '') {
            setIsModalOpen(false);
            let postData = addRoomName;
            postData.job_id = Number(jobID)
            postData.project_id = Number(jobDetails.project_id)
            dispatch(addRoom({ postData }));
            console.log(postData, 'postData');
            setAddRoomName({
                ...addRoomName,
                room_name: '',
                job_id: Number(jobID)
            })
        }
        else {
            toast.error('Please enter room name')
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setAddRoomName({
            ...addRoomName,
            room_name: '',
            // job_id: Number(atob(job_id))
        })
    };

    const formatPhoneNumber = (value) => {
        // Remove all non-digit characters
        const cleaned = ('' + value).replace(/\D/g, '');

        // Check if the input is longer than 10 characters
        if (cleaned.length <= 10) {
            const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
            if (match) {
                return [match[1], match[2], match[3]].filter(Boolean).join('-');
            }
        }
        return value; // Return unformatted value if it's too long
    };

    const editJobDetails = (e) => {
        const { name, value } = e.target;
        const formattedValue = name === "contact_phone" ? formatPhoneNumber(value) : value;

        setJobDetails(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));
        setTouchedFields(prevState => ({
            ...prevState,
            [name]: true
        }));
    };

    const onRoomsClick = (roomid) => {
        localStorage.setItem('selectedJobId', selectedJobId)
        navigate(`/projects/${btoa(project_id)}/${btoa(selectedJobId)}/rooms/${btoa(roomid)}`)
    }

    console.log(fileList, 'fileList');


    const handleSubmit = (event) => {
        let updateID = selectedJobId
        let postData = jobDetails
        postData.imgpath = fileListOnChange && fileListOnChange.length > 0 ? fileListOnChange?.[0]?.name : fileList && fileList.length > 0 && fileList?.[0]?.name
        postData.front_image = fileListOnChange && fileListOnChange.length > 0 ? fileListOnChange?.[0]?.thumbUrl : fileList && fileList.length > 0 && fileList?.[0]?.thumbUrl != undefined ? fileList?.[0]?.thumbUrl : ''
        console.log(postData, 'postData');
        dispatch(updateJob({ postData, updateID }));
        event.stopPropagation();
    }

    useEffect(() => {
        const payload = { id: "1" };
        console.log("payload", payload);
        dispatch(getRoomsList(payload));
    }, [])

    useEffect(() => {
        dispatch(getPhotographerJobsData({ id: Number(selectedJobId) }))

    }, [selectedJobId])

    console.log(updateRoomSuccess, 'updateRoomSuccess');


    useEffect(() => {
        if (selectedJobId) {
            dispatch(getPhotographerRoomsData({ id: selectedJobId }))
        }
        if (addRoomSuccess === true) {
            toast.success('Room Added Successfully!');
            dispatch(resetRoomSuccessFlag({ flag: 'addRoomSuccess' }));
        }
        if (deleteRoomSuccess === true) {
            toast.success('Room Deleted Successfully!');
            dispatch(resetRoomSuccessFlag({ flag: 'deleteRoomSuccess' }));
        }
        if (updateRoomSuccess === true) {
            toast.success('Room Updated Successfully!');
            dispatch(resetRoomSuccessFlag({ flag: 'updateRoomSuccess' }));
        }
    }, [selectedJobId, , addRoomSuccess, deleteRoomSuccess, updateRoomSuccess])

    useEffect(() => {
        // Dispatch the thunk to fetch and set photographerPermissionIds
        dispatch(fetchAndSetPhotographerPermissionIds());
    }, [dispatch]);

    console.log(getPhotographerJobs,'getPhotographerJobs');
    
    useEffect(() => {
        // Set job details and roomsArray when selectedJobId changes or when jobs data is successfully fetched
        if (selectedJobId || getPhotographerJobsSuccess === true || updateRoomSuccess === true) {
            const foundJob = getPhotographerJobs.find((job) => job.id === selectedJobId);

            if (foundJob) {
                setJobDetails({
                    ...jobDetails,
                    project_number: foundJob?.project_number,
                    inventory_name: foundJob?.inventory_name,
                    due_date: dayjs(foundJob?.due_date),
                    inventory_number: foundJob?.job_number,
                    assign_staff: foundJob?.assign_staff,
                    date: dayjs(foundJob?.date),
                    assign_to_qc: foundJob?.assign_to_qc,
                    addl_team_staff: foundJob?.addl_team_staff,
                    addl_team_staff_2: foundJob?.addl_team_staff_2,
                    comments: foundJob?.comments,
                    front_image: foundJob?.front_image,
                    project_id: foundJob?.project_id,
                    project_number: foundJob?.project_number,
                    job_address: foundJob?.job_address,
                    job_name: foundJob?.job_name,
                    assign_staff: foundJob?.assign_staff,
                    assign_staff_name: foundJob?.assign_staff_name,
                    address_one: foundJob?.address_one,
                    address_two: foundJob?.address_two,
                    inventory_lead: foundJob?.inventory_lead,
                    city: foundJob?.city,
                    state: foundJob?.state,
                    zip_code: foundJob?.zip_code,
                    contact_name: foundJob?.contact_name,
                    contact_phone: foundJob?.contact_phone,
                });
                setJobNum(foundJob?.job_number);
                setJobId(foundJob?.id);
                // setRoomsArray(foundJob);  // Set roomsArray with updated data
            }
            dispatch(resetRoomSuccessFlag({ flag: 'updateRoomSuccess' }));

        }
    }, [selectedJobId, getPhotographerJobsSuccess, updateRoomSuccess, getPhotographerJobs]);

    useEffect(() => {
        if (jobDetails.front_image != null) {
            const imageSrc = `${assestURL}${jobDetails.front_image}`;
            console.log(imageSrc, 'imageSrc==');
            setFileList([{ uid: '-1', name: 'image.png', status: 'done', url: imageSrc }]);
        }
        else {
            setFileList([]);
        }
    }, [jobDetails]);

    useEffect(() => {
        if (updateJobSuccess) {
            toast.success('Job Updated Successfully!');
            dispatch(resetRoomSuccessFlag({ flag: 'updateJobSuccess' }));
            // navigate(`/editproject/${project_id}/${client_id}`);
        }
        if (updateJobError) {
            toast.error(updateJobError)
        }
    }, [updateJobSuccess, updateJobError, dispatch, navigate]);

    useEffect(() => {
        if (localStorage.getItem('selectedJobId')) {
            setSelectedJobId(Number(localStorage.getItem('selectedJobId')))
        }
    }, [localStorage.getItem('selectedJobId')])

    const handleEdit = (editedData, id) => {
        const postData = { ...editedData };
        postData.job_id = Number(selectedJobId)
        dispatch(updateRoom({ postData, updateID: id }));
    };

    const handleDelete = (id) => {
        // Show SweetAlert confirmation dialog
        MySwal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this room? This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            // confirmButtonColor: '#3085d6',
            // cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                // If confirmed, call the deleteRoom action
                dispatch(deleteRoom({ deleteID: id }));

                // Optional: show a success message after API call is completed
                MySwal.fire(
                    'Deleted!',
                    'The room has been deleted.',
                    'success'
                );
            } else {
                // If cancel is clicked, do nothing
                MySwal.fire(
                    'Cancelled',
                    'The room is safe :)',
                    'error'
                );
            }
        });
    };

    const handleClear = (event) => {
        event.stopPropagation();
        setInputValue('');
        dispatch(getPhotographerRoomsData({ id: selectedJobId, query: '' }))
    };

    const handleSearchChange = (e) => {
        e.stopPropagation();
        setInputValue(e.target.value);
        dispatch(getPhotographerRoomsData({ id: selectedJobId, query: e.target.value }))
    };

    console.log(selectedJobId, 'selectedJobId');

    return (
        <div>
            <h5 className='mt-2 mb-2'><strong>{jobDetails?.job_name}</strong></h5>
            <Breadcrumb
                separator=">"
                items={[
                    {
                        title: <HomeOutlined />,
                        href: '/photographer/dashboard'
                    },
                    {
                        title: jobNum,
                    },
                ]}
            />
            <div className="photographer-jobs-container">
                <CardComponent className="left-card">
                    <div className="left-side align-items-center">
                        {getPhotographerJobs.map((job, index) => (
                            <LeftSideCard
                                key={index}
                                jobId={job.job_name}
                                date={moment(job.date).format('MMMM D, YYYY, h:mm A')}
                                imageSrc={job.front_image}
                                isSelected={selectedJobId === job.id}
                                onSelect={() => setSelectedJobId(job.id)}
                            />

                        ))}
                    </div>
                </CardComponent>
                <div className="photographer-divider"></div>
                <div className="right-side">
                    {selectedJobId ?
                        <>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['1']}
                                style={{ width: '100%' }}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            >
                                <Panel key="1" style={{ backgroundColor: 'white' }} header={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer' }}>
                                        <strong>Job Detail - {jobNum}</strong>
                                        <Space size={"middle"} className="my-3">
                                            {/* <Input
                                placeHolder="Search Rooms"
                                className={`rounded rounded-4`}
                                prefix={<SearchOutlined />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setDefaultActiveKey('1');
                                }}
                                onChange={e => {
                                    e.stopPropagation();
                                    setDefaultActiveKey('1');
                                    const payloadData = {
                                        query: e.target.value,
                                    };
                                    getData(payloadData);
                                }}
                            /> */}
                                            {photographerPermissionIds && photographerPermissionIds.length > 0 && photographerPermissionIds.includes(4) === true || photographerPermissionIds && photographerPermissionIds.length > 0 && photographerPermissionIds.includes(5) === true ?
                                                <Link to={`/uploadPanos/${btoa(project_id)}/${btoa(jobID)}`}>
                                                    <ButtonComponent
                                                        style={{ backgroundColor: "#f7931e", color: "white" }}
                                                        buttonName="Build 360 Tour"
                                                        icon={<TbView360Number size={20} />}
                                                        onClick={() => {
                                                            localStorage.setItem('selectedJobId', selectedJobId)
                                                        }}
                                                    />
                                                </Link> : null}
                                            <Link to={`/client/tour`}>
                                                <ButtonComponent
                                                    style={{ backgroundColor: "#f7931e", color: "white" }}
                                                    buttonName="View"
                                                    icon={<TbView360Number size={20} />}
                                                    onClick={() => {
                                                        localStorage.setItem('selectedJobId', selectedJobId)
                                                    }}
                                                />
                                            </Link>
                                        </Space>
                                    </div>
                                }
                                >
                                    {selectedJobId ? (
                                        <>
                                            <div>
                                                <div className="grid-container-job">
                                                    <div class="grid-item-job">
                                                        <div>
                                                            <label for="project_number">Scheduled Date</label><br />
                                                            <DatePicker
                                                                placeholder='Scheduled Date'
                                                                name='date'
                                                                style={{ width: '80%' }}
                                                                // readOnly={true}
                                                                disabled={true}
                                                                // onChange={onDueDateChange}
                                                                format={'MM/DD/YYYY hh:mm:A'}
                                                                // disabledDate={disabledDate}
                                                                value={jobDetails.date ? dayjs(jobDetails.date) : undefined}
                                                                defaultValue={jobDetails.date ? dayjs(jobDetails.date) : undefined}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="grid-item-job">
                                                        <div>
                                                            <label for="project_number">Due Date</label><br />
                                                            <DatePicker
                                                                placeholder='Due Date'
                                                                name='due_date'
                                                                style={{ width: '80%' }}
                                                                // readOnly={true}
                                                                disabled={true}
                                                                // onChange={onDueDateChange}
                                                                format={'MM/DD/YYYY'}
                                                                // disabledDate={disabledDate}
                                                                value={jobDetails.due_date ? dayjs(jobDetails.due_date) : undefined}
                                                                defaultValue={jobDetails.due_date ? dayjs(jobDetails.due_date) : undefined}
                                                            />
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="grid-item-job upload">
                                                        <>
                                                            {/* <ImgCrop rotate> */}
                                                            <Upload
                                                                className='job'
                                                                listType="picture-card"
                                                                fileList={fileList}
                                                                onPreview={handlePreview}
                                                                onChange={handleChange}
                                                                maxCount={1}
                                                                action="https://yourserver.com/upload"  // Replace with your actual upload URL
                                                                onRemove={(file) => {
                                                                    // Optionally, handle image removal logic
                                                                    message.success('Image removed');
                                                                }}
                                                            >
                                                                {fileList.length >= 1 ? null : uploadButton}
                                                            </Upload>
                                                            {/* </ImgCrop> */}
                                                            {previewImage && (
                                                                <Image
                                                                    style={{
                                                                        display: 'none',
                                                                        height: '150px',
                                                                        width: '150px'
                                                                    }}
                                                                    preview={{
                                                                        visible: previewOpen,
                                                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                                                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                                    }}
                                                                    src={previewImage}
                                                                />
                                                            )}
                                                        </>
                                                    </div>
                                                    <div class="grid-item-job">
                                                        <div>
                                                            <label for="status">Address 1</label><br />
                                                            <InputComponent
                                                                name="address_one"
                                                                placeholder="Address Line 1"
                                                                type="text"
                                                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                                                value={jobDetails.address_one}
                                                                disabled={true}
                                                                // onChange={editJobDetails}
                                                                validator={validator}
                                                                touchedFields={touchedFields}
                                                                validationRules={validationRules}
                                                                style={{ width: '80%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="grid-item-job">
                                                        <div>
                                                            <label for="inventory_name">Address 2</label>
                                                            <InputComponent
                                                                name="address_two"
                                                                placeholder="Address Line 2"
                                                                type="text"
                                                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                                                value={jobDetails.address_two}
                                                                disabled={true}
                                                                // onChange={editJobDetails}
                                                                validator={validator}
                                                                touchedFields={touchedFields}
                                                                validationRules={validationRules}
                                                                style={{ width: '80%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="grid-item-job">
                                                        <div>
                                                            <label for="due_date">City</label><br />
                                                            <InputComponent
                                                                name="city"
                                                                placeholder="City"
                                                                type="text"
                                                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                                                value={jobDetails.city}
                                                                disabled={true}
                                                                // onChange={editJobDetails}
                                                                validator={validator}
                                                                touchedFields={touchedFields}
                                                                validationRules={validationRules}
                                                                style={{ width: '80%' }}
                                                            />
                                                            {/* {(validationRules.date || touchedFields.date) && (
                                <div style={{ color: 'red' }}>
                                    {validator.current.message('date', jobDetails.date, validationRules.date)}
                                </div>
                            )} */}
                                                        </div>
                                                    </div>
                                                    <div class="grid-item-job">
                                                        <div>
                                                            <label for="due_date">State</label><br />
                                                            <InputComponent
                                                                name="state"
                                                                placeholder="State"
                                                                type="text"
                                                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                                                value={jobDetails.state}
                                                                disabled={true}
                                                                // onChange={editJobDetails}
                                                                validator={validator}
                                                                touchedFields={touchedFields}
                                                                validationRules={validationRules}
                                                                style={{ width: '80%' }}
                                                            />
                                                            {/* {(validationRules.date || touchedFields.date) && (
                                <div style={{ color: 'red' }}>
                                    {validator.current.message('date', jobDetails.date, validationRules.date)}
                                </div>
                            )} */}
                                                        </div>
                                                    </div>
                                                    <div class="grid-item-job">
                                                        <div>
                                                            <label for="due_date">Zip Code</label><br />
                                                            <InputComponent
                                                                name="zip_code"
                                                                placeholder="Zip Code"
                                                                type="text"
                                                                icon={<i className="bi bi-geo-alt-fill"></i>}
                                                                value={jobDetails.zip_code}
                                                                disabled={true}
                                                                // onChange={editJobDetails}
                                                                validator={validator}
                                                                touchedFields={touchedFields}
                                                                validationRules={validationRules}
                                                                style={{ width: '80%' }}
                                                            />
                                                            {/* {(validationRules.date || touchedFields.date) && (
                                <div style={{ color: 'red' }}>
                                    {validator.current.message('date', jobDetails.date, validationRules.date)}
                                </div>
                            )} */}
                                                        </div>
                                                    </div>
                                                    <div class="grid-item-job">
                                                        <div>
                                                            <label for="due_date">Onsite Contact</label><br />
                                                            <InputComponent
                                                                name="contact_name"
                                                                placeholder="Onsite Contact"
                                                                type="text"
                                                                icon={<UserOutlined />}
                                                                value={jobDetails.contact_name}
                                                                onChange={editJobDetails}
                                                                validator={validator}
                                                                touchedFields={touchedFields}
                                                                validationRules={validationRules}
                                                                style={{ width: '80%' }}
                                                            />
                                                            {/* {(validationRules.date || touchedFields.date) && (
                                <div style={{ color: 'red' }}>
                                    {validator.current.message('date', jobDetails.date, validationRules.date)}
                                </div>
                            )} */}
                                                        </div>
                                                    </div> <div class="grid-item-job">
                                                        <div>
                                                            <label for="due_date">Phone</label><br />
                                                            <InputComponent
                                                                name="contact_phone"
                                                                placeholder="Phone"
                                                                type="text"
                                                                icon={<PhoneOutlined />}
                                                                value={jobDetails.contact_phone}
                                                                onChange={editJobDetails}
                                                                validator={validator}
                                                                touchedFields={touchedFields}
                                                                validationRules={validationRules}
                                                                style={{ width: '80%' }}
                                                            />
                                                            {/* {(validationRules.date || touchedFields.date) && (
                                <div style={{ color: 'red' }}>
                                    {validator.current.message('date', jobDetails.date, validationRules.date)}
                                </div>
                            )} */}
                                                        </div>
                                                    </div>
                                                    <div class="grid-item-job">
                                                        <div>
                                                            <label for="last_name">Comments</label><br />
                                                            <InputComponent
                                                                name="comments"
                                                                placeholder="Comments"
                                                                type="text"
                                                                icon={<UserOutlined />}
                                                                value={jobDetails.comments}
                                                                onChange={editJobDetails}
                                                                validator={validator}
                                                                touchedFields={touchedFields}
                                                                validationRules={validationRules}
                                                                style={{ width: '80%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Add more details here */}
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-4">

                                                    </div>
                                                    <div className="col-md-4">
                                                        <ButtonComponent
                                                            style={{ backgroundColor: "#f7931e", color: "white" }}
                                                            buttonName="Update"
                                                            icon={<i class="fa fa-file-text" aria-hidden="true"></i>}
                                                            onClick={(event) => {
                                                                handleSubmit(event)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <center><p><b>Welcome {user_name}</b><br />
                                            You have {`(${getPhotographerJobs?.length})`} TakeStock assignments. Please select theme on the left navigation column.</p>
                                        </center>
                                    )}

                                </Panel>
                            </Collapse>
                            <br />
                            <Collapse
                                bordered={false}
                                // defaultActiveKey={['1']}
                                defaultActiveKey={defaultActiveKey}
                                style={{ width: '100%' }}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            >
                                <Panel key="1" style={{ backgroundColor: 'white' }} header={
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer' }}>
                                        <strong>Rooms</strong>
                                        <Space size={"middle"} className="my-3">
                                            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                                <Input
                                                    placeHolder="Search Rooms"
                                                    className="rounded rounded-4"
                                                    prefix={<SearchOutlined />}
                                                    value={inputValue}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setDefaultActiveKey('1');
                                                    }}
                                                    onChange={handleSearchChange}
                                                    style={{ paddingRight: '30px' }} // Adjust padding to accommodate clear icon
                                                />
                                                {inputValue && (
                                                    <CloseCircleOutlined
                                                        style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '50%',
                                                            transform: 'translateY(-50%)',
                                                            cursor: 'pointer',
                                                            zIndex: 1,
                                                            color: '#000', // Ensure the icon is visible
                                                        }}
                                                        onClick={handleClear}
                                                    />
                                                )}
                                            </div>
                                            {photographerPermissionIds && photographerPermissionIds.length > 0 && photographerPermissionIds.includes(1) === true ?
                                                <ButtonComponent
                                                    style={{ backgroundColor: "#f7931e", color: "white" }}
                                                    buttonName="Add Rooms"
                                                    icon={<PlusOutlined />}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setIsModalOpen(true)
                                                        setDefaultActiveKey('1')
                                                    }}
                                                /> : null}
                                        </Space>
                                    </div>
                                }>
                                    <div className="col-md-12">
                                        <div className="row">
                                            {getPhotographerRooms && getPhotographerRooms.length > 0 &&
                                                [...getPhotographerRooms] // Create a shallow copy of the array
                                                    .sort((a, b) => a.room_name.localeCompare(b.room_name)) // Sort alphabetically by room_name
                                                    .map((item) => {
                                                        return (
                                                            <div className="col-md-4" key={item.id}>
                                                                <RoomCards
                                                                    id={item.id}
                                                                    room_name={item.room_name}
                                                                    totalRooms={Number(item?.item_count)}
                                                                    onEdit={handleEdit}
                                                                    onDelete={handleDelete}
                                                                    onUpdate={handleEdit}
                                                                    onClick={() => { onRoomsClick(item.id) }}
                                                                    isCompleted={item?.is_completed === 1 ? true : false}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                        </div>
                                    </div>

                                </Panel>
                            </Collapse>
                        </>
                        :
                        <CardComponent>
                            <center><p><b>Welcome {user_name}</b><br />
                                You have {`(${getPhotographerJobs?.length})`} TakeStock assignments. Please select theme on the left navigation column.</p>
                            </center>
                        </CardComponent>
                    }
                </div>
            </div>
            <Modal
                destroyOnClose
                title="Add Rooms"
                open={isModalOpen}
                onOk={handleSave}
                okText="Save"
                okButtonProps={{ shape: "round", style: { backgroundColor: "#f7931e" } }}
                cancelButtonProps={{
                    shape: "round",
                    style: { backgroundColor: "#6E6E6E", color: "white" },
                }}
                onCancel={handleCancel}
            >
                <Input
                    placeholder="Enter Room name"
                    value={addRoomName.room_name}
                    onChange={e => setAddRoomName({
                        ...addRoomName,
                        room_name: e.target.value
                    })}
                    onKeyDown={(e) => {
                        console.log(e, 'eee');
                        if (e.key === 'Enter') {
                            handleSave();
                        }
                    }}
                />
            </Modal>
        </div>
    )
}

export default PhotographerJobList
import React from 'react';
import { Select } from 'antd';


// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

/**
 * 
 * @param {*} param0 
 * @returns 
 */

const SelectComponent = ({ name, value, placeholder, onChange, onSearch, options, validator, touchedFields, validationRules, style,mode,disabled }) => {

    const defaultOption = {
        value: "",
        label: "Select",
    }

    const optionsWithDefaultValue = options && [defaultOption, ...options];

    console.log("options", options && [...options]);

    return (
        <>
            <Select
                showSearch
                value={value === 0 || value ? value : ""}
                placeholder={placeholder}
                optionFilterProp="children"
                onChange={(e) => { onChange(e); validator.current.showMessageFor(name); }}
                onSearch={onSearch}
                filterOption={filterOption}
                // options={options}
                options={optionsWithDefaultValue}
                style={style || { width: '100%' }}
                mode={mode || ''}
                disabled={disabled || false}
            />
            {(validationRules[name] || touchedFields[name]) && (
                <div style={{ color: 'red' }}>
                    {validator.current.message(name, value, validationRules[name])}
                </div>
            )}
        </>
    )
};

export default SelectComponent;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { tryLogin, forgotPassword, resetPassword, dashboardData, dashboardJobData, staffDashboardData, staffDashboardJobData, clientDashboardJobData, clientDashboardReviewData, jobStatusUpdate } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import { toast } from 'react-toastify';

const namespace = "login";

const initialState = {
    loading: "initial",
    forgotloading: "initial",
    resetloading: "initial",
    errorMessage: null,
    loginData: null,
    dashboardDatas: null,
    staffDashboardDatas: null,
    dashboardJobsDatas: null,
    staffDashboardJobsDatas: null,
    clientDashboardJobsDatas: null,
    clientDashboardReviewDatas: null,
    jobStatusResponse: false
};

export const doLogin = createAsyncThunk(
    `${namespace}/doLogin`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            postData.role = "admin";
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await tryLogin(postData);
            console.log("getScanCount--> ", data);
            toast.success("Loggedin Successfully!");
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const forgotPwd = createAsyncThunk(
    `${namespace}/forgotPwd`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await forgotPassword(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const resetPwd = createAsyncThunk(
    `${namespace}/resetPwd`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await resetPassword(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getDashboardData = createAsyncThunk(
    `${namespace}/getDashboardData`,
    async ({ type, id,staff_admin }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await dashboardData(type, id,staff_admin);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getStaffDashboardData = createAsyncThunk(
    `${namespace}/getStaffDashboardData`,
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await staffDashboardData(id);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getDashboardJobsData = createAsyncThunk(
    `${namespace}/getDashboardJobsData`,
    async (
        { query = "", page = "", limit = "", sortby = "", order = "", type = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await dashboardJobData(query, page, limit, sortby, order, type);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating staff data
export const updateJobStatus = createAsyncThunk(
    `${namespace}/updateJobStatus`,
    async ({ postData, job_id }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await jobStatusUpdate({ payload }, job_id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getStaffDashboardJobsData = createAsyncThunk(
    `${namespace}/getStaffDashboardJobsData`,
    async (
        { query = "", page = "", limit = "", sortby = "", order = "", type = "", id = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await staffDashboardJobData(query, page, limit, sortby, order, type, id);
            // console.log(JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)),'data');
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


export const getClientDashboardJobsData = createAsyncThunk(
    `${namespace}/getClientDashboardJobsData`,
    async (
        { id, job_id },
        { rejectWithValue }
    ) => {
        try {
            const data = await clientDashboardJobData(id, job_id);
            // console.log(JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)),'data');
            console.log(data, 'data');
            return data.data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getClientDashboardReviewData = createAsyncThunk(
    `${namespace}/getClientDashboardReviewData`,
    async (
        { id, job_id },
        { rejectWithValue }
    ) => {
        try {
            const data = await clientDashboardReviewData(id, job_id);
            // console.log(JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey)),'data');
            console.log(data, 'data');
            return data.data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const loginSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        logOut: () => {
            localStorage.clear();
            window.location.reload(true);
        },
        resetJobStatusResponse: (state) => {
            state.jobStatusResponse = false;
        },
    },
    extraReducers: {
        [doLogin.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [doLogin.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log(payload?.data, 'payload?.data');
            state.loginData = payload?.data;
            localStorage.setItem('user_id', payload?.data?.id)
            if (payload?.data?.roleId === 2) {
                localStorage.setItem('category_type', payload?.data?.staff?.category_type)
                localStorage.setItem('staff_id', payload?.data?.staff?.id)
                localStorage.setItem('user_image',payload?.data?.staff?.image)
            }
            if (payload?.data?.roleId === 3) {
                localStorage.setItem('photographer_id', payload?.data?.photographer?.id)
                localStorage.setItem('photographer_permission', JSON.stringify(payload?.data?.photographer?.permissions))
            }
            if (payload?.data?.roleId === 4) {
                localStorage.setItem('client_id', payload?.data?.portalusers?.id)
                localStorage.setItem('pu_user_id', payload?.data?.portalusers?.user_id)
                localStorage.setItem('pu_type', payload?.data?.portalusers?.pu_type)
                localStorage.setItem('pu_project_id', payload?.data?.portalusers?.project_id)
                localStorage.setItem('display_name', payload?.data?.portalusers?.display_name)
            }
            localStorage.setItem('authToken', payload?.data?.accessToken)
            localStorage.setItem('isAuthenticated', true)
            localStorage.setItem('username', payload?.data?.username)
            localStorage.setItem('role_id', payload?.data?.roleId)
            localStorage.setItem('role_type', payload?.data?.roleType)
        },
        [doLogin.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [forgotPwd.pending](state) {
            state.forgotloading = API_STATUS.PENDING;
        },
        [forgotPwd.fulfilled](state, { payload }) {
            state.forgotloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [forgotPwd.rejected](state, action) {
            console.log(action?.payload?.data,action,'action?.payload?.data');
            state.forgotloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data?.message ? action?.payload?.data?.message : action?.payload?.data;
        },
        [resetPwd.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [resetPwd.fulfilled](state, { payload }) {
            state.resetloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [resetPwd.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getDashboardData.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [getDashboardData.fulfilled](state, { payload }) {
            state.dashboardDatas = payload?.response
            console.log(payload, 'payyy');
        },
        [getDashboardData.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getStaffDashboardData.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [getStaffDashboardData.fulfilled](state, { payload }) {
            state.staffDashboardDatas = payload?.response
            console.log(payload, 'payyy');
        },
        [getStaffDashboardData.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getDashboardJobsData.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [getDashboardJobsData.fulfilled](state, { payload }) {
            state.dashboardJobsDatas = payload
            console.log(payload, 'payyy');
        },
        [getDashboardJobsData.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getStaffDashboardJobsData.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [getStaffDashboardJobsData.fulfilled](state, { payload }) {
            console.log(payload, 'payyy');
            state.staffDashboardJobsDatas = payload
        },
        [getStaffDashboardJobsData.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getClientDashboardJobsData.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [getClientDashboardJobsData.fulfilled](state, { payload }) {
            console.log(payload, 'payyy');
            state.clientDashboardJobsDatas = payload
        },
        [getClientDashboardJobsData.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getClientDashboardReviewData.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [getClientDashboardReviewData.fulfilled](state, { payload }) {
            console.log(payload, 'payyy');
            state.clientDashboardReviewDatas = payload
        },
        [getClientDashboardReviewData.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [updateJobStatus.pending](state) {
            state.resetloading = API_STATUS.PENDING;
            state.jobStatusResponse = false

        },
        [updateJobStatus.fulfilled](state, { payload }) {
            console.log(payload, 'payyy');
            state.jobStatusResponse = true
        },
        [updateJobStatus.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
            state.jobStatusResponse = false
        },

    },
});

export const { clearData, logOut,resetJobStatusResponse } = loginSlice.actions;

export const loginSelector = (state) => state.login;

export default loginSlice.reducer;

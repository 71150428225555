import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { assestURL } from "../../services/config";
import { getClientUserJobs } from "../../store/reducer/portalUserSlice";

const ExecutorInventory = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [executorJobs, setExecutorJobs] = useState([]);
   const portalUserID = localStorage.getItem("client_id");
   const portalUserType = localStorage.getItem("pu_type");
   const { clientUserJobs } = useSelector(state => state.portalUser);

   useEffect(() => {
      dispatch(getClientUserJobs({ id: portalUserID }));
   }, []);

   useEffect(() => {
      if (clientUserJobs?.length) {
         setExecutorJobs([...clientUserJobs]);
      }
   }, [clientUserJobs]);

   return (
      <>
         <div className="heading-container">
            <h1 className="client-heading">Welcome to Your Inventory</h1>
            <p className="subheading">Select a property to get started</p>
         </div>
         <div className="client-portal-home-container">
            <div className="client-portal-home-card-container">
               {executorJobs?.map((card, index) => (
                  <div key={card.id} className="client-portal-home-card">
                     <div
                        className="client-portal-home-card-image"
                        onClick={() => {
                           if (portalUserType === "2") {
                              navigate(`/client/executor/${btoa(card.id)}`);
                           }

                           if (portalUserType === "3") {
                              navigate(`/client/user/${btoa(card.id)}`);
                           }
                        }}
                        style={{ backgroundImage: `url("${assestURL + card.front_image}")` }}
                     />
                     <strong>
                        {card.job_name ? (
                           <center>{card.job_name + " - " + card.job_number}</center>
                        ) : (
                           <center>{card.job_number}</center>
                        )}
                     </strong>
                  </div>
               ))}
            </div>
         </div>
      </>
   );
};

export default ExecutorInventory;

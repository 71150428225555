import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createProject, editProject, getProjectDataByID, projectDelete, projectList, updateProjectStatus } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "project";

// Async thunk for fetching the Project list
export const getProjectList = createAsyncThunk(
    `${namespace}/getProjectList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "", id = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await projectList(query, page, limit, sortby, order, id);
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a Project
export const addProject = createAsyncThunk(
    `${namespace}/project`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await createProject({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for fetching Project data by ID
export const getProjectData = createAsyncThunk(
    `${namespace}/projectbyId`,
    async ({ id }, { rejectWithValue }) => {
        try {
            const data = await getProjectDataByID(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating Project data
export const updateProject = createAsyncThunk(
    `${namespace}/UpdateCategory`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await editProject({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const projectStatus = createAsyncThunk(
    `${namespace}/UpdateStatus`,
    async ({ postData, updateID }, { rejectWithValue }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(postData, secretKey);
            const data = await updateProjectStatus({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const deleteProject = createAsyncThunk(
    `${namespace}/projectDelete`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await projectDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const projectSlice = createSlice({
    name: namespace,
    initialState: {
        projectListData: [],
        projectListLoading: false,
        addProjectLoading: false,
        addProjectSuccess: false,
        addProjectError: null,
        getAddedProjectDataLoading: false,
        getAddedProjectData: [],
        updateData: [],
        updateProjectSuccess: false,
        updateProjectError:null,
        deleteProjectLoading: false,
        deleteProjectSuccess: false,
        deleteProjectError: false,
        deleteprojectErrorMessage: "",
        projectStatusLoading: false,
        projectStatusSuccess: false,
        projectStatusError: false,
        projectStatusErrorMessage: "",
    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle getProjectList
            .addCase(getProjectList.pending, (state) => {
                state.projectListLoading = true;
                state.addProjectLoading = false;
                state.addProjectSuccess = false;
                state.addProjectError = null;
                state.addedProject = null;
                state.getAddedProjectData = [];
                state.updateData = [];
                state.updateProjectSuccess = false;
                state.deleteProjectLoading = false;
                state.deleteProjectSuccess = false;
                state.deleteProjectError = false;
                state.deleteprojectErrorMessage = "";
                state.projectStatusLoading = false;
                state.projectStatusSuccess = false;
                state.projectStatusError = false;
                state.projectStatusErrorMessage = "";
            })
            .addCase(getProjectList.fulfilled, (state, action) => {
                const data = action.payload;
                state.projectListData = data;
                state.projectListLoading = false;
            })
            .addCase(getProjectList.rejected, (state) => {
                state.projectListLoading = false;
            })

            // Handle addProject
            .addCase(addProject.pending, (state) => {
                state.addProjectLoading = true;
                state.addProjectError = null;
                state.addedProject = null;
                state.addProjectSuccess = false;
            })
            .addCase(addProject.fulfilled, (state, action) => {
                state.addProjectLoading = false;
                state.addProjectSuccess = true;
            })
            .addCase(addProject.rejected, (state, action) => {
                console.log(action, 'action');
                state.addProjectLoading = false;
                if (action.payload.data.message) {
                    state.addProjectError = action.payload.data.message;
                }
                else {
                    state.addProjectError = action.error.message;
                }
                state.addProjectSuccess = false;
            })

            // Handle getAddedProjectData by ID
            .addCase(getProjectData.pending, (state) => {
                state.getAddedProjectDataLoading = true;
            })
            .addCase(getProjectData.fulfilled, (state, action) => {
                const data = JSON.parse(EncryptDecrypt.decryptdata(action.payload.data.datas, secretKey));
                state.getAddedProjectData = data.response;
                state.getAddedProjectDataLoading = false;
            })
            .addCase(getProjectData.rejected, (state) => {
                state.getAddedProjectDataLoading = false;
            })

            // Handle updateProject
            .addCase(updateProject.pending, (state) => {
                state.updateProjectSuccess = false;
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                state.updateData = action.payload;
                state.updateProjectSuccess = true;
            })
            .addCase(updateProject.rejected, (state,action) => {
                state.updateProjectSuccess = false;
                if (action.payload.data.message) {
                    state.updateProjectError = action.payload.data.message;
                }
                else {
                    state.updateProjectError = action.error.message;
                }
            })

            // Update Project status
            .addCase(projectStatus.pending, state => {
                state.projectStatusLoading = true;
                state.projectStatusSuccess = false;
                state.projectStatusError = false;
                state.projectStatusErrorMessage = "";
                state.deleteprojectLoading = false;
                state.deleteProjectError = false;
                state.deleteProjectSuccess = false;
                state.deleteprojectErrorMessage = "";
            })
            .addCase(projectStatus.fulfilled, (state, action) => {
                state.projectStatusSuccess = true;
                state.projectStatusLoading = false;
            })
            .addCase(projectStatus.rejected, (state, action) => {
                state.projectStatusError = true;
                state.projectStatusLoading = false;
                state.projectStatusErrorMessage = action.error.message;
            })

            // Handle Deleteproject
            .addCase(deleteProject.pending, state => {
                state.deleteProjectLoading = true;
                state.deleteProjectError = false;
                state.deleteProjectSuccess = false;
                state.deleteprojectErrorMessage = "";
                state.projectStatusLoading = false;
                state.projectStatusSuccess = false;
                state.projectStatusError = false;
                state.projectStatusErrorMessage = "";
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.deleteProjectSuccess = true;
                state.deleteProjectLoading = false;
            })
            .addCase(deleteProject.rejected, (state, action) => {
                state.deleteProjectError = true;
                state.deleteProjectLoading = false;
                state.deleteprojectErrorMessage = action.error.message;
            })

    },
});

export const { resetSuccessFlag } = projectSlice.actions;

export const projectSelector = (state) => state[namespace];

export default projectSlice.reducer;

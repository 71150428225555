import React, { useState, useRef, useEffect } from 'react'
import PageHeader from '../../view/pageheader'
import CardComponent from '../../fields/CardComponent'
import { Collapse, DatePicker, Upload, Flex, message, Image, Button, Row, Col, Input, Modal, Space, Select, Radio } from 'antd'
import InputComponent from '../../fields/InputComponent'
import SelectComponent from '../../fields/SelectComponent'
import { CaretRightOutlined, UserOutlined, LoadingOutlined, PlusOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import SimpleReactValidator from 'simple-react-validator';
import ButtonComponent from '../../fields/ButtonComponent';
import groupImage from '../../assets/images/xs/Group 1000004197.png';
import RoomCards from '../../fields/RoomCards'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getJobData, updateJob } from '../../store/reducer/jobSlice'
import { getStaffList } from '../../store/reducer/staffSlice'
import _ from 'lodash'
import moment from 'moment'
import { getPhotographerList } from '../../store/reducer/photographerSlice'
import { addRoom, deleteRoom, getRoomsList, resetSuccessFlag, updateRoom } from '../../store/reducer/roomSlice'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { assestURL } from '../../services/config';
import SearchComponent from '../../fields/SearchComponent'
import { TbView360Number } from "react-icons/tb";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const EditJobWithRooms = () => {
    const { Panel } = Collapse;
    const { project_id, job_id, client_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    const { updateJobSuccess, getAddedJobData, updateJobError } = useSelector((state) => state.job)
    const { staffListData } = useSelector(state => state.staff);
    //Selectors
    const { photographerListData } = useSelector(state => state.photographer);
    const { addRoomSuccess,
        addRoomError,
        updateRoomSuccess,
        updateRoomError,
        deleteItemCategoriesSuccess,
        deleteItemCategoriesError, addRoomErrorMessage, updateRoomErrorMessage, roomsListData, deleteRoomSuccess } = useSelector(state => state.rooms)

    const [type, setType] = useState('add')
    const [radioValue, setRadioValue] = useState()
    const [touchedFields, setTouchedFields] = useState({});
    const [googleAIsearchCount, setgoogleAIsearchCount] = useState(0);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileListOnChange, setFileListOnChange] = useState([]);
    const [jobDetails, setJobDetails] = useState({
        project_number: '',
        status: 'scheduled',
        inventory_name: '',
        due_date: '',
        inventory_number: '',
        assign_staff: '',
        date: '',
        assign_to_qc: '',
        inventory_lead: '',
        addl_team_staff: '',
        addl_team_staff_2: '',
        comments: '',
        front_image: '',
        imgpath: '',
        project_id: '',
        job_address: 0,
        job_name: '',
        assign_staff_name: '',
        address_one: '',
        address_two: '',
        inventory_lead: '',
        city: '',
        state: '',
        zip_code: '',
        contact_name: '',
        contact_phone: '',
        valuation: 0
    })
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        let totalSearchCount = 0;

        if (getAddedJobData && getAddedJobData.rooms) {
            getAddedJobData.rooms.forEach((room) => {
                if (room.room_items) {
                    totalSearchCount += room.room_items.reduce((acc, item) => acc + (item.search_count || 0), 0);
                }
            });
        }
        setgoogleAIsearchCount(totalSearchCount)
        console.log("Total Search Count:", totalSearchCount);
    }, [getAddedJobData]);

    const jobStatus = [
        {
            label: 'Scheduled',
            value: 'scheduled'
        },
        {
            label: 'Ready to Edit/Build',
            value: 'ready_to_edit_or_build'
        },
        {
            label: 'Ready to QC',
            value: 'ready_to_qc'
        },
        {
            label: 'Ready to Deliver',
            value: 'ready_to_deliver'
        },
        {
            label: 'Delivered',
            value: 'delivered'
        }
    ]
    const [staffList, setStaffList] = useState()
    const [photographerList, setPhotographerList] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addRoomName, setAddRoomName] = useState({
        room_name: '',
        job_id: Number(atob(job_id)),
        project_id: Number(atob(project_id))
    })
    const [inventoryLeadList, setInventoryLeadList] = useState([]);
    const [defaultActiveKey, setDefaultActiveKey] = useState('1')
    const roleID = localStorage.getItem('role_id')
    const category_type = localStorage.getItem('category_type')

    console.log(addRoomName, 'addRoomName');

    //Validation Rules & Validator
    const validator = useRef(new SimpleReactValidator({
        className: 'validation-message',
        messages: {
            required: 'Please enter :attribute',
            email: 'Email should contain @ and .',
            alpha: ':attribute should contain only alphabets',
            regex: ':attribute format is invalid',
            in: ':attribute should be one of the accepted values',
            min: ':attribute must be at least :min characters',
            confirmed: 'Passwords do not match'
        }
    }));

    const validationRules = {
        project_number: '',
        status: '',
        inventory_name: '',
        due_date: '',
        inventory_number: '',
        assign_staff: '',
        date: `required`,
        job_name: '',
        assign_to_qc: '',
        inventory_lead: '',
        addl_team_staff: '',
        addl_team_staff_2: '',
        comments: '',
        front_image: ''
    };

    const onChange = (value, name) => {
        console.log(value, name, 'changes');
        setJobDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    //onChange
    const editJobDetails = (e) => {
        const { name, value } = e.target;
        setJobDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
        setTouchedFields(prevState => ({
            ...prevState,
            [name]: true
        }));
    };

    const onDateChange = (date, dateString) => {
        console.log(dateString, 'dateString');
        setJobDetails({
            ...jobDetails,
            date: date ? dayjs(dateString) : null
        });
        setTouchedFields({
            ...touchedFields,
            date: true
        });
    };

    const onDueDateChange = (date, dateString) => {
        console.log('Selected due date:', dateString);

        const startDate = moment(jobDetails.date);
        const dueDate = moment(dateString);

        console.log('Start Date:', startDate.format('YYYY-MM-DD'));
        console.log('Due Date:', dueDate.format('YYYY-MM-DD'));

        if (dueDate.isAfter(startDate) || dueDate.isSame(startDate)) {
            setJobDetails({
                ...jobDetails,
                due_date: date ? dueDate : null
            });
            setTouchedFields({
                ...touchedFields,
                due_date: true
            });
        } else {
            message.error('Due date must be greater than or equal to the start date');
            setJobDetails({
                ...jobDetails,
                due_date: null
            });
        }
    };

    const handleSave = () => {
        if (addRoomName.room_name != '') {
            setIsModalOpen(false);
            let postData = addRoomName;
            dispatch(addRoom({ postData }));
            setAddRoomName({
                ...addRoomName,
                room_name: '',
                job_id: Number(atob(job_id)),
            })
        }
        else {
            toast.error('Please enter room name')
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setAddRoomName({
            ...addRoomName,
            room_name: '',
            job_id: Number(atob(job_id))
        })
    };

    const handleSubmit = () => {
        let updateID = atob(job_id)
        let postData = jobDetails
        postData.imgpath = fileListOnChange && fileListOnChange.length > 0 ? fileListOnChange?.[0]?.name : fileList && fileList.length > 0 && fileList?.[0]?.name
        postData.front_image = fileListOnChange && fileListOnChange.length > 0 ? fileListOnChange?.[0]?.thumbUrl : fileList && fileList.length > 0 && fileList?.[0]?.thumbUrl != undefined ? fileList?.[0]?.thumbUrl : jobDetails.front_image
        dispatch(updateJob({ postData, updateID }));
    }

    //Image
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        console.log(file, 'file');
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileListOnChange(newFileList)
        setFileList(newFileList)
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {/* <PlusOutlined /><br /> */}
            <strong
                style={{
                    marginTop: 8,
                }}
            >
                Upload Front Image
            </strong>
        </button>
    );

    const handleEdit = (editedData, id) => {
        const postData = { ...editedData };
        postData.job_id = Number(atob(job_id))
        console.log(postData, 'postData');
        dispatch(updateRoom({ postData, updateID: id }));
    };

    const handleDelete = (id) => {
        // Show SweetAlert confirmation dialog
        MySwal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this room? This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            // confirmButtonColor: '#3085d6',
            // cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                // If confirmed, call the deleteRoom action
                dispatch(deleteRoom({ deleteID: id }));

                // Optional: show a success message after API call is completed
                MySwal.fire(
                    'Deleted!',
                    'The room has been deleted.',
                    'success'
                );
            } else {
                // If cancel is clicked, do nothing
                MySwal.fire(
                    'Cancelled',
                    'The room is safe :)',
                    'error'
                );
            }
        });
    };

    const handleClear = (event) => {
        event.stopPropagation();
        setInputValue('');
        setDefaultActiveKey('1');
        getData({ query: '' });
    };

    const handleSearchChange = (e) => {
        e.stopPropagation();
        setInputValue(e.target.value);
        setDefaultActiveKey('1');
        getData({ query: e.target.value });
    };

    const onRoomsClick = (roomid) => {
        localStorage.setItem('job_name', jobDetails?.job_name)
        navigate(`/projects/${project_id}/${job_id}/rooms/${btoa(roomid)}`)
    }

    //Use Effects 
    useEffect(() => {
        if (job_id) {
            setType('edit')
            const decodedId = atob(job_id); // Use a different variable name
            dispatch(getJobData({ id: decodedId }));
        }
    }, [job_id, dispatch]);

    useEffect(() => {
        if (photographerListData && photographerListData.data && photographerListData.data.length > 0) {
            let activeStaffList = _.filter(photographerListData.data, { status: 1 })
            if (activeStaffList) {
                const options = activeStaffList.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.first_name} ${item.last_name}`
                }));
                console.log(options, 'options');
                setPhotographerList(options)
                // setClientIds(options)
            }
        }
    }, [photographerListData])

    useEffect(() => {
        dispatch(getStaffList({}))
        dispatch(getPhotographerList({}));
    }, [])

    useEffect(() => {
        if (staffListData && staffListData.data && staffListData.data.length > 0) {
            let activeStaffList = _.filter(staffListData.data, { status: 1 })
            if (activeStaffList) {
                const options = activeStaffList.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.first_name} ${item.last_name}`
                }));
                console.log(options, 'options');
                setStaffList(options)
                // setClientIds(options)
            }
        }

        const { count, data } = photographerListData;
        if (data?.length) {
            let activePhotographer = _.filter(data, { status: 1 })
            if (activePhotographer) {
                const options = activePhotographer.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.first_name} ${item.last_name}`
                }));
                setInventoryLeadList(options);
            }
        }
    }, [staffListData, photographerListData])


    // useEffect(() => {
    //     if (photographerListData && photographerListData.data && photographerListData.data.length > 0) {
    //         let activeStaffList = _.filter(photographerListData.data, { status: 1 })
    //         if (activeStaffList) {
    //             const options = activeStaffList.map((item) => ({
    //                 value: `${item.id}`,
    //                 label: `${item.first_name} ${item.last_name}`
    //             }));
    //             console.log(options, 'options');
    //             setPhotographerList(options)
    //             // setClientIds(options)
    //         }
    //     }
    // }, [photographerListData])

    console.log(getAddedJobData, 'getAddedJobData');

    useEffect(() => {
        if (getAddedJobData) {
            setJobDetails({
                ...jobDetails,
                project_number: getAddedJobData?.project_number,
                inventory_name: getAddedJobData?.inventory_name,
                due_date: dayjs(getAddedJobData?.due_date),
                inventory_number: getAddedJobData?.job_number,
                assign_staff: getAddedJobData?.assign_staff,
                date: dayjs(getAddedJobData?.date),
                assign_to_qc: getAddedJobData?.assign_to_qc,
                addl_team_staff: getAddedJobData?.addl_team_staff,
                addl_team_staff_2: getAddedJobData?.addl_team_staff_2 ? (getAddedJobData?.addl_team_staff_2).split(',') : getAddedJobData?.addl_team_staff_2,
                comments: getAddedJobData?.comments,
                front_image: getAddedJobData?.front_image,
                project_id: getAddedJobData?.project_id,
                project_number: getAddedJobData?.project_number,
                job_address: getAddedJobData?.job_address,
                job_name: getAddedJobData?.job_name,
                assign_staff: getAddedJobData?.assign_staff,
                assign_staff_name: getAddedJobData?.assign_staff_name,
                address_one: getAddedJobData?.address_one,
                address_two: getAddedJobData?.address_two,
                inventory_lead: getAddedJobData?.inventory_lead ? (getAddedJobData?.inventory_lead).split(',') : getAddedJobData?.inventory_lead,
                city: getAddedJobData?.city,
                state: getAddedJobData?.state,
                zip_code: getAddedJobData?.zip_code,
                contact_name: getAddedJobData?.contact_name,
                contact_phone: getAddedJobData?.contact_phone,
                valuation: getAddedJobData?.valuation ? getAddedJobData?.valuation : 0
            })
        }
    }, [getAddedJobData])

    const disabledDate = (current) => {
        // Disable dates before today
        return current && current < moment().startOf('day');
    };

    function getData(data) {
        const payload = { ...data, id: atob(job_id) };
        console.log("payload", payload);
        dispatch(getRoomsList(payload));
    }

    useEffect(() => {
        if (jobDetails.front_image) {
            const imageSrc = `${assestURL}${jobDetails.front_image}`;
            console.log(imageSrc, 'imageSrc==');
            setFileList([{ uid: '-1', name: 'image.png', status: 'done', url: imageSrc }]);
        }
    }, [jobDetails]);

    useEffect(() => {
        getData();

        if (addRoomSuccess) {
            dispatch(resetSuccessFlag({ flag: 'addRoomSuccess' }));
            const decodedId = atob(job_id); // Use a different variable name
            dispatch(getJobData({ id: decodedId }));
            toast.success("Room added Successfully");
        }
        if (addRoomError) {
            dispatch(resetSuccessFlag({ flag: 'addRoomError' }));
            toast.error(addRoomErrorMessage);
        }

        if (updateRoomSuccess) {
            dispatch(resetSuccessFlag({ flag: 'updateRoomSuccess' }));
            const decodedId = atob(job_id); // Use a different variable name
            dispatch(getJobData({ id: decodedId }));
            toast.success("Room updated successfully");
        }

        if (updateRoomError) {
            dispatch(resetSuccessFlag({ flag: 'updateRoomError' }));
            toast.error(updateRoomErrorMessage);
        }

        if (deleteRoomSuccess) {
            dispatch(resetSuccessFlag({ flag: 'deleteRoomSuccess' }));
            toast.success('Room Deleted Successfully!')
            const decodedId = atob(job_id); // Use a different variable name
            dispatch(getJobData({ id: decodedId }));
        }
    }, [
        addRoomSuccess,
        addRoomError,
        updateRoomSuccess,
        updateRoomError,
        deleteRoomSuccess,
        deleteRoomSuccess,
        deleteItemCategoriesError,
    ]);

    console.log(inputValue, 'inputValue');

    useEffect(() => {
        if (updateJobSuccess) {
            // setJobDetails({
            //     ...jobDetails,
            //     project_number: '',
            //     status: 'scheduled',
            //     inventory_name: '',
            //     due_date: '',
            //     inventory_number: '',
            //     assign_staff: '',
            //     date: '',
            //     assign_to_qc: '',
            //     inventory_lead: '',
            //     addl_team_staff: '',
            //     addl_team_staff_2: '',
            //     comments: '',
            //     front_image: '',
            //     project_id: '',
            //     job_address: 0,
            //     job_name: '',
            //     assign_staff_name: '',
            //     address_one: '',
            //     address_two: '',
            //     inventory_lead: '',
            //     city: '',
            //     state: '',
            //     zip_code: '',
            //     contact_name: '',
            //     contact_phone: '',
            // })
            toast.success('Job Updated Successfully!');
            dispatch(resetSuccessFlag({ flag: 'updateJobSuccess' }));
            // navigate(`/editproject/${project_id}/${client_id}`);
        }
        if (updateJobError) {
            toast.error(updateJobError)
        }
    }, [updateJobSuccess, updateJobError, dispatch, navigate]);

    console.log(roomsListData, 'roomsListData');

    return (
        <div className="container-fluid-staff" >
            <PageHeader
                HeaderText={'Update Job'}
                Breadcrumb={[{ name: type === 'add' ? 'Projects' : 'Projects' }]}
                backButtonUrl={`/editproject/${project_id}/${client_id}`}
            />
            <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
                <Panel key="1" style={{ backgroundColor: 'white' }} header={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer' }}>
                        <strong>Inventory Job Details</strong>
                        <Space size={"middle"} className="my-3">
                            {/* <Input
                            placeHolder="Search Rooms"
                            className={`rounded rounded-4`}
                            prefix={<SearchOutlined />}
                            onClick={(event) => {
                                event.stopPropagation();
                                setDefaultActiveKey('1');
                            }}
                            onChange={e => {
                                e.stopPropagation();
                                setDefaultActiveKey('1');
                                const payloadData = {
                                    query: e.target.value,
                                };
                                getData(payloadData);
                            }}
                        /> */}
                            <Link to={`/uploadPanos/${client_id}/${project_id}/${job_id}`}>
                                <ButtonComponent
                                    style={{ backgroundColor: "#f7931e", color: "white" }}
                                    buttonName="Build 360 Tour"
                                    icon={<TbView360Number size={20} />}
                                />
                            </Link>
                        </Space>
                    </div>
                }
                >
                    <div className="grid-container-job">
                        <div class="grid-item-job">
                            <div>
                                <label for="project_number">Project Number</label><br />
                                <a href="javascript:;" onClick={() => { navigate(`/editproject/${btoa(getAddedJobData?.project_id)}/${client_id}`) }}>{jobDetails.project_number}</a>
                            </div>
                        </div>
                        <div class="grid-item-job">
                            <div>
                                <label for="status">Status</label><br />
                                <SelectComponent
                                    name="status"
                                    value={jobDetails.status ? jobDetails.status : null}
                                    placeholder="Choose status"
                                    onChange={(value) => onChange(value, 'status')}
                                    onSearch={onSearch}
                                    options={jobStatus}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                    style={{ width: '80%' }}
                                />
                            </div>
                        </div>
                        <div class="grid-item-job upload">
                            <>
                                {/* <ImgCrop rotate> */}
                                <Upload
                                    className='job'
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    maxCount={1}
                                    action="https://yourserver.com/upload"  // Replace with your actual upload URL
                                    onRemove={(file) => {
                                        // Optionally, handle image removal logic
                                        message.success('Image removed');
                                        setJobDetails({
                                            ...jobDetails,
                                            front_image: ""
                                        })
                                    }}
                                >
                                    {fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                                {/* </ImgCrop> */}
                                {previewImage && (
                                    <Image
                                        style={{
                                            display: 'none',
                                            height: '150px',
                                            width: '150px'
                                        }}
                                        preview={{
                                            visible: previewOpen,
                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                        }}
                                        src={previewImage}
                                    />
                                )}
                            </>
                        </div>
                        <div class="grid-item-job">
                            <div>
                                <label for="job_name">Inv Name</label>
                                <InputComponent
                                    name="job_name"
                                    placeholder="Inv Name"
                                    type="text"
                                    icon={<UserOutlined />}
                                    value={jobDetails.job_name}
                                    onChange={editJobDetails}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                    style={{ width: '80%' }}
                                />
                            </div>
                        </div>
                        <div class="grid-item-job">
                            <div>
                                <label for="due_date">Due Date</label><br />
                                <DatePicker
                                    placeholder='Due Date'
                                    name='due_date'
                                    style={{ width: '80%' }}
                                    onChange={onDueDateChange}
                                    format={'MM/DD/YYYY'}
                                    disabledDate={disabledDate}
                                    value={jobDetails.due_date ? dayjs(jobDetails.due_date) : undefined}
                                    defaultValue={jobDetails.due_date ? dayjs(jobDetails.due_date) : undefined}
                                />
                                {/* {(validationRules.date || touchedFields.date) && (
                                <div style={{ color: 'red' }}>
                                    {validator.current.message('date', jobDetails.date, validationRules.date)}
                                </div>
                            )} */}
                            </div>
                        </div>
                        <div class="grid-item-job">
                            <div>
                                <label for="last_name">Inv Number</label><br />
                                <span>{jobDetails.inventory_number}</span>
                            </div>
                        </div>
                        <div class="grid-item-job">
                            <div>
                                <label for="assign_staff">Assign to Staff</label>
                                <SelectComponent
                                    name="assign_staff"
                                    value={jobDetails.assign_staff ? jobDetails.assign_staff : null}
                                    placeholder="Choose edited user"
                                    onChange={(value) => onChange(value, 'assign_staff')}
                                    onSearch={onSearch}
                                    options={staffList}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                    style={{ width: '80%' }}
                                />
                            </div>
                        </div>
                        <div class="grid-item-job">
                            <div>
                                <label for="date">Inv Date</label>
                                <span className="m-1" style={{ color: 'red' }}>*</span><br />
                                <DatePicker showTime
                                    placeholder='Inv Date'
                                    name='date'
                                    style={{ width: '80%' }}
                                    onChange={onDateChange}
                                    disabledDate={disabledDate}
                                    format={'MM/DD/YYYY hh:mm A'}
                                    value={jobDetails.date ? dayjs(jobDetails.date) : undefined}
                                    defaultValue={jobDetails.date ? dayjs(jobDetails.date) : undefined}
                                />
                            </div>
                        </div>
                        <div class="grid-item-job">
                            <div>
                                <label for="last_name">Assign to QC</label>
                                <SelectComponent
                                    name="assign_to_qc"
                                    value={jobDetails.assign_to_qc ? jobDetails.assign_to_qc : null}
                                    placeholder="Assign to QC"
                                    onChange={(value) => onChange(value, 'assign_to_qc')}
                                    onSearch={onSearch}
                                    options={staffList}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                    style={{ width: '80%' }}
                                />
                            </div>
                        </div>
                        <div class="grid-item-job">
                            <div>
                                <label for="inventory_lead">Inv Lead</label><br />
                                <Select
                                    name="inventory_lead"
                                    value={jobDetails.inventory_lead ? jobDetails.inventory_lead : null}
                                    placeholder="Select Inventory Lead"
                                    onChange={(value) => onChange(value, 'inventory_lead')}
                                    onSearch={onSearch}
                                    options={photographerList}
                                    style={{ width: '80%' }}
                                    mode='multiple'
                                />
                            </div>
                        </div>
                        <div class="grid-item-job">
                            <div>
                                <label for="comments">Comments</label>
                                <InputComponent
                                    name="comments"
                                    placeholder="Comments"
                                    type="text"
                                    icon={<UserOutlined />}
                                    value={jobDetails.comments}
                                    onChange={editJobDetails}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                    style={{ width: '80%' }}
                                />
                            </div>
                        </div>
                        <div class="grid-item-job">
                            <div>
                                <label for="last_name">Addl Team</label>
                                <SelectComponent
                                    name="addl_team_staff"
                                    value={jobDetails.addl_team_staff}
                                    placeholder="Choose Additional Team"
                                    onChange={(value) => onChange(value, 'addl_team_staff')}
                                    onSearch={onSearch}
                                    options={staffList}
                                    validator={validator}
                                    touchedFields={touchedFields}
                                    validationRules={validationRules}
                                    style={{ width: '80%' }}
                                />
                            </div>
                        </div>
                        {/* {localStorage.getItem('project_type') === '1' || localStorage.getItem('project_type') === '2' ? */}
                            <div class="grid-item-job">
                                <div>
                                    <label for="last_name">Valuation</label><br />
                                    <Radio.Group onChange={(e) => {
                                        setJobDetails({
                                            ...jobDetails,
                                            valuation: e.target.value
                                        })
                                    }} value={jobDetails.valuation}>
                                        <Radio value={1}>Yes</Radio>
                                        <Radio value={0}>No</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                          {/* : null} */}
                        <div class="grid-item-job">
                            <div>
                                <label for="last_name">Addl Team 2</label>
                                <Select
                                    name="addl_team_staff_2"
                                    value={jobDetails.addl_team_staff_2 ? jobDetails.addl_team_staff_2 : null}
                                    placeholder="Choose Additional Team 2"
                                    onChange={(value) => onChange(value, 'addl_team_staff_2')}
                                    onSearch={onSearch}
                                    options={photographerList}
                                    style={{ width: '80%' }}
                                    mode='multiple'
                                />
                            </div>
                        </div>

                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-4">
                                <ButtonComponent style={{ backgroundColor: '#f7931e', color: 'white' }} onClick={handleSubmit} buttonName="Update" icon={<i class="fa fa-file-text" aria-hidden="true"></i>} />&nbsp;
                            </div>
                            <div className="col-md-4">
                                <p>Total Number of Google AI Inquiries: <b>{googleAIsearchCount}</b></p>
                            </div>
                        </div>
                    </div>
                </Panel>
            </Collapse>
            <br />
            <Collapse
                bordered={false}
                // defaultActiveKey={['1']}
                defaultActiveKey={defaultActiveKey}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
                <Panel key="1" style={{ backgroundColor: 'white' }} header={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer' }}>
                        <strong>Rooms</strong>
                        <Space size={"middle"} className="my-3">
                            {/* <Input
                                placeHolder="Search Rooms"
                                className={`rounded rounded-4`}
                                prefix={<SearchOutlined />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setDefaultActiveKey('1');
                                }}
                                onChange={e => {
                                    e.stopPropagation();
                                    setDefaultActiveKey('1');
                                    const payloadData = {
                                        query: e.target.value,
                                    };
                                    getData(payloadData);
                                }}
                            /> */}
                            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                <Input
                                    placeHolder="Search Rooms"
                                    className="rounded rounded-4"
                                    prefix={<SearchOutlined />}
                                    value={inputValue}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setDefaultActiveKey('1');
                                    }}
                                    onChange={handleSearchChange}
                                    style={{ paddingRight: '30px' }} // Adjust padding to accommodate clear icon
                                />
                                {inputValue && (
                                    <CloseCircleOutlined
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            zIndex: 1,
                                            color: '#000', // Ensure the icon is visible
                                        }}
                                        onClick={handleClear}
                                    />
                                )}
                            </div>
                            {roleID === '2' && category_type === '3' ? null :
                                <ButtonComponent
                                    style={{ backgroundColor: "#f7931e", color: "white" }}
                                    buttonName="Add Rooms"
                                    icon={<PlusOutlined />}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setIsModalOpen(true)
                                        setDefaultActiveKey('1')
                                    }}
                                />}
                        </Space>
                    </div>
                }>
                    <div className="col-md-12">
                        <div className="row">
                            {roomsListData && roomsListData.data && roomsListData.data.length > 0 &&
                                [...roomsListData.data] // Create a shallow copy of the array
                                    .sort((a, b) => a.room_name.localeCompare(b.room_name)) // Sort by room_name in alphabetical order (A to Z)
                                    .map((item) => {
                                        return (
                                            <div className="col-md-4" key={item.id}>
                                                <RoomCards
                                                    id={item.id}
                                                    room_name={item.room_name}
                                                    totalRooms={Number(item?.item_count)}
                                                    onEdit={handleEdit}
                                                    onDelete={roleID === '2' && category_type === '3' ? null : handleDelete}
                                                    onUpdate={handleEdit}
                                                    onClick={() => { onRoomsClick(item.id) }}
                                                    isCompleted={item?.is_completed === 1 ? true : false}
                                                />
                                            </div>
                                        );
                                    })}
                        </div>
                    </div>

                </Panel>
            </Collapse>
            <Modal
                destroyOnClose
                title="Add Rooms"
                open={isModalOpen}
                onOk={handleSave}
                okText="Save"
                okButtonProps={{ shape: "round", style: { backgroundColor: "#f7931e" } }}
                cancelButtonProps={{
                    shape: "round",
                    style: { backgroundColor: "#6E6E6E", color: "white" },
                }}
                onCancel={handleCancel}

            >
                <Input
                    placeholder="Enter Room name"
                    value={addRoomName.room_name}
                    onChange={e => setAddRoomName({
                        ...addRoomName,
                        room_name: e.target.value
                    })}
                    onKeyDown={(e) => {
                        console.log(e, 'eee');
                        if (e.key === 'Enter') {
                            handleSave();
                        }
                    }}

                />
            </Modal>
        </div>
    )
}

export default EditJobWithRooms
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { statesList } from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "states";

// Async thunk for fetching the staff list
export const getStatesList = createAsyncThunk(
    `${namespace}/getStatesList`,
    async ({ }, { rejectWithValue }) => {
        try {
            const data = await statesList();
            return JSON.parse(EncryptDecrypt.decryptdata(data.data.datas, secretKey));
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const statesSlice = createSlice({
    name: namespace,
    initialState: {
        statesListData: [],
        statesListLoading: false,
        statesError: false,
        statesErrorMessage: "",
    },
    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle getStatesList
            .addCase(getStatesList.pending, (state) => {
                state.statesListLoading = true;
                state.statesError = false;
            })
            .addCase(getStatesList.fulfilled, (state, action) => {
                const data = action.payload.response;
                state.statesListData = data;
                state.statesListLoading = false;
                state.statesErrorMessage = '';
            })
            .addCase(getStatesList.rejected, (state, action) => {
                const payload = action.payload.data;
                state.statesListLoading = false;
                state.statesError = true;
                state.statesErrorMessage = "errors" in payload ? payload.errors[0].message : payload.message;
            })
    },
});

export const { resetSuccessFlag } = statesSlice.actions;

export const staffSelector = (state) => state[namespace];

export default statesSlice.reducer;

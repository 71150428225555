import React from 'react'
import Logo from '../assets/images/takeStock_logo.png'

const WelcomePage = () => {
  return (
    <div className='welcome-page'>
        <img src={Logo} alt="" />
        <span className='welcome-content'><h2>Welcome<strong className='ml-2'>Infognana</strong></h2></span>
    </div>
  )
}

export default WelcomePage
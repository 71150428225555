import React from 'react';
import { Input, InputNumber } from 'antd';
import TextArea from "antd/es/input/TextArea";


const InputComponent = ({
    name,
    placeholder,
    type,
    icon,
    value,
    onChange,
    validator,
    touchedFields,
    validationRules,
    style,
    disabled = false
}) => {
    const inputType = ["password", "confirm_password"];
    const isValid = touchedFields?.[name] && validator.current.fieldValid(name);

    return (
        <div>
            {name === "address_one" || name === "address_two" || name === "comments" ? (
                <TextArea
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    style={style}
                    value={value}
                    onChange={e => onChange(e)}
                    onBlur={() => validator.current.showMessageFor(name)}
                    suffix={icon}
                    disabled={disabled}
                />
            ) : !inputType.includes(name) ? (
                <Input
                    autoComplete="username email"
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    style={{
                        padding: "5px",
                        ...style
                    }}
                    value={value}
                    onChange={e => onChange(e)}
                    onBlur={() => validator.current.showMessageFor(name)}
                    suffix={icon}
                    disabled={disabled}
                />
            ) : (
                <Input.Password
                    autoComplete="new-password"
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    style={{
                        padding: "5px",
                        ...style
                    }}
                    value={value}
                    onChange={e => onChange(e)}
                    onBlur={() => validator.current.showMessageFor(name)}
                    suffix={icon}
                />
            )}
            {(validationRules[name] || touchedFields[name]) && (
                <div style={{ color: "red" }}>
                    {validator.current.message(name, value, validationRules[name])}
                </div>
            )}
        </div>
    );
};

export default InputComponent;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/takeStock_logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { resetPwd, loginSelector } from "../store/reducer/login";
import { API_STATUS } from "../utils/constants";

function ResetPassword() {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginDetails, setLoginDetails] = useState({ token: params?.token, new_password: "", confirm_password: "" });
    const { resetloading } = useSelector(loginSelector);
    const validator = useRef(new SimpleReactValidator());

    const onLoginSubmit = (e) => {
        e.preventDefault();

        if (validator.current.allValid()) {
            const postData = loginDetails;
            dispatch(resetPwd({ postData }));
        } else {
            validator.current.showMessages();
            // Force a re-render to display validation messages
            setLoginDetails({ ...loginDetails });
        }
    };

    useEffect(() => {
        if (resetloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Password reset successfully!");
            navigate('/');
        }
        if (resetloading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.error('Error resetting password');
            setLoginDetails({
                ...loginDetails,
                new_password: "",
                confirm_password: ""
            })
            // navigate('/resetpassword/');
        }
    }, [resetloading, navigate]);

    return (
        <div className="theme-cyan">
            <div>
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle auth-main">
                        <div className="auth-box">
                            <div className="top">
                                <img src={Logo} alt="TakeStock Logo" style={{ height: "55px", width: "auto", margin: "10px" }} />
                            </div>
                            <div className="card">
                                <div className="header">
                                    <p className="lead">Recover my password</p>
                                </div>
                                <div className="body">
                                    <p>Please enter your new password and confirm it to reset.</p>
                                    <form className="form-auth-small" onSubmit={onLoginSubmit}>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="New Password"
                                                type="password"
                                                value={loginDetails.new_password}
                                                onChange={(e) => setLoginDetails({ ...loginDetails, new_password: e.target.value })}
                                            />
                                            {validator.current.message(
                                                'new_password',
                                                loginDetails.new_password,
                                                'required|min:8|max:20|regex:^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]+$',
                                                {
                                                    className: 'text-danger', messages: {
                                                        regex: 'Password must be at least 8 characters long, and include an uppercase letter, a lowercase letter, a number, and a special character.'
                                                    }
                                                }
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                type="password"
                                                value={loginDetails.confirm_password}
                                                onChange={(e) => setLoginDetails({ ...loginDetails, confirm_password: e.target.value })}
                                            />
                                            {validator.current.message(
                                                'confirm_password',
                                                loginDetails.confirm_password,
                                                `required|in:${loginDetails.new_password}`,
                                                {
                                                    className: 'text-danger', messages: {
                                                        in: 'Confirm Password does not match.'
                                                    }
                                                }
                                            )}
                                        </div>
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">
                                            RESET PASSWORD
                                        </button>
                                        <div className="bottom">
                                            <span className="helper-text">
                                                Know your password? <a href={`${process.env.PUBLIC_URL}/`}>Login</a>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;

import { TbView360Number } from "react-icons/tb";

export const Leftmenu = () => {
    const roleID = localStorage.getItem('role_id')
    const portalUserType = localStorage.getItem('pu_type')
    const category_type = localStorage.getItem('category_type')
    const currentUrl = window.location.href;
    const demoMode = currentUrl.includes('preview')
    const projectType = localStorage.getItem('project_type')

    console.log(roleID,demoMode, 'roleID==');

    const menus = [
        { menu: "Dashboard", url: 'dashboard', container: "Dashboard Container", ischild: false, icon: "fa fa-home", child: [{ menu: "", url: '' }] },
        { menu: "Staff", url: 'staff', container: "Staff Container", ischild: false, icon: "fa fa-address-book-o", child: [{ menu: "", url: '' }] },
        { menu: "Inventory Team", url: 'photographer', container: "Inventory Team Container", ischild: false, icon: "fa fa-camera", child: [{ menu: "", url: '' }] },
        // { menu: "Users", url: 'user', container: "User Container", ischild: true, icon: "fa fa-user-circle-o", child: [{ menu: "Staff", url: 'staff', icon: "fa fa-address-book-o", container: "Staff Container" }, { menu: "Photographer", icon: "fa fa-camera-retro", url: 'photographer', container: "Photographer Container" }] },
        // { menu: "Clients", url: 'clients', container: "Client Container", ischild: true, icon: "fa fa-users", child: [{ menu: "New Client", url: 'addclient', icon: "fa fa-user", container: "Client Container" }, { menu: "Client List", icon: "fa fa-bars", url: 'clientlist', container: "Client Container" }] },
        { menu: "Clients", url: 'clients', container: "Client Container", ischild: false, icon: "fa fa-users", child: [{ menu: '', url: '' }] },
        { menu: "Projects", url: 'projects', container: "Project Container", ischild: false, icon: "fa fa-address-card-o", child: [{ menu: "", url: '' }] },
        // { menu: "Reports", url: 'reports', container: "Report Container", ischild: false, icon: "fa fa-bar-chart", child: [{ menu: "", url: '' }] },
        { menu: "Google Logs", url: 'googlelogs', container: "Logs Container", ischild: false, icon: "fa fa-bar-chart", child: [{ menu: "", url: '' }] },
    ]

    const executorUserMenu = [
        { menu: "Inventory", url: '/clientportal/executor/home', container: "Inventory Container", ischild: false, icon: "fa fa-address-book-o", child: [{ menu: "", url: '' }] },        
        // { menu: "Reports", url: '/reports', container: "Reports Container", ischild: false, icon: "fa fa-print", child: [{ menu: '', url: '' }] },
    ];
    
    const readWriteUserMenu = [
        { menu: "Home", url: '/clientportal/home', container: "Inventory Container", ischild: false, icon: "fa fa-address-book-o", child: [{ menu: "", url: '' }] },
        // { menu: "360 View", url: '/client/tour', container: "360 View Container", ischild: false, icon: "fa fa-address-book-o", child: [{ menu: "", url: '' }] },
        // { menu: "Users", url: 'user', container: "User Container", ischild: true, icon: "fa fa-user-circle-o", child: [{ menu: "Staff", url: 'staff', icon: "fa fa-address-book-o", container: "Staff Container" }, { menu: "Photographer", icon: "fa fa-camera-retro", url: 'photographer', container: "Photographer Container" }] },
        // { menu: "Clients", url: 'clients', container: "Client Container", ischild: true, icon: "fa fa-users", child: [{ menu: "New Client", url: 'addclient', icon: "fa fa-user", container: "Client Container" }, { menu: "Client List", icon: "fa fa-bars", url: 'clientlist', container: "Client Container" }] },
        // { menu: "Reports", url: '/reports', container: "Reports Container", ischild: false, icon: "fa fa-print", child: [{ menu: '', url: '' }] },
    ]

    const readOnlyUserMenu = [
        { menu: "Home", url: '/clientportal/executor/home', container: "Inventory Container", ischild: false, icon: "fa fa-address-book-o", child: [{ menu: "", url: '' }] },
        // { menu: "Reports", url: '/reports', container: "Reports Container", ischild: false, icon: "fa fa-print", child: [{ menu: '', url: '' }] },
    ];

    const managerUserMenu = [
        { menu: "Inventory", url: '/clientportal/home', container: "Inventory Container", ischild: false, icon: "fa fa-address-book-o", child: [{ menu: "", url: '' }] },
        // { menu: "Reports", url: 'clients', container: "Reports Container", ischild: false, icon: "fa fa-print", child: [{ menu: '', url: '' }] },
    ];

    const clientMenus =
       portalUserType === "1"
          ? readWriteUserMenu
          : portalUserType === "2"
          ? executorUserMenu
          : portalUserType === "3"
          ? readOnlyUserMenu
          : managerUserMenu;

    const photographerMenus = [
        { menu: "Dashboard", url: '/photographer/dashboard', container: "Dashboard Container", ischild: false, icon: "fa fa-home", child: [{ menu: "", url: '' }] },
        { menu: "360 View", url: '/tourview', container: "360 View Container", ischild: false, icon: "fa fa-address-book-o", child: [{ menu: "", url: '' }] },
    ]

    const productionStaffMenus = [
        { menu: "Dashboard", url: 'dashboard', container: "Dashboard Container", ischild: false, icon: "fa fa-home", child: [{ menu: "", url: '' }] },
        { menu: "Inventory Team", url: 'photographer', container: "Inventory Team Container", ischild: false, icon: "fa fa-camera", child: [{ menu: "", url: '' }] },
        { menu: "Clients", url: 'clients', container: "Client Container", ischild: false, icon: "fa fa-users", child: [{ menu: '', url: '' }] },
        { menu: "Projects", url: 'projects', container: "Project Container", ischild: false, icon: "fa fa-address-card-o", child: [{ menu: "", url: '' }] },
        // { menu: "Reports", url: 'reports', container: "Report Container", ischild: false, icon: "fa fa-bar-chart", child: [{ menu: "", url: '' }] },
    ]

    const demoWithoutDashboard = [
        { menu: "Inventory", url: `/preview/clientportal/home/${btoa(Number(localStorage.getItem('project_id')))}`, container: "Inventory Container", ischild: false, icon: "fa fa-address-book-o", child: [{ menu: "", url: '' }] }
    ]

    return roleID === '1' && demoMode === false ? menus 
    : demoMode === true ? demoWithoutDashboard 
    : roleID === '4' ? clientMenus : roleID === '3' ? photographerMenus : 
    roleID === '2' && category_type === '3' ? productionStaffMenus :
    menus;
};